import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  ${({ theme }) => css`
    flex: 1 1 15.625rem;

    .MuiInputBase-root {
      font-size: 14px;
    }

    .MuiFormControl-root {
      width: 100%;
    }

    .MuiInputLabel-root {
      color: ${theme.colors.gray_700};
    }

    .MuiOutlinedInput-notchedOutline {
      border: 0;
      border-bottom: 1px solid ${theme.colors.gray_300};
      border-radius: 0;
    }
  `}
`;
