import React, { HtmlHTMLAttributes } from 'react';

import * as S from './styles';

type CustomCheckboxType = {
  disabled?: boolean;
  checked: boolean;
} & HtmlHTMLAttributes<HTMLInputElement>;

export default function CustomCheckbox({
  disabled,
  checked,
  ...props
}: CustomCheckboxType) {
  return (
    <S.Input type="checkbox" checked={checked} disabled={disabled} {...props} />
  );
}
