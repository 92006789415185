import { Delete, ViewList } from '@mui/icons-material';
import {
  AppBar,
  Button,
  ButtonGroup,
  Modal,
  Toolbar,
  Typography,
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import React, { useEffect, useRef } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import {
  Control,
  Controller,
  FieldValues,
  UseFormSetValue,
} from 'react-hook-form';

import * as appStyles from '../../../AppStyle';
import { getAllSeason } from '../../../services/seasonSerie';
import ContainerLesson from '../../Drag/ContainerLesson';
import * as styles from './styles';
import { StyledBox } from './styles';

interface IModule {
  content: any;
  view: boolean;
  setValue: UseFormSetValue<FieldValues>;
  control: Control<FieldValues, any>;
}

const SeasonSection: React.FC<IModule> = ({
  content,
  view,
  setValue,
  control,
}) => {
  const [filterModalOpen, setFilterModalOpen] = React.useState<boolean>(false);
  const [selectedModules, setSelectedModules] = React.useState<any>([]);
  const [modalCollections, setModalModules] = React.useState<any>([]);

  const ordersToRefFileModules: any = useRef();
  ordersToRefFileModules.current = selectedModules;

  const fillModules = () => {
    const editingModules = content.modules;

    if (editingModules.length > 0) {
      setSelectedModules(editingModules);
    }
  };

  useEffect(() => {
    if (content != null) {
      fillModules();
    }
  }, []);

  const modulesColumns = [
    {
      headerName: 'Titulo',
      field: 'title',
      minWidth: 220,
      flex: 1,
      renderCell: (cellValues: any) => (
        <p style={{ whiteSpace: 'normal' }}>{cellValues?.value}</p>
      ),
    },
    {
      headerName: 'Descrição',
      field: 'description',
      minWidth: 220,
      flex: 1,
      renderCell: (cellValues: any) => (
        <p style={{ whiteSpace: 'normal' }}>{cellValues?.value}</p>
      ),
    },
    {
      headerName: 'Selecionar',
      field: '',
      minWidth: 180,
      flex: 1,
      renderCell: (cellValues: any) => {
        return (
          <Button
            variant="contained"
            size="small"
            onClick={() => {
              const currentModules = selectedModules;
              currentModules.push(cellValues.row);
              setSelectedModules(currentModules);
              setFilterModalOpen(false);

              const ModulesId: any[] = [];
              selectedModules.forEach((module: any) => {
                ModulesId.push(module.module_id);
              });

              setValue('modules', ModulesId);
            }}
          >
            Selecionar
          </Button>
        );
      },
    },
  ];

  React.useEffect(() => {
    getAllSeason({ is_available: true }).then((result: any) => {
      setModalModules(result.data);
    });
  }, []);

  const onChangeDeleteItem = (item: any) => () => {
    const updatedFileCollections = ordersToRefFileModules.current.filter(
      e => e.module_id !== item.module_id,
    );
    setSelectedModules(updatedFileCollections);
  };

  useEffect(() => {
    const ModulesId: any[] = [];
    selectedModules.forEach((module: any) => {
      ModulesId.push(module.module_id);
    });

    setValue('modules', ModulesId);
  }, [selectedModules]);

  return (
    <div style={{ display: 'flex', flexDirection: 'column', margin: '0.5rem' }}>
      <Typography variant="h4" style={{ marginTop: '1rem' }}>
        Temporadas *
      </Typography>
      <Controller
        name="modules"
        control={control}
        rules={{ required: selectedModules.length === 0 ? true : false }}
        render={({ field: { onChange, value }, fieldState: { error } }) => {
          return (
            <>
              <DndProvider backend={HTML5Backend}>
                <ContainerLesson
                  item={selectedModules}
                  setItem={setSelectedModules}
                  onChangeDeleteItem={onChangeDeleteItem}
                />
              </DndProvider>
              {selectedModules.length === 0 ? (
                <span
                  style={{
                    color: 'disable',
                    marginTop: '1rem',
                    marginBottom: '1rem',
                  }}
                >
                  Nenhum módulo selecionado
                </span>
              ) : (
                <></>
              )}
              {error !== undefined && selectedModules.length === 0 ? (
                <appStyles.inputLabel
                  style={{ color: 'red', marginBottom: '0.5rem' }}
                >
                  *Campo obrigatório
                </appStyles.inputLabel>
              ) : (
                <></>
              )}
            </>
          );
        }}
      />
      <ButtonGroup
        style={{ boxShadow: '0px 0px' }}
        variant="contained"
        size="small"
      >
        <Button
          disabled={view}
          startIcon={<ViewList />}
          onClick={() => setFilterModalOpen(true)}
          style={{
            marginRight: 1,
          }}
        >
          <span>Listar</span>
        </Button>
        <Button
          disabled={view}
          onClick={() => {
            setSelectedModules([]);
            setValue('modules', []);
          }}
          startIcon={<Delete />}
          style={{
            backgroundColor: '#d73925',
            marginRight: 1,
          }}
        >
          <span>Remover Todos</span>
        </Button>
      </ButtonGroup>
      <Modal
        open={filterModalOpen}
        onClose={() => setFilterModalOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{ alignItems: 'center', justifyContent: 'center' }}
      >
        <StyledBox>
          <AppBar
            position="static"
            style={{ marginBottom: '1rem' }}
            sx={{ bgcolor: '#1565c0', height: '3.5rem' }}
          >
            <Toolbar>
              <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                Lista Temporadas
              </Typography>
              <Button onClick={() => setFilterModalOpen(false)} color="inherit">
                x
              </Button>
            </Toolbar>
          </AppBar>
          <styles.dataGridHeight>
            <styles.dataGridDisplay>
              <styles.dataGridFlexGrow>
                <DataGrid
                  getRowId={row => row.module_id}
                  rowHeight={100}
                  aria-labelledby={'module_id'}
                  columns={modulesColumns}
                  rows={modalCollections}
                />
              </styles.dataGridFlexGrow>
            </styles.dataGridDisplay>
          </styles.dataGridHeight>
        </StyledBox>
      </Modal>
    </div>
  );
};

export default SeasonSection;
