import styled, { css, CSSProperties } from 'styled-components';

type Props = {
  alignItems?: 'normal' | 'center' | 'flex-start' | 'flex-end';
  justifyContent?:
    | 'space-between'
    | 'center'
    | 'flex-start'
    | 'flex-end'
    | 'space-around'
    | 'space-evenly';
  flexDirection?: 'row' | 'column' | 'column-reverse' | 'row-reverse';
  flexWrap?: 'nowrap' | 'wrap' | 'wrap-reverse';
  gap?: string;
  width?: string;
  flex?: string;
  height?: string;
} & Pick<CSSProperties, 'alignSelf' | 'padding'>;

const flexContainerModifier = {
  determinesWith: (width: string) => css`
    width: ${width};
  `,
  determinesFlex: (flex: string) => css`
    flex: ${flex};
  `,
};

export const FlexContainer = styled.div<Props>`
  ${({
    alignItems = 'flex-start',
    justifyContent = 'flex-start',
    flexDirection = 'row',
    flexWrap = 'nowrap',
    gap = '0',
    width,
    flex,
    alignSelf,
    height,
  }) => css`
    align-self: ${alignSelf};
    align-items: ${alignItems};
    display: flex;
    justify-content: ${justifyContent};
    flex-direction: ${flexDirection};
    flex-wrap: ${flexWrap};
    height: ${height};
    gap: ${gap};

    ${!!width && flexContainerModifier.determinesWith(width)}
    ${!!flex && flexContainerModifier.determinesFlex(flex)}
  `}
`;
