import { Button, TextField } from '@mui/material';
import Card from '@mui/material/Card';
import styled, { css } from 'styled-components';

type InputWrapperProps = {
  isDisabled: boolean;
};

export const Container = styled.div`
  display: flex;
  gap: 0.857rem;
  padding-top: 1rem;
`;

export const CardBottons = styled(Card)`
  flex-direction: 'row';
  display: flex;
  margin: 2rem;
  width: '100%';

  max-width: '100%';
`;

export const InputText = styled(TextField)`
  line-height: 1.42857143;
  color: #333;
  font-size: 5px;

  fieldset {
    height: 38px;
    border-color: #d2d6de;
    top: 7px;
  }
`;
export const Label = styled.label`
  display: inline-block;
  max-width: 100%;
  font-weight: 700;
  font-size: 16px;
  line-height: 1;
`;

export const Preview = styled.img`
  margin-top: auto;
  margin-bottom: auto;
  max-height: 200px;
`;

export const ButtonAddNew = styled(Button)`
  display: flex;
  margin-top: 8px;
  left: 5px;
  padding: 1px 20px;
`;

export const InputWrapper = styled.div<InputWrapperProps>`
  ${({ isDisabled }) => css`
    align-items: center;
    display: flex;
    gap: 16px;
    button {
      border: 1px solid #d2d6de;
      border-radius: 6px;
      background-color: transparent;
      cursor: ${isDisabled ? 'not-allowed' : 'pointer'};
      height: 36px;
      width: 36px;
      svg {
        width: 20px;
      }
    }
  `}
`;
