import z from 'zod';

export const categorySchema = z.object({
  category_id: z.string(),
  name: z.string(),
  description: z.string().nullable(),
  slug: z.string(),
  color: z.string().nullable(),
  cod_sebrae: z.string().nullable(),
  icon_class: z.string().nullable().nullable(),
  icon_title: z.string().nullable().nullable(),
  tooltip_title: z.string().nullable(),
  parent_id: z.string().nullable(),
  status: z.string(),
  type: z.string(),
  index: z.number().nullable(),
  image_file_id: z.string().nullable(),
  created_at: z.any(),
  updated_at: z.any(),
});
