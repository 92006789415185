import httpClient from '../http-client';

interface IPodcastsResponseDto {
  data: any[];
  mataData: any;
}

export const getPodcasts = async () => {
  const podcasts = (
    await httpClient.get<IPodcastsResponseDto>(
      `/content/admin/list?types[]=podcast`,
    )
  ).data;
  return podcasts.data;
};

export const getPodcast = async (contentId: string | null) => {
  const content = await httpClient.get(`content/${contentId}`);
  return content.data;
};
