import { z } from 'zod';

import { requiredMessage } from '../../constants/ErroMessages';

export const courseConsumptionSchema = z
  .object({
    code_integration: z
      .string({ required_error: requiredMessage })
      .nonempty(requiredMessage),
    company: z.string().nullable().optional(),
    credential: z
      .string({ required_error: requiredMessage })
      .nonempty(requiredMessage),
    date_hour_start: z.date({
      required_error: requiredMessage,
      invalid_type_error: requiredMessage,
    }),
    momento_id: z
      .string({ required_error: requiredMessage })
      .nonempty(requiredMessage)
      .regex(/([0-9])/),
    nome_realizacao: z
      .string({ required_error: requiredMessage })
      .nonempty(requiredMessage),
    course_origin_id: z
      .string({ required_error: requiredMessage })
      .nonempty(requiredMessage)
      .regex(/([0-9])/),
    portifolio_disponibilizacao_id: z
      .string({ required_error: requiredMessage })
      .nonempty(requiredMessage)
      .regex(/([0-9])/),
    theme_id: z
      .string({ required_error: requiredMessage })
      .nonempty(requiredMessage)
      .regex(/([0-9])/),
    tipo_realizacao: z
      .string({ required_error: requiredMessage })
      .nonempty(requiredMessage),
    title: z
      .string({ required_error: requiredMessage })
      .nonempty(requiredMessage),
    type: z
      .string({ required_error: requiredMessage })
      .nonempty(requiredMessage),
  })
  .superRefine((arg, ctx) => {
    const { company } = arg;
    if (company) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'CNPJ inválido',
        path: ['company'],
      });
    }
  });
