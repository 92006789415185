import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import Swal from 'sweetalert2';

import { KeyIds } from '../../../common/enum/keyIds';
import Button from '../../../components/Button/index';
import { FlexContainer } from '../../../components/FlexContainer';
import GenericDragAndDrop from '../../../components/GenericDragAndDrop';
import Loading from '../../../components/Loading';
import { createTag, getAllTags } from '../../../services/tags';
import { createTopics, getAllTopics } from '../../../services/topics';
import * as S from './styles';

type SyncCourseStageFourProps = {
  view: boolean;
  isLoading: boolean;
  onSubmit: (status) => void;
  onCancel: () => void;
  getFunc: (id: string) => Promise<void>;
  handleCloneState: React.Dispatch<React.SetStateAction<any[]>>;
};

export default function SyncCourseStageFour({
  view,
  onSubmit,
  onCancel,
  getFunc,
  isLoading,
  handleCloneState,
}: SyncCourseStageFourProps) {
  const courseId = new URLSearchParams(location.search).get('id');
  const { getValues } = useFormContext();

  useEffect(() => {
    if (courseId)
      getFunc(courseId).then(() => {
        handleCloneState(state => {
          const arr = state;
          arr[3] = getValues();

          return arr;
        });
      });
  }, [courseId]);

  if (isLoading) return <Loading />;

  return (
    <S.Form>
      <FlexContainer gap="0.875rem" flexWrap="wrap">
        <GenericDragAndDrop
          view={view}
          keyId={KeyIds.TOPIC}
          createItens={createTopics}
          getItens={getAllTopics}
          name="topics"
          label="Tópicos"
          required
        />
        <GenericDragAndDrop
          view={view}
          keyId={KeyIds.TAGS}
          createItens={createTag}
          getItens={getAllTags}
          name="tags"
          label="Tags"
          needSlug
        />
      </FlexContainer>
      <FlexContainer gap="0.875rem" flexWrap="wrap" flexDirection="row-reverse">
        <Button
          type="button"
          variant="contained"
          color="success"
          onClick={() => onSubmit('published')}
        >
          Publicar
        </Button>
        <Button
          type="button"
          variant="outlined"
          color="secondary"
          onClick={() => onSubmit('draft')}
        >
          Rascunho
        </Button>
        <Button
          type="button"
          variant="contained"
          color="warning"
          onClick={() => {
            Swal.fire({
              title: '<strong>Confirmação</strong>',
              html: 'Tem certeza que deseja ocultar o curso?',
              showCancelButton: true,
              cancelButtonText: 'Cancelar',
              focusConfirm: false,
              confirmButtonColor: '#5e72e4',
            }).then(async result => {
              if (result.isConfirmed) {
                onSubmit('hidden');
              }
            });
          }}
        >
          Ocultar
        </Button>
        <Button
          type="button"
          variant="contained"
          color="error"
          onClick={onCancel}
        >
          Cancelar
        </Button>
      </FlexContainer>
    </S.Form>
  );
}
