import { zodResolver } from '@hookform/resolvers/zod';
import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import { z } from 'zod';

import { contentExclusiveSchema } from '../../../../common/validations/contentExclusiveValidation';
import { useContentSubmit } from '../../../../hooks/index';
import { IContent } from '../../../../models/content';
import { getContentById, updateContent } from '../../../../services/contents';
import ExclusiveTemplate from '../../../../templates/ExclusiveTemplate';
import { groups, loadFileToInput } from '../../../../utils';

export type ContentExclusiveFormValues = z.infer<typeof contentExclusiveSchema>;

export default function ExclusiveEdit() {
  const [loading, setLoading] = useState(false);
  const [content, setContent] = useState<IContent>();

  const methods = useForm<ContentExclusiveFormValues>({
    resolver: zodResolver(contentExclusiveSchema),
  });

  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const getContent = async (id: string | undefined): Promise<void> => {
    if (!id) {
      Swal.fire({
        icon: 'error',
        iconColor: '#f5365c',
        title: 'Erro ao buscar o conteúdo!',
        text: 'O id passado na url é inválido',
        buttonsStyling: false,
        customClass: {
          title: 'modal-title',
          htmlContainer: 'modal-html-container',
          confirmButton: 'btn-ok',
        },
      }).then(() => navigate('/content/exclusive'));
      return;
    }

    setLoading(true);
    try {
      const { setValue } = methods;
      const resp = await getContentById(id);
      const groupsSelected = resp.groups
        ? groups.filter(group => resp.groups?.some(el => group.value === el))
        : null;
      const audioFile =
        resp.audio_file !== null
          ? await loadFileToInput(resp.audio_file)
          : resp.audio_file;

      setValue('audio_file', audioFile);
      setValue('audio_file_id', !audioFile ? null : resp.audio_file_id);
      setValue(
        'authors',
        resp.authors.map(author => author.author_id),
      );
      setValue('categories', resp.categories);
      setValue(
        'company_units',
        resp.company_units.map(company => ({
          label: company.name,
          value: company.company_unit_id,
        })),
      );
      setValue('complement_files', resp.complement_files);
      setValue(
        'complement_contents',
        resp.complement_contents.map(content => content.content_id),
      );
      setValue('description', resp.description);
      setValue('groups', groupsSelected);
      setValue('image_file', resp.image_file);
      setValue('image_file_id', resp.image_file_id);
      setValue('image_name', resp.image_name);
      setValue('image_toltip', resp.image_toltip);
      setValue('page_title', resp.page_title);
      setValue('requester_id', resp.requester_id);
      setValue('requester', {
        label: resp.requester.name,
        value: resp.requester.requester_id,
      });
      setValue('resume', resp.resume);
      setValue('review_in', new Date(resp.review_in));
      setValue('slug', resp.slug);
      setValue('subtitle', resp.subtitle);
      setValue('title', resp.title);
      setValue('visibility', resp.visibility);

      setContent(resp);
    } catch (error: any) {
      console.error(error);
      Swal.fire({
        icon: 'error',
        iconColor: '#f5365c',
        title: 'Erro ao buscar o conteúdo!',
        text: error.message || error,
        customClass: {
          confirmButton: 'btn-ok',
        },
      });
    } finally {
      setLoading(false);
    }
  };

  const submit = (data: ContentExclusiveFormValues, status: string) => {
    const { obj } = useContentSubmit({
      data,
      id: searchParams.get('id')!,
    });

    obj['company_units'] = data['company_units'].map(company => company.value);
    obj['status'] = status;
    obj['type'] = 'exclusive';

    updateContent(obj)
      .then(() => {
        Swal.fire({
          title: 'Conteúdo atualizado com sucesso',
          icon: 'success',
          customClass: {
            title: 'modal-title',
            htmlContainer: 'modal-html-container',
            confirmButton: 'btn-ok',
          },
          buttonsStyling: false,
        }).then(() => navigate(-1));
      })
      .catch(error => {
        Swal.fire({
          icon: 'error',
          title: 'Algo deu errado',
          iconColor: '#f5365c',
          text: error.message,
          customClass: {
            title: 'modal-title',
            htmlContainer: 'modal-html-container',
            confirmButton: 'btn-ok',
          },
          buttonsStyling: false,
        });
      });
  };

  useEffect(() => {
    getContent(searchParams.get('id')!);
  }, []);

  return (
    <FormProvider {...methods}>
      <ExclusiveTemplate
        title="Edição do Conteúdo Exclusivo"
        view={searchParams.get('view') === 'true'}
        content={content}
        isLoading={loading}
        submit={submit}
      />
    </FormProvider>
  );
}
