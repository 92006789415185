import { AddCircle, Delete, RotateLeft } from '@mui/icons-material';
import { Box, Checkbox, Typography } from '@mui/material';
import React from 'react';
import { Controller } from 'react-hook-form';

import * as appStyles from '../../AppStyle';
import { hideModal, showModal } from '../../helpers/modal';
import MediaFromResponse from '../../models/from-api-response/media';
import { putLessonDuration } from '../../services/lessons';
import { getMedia } from '../../services/medias';
import Button from '../Button';
import SelectSambaVideosContent from '../SelectSambaVideosContent';
import * as S from './styles';

interface Imedia {
  id: string;
}
interface ImediaObject {
  media: Imedia;
}
export default function SambaMainFile(props: any) {
  const [fileName, setFileName] = React.useState<string>();
  const [allowDownload, setAllowDownload] = React.useState<boolean>(false);

  const onSelectContent = (sambaVideosContent: MediaFromResponse) => {
    if (sambaVideosContent) {
      if (sambaVideosContent.files && sambaVideosContent.files.length) {
        const firstFile = sambaVideosContent.files[0];

        if (firstFile) {
          const playerKey = process.env.REACT_APP_PLAYER_KEY;
          const referenceUrl = `${process.env.REACT_APP_PLAYER_INITIAL_URL}/${playerKey}/${sambaVideosContent.id}`;

          setFileName(firstFile.fileName);

          if (typeof sambaVideosContent.id === 'string') {
            getMedia(sambaVideosContent.id).then((data: any) => {
              if (props?.typeDuration !== 'course') {
                props.setValue('duration', data.files[0].fileInfo.duration);
              }
              props.setValue('reference', referenceUrl);
              if (props.type === 'video') {
                props.setValue('file_name', firstFile.fileName);
                props.setValue('allow_download', allowDownload);
              }
              if (props.type === 'lesson' || props.type === 'sync-course') {
                props.setValue('file_name', firstFile.fileName);
              }
            });
          }
        }
      }
    }
    hideModal();
  };

  const selectContent = () => {
    showModal(
      'Selecionar Conteúdo',
      <SelectSambaVideosContent
        mediaType={props?.label?.toUpperCase()}
        onSelectContent={onSelectContent}
        setValue={props.setValue}
        allowDownload={allowDownload}
        setFileName={setFileName}
        type={props.type}
      />,
    );
  };

  const reloadDuration = async (lessonId: string) => {
    const response = await putLessonDuration(lessonId);

    if (response.duration !== 0) {
      props.setValue('duration', response.duration);
    }
  };

  React.useEffect(() => {
    if (props.content) {
      props.setValue('duration', props.content.duration);
      props.setValue('reference', props.content.reference);
      props.setValue('file_name', props.content.file_name);
      props.setValue('file_toltip', props.content.file_toltip);
      setFileName(props.content.file_name);
      if (hasAllowDownload(props.type)) {
        props.setValue('allow_download', props.content.allow_download);
      }
      setAllowDownload(props.content.allow_download);
    }
  }, [props]);

  function hasAllowDownload(type: string) {
    return type && type === 'video';
  }

  const title = props.title ? props.title : 'Arquivo Principal';

  const handleRemoveMainFile = () => {
    props.setValue('file_id', null);
    props.setValue('file_name', null);
    props.setValue('file_toltip', null);
    props.setValue('duration', null);
    props.setValue('reference', undefined);
    setFileName('');
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
      <Typography variant="h4" style={{ marginBottom: '1rem' }}>
        {props.label ? props.label : title}
      </Typography>
      <Box
        component="div"
        sx={{
          '& .MuiTextField-root': { width: '100%' },
        }}
      >
        <S.Label>Arquivo</S.Label>
        <Controller
          name="lessons"
          control={props.control}
          rules={{
            required: props.type === 'lesson' && !fileName ? true : false,
          }}
          render={({ field: { onChange, value }, fieldState: { error } }) => {
            return (
              <>
                <S.InputWrapper isDisabled={props.getValues('duration')}>
                  <S.InputText
                    name="file"
                    value={fileName ? fileName : 'Nenhum arquivo selecionado'}
                    disabled
                    required
                    id="outlined-required"
                  />
                  <button
                    type="button"
                    onClick={() => reloadDuration(props.content.lesson_id)}
                    disabled={props.getValues('duration')}
                  >
                    <RotateLeft />
                  </button>
                </S.InputWrapper>

                {error !== undefined &&
                  props.type === 'lesson' &&
                  !fileName && (
                    <appStyles.inputLabel
                      style={{ color: 'red', marginBottom: '0.5rem' }}
                    >
                      *Campo obrigatório
                    </appStyles.inputLabel>
                  )}
              </>
            );
          }}
        />
      </Box>

      <S.Container>
        {!!fileName && (
          <Button
            type="button"
            onClick={handleRemoveMainFile}
            startIcon={<Delete />}
            variant="outlined"
            color="secondary"
          >
            <span>Deletar</span>
          </Button>
        )}
        <Button
          type="button"
          startIcon={<AddCircle />}
          onClick={selectContent}
          variant="contained"
        >
          <a> Adicionar novo</a>
        </Button>
      </S.Container>

      {hasAllowDownload(props.type) && (
        <Box
          component="div"
          sx={{
            '& .MuiTextField-root': { m: 0, width: '100%' },
          }}
        >
          <S.Label>Permitir Download</S.Label>
          <Controller
            name="allow_download"
            control={props.control}
            render={({ field: { onChange, value }, fieldState: { error } }) => {
              return (
                <>
                  <Checkbox
                    checked={allowDownload}
                    value={allowDownload}
                    onChange={(event: any) => {
                      setAllowDownload(event.target.checked);
                      onChange(event);
                      props.setValue('allow_download', event.target.checked);
                    }}
                  />
                </>
              );
            }}
          />
        </Box>
      )}
    </div>
  );
}
