import { zodResolver } from '@hookform/resolvers/zod';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import { z } from 'zod';

import { ContentStatusType } from '../../../../common/types/contentStatusType';
import { kitsTematicSchema } from '../../../../common/validations/kitsTematicValidation';
import { useContentSubmit } from '../../../../hooks';
import { createContent } from '../../../../services/contents';
import KitsTematicsTemplate from '../../../../templates/KitsTematicsTemplate';
import { groups } from '../../../../utils';

export type KitsTematicFromValues = z.infer<typeof kitsTematicSchema>;

export default function KitsTematicCreate() {
  const methods = useForm<KitsTematicFromValues>({
    resolver: zodResolver(kitsTematicSchema),
    defaultValues: {
      visibility: 'restricted',
      groups: groups,
    },
  });
  const navigate = useNavigate();

  const submit = (data: KitsTematicFromValues, status: ContentStatusType) => {
    const { obj } = useContentSubmit({ data });

    if (data['antecipation'])
      obj['antecipation_groups'] = data['antecipation_groups']?.map(
        group => group.value,
      );
    if (data['topics'] !== undefined || data['topics'] !== null)
      obj['topics'] = data['topics']?.map(topic => topic.topic_id);

    obj['company_units'] = data['company_units'].map(company => company.value);
    obj['company_sizes'] = data['company_sizes'].map(company => company.value);
    obj['tags'] = data['tags'].map(tag => tag.tag_id);
    obj['status'] = status;
    obj['type'] = 'thematic_kit';

    createContent(obj)
      .then(() => {
        Swal.fire({
          title: 'Curso atualizado com sucesso',
          icon: 'success',
          customClass: {
            title: 'modal-title',
            htmlContainer: 'modal-html-container',
            confirmButton: 'btn-ok',
          },
          buttonsStyling: false,
        }).then(() => navigate(-1));
      })
      .catch(error => {
        Swal.fire({
          icon: 'error',
          title: 'Algo deu errado',
          iconColor: '#f5365c',
          text: error.message,
          customClass: {
            title: 'modal-title',
            htmlContainer: 'modal-html-container',
            confirmButton: 'btn-ok',
          },
          buttonsStyling: false,
        });
      });
  };

  return (
    <FormProvider {...methods}>
      <KitsTematicsTemplate
        title="Criação de um kit temático"
        view={false}
        submit={submit}
      />
    </FormProvider>
  );
}
