import { MenuItem, Select, TextField } from '@mui/material';
import Card from '@mui/material/Card';
import styled from 'styled-components';

export const dataGridHeight = styled.div`
  height: 85%;
  width: 100%;
`;
export const dataGridDisplay = styled.div`
  display: flex;
  height: 100%;
`;

export const dataGridFlexGrow = styled.div`
  flex-grow: 1;
  width: 100%;
`;

export const Container = styled.div`
  .extra-materials {
    display: flex;

    background: var(--primary-color);
    color: white;
    outline: none;
    border: none;
    cursor: pointer;
    padding: 15px 20px;
    font-size: var(--font-small);
    font-weight: bold;
    border-radius: 5px;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.08),
      0px 4px 6px rgba(50, 50, 93, 0.1);
  }
`;

export const CardInput = styled(Card)`
  margin: 2rem;
  width: '100%';
  max-width: '100%';
`;

export const InputText = styled(TextField)`
  line-height: 1.42857143;
  color: #333;
  font-size: 5px;

  fieldset {
    height: 38px;
    border-color: #d2d6de;
    top: 7px;
  }
`;
export const Label = styled.label`
  display: inline-block;
  max-width: 100%;

  font-weight: 700;
  font-size: 14px;
  line-height: 1;
`;

export const ContentSeletor = styled.div`
  padding: 0px 22px;
  margin-bottom: 22px;
`;

export const SelectorSelect = styled(Select)`
  height: 38px;
  border-color: #d2d6de;
  top: 7px;
  right: 5px;
`;

export const MenuItemSelector = styled(MenuItem)`
  font-size: 14px;
`;
