import React from 'react';

import * as S from './styles';

type PillsProps = {
  children: React.ReactNode | string;
} & S.WrapperProps;

export default function Pills({ children, color }: PillsProps) {
  return (
    <S.Wrapper color={color}>
      <S.Label>{children}</S.Label>
    </S.Wrapper>
  );
}
