import { removeTraitPoints } from './mask';

export function validateCPF(cpf: string): boolean {
  const cleanedCPF = removeTraitPoints(cpf)!;

  if (cleanedCPF.length !== 11) {
    return false;
  }

  if (/^(\d)\1{10}$/.test(cleanedCPF)) {
    return false;
  }

  let sum = 0;
  for (let i = 1; i <= 9; i++) {
    sum += parseInt(cleanedCPF[i - 1]) * (11 - i);
  }
  let verificationDigit1 = (sum * 10) % 11;
  if (verificationDigit1 === 10) {
    verificationDigit1 = 0;
  }

  sum = 0;
  for (let i = 1; i <= 10; i++) {
    sum += parseInt(cleanedCPF[i - 1]) * (12 - i);
  }
  let verificationDigit2 = (sum * 10) % 11;
  if (verificationDigit2 === 10) {
    verificationDigit2 = 0;
  }

  if (
    verificationDigit1 === parseInt(cleanedCPF[9]) &&
    verificationDigit2 === parseInt(cleanedCPF[10])
  ) {
    return true;
  }

  return false;
}
