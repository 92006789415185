import { Delete, HideSource, Save, SaveAs } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';

import Button from '../Button/index';
import * as S from './styles';

interface IBasicButtonGroup {
  content?: any;
  view: any;
  handleSubmit: any;
  setValue?: any;
  status?: any;
  notShowCancelModal?: boolean;
  closeModal?: any;
  disabledButtons?: boolean;
  isDataSul?: boolean;
  isExclusive?: boolean;
  type?: string;
}
export default function BasicButtonGroup(props: IBasicButtonGroup) {
  const navigate = useNavigate();
  const {
    view,
    setValue,
    status,
    handleSubmit,
    notShowCancelModal,
    closeModal,
    disabledButtons,
    type,
    content,
    isDataSul,
    isExclusive = false,
  } = props;

  const returnPage = async () => {
    if (notShowCancelModal) {
      closeModal();
    } else {
      Swal.fire({
        title: '<strong>Confirmação</strong>',
        html: 'Tem certeza que deseja cancelar as alterações?',
        showCancelButton: true,
        cancelButtonText: 'Cancelar',
        focusConfirm: false,
      }).then(async result => {
        if (result.isConfirmed) {
          navigate(-1);
        }
      });
    }
  };

  const warningMessageToHide = {
    lesson:
      'Tem certeza que deseja ocultar a aula? A mesma está vinculada a um módulo.',
    module:
      'Tem certeza que deseja ocultar o módulo? O mesmo está vinculado a um curso.',
    course_sync: 'Tem certeza que deseja ocultar o curso?',
  };

  return (
    <S.Wrapper>
      <Button
        disabled={type === 'course' ? !isDataSul : view}
        startIcon={<Save />}
        color="blue"
        variant="contained"
        type="button"
        onClick={() => {
          handleSubmit('published');
        }}
      >
        <span>Publicar</span>
      </Button>
      {isExclusive && (
        <Button
          startIcon={<Save />}
          color="orange"
          variant="contained"
          type="button"
          onClick={() => {
            handleSubmit('exclusive_series');
          }}
        >
          <span>Publicar como Exclusivo</span>
        </Button>
      )}
      <Button
        disabled={view || disabledButtons}
        startIcon={<SaveAs />}
        type="button"
        variant="outlined"
        onClick={() => {
          handleSubmit('draft');
        }}
      >
        <span>Salvar Rascunho</span>
      </Button>
      <Button
        disabled={view}
        startIcon={<HideSource />}
        color="info"
        type="button"
        variant="contained"
        onClick={() => {
          if (
            (type === 'lesson' && content.module_id) ||
            (type === 'module' && content.content_id) ||
            type === 'course_sync'
          ) {
            Swal.fire({
              title: '<strong>Confirmação</strong>',
              html: warningMessageToHide[type],
              showCancelButton: true,
              cancelButtonText: 'Cancelar',
              focusConfirm: false,
              confirmButtonColor: '#5e72e4',
            }).then(async result => {
              if (result.isConfirmed) {
                handleSubmit('hidden');
              }
            });
          } else {
            handleSubmit('hidden');
          }
        }}
      >
        <span>Ocultar</span>
      </Button>
      <Button
        disabled={view}
        startIcon={<Delete />}
        color="secondary"
        type="button"
        variant="contained"
        onClick={() => {
          returnPage();
        }}
      >
        <span>Cancelar</span>
      </Button>
    </S.Wrapper>
  );
}
