import { KeyboardArrowRight, ViewComfy } from '@mui/icons-material';
import Collapse from '@mui/material/Collapse';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import type listMenuItemProps from '../../../@types/menuItem';
import { FlexContainer } from '../../FlexContainer';
import MenuItem from '../MenuItem';
import * as S from './styles';

interface ListMenuItemProps {
  listMenuItems?: listMenuItemProps[];
  label: string;
  icon?: JSX.Element;
  isContent?: boolean;
  isOpen?: boolean;
  isExpanded?: boolean;
  navigateTo?: string;
  handleOpenMenu?: (label: string) => void;
}

export default function ListMenuItem({
  listMenuItems,
  label,
  icon,
  navigateTo,
  isOpen = false,
  isExpanded = false,
  handleOpenMenu,
}: ListMenuItemProps) {
  const navigate = useNavigate();

  return (
    <li>
      {listMenuItems?.length ? (
        <>
          <FlexContainer data-testid="list-menu-item">
            <S.ItemButton
              isOpen={isOpen}
              isExpanded={isExpanded}
              onClick={() => {
                handleOpenMenu && handleOpenMenu(label);
              }}
            >
              {icon || <ViewComfy />}
              <span>{label}</span>
              {listMenuItems !== undefined && (
                <KeyboardArrowRight
                  className="expansion-indicator"
                  data-testid="expandLess"
                  onClick={() => handleOpenMenu && handleOpenMenu(label)}
                />
              )}
            </S.ItemButton>
          </FlexContainer>

          <Collapse in={isOpen} timeout="auto" unmountOnExit>
            {listMenuItems?.map((menuItem, index) => {
              return <MenuItem key={index} {...menuItem} index={index} />;
            })}
          </Collapse>
        </>
      ) : (
        <FlexContainer data-testid="list-menu-item">
          <S.ItemButton
            isOpen={isOpen}
            isExpanded={isExpanded}
            onClick={() => {
              handleOpenMenu && handleOpenMenu(label);
              navigate(navigateTo || '/');
            }}
          >
            {icon || <ViewComfy />}
            <span>{label}</span>
          </S.ItemButton>
        </FlexContainer>
      )}
    </li>
  );
}
