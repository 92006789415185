import styled, { css } from 'styled-components';

type WrapperProps = {
  thereIsError: boolean;
};

export const Wrapper = styled.div<WrapperProps>`
  ${({ theme, thereIsError }) => css`
    background-color: ${theme.colors.white};
    border: 1px solid;
    border-color: ${thereIsError ? theme.colors.red_400 : 'hsl(0, 0%, 80%)'};
    border-radius: ${theme.border.radius};
    box-shadow: 0 3px 2px rgba(233, 236, 239, 0.05);
    color: ${theme.colors.neutral_600};
    font-size: ${theme.font.sizes.xsmall};
    padding: 0.625rem 0.75rem;
    transition: all 0.15s ease-in-out;
    position: relative;
    width: 100%;

    :focus {
      border-color: ${thereIsError
        ? theme.colors.red_400
        : theme.colors.default};
      box-shadow: 0 0 0 1px
          ${thereIsError ? theme.colors.red_400 : theme.colors.default},
        3px 4px 8px rgba(94, 114, 228, 0.1);
      outline: 0;
    }

    :hover {
      border-color: hsl(0, 0%, 70%);
    }

    :disabled {
      background-color: ${theme.colors.gray_200};
    }

    ::placeholder {
      color: ${theme.colors.gray_600};
      font-size: ${theme.font.sizes.xsmall};
      font-weight: ${theme.font.light};
      font-style: italic;
      line-height: 1.5;
    }

    & > ::-webkit-file-upload-button {
      border: 0;
      border-radius: ${theme.border.radius};
      background-color: ${theme.colors.blue_400};
      color: ${theme.colors.white};
      cursor: pointer;
      font-size: ${theme.font.sizes.xxsmall};
      font-weight: 600;
      padding: 4px;
    }

    & > button {
      background-color: transparent;
      border: 0;
      bottom: 17.6px;
      color: #8f95b2;
      cursor: pointer;
      font-size: 12px;
      height: 0.75rem;
      position: absolute;
      right: 10px;
    }
  `}
`;

export const InvalidFeedback = styled.span`
  ${({ theme }) => css`
    color: ${theme.colors.red_400};
    font-size: 0.75rem;
    margin-top: -0.5rem;
  `}
`;
