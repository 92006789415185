import styled, { css, DefaultTheme } from 'styled-components';

import { InputProps } from '../Input/index';
import { TextFieldProps } from '.';

export type WrapperProps = {
  direction?: 'row' | 'row-reverse' | 'column' | 'column-reverse';
  withIcon?: boolean;
  thereIsError?: boolean;
  standard?: boolean;
  flex?: string;
};

const wrapperModifier = {
  standard: () => css`
    gap: 0.875rem;
  `,
  withIcon: (theme: DefaultTheme, thereIsError: boolean) => css`
    border: 1px solid;
    border-color: ${thereIsError ? theme.colors.red_400 : 'hsl(0, 0%, 80%)'};
    border-radius: 0.25rem;
    box-shadow: 0 3px 2px rgba(233, 236, 239, 0.05);
    transition: all 0.15s ease-in-out;
    flex: 1;
    flex-wrap: nowrap;
    gap: 0;
    overflow: hidden;
    &:focus-within {
      border-color: ${thereIsError
        ? theme.colors.red_400
        : theme.colors.default};
      box-shadow: 0 0 0 1px
          ${thereIsError ? theme.colors.red_400 : theme.colors.default},
        3px 4px 8px rgba(94, 114, 228, 0.1);
      outline: 0;
    }
    &:hover {
      border-color: hsl(0, 0%, 70%);
    }
    input:disabled {
      background-color: ${theme.colors.gray_200};
    }
    span {
      color: ${theme.colors.neutral_600};
      font-size: ${theme.font.sizes.xsmall};
    }
  `,
};
export const Wrapper = styled.div<WrapperProps>`
  ${({
    theme,
    direction = 'column',
    withIcon,
    thereIsError,
    standard,
    flex,
  }) => css`
    align-items: ${direction.includes('row') ? 'center' : 'flex:start'};
    display: flex;
    flex-direction: ${direction};
    gap: 0.875rem;
    position: relative;
    width: 100%;
    flex: ${flex};

    ${!!standard && wrapperModifier.standard()};
    ${!!withIcon && wrapperModifier.withIcon(theme, thereIsError!)};
  `}
`;

type LabelProps = Pick<TextFieldProps, 'required' | 'color'>;

const labelModifiers = {
  true: (theme: DefaultTheme) => css`
    &::after {
      content: ' *';
      color: ${theme.colors.red_400};
    }
  `,
};

export const Label = styled.label<LabelProps>`
  ${({ theme, required, color }) => css`
    color: ${color === undefined ? theme.colors.gray_700 : theme.colors[color]};
    font-size: ${theme.font.sizes.xsmall};
    font-weight: ${theme.font.semiBold};

    ${!!required && labelModifiers.true(theme)};
  `}
`;

export const InvalidFeedback = styled.span`
  ${({ theme }) => css`
    color: ${theme.colors.red_400};
    font-size: 0.75rem;
    margin-top: -0.5rem;
  `}
`;

type ContainerProps = Pick<InputProps, 'thereIsError'>;

export const Container = styled.div<ContainerProps>`
  ${({ theme, thereIsError }) => css`
    align-items: center;
    border-right: 1px solid;
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
    border-color: ${thereIsError ? theme.colors.red_400 : 'hsl(0, 0%, 80%)'};
    display: flex;
    flex: 1;
    gap: 0.25rem;
    padding: 8px 16px;
    margin-right: 0.625rem;
    transition: all 0.15s ease-in-out;
    z-index: 1;
    svg,
    label {
      color: ${thereIsError ? theme.colors.red_400 : theme.colors.neutral_200};
    }
    svg {
      font-size: 1.5rem;
    }
    label {
      display: none;
    }
    @media (min-width: 600px) {
      min-width: 7.6rem;
      label {
        display: block;
      }
    }
  `}
`;
