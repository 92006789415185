import httpClient from '../http-client';
import {
  ILessonSync,
  ILessonSyncParams,
} from '../models/synchronous/lessonSync';

interface ILessonSyncResponse {
  data: ILessonSync[];
  metaData: {
    total: number;
    limit?: number;
    offset?: number;
    search?: string;
  };
}

export const getAllLessonSyncs = async (params?: ILessonSyncParams) => {
  const lessons = (
    await httpClient.get<ILessonSyncResponse>('/lesson-sync', { params })
  ).data;
  return lessons;
};

export const getLessonSync = async (lessonId: string) => {
  const lesson = (await httpClient.get<ILessonSync>(`/lesson-sync/${lessonId}`))
    .data;

  return lesson;
};

export const editLessonSync = async (id: string | null, data: ILessonSync) => {
  await httpClient.put(`/lesson-sync/${id}`, data);
};

export const createLessonSync = async (body: ILessonSync) => {
  const lesson = (await httpClient.post<ILessonSync>('/lesson-sync', body))
    .data;

  return lesson;
};

export const deleteLessonSync = async (lessonId: string) => {
  await httpClient.delete(`/lesson-sync/${lessonId}`);
};
