import {
  IGetAuthorResponse,
  IListAuthorResponse,
} from '../common/interfaces/author';
import httpClient from '../http-client';
import { IAuthor, IAuthorParams } from '../models/author';
import { uploadFile } from './files';

const getAuthor = async (authorId: string | null) => {
  const author = await httpClient.get<IAuthor>(`authors/${authorId}`);
  return author.data;
};

const getAllAuthors = async (params?: IAuthorParams) => {
  const authors = (
    await httpClient.get<IGetAuthorResponse>(`/authors`, { params })
  ).data;

  return authors;
};

export const listAuthors = async (params?: IAuthorParams) => {
  const authors = (
    await httpClient.get<IListAuthorResponse>('/authors/admin/list', { params })
  ).data;

  return authors;
};

const createAuthor = async (newAuthor: any) => {
  const createAuthorResponse = (
    await httpClient.post<any>(`authors`, newAuthor)
  ).data;
  return createAuthorResponse;
};

const updateAuthor = async (authorId: string, item: any) => {
  await httpClient.put(`authors/${authorId}`, item);
};

const deleteAuthor = async (contentId: string) => {
  await httpClient.delete(`authors/${contentId}`);
};

const changeAuthorThumbnail = async (authorId: string, formData: FormData) => {
  const uploadedFile = await uploadFile(formData);
  await httpClient.patch(`authors/${authorId}/avatar`, {
    avatar_url: uploadedFile.reference,
  });
};

const activateOrInactivateContent = async (
  contentId: string,
  activate: boolean,
) => {
  await httpClient.put(`contents/${contentId}/active`, { is_active: activate });
};

export {
  activateOrInactivateContent,
  changeAuthorThumbnail,
  createAuthor,
  deleteAuthor,
  getAllAuthors,
  getAuthor,
  updateAuthor,
};
