import { zodResolver } from '@hookform/resolvers/zod';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import Swal from 'sweetalert2';

import { moderatorSchema } from '../../../common/validations/moderatorValidation';
import { IMinimalUser } from '../../../models/user';
import { ModeratorFormValues } from '../../../pages/Users/ModeratorCreate';
import { createUser } from '../../../services/users';
import ModeratorTemplate from '../../../templates/ModeratorTemplate';
import { removeTraitPoints } from '../../../utils';
import SimpleModal from '..';

type RegisterUserModalProps = {
  isOpen: boolean;
  onCloseChange: () => void;
  resgisteredNewUser: () => void;
  handleUserSelected: (userId: IMinimalUser) => void;
};

export default function RegisterUserModal({
  isOpen,
  onCloseChange,
  resgisteredNewUser,
  handleUserSelected,
}: RegisterUserModalProps) {
  const methods = useForm<ModeratorFormValues>({
    resolver: zodResolver(moderatorSchema),
  });

  const handleSubmit = async (data: ModeratorFormValues) => {
    data['cpf'] = removeTraitPoints(data['cpf'])!;

    await createUser(data)
      .then(response => {
        Swal.fire({
          title: 'Usuário criado com sucesso',
          icon: 'success',
          customClass: {
            title: 'modal-title',
            htmlContainer: 'modal-html-container',
            confirmButton: 'btn-ok',
          },
          buttonsStyling: false,
        }).then(() => {
          handleUserSelected([response] as unknown as IMinimalUser);
          resgisteredNewUser();
          onCloseChange();
        });
      })
      .catch(error => {
        console.error(error.response.data.message);
        Swal.fire({
          icon: 'error',
          title: 'Algo deu errado ao criar o usuário',
          iconColor: '#f5365c',
          text: error.response.data.message,
          customClass: {
            title: 'modal-title',
            htmlContainer: 'modal-html-container',
            confirmButton: 'btn-ok',
          },
          buttonsStyling: false,
        }).then(value => {
          if (value.isConfirmed) onCloseChange();
        });
      });
  };

  const handleCancel = () => {
    Swal.fire({
      icon: 'question',
      title: 'Confirmação',
      html: 'Tem certeza que deseja cancelar o cadastro?',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      reverseButtons: true,
      focusConfirm: true,
      customClass: {
        title: 'modal-title',
        htmlContainer: 'modal-html-container',
        confirmButton: 'btn-ok',
        cancelButton: 'btn-cancel',
      },
      buttonsStyling: false,
    }).then(async result => {
      if (result.isConfirmed) {
        methods.reset();
        onCloseChange();
      }
    });
  };

  return (
    <SimpleModal
      isOpen={isOpen}
      onCloseChange={onCloseChange}
      title="Cadastrar usuário"
    >
      <FormProvider {...methods}>
        <ModeratorTemplate submit={handleSubmit} cancel={handleCancel} />
      </FormProvider>
    </SimpleModal>
  );
}
