import { zodResolver } from '@hookform/resolvers/zod';
import React, { useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Swal from 'sweetalert2';

import { ContentStatusType } from '../../../../common/types/contentStatusType';
import { kitsTematicSchema } from '../../../../common/validations/kitsTematicValidation';
import { useContentSubmit } from '../../../../hooks';
import { IContent } from '../../../../models/content';
import { updateContent } from '../../../../services/contents';
import { getKitsTematic } from '../../../../services/kitsTematics';
import KitsTematicsTemplate from '../../../../templates/KitsTematicsTemplate';
import { groups, loadFileToInput } from '../../../../utils';
import { KitsTematicFromValues } from '../KitsTematicCreate';

export default function KitsTematicEdit() {
  const [loading, setLoading] = useState(false);
  const [content, setContent] = useState<IContent>();

  const methods = useForm<KitsTematicFromValues>({
    resolver: zodResolver(kitsTematicSchema),
  });
  const [searchParams] = useSearchParams();
  const id = searchParams.get('id');
  const navigate = useNavigate();
  const isView = useMemo(() => !!searchParams.get('view'), [searchParams]);

  const getContent = async (id: string | null): Promise<void> => {
    if (!id) {
      Swal.fire({
        icon: 'error',
        iconColor: '#f5365c',
        title: 'Erro ao buscar o conteúdo!',
        text: 'O id passado na url é inválido',
        buttonsStyling: false,
        customClass: {
          title: 'modal-title',
          htmlContainer: 'modal-html-container',
          confirmButton: 'btn-ok',
        },
      }).then(() => navigate('/content/article'));
      return;
    }

    setLoading(true);

    try {
      const { setValue } = methods;
      const resp = await getKitsTematic(id);
      const antecipationGroupsSelected = resp.antecipation_groups
        ? groups.filter(group =>
            resp.antecipation_groups?.some(el => group.value === el),
          )
        : null;
      const groupsSelected = resp.groups
        ? groups.filter(group => resp.groups?.some(el => group.value === el))
        : null;
      const audioFile =
        resp.audio_file !== null
          ? await loadFileToInput(resp.audio_file)
          : resp.audio_file;

      setValue('antecipation', resp.antecipation || false);
      setValue('antecipation_days', resp.antecipation_days?.toString());
      setValue('antecipation_groups', antecipationGroupsSelected);
      setValue('audio_file', audioFile);
      setValue('audio_file_id', !audioFile ? null : resp.audio_file_id);
      setValue(
        'authors',
        resp.authors.map(author => author.author_id),
      );
      setValue('categories', resp.categories);
      setValue(
        'company_sizes',
        resp.company_sizes.map(company => ({
          label: company.name,
          value: company.company_size_id,
        })),
      );
      setValue(
        'company_units',
        resp.company_units.map(company => ({
          label: company.name,
          value: company.company_unit_id,
        })),
      );
      setValue('complement_files', resp.complement_files);
      setValue('description', resp.description);
      setValue(
        'featured_end_at',
        !resp.featured_end_at ? null : new Date(resp.featured_end_at),
      );
      setValue(
        'featured_start_at',
        !resp.featured_start_at ? null : new Date(resp.featured_start_at),
      );
      setValue('file_name', resp.file_name);
      setValue('groups', groupsSelected);
      setValue(
        'highlight',
        Boolean(resp.featured_end_at) && Boolean(resp.featured_start_at),
      );
      setValue('image_file', resp.image_file);
      setValue('image_file_id', resp.image_file_id);
      setValue('image_name', resp.image_name);
      setValue('image_toltip', resp.image_toltip);
      setValue('metatags', resp.metatags);
      setValue('page_title', resp.page_title);
      setValue(
        'published_end_at',
        !resp.published_end_at ? null : new Date(resp.published_end_at),
      );
      setValue(
        'published_start_at',
        !resp.published_start_at ? null : new Date(resp.published_start_at),
      );
      setValue('requester', {
        label: resp.requester.name,
        value: resp.requester.requester_id,
      });
      setValue('requester_id', resp.requester_id);
      setValue('resume', resp.resume);
      setValue('review_in', new Date(resp.review_in));
      setValue('slug', resp.slug);
      setValue('subtitle', resp.subtitle);
      setValue('tags', resp.tags);
      setValue('title', resp.title);
      setValue('topics', resp.topics);
      setValue('visibility', resp.visibility);

      setContent(resp);
    } catch (error: any) {
      console.error(error);
      Swal.fire({
        icon: 'error',
        iconColor: '#f5365c',
        title: 'Erro ao buscar o conteúdo!',
        text: error.message || error,
        customClass: {
          confirmButton: 'btn-ok',
        },
      });
    } finally {
      setLoading(false);
    }
  };

  const submit = (data: KitsTematicFromValues, status: ContentStatusType) => {
    const { obj } = useContentSubmit({ data });

    if (data['antecipation'])
      obj['antecipation_groups'] = data['antecipation_groups']?.map(
        group => group.value,
      );
    if (data['topics'] !== undefined || data['topics'] !== null)
      obj['topics'] = data['topics']?.map(topic => topic.topic_id);

    obj['company_units'] = data['company_units'].map(company => company.value);
    obj['company_sizes'] = data['company_sizes'].map(company => company.value);
    obj['tags'] = data['tags'].map(tag => tag.tag_id);
    obj['status'] = status;
    obj['type'] = 'thematic_kit';
    obj['content_id'] = id;

    updateContent(obj)
      .then(() => {
        Swal.fire({
          title: 'Conteúdo atualizado com sucesso',
          icon: 'success',
          customClass: {
            title: 'modal-title',
            htmlContainer: 'modal-html-container',
            confirmButton: 'btn-ok',
          },
          buttonsStyling: false,
        }).then(() => navigate(-1));
      })
      .catch(error => {
        Swal.fire({
          icon: 'error',
          title: 'Algo deu errado',
          iconColor: '#f5365c',
          text: error.message,
          customClass: {
            title: 'modal-title',
            htmlContainer: 'modal-html-container',
            confirmButton: 'btn-ok',
          },
          buttonsStyling: false,
        });
      });
  };

  useEffect(() => {
    getContent(id);
  }, []);

  return (
    <FormProvider {...methods}>
      <KitsTematicsTemplate
        title="Edição de um e-book"
        view={isView}
        submit={submit}
        content={content}
        isLoading={loading}
      />
    </FormProvider>
  );
}
