import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.875rem;
  height: 100%;
  max-width: 90vw;
`;

export const Label = styled.label`
  ${({ theme }) => css`
    color: ${theme.colors.gray_700};
    flex: 1;
    font-size: ${theme.font.sizes.xsmall};
    font-weight: ${theme.font.semiBold};
    text-transform: capitalize;
  `}
`;

export const Input = styled.input`
  ${({ theme }) => css`
    background-color: ${theme.colors.white};
    border: 1px solid;
    border-color: ${'hsl(0, 0%, 80%)'};
    border-radius: 0.25rem;
    box-shadow: 0 3px 2px rgba(233, 236, 239, 0.05);
    color: ${theme.colors.gray_600};
    font-size: ${theme.font.sizes.xsmall};
    margin-top: 0.875rem;
    padding: 0.625rem 0.75rem;
    position: relative;
    line-height: 1.5;
    transition: all 0.15s ease-in-out;
    width: 100%;

    &::placeholder {
      color: ${theme.colors.gray_600};
      font-size: ${theme.font.sizes.xsmall};
      font-weight: ${theme.font.light};
      font-style: italic;
      line-height: 1.5;
    }

    :focus {
      border-color: ${theme.colors.default};
      box-shadow: 0 0 0 1px ${theme.colors.default},
        3px 4px 8px rgba(94, 114, 228, 0.1);
      outline: 0;
    }

    :hover {
      border-color: hsl(0, 0%, 70%);
    }

    :disabled {
      background-color: ${theme.colors.gray_200};
    }
  `}
`;

export const SearchButton = styled.button`
  ${({ theme }) => css`
    align-self: self-end;
    background-color: transparent;
    border: 1px solid;
    border-radius: 0.25rem;
    border-color: ${theme.colors.neutral_400};
    color: ${theme.colors.neutral_700};
    cursor: pointer;
    font-size: ${theme.font.sizes.small};
    flex-shrink: 0;
    padding: 10px 12px;

    &:hover {
      background-color: ${theme.colors.default};
      border-color: ${theme.colors.neutral_600};
      color: ${theme.colors.white};
    }

    &:active {
      background-color: ${theme.colors.default};
      border-color: ${theme.colors.neutral_500};
      color: ${theme.colors.white};
    }

    &:focus {
      outline: 2px solid;
      outline-color: ${theme.colors.blue_200};
    }
  `}
`;
