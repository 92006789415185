import styled, { css, DefaultTheme } from 'styled-components';

export type WrapperProps = {
  color: 'neutral' | 'blue' | 'yellow' | 'red' | 'teal';
};

const wrapperModifiers = {
  neutral: (theme: DefaultTheme) => css`
    background-color: #edeff5;
  `,
  blue: (theme: DefaultTheme) => css`
    background-color: ${theme.colors.blue_200};
  `,
  yellow: (theme: DefaultTheme) => css`
    background-color: ${theme.colors.yellow_200};
  `,
  red: (theme: DefaultTheme) => css`
    background-color: ${theme.colors.red_200};
  `,
  teal: (theme: DefaultTheme) => css`
    background-color: ${theme.colors.teal_200};
  `,
};

export const Wrapper = styled.div<WrapperProps>`
  ${({ theme, color }) => css`
    ${wrapperModifiers[color](theme)}
  `}
`;

type TitleProps = Pick<WrapperProps, 'color'>;

const spanModifiers = {
  neutral: (theme: DefaultTheme) => css`
    color: ${theme.colors.neutral_800};
  `,
  blue: (theme: DefaultTheme) => css`
    color: ${theme.colors.blue_500};
  `,
  yellow: (theme: DefaultTheme) => css`
    color: ${theme.colors.yellow_600};
  `,
  red: (theme: DefaultTheme) => css`
    color: ${theme.colors.red_600};
  `,
  teal: (theme: DefaultTheme) => css`
    color: ${theme.colors.teal_600};
  `,
};

export const Title = styled.span<TitleProps>`
  ${({ theme, color }) => css`
    font-size: ${theme.font.sizes.xxsmall};
    font-weight: ${theme.font.semiBold};
    margin: 0 6px;
    text-transform: uppercase;

    ${spanModifiers[color](theme)}
  `}
`;

export const Button = styled.button`
  ${({ theme }) => css`
    background: transparent;
    color: ${theme.colors.neutral_500};
    border: none;
    font-size: 11px;
    padding: 0 6px;

    &:hover {
      background-color: #ffbdad;
      color: #de350b;
    }
  `}
`;
