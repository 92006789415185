import { Button, MenuItem, Select, TextField } from "@mui/material";
import Card from "@mui/material/Card";
import styled from "styled-components";

export const  ContentSeletor = styled.div`
  padding: 0px 22px;
  margin-bottom: 22px;
`
export const CardBottons = styled(Card)`
      flex-direction: 'row';
      display: flex;
      margin: 2rem;
      width: '100%';
     
      max-width: '100%';
`;

export const InputText = styled(TextField)`

    line-height: 1.42857143;
    color: #333;
    font-size: 5px;
   
    fieldset{
       height: 38px;
       border-color: #d2d6de;
       top: 7px;
    }
`
export const Label = styled.label`
    display: inline-block;
    max-width: 100%;
    font-weight: 600;
    font-size: 14px;
    line-height: 1;
    margin-bottom: 0.5rem;
`;

export const Preview = styled.img`
    margin-top: auto;
    margin-bottom: auto;
    max-height: 200px;
`;

export const  ButtonAddNew = styled(Button)`
 display: flex;
 margin-top: 8px;
 left: 5px;
 padding: 1px 20px;
`
export const SelectorSelect = styled(Select)`
       height: 35px;
       
      
       right: 9px;
`
export const MenuItemSelector = styled(MenuItem)`
  font-size: 14px;
`
  