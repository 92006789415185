import httpClient from '../http-client';

interface IMagazineResponseDto {
  data: any[];
  mataData: any;
}

export const getMagazines = async () => {
  const magazines = (
    await httpClient.get<IMagazineResponseDto>(
      `/content/admin/list?types[]=magazine`,
    )
  ).data;
  return magazines.data;
};

export const getMagazine = async (contentId: string | null) => {
  const content = await httpClient.get(`content/${contentId}`);
  return content.data;
};
