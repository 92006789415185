import { z } from 'zod';

import { categorySchema } from './categoryValidation';
import { imageSchema } from './imageValidation';
import { optionSelectSchema } from './optionSelectValidation';

export const contentExclusiveSchema = z
  .object({
    audio_file: z.any().nullable().optional(),
    audio_file_id: z.string().nullable().optional(),
    authors: z.string().array(),
    categories: z
      .array(z.union([z.string(), categorySchema]), {
        required_error: 'Este campo é obrigatório',
      })
      .refine(val => {
        const isArrayOfStrings = val.every(item => typeof item === 'string');
        const isArrayOfObjects = val.every(
          item => categorySchema.safeParse(item).success,
        );

        return isArrayOfStrings || isArrayOfObjects;
      }),
    company_units: z
      .array(optionSelectSchema, {
        required_error: 'Este campo é obrigatório',
      })
      .superRefine((val, ctx) => {
        if (!val.length) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: 'Este campo é obrigatório',
          });
        }
      }),
    complement_files: z.string().array().optional(),
    complement_contents: z.any().array().optional(),
    description: z.string().nonempty('Este campo é obrigatório'),
    groups: z.array(optionSelectSchema).nullable().optional(),
    image_file: imageSchema.nullable().optional(),
    image_file_id: z.string().uuid().nullable().default(null).optional(),
    image_name: z.string().nullable().default(null).optional(),
    image_toltip: z.string().nullable().default(null).optional(),
    page_title: z
      .string()
      .nonempty('Este campo é obrigatório')
      .max(44, 'O título da página não poder conter mais que 44 caracteres'),
    requester_id: z.string().uuid().nonempty('Este campo é obrigatório'),
    requester: z.object(
      {
        label: z.string(),
        value: z.string(),
      },
      { required_error: 'Este campo é obrigatório' },
    ),
    resume: z
      .string()
      .nonempty('Este campo é obrigatório')
      .max(155, 'O resumo não poder conter mais que 155 caracteres'),
    review_in: z.date({ required_error: 'Este campo é obrigatório' }),
    slug: z
      .string()
      .nonempty('Este campo é obrigatório')
      .max(70, 'O slug da página não poder conter mais que 70 caracteres'),
    subtitle: z.string().nonempty('Este campo é obrigatório'),
    title: z
      .string()
      .nonempty('Este campo é obrigatório')
      .max(70, 'O título não poder conter mais que 70 caracteres'),
    visibility: z.string({ required_error: 'Este campo é obrigatório' }),
  })
  .superRefine((val, ctx) => {
    if (val.visibility === 'restricted' && !val.groups?.length) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'Este campo é obrigatório',
        path: ['groups'],
      });
    }
  });
