import {
  FieldValues,
  UseFormGetValues,
  UseFormSetValue,
} from 'react-hook-form';

import { IContent } from '../models/content';

type ContentSelectorType = {
  getValues: UseFormGetValues<FieldValues>;
  setValue: UseFormSetValue<FieldValues>;
};

export default function useContentSelector({
  setValue,
  getValues,
}: ContentSelectorType) {
  const handleSelectedContent = value => {
    setValue('contents', value);
  };

  const handleContentSelected = (content: IContent) => {
    const currentContents = getValues('contents');
    const contents =
      getValues('contents') === undefined
        ? [content]
        : [...currentContents, content];

    setValue('contents', contents);
  };

  const handleRemoveSelected = (contentId: string) => {
    const contents = getValues('contents').filter(
      content => content.value !== contentId,
    );

    setValue('contents', contents);
  };

  return { handleSelectedContent, handleContentSelected, handleRemoveSelected };
}
