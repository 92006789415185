import { zodResolver } from '@hookform/resolvers/zod';
import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import { z } from 'zod';

import { serieSchema } from '../../../../common/validations/seriesValidations';
import { useSerieSubmit } from '../../../../hooks';
import { IContent } from '../../../../models/content';
import { getContentById, updateContent } from '../../../../services/contents';
import SerieTemplate from '../../../../templates/SerieTemplate';
import { groups } from '../../../../utils';

export type SeriesFormValuesType = z.infer<typeof serieSchema>;

export default function SerieEdit() {
  const [loading, setLoading] = useState(false);
  const [content, setContent] = useState<IContent>();

  const [searchParams] = useSearchParams();
  const methods = useForm<SeriesFormValuesType>({
    resolver: zodResolver(serieSchema),
  });
  const navigate = useNavigate();

  const getContent = async (id: string | null): Promise<void> => {
    if (!id) {
      Swal.fire({
        icon: 'error',
        iconColor: '#f5365c',
        title: 'Erro ao buscar o conteúdo!',
        text: 'O id passado na url é inválido',
        buttonsStyling: false,
        customClass: {
          title: 'modal-title',
          htmlContainer: 'modal-html-container',
          confirmButton: 'btn-ok',
        },
      }).then(() => navigate(-1));
      return;
    }

    setLoading(true);
    try {
      const { setValue } = methods;
      const resp = await getContentById(id);
      const groupsSelected = resp.groups
        ? groups.filter(group => resp.groups?.some(el => group.value === el))
        : null;

      setValue(
        'company_units',
        resp.company_units.map(company => ({
          label: company.name,
          value: company.company_unit_id,
        })),
      );
      setValue('description', resp.description);
      setValue(
        'featured_end_at',
        !resp.featured_end_at ? null : new Date(resp.featured_end_at),
      );
      setValue(
        'featured_start_at',
        !resp.featured_start_at ? null : new Date(resp.featured_start_at),
      );
      setValue('groups', groupsSelected);
      setValue('image_file', resp.image_file);
      setValue('image_file_id', resp.image_file_id);
      setValue('image_name', resp.image_name);
      setValue('image_toltip', resp.image_toltip);
      setValue('knowledges', resp.knowledges);
      setValue('page_title', resp.page_title);
      setValue(
        'published_start_at',
        !resp.published_start_at ? null : new Date(resp.published_start_at),
      );
      setValue(
        'published_end_at',
        !resp.published_end_at ? null : new Date(resp.published_end_at),
      );
      setValue('review_in', new Date(resp.review_in));
      setValue('resume', resp.resume);
      setValue('requester_id', resp.requester_id);
      setValue('requester', {
        label: resp.requester.name,
        value: resp.requester.requester_id,
      });
      setValue('slug', resp.slug);
      setValue('subtitle', resp.subtitle);
      setValue('tags', resp.tags);
      setValue('title', resp.title);
      setValue('topics', resp.topics);
      setValue('visibility', resp.visibility);

      setContent(resp);
    } catch (error: any) {
      console.error(error);
      Swal.fire({
        icon: 'error',
        iconColor: '#f5365c',
        title: 'Erro ao buscar o conteúdo!',
        text: error.message || error,
        buttonsStyling: false,
        customClass: {
          confirmButton: 'btn-ok',
        },
      });
    } finally {
      setLoading(false);
    }
  };

  const submit = (data: SeriesFormValuesType, status: string) => {
    const { obj } = useSerieSubmit({
      data,
      status,
      id: searchParams.get('id')!,
    });

    updateContent(obj)
      .then(() => {
        Swal.fire({
          title: 'Série atualizada com sucesso',
          icon: 'success',
          customClass: {
            title: 'modal-title',
            htmlContainer: 'modal-html-container',
            confirmButton: 'btn-ok',
          },
          buttonsStyling: false,
        }).then(() => navigate(-1));
      })
      .catch(error => {
        Swal.fire({
          icon: 'error',
          title: 'Algo deu errado',
          iconColor: '#f5365c',
          text: error.message,
          customClass: {
            title: 'modal-title',
            htmlContainer: 'modal-html-container',
            confirmButton: 'btn-ok',
          },
          buttonsStyling: false,
        });
      });
  };

  useEffect(() => {
    getContent(searchParams.get('id'));
  }, []);

  return (
    <FormProvider {...methods}>
      <SerieTemplate
        title="Edição da Série"
        view={searchParams.get('view') === 'true'}
        content={content}
        isLoading={loading}
        submit={submit}
      />
    </FormProvider>
  );
}
