import { SearchOutlined } from '@mui/icons-material';
import { GridInitialStateCommunity } from '@mui/x-data-grid/models/gridStateCommunity';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { MultiValue } from 'react-select';

import { ContentType } from '../../../common/enum/contentType';
import { useGetContent, useTablePagination } from '../../../hooks';
import { IContent, TypeDictionary } from '../../../models/content';
import { listContents } from '../../../services/contents';
import Badges from '../../Badges';
import Button from '../../Button';
import CustomSelect from '../../CustomSelect';
import { FlexContainer } from '../../FlexContainer';
import Table from '../../Table';
import SimpleModal from '..';
import * as S from './styles';

type ContentsModalProps = {
  isOpen: boolean;
  displayType?: boolean;
  onCloseChange: () => void;
  handleSelected: (content) => void;
  handleRemoveSelected: (contentId) => void;
  selectedContent: RowType[] | [];
};

type RowType = { label: string; value: string };

export default function ContentsModal({
  isOpen,
  onCloseChange,
  handleSelected,
  selectedContent,
  handleRemoveSelected,
  displayType = true,
}: ContentsModalProps) {
  const [rows, setRows] = useState<IContent[]>([]);
  const [selectedRows, setSelectedRows] = useState<RowType[]>(selectedContent);
  const [selectedType, setSelectedType] = useState<
    MultiValue<{
      label: string;
      value: string;
    }>
  >();

  const inputRef = useRef<HTMLInputElement>(null);
  const { loading, getVariedContents } = useGetContent(listContents);
  const { paginationModel, rowCount, setRowCount, changePage, changePageSize } =
    useTablePagination();

  const formatList: { label: string; value: string }[] = [];

  for (const type in ContentType) {
    const obj = { label: TypeDictionary[type], value: ContentType[type] };

    formatList.push(obj);
  }

  const params = {
    limit: paginationModel.pageSize,
    offset: paginationModel.page,
  };

  const initialStateTable: GridInitialStateCommunity = {
    sorting: {
      sortModel: [{ field: 'created_at', sort: 'desc' }],
    },
  };

  const columns = useMemo(
    () => [
      {
        headerName: 'Nome',
        field: 'title',
        minWidth: 220,
        flex: 2,
        sortable: false,
        renderCell: (cellValues: any) => (
          <p style={{ whiteSpace: 'normal' }}>{cellValues?.value}</p>
        ),
      },
      {
        headerName: 'Tipo',
        field: 'type',
        minWidth: 180,
        flex: 1,
        sortable: false,
        renderCell: (cellValues: any) => (
          <p style={{ whiteSpace: 'normal' }}>{cellValues?.value}</p>
        ),
      },
      {
        headerName: 'Selecionar',
        field: '',
        minWidth: 160,
        flex: 1,
        sortable: false,
        renderCell: (cellValues: any) => {
          return (
            <FlexContainer justifyContent="center" width="100%">
              <Button
                id={cellValues.row.content_id}
                variant="contained"
                disabled={
                  !!selectedRows.find(
                    content => content.value === cellValues.row.content_id,
                  )
                }
                onClick={e => {
                  e.currentTarget.disabled = !e.currentTarget.disabled;
                  const content = {
                    label: cellValues.row.title,
                    value: cellValues.row.content_id,
                  };
                  setSelectedRows(prevState => [...prevState, content]);
                  handleSelected(content);
                }}
              >
                Selecionar
              </Button>
            </FlexContainer>
          );
        },
      },
    ],
    [],
  );

  useEffect(() => {
    getVariedContents(setRows, setRowCount, params);
  }, [paginationModel.pageSize, paginationModel.page]);

  const tableProps = {
    columns,
    rows,
    keyId: 'content_id',
    initialState: initialStateTable,
    onPageChange: page => changePage(page),
    onPageSizeChange: pageSize => changePageSize(pageSize),
    page: paginationModel.page - 1,
    rowsPerPageOptions: [5, 10, 25],
    pageSize: paginationModel.pageSize,
    rowCount,
    loading,
    // TODO: ADICIONAR ORDENAÇÃO
    // onSortModelChange: model => handleSort(model),
  };

  return (
    <SimpleModal
      isOpen={isOpen}
      onCloseChange={onCloseChange}
      title="Lista de Conteúdos"
    >
      <S.Wrapper>
        <FlexContainer gap="0.875rem">
          <S.Label htmlFor="search">
            Busca por Nome
            <S.Input
              id="search"
              ref={inputRef}
              placeholder="Busque pelo título do conteúdo"
            />
          </S.Label>

          {displayType && (
            <S.Label htmlFor="format">
              Tipo do conteúdo
              <CustomSelect
                id="format"
                isMulti
                isClearable
                isRtl={false}
                isSearchable
                options={formatList}
                onChange={value => setSelectedType(value)}
                value={selectedType}
                thereIsError={false}
                marginTop="0.875rem"
                placeholder="Selecione o tipo de conteúdo a ser filtrado"
              />
            </S.Label>
          )}
          <S.SearchButton
            type="button"
            onClick={() => {
              const search = inputRef.current?.value;
              const searchParams = params;

              if (search !== undefined && search.length) {
                searchParams['search'] = search;
              }

              if (selectedType?.length) {
                searchParams['types'] = selectedType?.map(item => item.value);
              }

              getVariedContents(setRows, setRowCount, searchParams);
            }}
          >
            <SearchOutlined fontSize="inherit" />
          </S.SearchButton>
        </FlexContainer>

        <FlexContainer
          flexWrap="wrap"
          gap="6px"
          width="100%"
          style={{ minWidth: '44px' }}
        >
          {selectedRows.map(row => (
            <Badges
              key={row.value}
              handleRemove={() => {
                handleRemoveSelected(row.value);
                setSelectedRows(prevState =>
                  prevState.filter(item => item.value !== row.value),
                );
              }}
            >
              {row.label}
            </Badges>
          ))}
        </FlexContainer>

        <Table {...tableProps} width="90vw" height="100%" disableColumnMenu />
      </S.Wrapper>
    </SimpleModal>
  );
}
