import React from 'react';
import Select, { GroupBase, Props } from 'react-select';

import * as S from './styles';

export default function CustomSelect<
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
>(props: Props<Option, IsMulti, Group> & S.WrapperProps): React.ReactElement {
  return (
    <S.Wrapper thereIsError={props.thereIsError} marginTop={props.marginTop}>
      <Select
        noOptionsMessage={() => 'Nenhuma opção encontrada'}
        menuPlacement="auto"
        className="react-select-container"
        classNamePrefix="react-select"
        {...props}
      />
    </S.Wrapper>
  );
}
