import React from 'react';
import {
  CropperBackgroundWrapperProps,
  TransformableImage,
} from 'react-advanced-cropper';

export default function BackgroundWrapper({
  children,
}: CropperBackgroundWrapperProps) {
  return <TransformableImage>{children}</TransformableImage>;
}
