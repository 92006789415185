import { AddCircleOutlineOutlined } from '@mui/icons-material';
import React from 'react';
import Select from 'react-select';

import * as S from './styles';

type ContentSelectorProps = {
  id?: string;
  value: any;
  handleOpenMenu: () => void;
  handleChange: (value) => void;
};

export default function ContentSelector({
  id,
  handleOpenMenu,
  value,
  handleChange,
}: ContentSelectorProps) {
  const components = {
    DropdownIndicator: null,
  };

  return (
    <S.Wrapper>
      <Select
        openMenuOnClick={false}
        id={id}
        isSearchable={false}
        components={components}
        className="react-select-container"
        classNamePrefix="react-select"
        isClearable
        isMulti
        menuIsOpen={false}
        onChange={handleChange}
        placeholder="Clique no botão ao lado para adicionar..."
        value={value}
      />
      <S.Button
        title="Abrir modal de conteúdos"
        type="button"
        onClick={handleOpenMenu}
      >
        <AddCircleOutlineOutlined fontSize="inherit" />
      </S.Button>
    </S.Wrapper>
  );
}
