import { zodResolver } from '@hookform/resolvers/zod';
import React, { useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Swal from 'sweetalert2';

import { syncClassSchema } from '../../../../common/validations/syncClassValidation';
import { convertTimeToMilliseconds } from '../../../../helpers/convertTimeToMilliseconds';
import useGetClass from '../../../../hooks/useGetClass';
import { ICreateClass } from '../../../../models/SyncCourse';
import { createSyncClass, getClassById } from '../../../../services/syncCourse';
import ClassTemplate from '../../../../templates/ClassTemplate';
import {
  convertMsToHour,
  convertStringToCurrency,
  convertStringToNumber,
  formatCpf,
  removeTraitPoints,
} from '../../../../utils';
import { SyncClassFormValues } from '../SyncClassCreate';

export default function SyncClassEdit() {
  const [isLoading, setIsLoading] = useState(true);

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const methods = useForm<SyncClassFormValues>({
    resolver: zodResolver(syncClassSchema),
    defaultValues: {
      instructor_is_email_cpf_blocked: true,
    },
  });
  const { getClass } = useGetClass(methods, setIsLoading);

  const verifyVisibility = useMemo(() => {
    if (searchParams.get('view')) {
      return true;
    } else {
      return false;
    }
  }, []);

  const messageClassError = 'Erro ao buscar a turma selecionada';

  const getClassForEdition = async (classId: string): Promise<void> => {
    try {
      setIsLoading(true);
      const { setValue } = methods;

      const resp = await getClassById(classId);

      setValue('audience', resp.audience);
      setValue('description', resp.description);
      setValue('duration', convertMsToHour(resp.duration));
      setValue('finish_at', new Date(resp.finish_at));
      setValue('instructor_cpf', formatCpf(resp.author.cpf) || '');
      setValue('instructor_name', resp.author.name);
      setValue('instructor_email', resp.author.email || '');
      setValue('instructor_is_email_cpf_blocked', true);
      setValue('instructor_slug', resp.author.slug);
      setValue('moderators_selected', resp.moderators);
      setValue(
        'moderators',
        resp.moderators.length
          ? resp.moderators.map(moderator => moderator.moderator_id)
          : [],
      );
      setValue('name', resp.name);
      setValue('price', convertStringToCurrency(resp.price.toString()));
      setValue('start_at', new Date(resp.start_at));
      setValue('status', resp.status);
      setValue('sebrae_code_content', resp.content.sebrae_code || '');
      setValue('sebrae_code', resp.sebrae_code);
      setValue(
        'users',
        resp.class_users.length
          ? resp.class_users.map(user => user.user_id)
          : [],
      );
      setValue(
        'users_selected',
        resp.class_users.length
          ? resp.class_users.map(classUser => classUser.user)
          : [],
      );
      setValue('vacancies', resp.vacancies.toString());

      !verifyVisibility && getClass();
    } catch (error: any) {
      Swal.fire({
        icon: 'error',
        title: messageClassError,
        iconColor: '#f5365c',
        text: error.message,
        customClass: {
          title: 'modal-title',
          htmlContainer: 'modal-html-container',
          confirmButton: 'btn-ok',
        },
        buttonsStyling: false,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubmit = async (data: SyncClassFormValues) => {
    const obj = {} as ICreateClass;

    delete data['moderators_selected'];
    delete data['users_selected'];
    Object.assign(obj, data);

    obj['price'] = convertStringToNumber(data['price']);
    obj['duration'] = convertTimeToMilliseconds(data['duration']);
    obj['instructor_cpf'] = removeTraitPoints(data['instructor_cpf'])!;

    createSyncClass(obj)
      .then(() => {
        Swal.fire({
          title: 'Turma criada com sucesso',
          icon: 'success',
          customClass: {
            title: 'modal-title',
            htmlContainer: 'modal-html-container',
            confirmButton: 'btn-ok',
          },
          buttonsStyling: false,
        }).then(() => navigate(-1));
      })
      .catch(error => {
        Swal.fire({
          icon: 'error',
          title: 'Erro ao criar a turma',
          iconColor: '#f5365c',
          text: error.message,
          customClass: {
            title: 'modal-title',
            htmlContainer: 'modal-html-container',
            confirmButton: 'btn-ok',
          },
          buttonsStyling: false,
        });
      });
  };

  useEffect(() => {
    const classId = searchParams.get('id');

    if (!classId) {
      Swal.fire({
        icon: 'error',
        title: messageClassError,
        iconColor: '#f5365c',
        text: 'O id da turma é inválida ou não existe',
        customClass: {
          title: 'modal-title',
          htmlContainer: 'modal-html-container',
          confirmButton: 'btn-ok',
        },
        buttonsStyling: false,
      });

      return;
    }

    getClassForEdition(classId);
  }, []);

  return (
    <FormProvider {...methods}>
      <ClassTemplate
        title="Edição de turma"
        getClass={getClass}
        isLoading={isLoading}
        submit={handleSubmit}
        visibility={verifyVisibility}
      />
    </FormProvider>
  );
}
