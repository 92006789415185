import { zodResolver } from '@hookform/resolvers/zod';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Swal from 'sweetalert2';

import { serieSchema } from '../../../../common/validations/seriesValidations';
import { useSerieSubmit } from '../../../../hooks';
import { createContent } from '../../../../services/contents';
import SerieTemplate from '../../../../templates/SerieTemplate';
import { SeriesFormValuesType } from '../SerieEdit';

export default function SerieCreate() {
  const [searchParams] = useSearchParams();
  const methods = useForm<SeriesFormValuesType>({
    resolver: zodResolver(serieSchema),
  });
  const navigate = useNavigate();

  const submit = (data: SeriesFormValuesType, status: string) => {
    const { obj } = useSerieSubmit({
      data,
      status,
    });

    createContent(obj)
      .then(() => {
        Swal.fire({
          title: 'Curso atualizado com sucesso',
          icon: 'success',
          customClass: {
            title: 'modal-title',
            htmlContainer: 'modal-html-container',
            confirmButton: 'btn-ok',
          },
          buttonsStyling: false,
        }).then(() => navigate(-1));
      })
      .catch(error => {
        Swal.fire({
          icon: 'error',
          title: 'Algo deu errado',
          iconColor: '#f5365c',
          text: error.message,
          customClass: {
            title: 'modal-title',
            htmlContainer: 'modal-html-container',
            confirmButton: 'btn-ok',
          },
          buttonsStyling: false,
        });
      });
  };

  return (
    <FormProvider {...methods}>
      <SerieTemplate
        title="Criação da Série"
        view={searchParams.get('view') === 'true'}
        submit={submit}
      />
    </FormProvider>
  );
}
