import axios from 'axios';
import Swal from 'sweetalert2';

import { UserToken } from '../contexts/AuthContext';
import theme from '../styles/theme';

export interface IFormSignIn {
  email: string;
  password: string;
}

const getErrorMessage = (error: any) => {
  return error && error.response && error.response.data
    ? // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      typeof error.response.data.message === 'any'
      ? error.response.data.message.message
      : error.response.data.message
    : error.message || error;
};

const signIn = async (user: IFormSignIn): Promise<UserToken> => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_BASE_URL}/session/login`,
      user,
      {
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Methods': 'GET, PUT, POST, DELETE, OPTIONS',
        },
      },
    );

    if (response.data && response.data.user && response.data.user.is_admin) {
      return response.data;
    } else {
      Swal.fire({
        title: 'Erro',
        text: 'O usuário informado não tem permissão para fazer login.',
        icon: 'error',
      });

      throw new Error('User is not an administrator');
    }
  } catch (err) {
    const errorMessage = getErrorMessage(err);
    if (errorMessage === 'Incorrect username or password.') {
      Swal.fire({
        title: 'Erro',
        text: 'A matrícula e senha informadas estão incorretas ou não existem.',
        icon: 'error',
      });
    } else {
      Swal.fire({
        title: 'Erro ao fazer o login',
        text: errorMessage,
        icon: 'error',
        confirmButtonColor: theme.colors.default,
      });
    }

    return Promise.reject();
  }
};

export { signIn };
