import { Category } from '../@types/categorization';
import httpClient from '../http-client';

type CategoryParams = {
  type?: string;
  children?: boolean;
};

const types = {
  skill: 'skills',
  moment: 'moments',
  sector: 'sectors',
};

export const getCategories = async (params?: CategoryParams): Promise<any> => {
  const response = (await httpClient.get<any>(`category`, { params })).data;

  if (params?.type) {
    const data = {};
    const label = types[`${params?.type}`];
    data[label] = response;
    return data;
  } else {
    response.skills.metaData = {
      ...response.skills.metaData,
      category_id: 'skills',
    };
    response.sectors.metaData = {
      ...response.sectors.metaData,
      category_id: 'sectors',
    };
    response.moments.metaData = {
      ...response.moments.metaData,
      category_id: 'moments',
    };

    return response;
  }
};

export const getCategory = async (categoryId): Promise<Category> => {
  const category = await httpClient.get<Category>(
    `category/${categoryId}?children=true`,
  );

  return category.data;
};

export const createCategory = async (body: Category) => {
  const category = (await httpClient.post<any>('category', body)).data;

  return category;
};

export const updateCategory = async (body: Category) => {
  const category = (await httpClient.put<any>('category', body)).data;

  return category;
};

export const deleteCategory = async category => {
  const response = await httpClient.delete<any>(
    `category/${category.category_id}`,
  );

  return response;
};
