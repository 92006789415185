import styled, { css } from 'styled-components';

type CardHeaderProps = {
  isExpandable?: boolean;
  isOpen: boolean;
};

type CardBodyProps = CardHeaderProps;

type CardProps = Pick<CardHeaderProps, 'isExpandable'>;

export const Card = styled.div<CardProps>`
  ${({ theme, isExpandable }) => css`
    background-color: ${theme.colors.white};
    border: 1px solid ${theme.colors.neutral_400};
    border-radius: 0.5rem;
    box-shadow: ${theme.shadow.card};
    height: min-content;
    overflow: ${isExpandable ? 'hidden' : 'visible'};
    word-wrap: break-word;
    width: 100%;
  `}
`;

export const CardHeader = styled.div<CardHeaderProps>`
  ${({ theme, isOpen, isExpandable }) => css`
    align-items: center;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
    display: flex;
    cursor: ${isExpandable ? 'pointer' : 'auto'};
    padding: 1.25rem 1.5rem;
    justify-content: space-between;
    list-style-type: none;
    transition: all ${theme.transition.default};

    h3 {
      font-size: ${theme.font.sizes.medium};
    }

    svg {
      color: ${theme.colors.primary};
      transition: transform ${theme.transition.default};
      transform: ${isOpen ? 'rotate(180deg)' : 'none'};
    }
  `}
`;

const CardBodyExpandable = {
  true: (isOpen: boolean) => css`
    max-height: ${isOpen ? '100%' : '0'};
    padding: ${isOpen ? '1.5rem' : '0'};
  `,
  false: () => css`
    max-height: 100%;
    padding: 1.5rem;
  `,
};

export const CardBody = styled.div<CardBodyProps>`
  ${({ isOpen, isExpandable }) => css`
    flex: 1 1 auto;
    transition: all padding 0.3s ease-in;

    ${isExpandable ? CardBodyExpandable.true(isOpen) : CardBodyExpandable.false}
  `}
`;
