import { zodResolver } from '@hookform/resolvers/zod';
import React from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import * as z from 'zod';

import { Slugify } from '../../../helpers/slugify';
import { dealWithTitleChange } from '../../../utils';
import Button from '../../Button/index';
import TextField from '../../FormComponent/TextField';
import SimpleModal from '..';
import * as S from './styles';

type RegisterModalProps = {
  schema: any;
  isOpen: boolean;
  needSlug: boolean;
  keyId: string;
  onCloseChange: () => void;
  handleOptionChange: (args) => void;
  funcCreate: (data) => Promise<any>;
};

enum Title {
  knowledge_id = 'Adicionar novo conhecimento',
  topic_id = 'Adicionar novo tópico',
  tag_id = 'Adicionar uma nova tag',
  company_unit_id = 'Adicionar uma nova unidade',
  requester_id = 'Adicionar um novo solicitante',
}

enum Label {
  knowledge_id = 'Nome Conhecimento Curso',
  topic_id = 'Nome do Tópico',
  tag_id = 'Nome da tag',
  company_unit_id = 'Nome da unidade',
  requester_id = 'Nome do solicitante',
}

enum SuccessMessage {
  knowledge_id = 'Conhecimento criado com sucesso.',
  topic_id = 'Tópico criado com sucesso.',
  tag_id = 'Tag criada com sucesso.',
  company_unit_id = 'Unidade criada com sucesso.',
  requester_id = 'Solicitante criado com sucesso.',
}

enum OptionKey {
  knowledge_id = 'knowledges',
  topic_id = 'topics',
  tag_id = 'tags',
  requester_id = 'requester',
  company_unit_id = 'company_units',
}

export default function RegisterModal({
  schema,
  isOpen,
  onCloseChange,
  funcCreate,
  handleOptionChange,
  needSlug,
  keyId,
}: RegisterModalProps) {
  const methods = useForm<z.infer<typeof schema>>({
    resolver: zodResolver(schema),
  });

  const { setValue, handleSubmit } = methods;

  const onError = error => {
    console.error(error);
    Swal.fire({
      icon: 'error',
      title: 'Erro ao fazer o cadastro',
      text: 'Por favor, preencha o formulário corretamente.',
      customClass: {
        title: 'modal-title',
        htmlContainer: 'modal-html-container',
        confirmButton: 'btn-ok',
      },
      buttonsStyling: false,
    });
  };

  const onSubmit: SubmitHandler<z.infer<typeof schema>> = data => {
    funcCreate(data)
      .then(resp => {
        if (resp === undefined) return;

        toast.success(SuccessMessage[keyId]);

        handleOptionChange({
          label: resp.name,
          key: OptionKey[keyId],
          value: resp[keyId],
          data: resp,
        });
        onCloseChange();
      })
      .catch(error => {
        toast.error(error.message);
      });
  };

  const handleChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => {
    const slug = dealWithTitleChange(event.target.value);
    setValue('slug', Slugify(slug));
  };

  return (
    <FormProvider {...methods}>
      <SimpleModal
        isOpen={isOpen}
        onCloseChange={onCloseChange}
        title={Title[keyId]}
      >
        <S.Form onSubmit={handleSubmit(onSubmit, onError)}>
          <TextField
            label={Label[keyId]}
            name="name"
            onChange={handleChange}
            required
          />
          {needSlug && <TextField label="Slug" name="slug" required />}

          <S.ButtonContainer>
            <Button
              type="button"
              variant="outlined"
              color="secondary"
              onClick={onCloseChange}
            >
              Cancelar
            </Button>
            <Button variant="contained" type="submit">
              Adicionar
            </Button>
          </S.ButtonContainer>
        </S.Form>
      </SimpleModal>
    </FormProvider>
  );
}
