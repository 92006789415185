import styled from 'styled-components';

const SelectSambaVideosContentContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;

const ContentUpload = styled.button`
  height: 100%;
  width: 50%;
  background: none;
  border: solid 1px var(--default-dark-gray);
  border-radius: 15px;
  cursor: pointer;
  color: var(--primary-color);
  &:hover {
    transition: all 0.3s;
    background: var(--primary-color);
    color: white;
  }
  p {
    font-size: var(--font-large);
    margin-bottom: 10px;
  }
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const ContentListContainer = styled.div`
  width: 50%;
  height: 100%;
`;

const ContentList = styled.div`
  height: 90%;
  padding: 0 15px;
  overflow-y: auto;
  & > div {
    margin: 0 auto;
  }
  & > :not(:last-child) {
    margin-bottom: 10px;
  }
`;

const ContentListFilters = styled.form`
  height: 8%;
  padding: 0 15px;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ContentListFilter = styled.input`
  flex-grow: 1;
  padding: 10px;
  height: 43px;
  outline: none;
  border: solid 1px var(--default-dark-gray);
  border-radius: 5px;
`;

export {
  ContentList,
  ContentListContainer,
  ContentListFilter,
  ContentListFilters,
  ContentUpload,
  SelectSambaVideosContentContainer,
};
