import React, { forwardRef, useRef } from 'react';
import {
  CircleStencil,
  Cropper,
  CropperPreview,
  CropperPreviewRef,
  CropperProps,
  CropperRef,
  defaultStencilConstraints,
  ImageRestriction,
  mergeRefs,
  RectangleStencil,
} from 'react-advanced-cropper';

import BackgroundWrapper from '../BackgroundWrapper';
import CropperWrapper, { NavigationProps } from '../CropperWrapper';

type AppCropperProps = {
  navigation?: boolean;
  navigationProps: NavigationProps;
  stencilType?: 'circle' | 'rectangle';
  isEdit: boolean;
  previewState: any;
} & Omit<
  CropperProps,
  | 'transitions'
  | 'priority'
  | 'imageRestriction'
  | 'stencilSize'
  | 'stencilConstraints'
  | 'transformImage'
>;

export const AppCropper = forwardRef(function AppCropper(
  props: AppCropperProps,
  ref,
) {
  const {
    stencilProps = {},
    stencilType = 'rectangle',
    navigationProps,
    className,
    isEdit,
    onUpdate,
    previewState,
    ...cropperProps
  } = props;

  const cropperRef = useRef<CropperRef>(null);
  const previewRef = useRef<CropperPreviewRef>(null);

  return (
    <>
      {isEdit ? (
        <Cropper
          {...cropperProps}
          ref={mergeRefs([ref, cropperRef])}
          stencilConstraints={defaultStencilConstraints}
          className={`cropper ${className}`}
          stencilProps={{
            ...stencilProps,
            overlayClassName: `${stencilProps.overlayClassName} app-cropper-overlay`,
          }}
          wrapperComponent={CropperWrapper}
          wrapperProps={{ navigationProps, isEdit }}
          imageRestriction={ImageRestriction.stencil}
          stencilComponent={
            stencilType === 'circle' ? CircleStencil : RectangleStencil
          }
          onUpdate={onUpdate}
          transformImage={{
            adjustStencil: false,
          }}
          backgroundWrapperComponent={BackgroundWrapper}
        />
      ) : (
        <CropperPreview
          ref={previewRef}
          {...previewState}
          className="preview"
          wrapperComponent={CropperWrapper}
          wrapperProps={{ navigationProps, isEdit }}
        />
      )}
    </>
  );
});
