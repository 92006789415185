import { DatePicker, LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { TextField } from '@mui/material';
import brLocale from 'date-fns/locale/pt-BR';
import React from 'react';
import { useFormContext, useWatch } from 'react-hook-form';

import * as S from './styles';

type CustomDatePickerProps = {
  label: string;
  name: string;
};

export default function CustomDatePicker({
  label,
  name,
}: CustomDatePickerProps) {
  const { register, setValue, control } = useFormContext();
  const value = useWatch({ control: control, name });

  const handleChange = date => {
    setValue(name, date);
  };

  return (
    <S.Wrapper>
      <LocalizationProvider dateAdapter={AdapterDateFns} locale={brLocale}>
        <DatePicker
          label={label}
          value={value}
          onChange={handleChange}
          renderInput={params => <TextField {...params} />}
          {...register}
        />
      </LocalizationProvider>
    </S.Wrapper>
  );
}
