import styled, { css } from 'styled-components';

export type WrapperProps = {
  thereIsError?: boolean;
  marginTop?: string;
};

export const Wrapper = styled.div<WrapperProps>`
  ${({ theme, thereIsError = false, marginTop }) => css`
    width: 100%;

    .react-select__control {
      background-color: ${theme.colors.white};
      border: 1px solid;
      border-color: ${thereIsError ? theme.colors.red_400 : 'hsl(0, 0%, 80%)'};
      box-shadow: 0 3px 2px rgba(233, 236, 239, 0.05);
      border-radius: 0.25rem;
      margin-top: ${marginTop};
    }

    .react-select__control--is-disabled {
      background-color: ${theme.colors.gray_200};
    }

    .react-select__value-container {
      color: ${theme.colors.neutral_600};
      font-size: ${theme.font.sizes.xsmall};
      padding-left: 0.75rem;
      line-height: 1.5;
    }

    .react-select__single-value {
      color: ${theme.colors.neutral_600};
    }

    .react-select__menu {
      font-size: ${theme.font.sizes.xsmall};
    }

    .react-select__control--is-focused {
      border-color: ${thereIsError
        ? theme.colors.red_400
        : theme.colors.default};
      box-shadow: 0 0 0 1px
          ${thereIsError ? theme.colors.red_400 : theme.colors.default},
        3px 4px 8px rgba(94, 114, 228, 0.1);
      outline: 0;
    }

    .react-select__placeholder {
      color: ${theme.colors.gray_600};
      font-size: ${theme.font.sizes.xsmall};
      font-weight: ${theme.font.light};
      font-style: italic;
      line-height: 1.5;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .react-select__dropdown-indicator {
      padding: 10.5px 12px;
    }
  `}
`;
