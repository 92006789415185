import styled, { css } from 'styled-components';

import theme from '../../styles/theme';

export type WrapperProps = {
  width?: string;
  height?: string;
  color?: keyof typeof theme.colors;
};

export const Wrapper = styled.div<WrapperProps>`
  ${({ theme, height = '40px', width = '40px', color = 'default' }) => css`
    @keyframes spin {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }

    .spinner {
      border: 3px solid #f3f3f3;
      border-radius: 50%;
      border-top: 3px solid ${theme.colors[color]};
      width: ${width};
      height: ${height};
      -webkit-animation: spin 1s linear infinite; /* Safari */
      animation: spin 1s linear infinite;
    }

    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 100%;
  `}
`;
