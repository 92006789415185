import { InputLabel, TextField } from '@mui/material';
import styled from 'styled-components';

export const DefaultInput = styled.input`
  border-radius: 5px;
  border: solid 1px var(--default-dark-gray);
  outline: none;
  font-size: var(--font-small);
  width: 100%;
  height: 44px;
  padding: 0 10px;
  color: var(--default-gray);
  margin-top: 8px;
`;

export const LoginContainer = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const LoginForm = styled.form`
  width: 400px;
  padding: 30px;
  border-radius: 15px;
  box-shadow: 0 0.5rem 3rem rgba(0, 0, 0, 0.4);
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #28969f;

  img {
    width: 240px;
    margin-bottom: 24px;
  }

  button {
    width: 100%;
    margin-top: 8px;
  }
`;

export const LoginFormGroup = styled.div`
  margin-bottom: 10px;
  width: 100%;

  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column;
  label {
    color: #fff;
  }
`;
export const ProfileContainer = styled.div`
  height: 100%;
  width: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
`;
const AppContainer = styled.div`
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  align-items: center;

  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Open Sans', sans-serif;
  }

  :root {
    --font-smallest: 12px;
    --font-small: 14px;
    --font-medium: 18px;
    --font-large: 26px;
    --font-larger: 32px;

    --default-title-color: #32325d;
    --default-dark-gray: #ced4da;
    --default-gray: #8898aa;
    --success-color: #2dce89;
    --primary-color: #354371;
    --danger-color: #f5365c;
    --info-color: #11cdef;
    --warning-color: #fb6340;
  }

  label.required::after {
    content: ' *';
    color: red;
  }

  .center {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .spinner {
    border: 16px solid #f3f3f3;
    border-radius: 50%;
    border-top: 16px solid #3498db;
    width: 120px;
    height: 120px;
    animation: spin 2s linear infinite;
  }

  .spinner.small {
    border: 10px solid #f3f3f3;
    border-radius: 50%;
    border-top: 10px solid #3498db;
    width: 35px;
    height: 35px;
    animation: spin 2s linear infinite;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  #global-modal {
    position: fixed;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.3);
    width: 100vw;
    height: 100vh;
    z-index: 4;

    display: flex;
    justify-content: center;
    align-items: center;
  }

  body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
      'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
      'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  }
`;

const AppLeftContent = styled.div`
  height: 100%;
  width: 250px;
  box-shadow: 0 0 2rem rgba(136, 152, 170, 0.15);

  *::-webkit-scrollbar {
    width: 8px;
  }

  *::-webkit-scrollbar-track {
    background: white;
  }

  *::-webkit-scrollbar-thumb {
    background: lightgray;
  }
`;

export const InputText = styled(TextField)`
  line-height: 1.42857143;
  color: #333;
  font-size: 5px;

  .MuiInputBase-input {
    padding: 16px 14px !important;
  }
  
  fieldset {
    height: 38px;
    border-color: #d2d6de;
    top: 7px;
  }
`;

export const row = styled.div`
  display: flex;
  flex-direction: row;
`;

export const column = styled.div`
  display: flex;
  flex-direction: column;
`;

const AppRightContent = styled.div`
  height: 100%;
  width: 85%;
  overflow-y: auto;

  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column;

  *::-webkit-scrollbar {
    width: 8px;
  }

  *::-webkit-scrollbar-track {
    background: white;
  }

  *::-webkit-scrollbar-thumb {
    background: lightgray;
  }
`;

const AppContent = styled.div`
  display: flex;
  justify-content: center;
  flex-grow: 1;
  padding: 20px 30px;
  width: 100%;
`;

export const inputLabel = styled(InputLabel)``;

export const span = styled.span`
  color: red;
  padding: 8px;
`;

export { AppContainer, AppContent, AppLeftContent, AppRightContent };
