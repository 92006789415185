import { z } from 'zod';

import { requiredMessage } from '../../constants/ErroMessages';

export const consumptionSchema = z.object({
  carga_horaria: z.string({ required_error: requiredMessage }).regex(/([0-9])/),
  cod_acao: z.string({ required_error: requiredMessage }),
  code_integration: z.string({ required_error: requiredMessage }),
  cod_projeto: z.string({ required_error: requiredMessage }),
  company: z.string().length(18, 'CNPJ inválido').optional(),
  cpf_responsavel: z.string({ required_error: requiredMessage }),
  credential: z.string({ required_error: requiredMessage }),
  customer: z.string().optional(),
  date_hour_end: z.date({ required_error: requiredMessage }),
  description: z.string({ required_error: requiredMessage }),
  instrumento: z.string({ required_error: requiredMessage }),
  nome_realizacao: z.string({ required_error: requiredMessage }),
  origin_id: z.string({ required_error: requiredMessage }).regex(/([0-9])/),
  title: z.string({ required_error: requiredMessage }),
  type: z.string({ required_error: requiredMessage }),
  theme_id: z.string({ required_error: requiredMessage }),
  date_hour_start: z.date({ required_error: requiredMessage }),
});
