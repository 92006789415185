import { zodResolver } from '@hookform/resolvers/zod';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { signInSchema } from '../../../common/validations/signInValidation';
import { useAuth } from '../../../contexts/AuthContext';
import LoginTemplate from '../../../templates/LoginTemplate';

const Login = () => {
  const { login, loading } = useAuth();
  const register = useForm({
    resolver: zodResolver(signInSchema),
  });

  const handleSubmit = async data => {
    await login(data);
  };

  return (
    <FormProvider {...register}>
      <LoginTemplate loading={loading} submitHandler={handleSubmit} />
    </FormProvider>
  );
};

export default Login;
