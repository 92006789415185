import styled from 'styled-components';

const SambaVideosContentCardContainer = styled.div`
  width: 100%;
  height: 120px;
  border: solid 1px var(--default-dark-gray);
  border-radius: 15px;
  cursor: pointer;
  transition: all 0.3s;
  overflow: hidden;
  &:hover {
    background: rgba(0, 0, 0, 0.1);
  }
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;

const SambaVideosContentThumbContainer = styled.div`
  width: 200px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const SambaVideosContentThumb = styled.img`
  height: 100%;
`;

const SambaVideosContentTextContent = styled.div`
  padding: 10px;
  flex-grow: 1;
`;

const SambaVideosContentTitle = styled.h4`
  white-space: nowrap;
  width: 280px;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 10px;
`;

const SambaVideosContentDuration = styled.p`
  font-size: var(--font-small);
`;

export {
  SambaVideosContentCardContainer,
  SambaVideosContentDuration,
  SambaVideosContentTextContent,
  SambaVideosContentThumb,
  SambaVideosContentThumbContainer,
  SambaVideosContentTitle,
};
