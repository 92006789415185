import React from 'react';

import { Progress, UploadProgressBarContainer } from './style';

interface UploadProgressBarProps {
  progress: number;
}

const UploadProgressBar: React.FC<UploadProgressBarProps> = ({ progress }) => {
  return (
    <UploadProgressBarContainer>
      <Progress style={{ width: `${progress}%` }}></Progress>
    </UploadProgressBarContainer>
  );
};

export default UploadProgressBar;
