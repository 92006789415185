import { Typography } from '@mui/material';
import React from 'react';
import { Controller } from 'react-hook-form';

import * as appStyles from '../../../AppStyle';
import { getCategories } from '../../../services/categorization';
import CategoryDropDown from '../../CategoryDropDown';

interface CategorizationProps {
  content?: any;
  setValue?: any;
  control: any;
  view?: boolean;
  setState?: any;
  filters?: Array<string>;
  type?: 'skill' | 'moment' | 'sector';
}

export default function Categorization(props: CategorizationProps) {
  const [backCategories, setBackCategories] = React.useState<string[]>([]);
  const [skill, setSkill] = React.useState<any>();
  const [moment, setMoment] = React.useState<any>();
  const [sector, setSector] = React.useState<any>();

  React.useEffect(() => {
    const params = props.type !== undefined ? { type: props.type } : {};

    getCategories(params).then(data => {
      if (data.skills) setSkill({ ...props, category: data.skills });
      if (data.moments) setMoment({ ...props, category: data.moments });
      if (data.sectors) setSector({ ...props, category: data.sectors });
    });
  }, [props.content]);

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        padding: '22px',
        width: '100%',
      }}
    >
      <Typography variant="h4" style={{ marginBottom: '1rem' }}>
        Categorização *
      </Typography>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        {
          <Controller
            name="categories"
            control={props.control}
            rules={{ required: '*Campo obrigatório' }}
            render={({ field: { onChange, value }, fieldState: { error } }) => {
              return (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '8px',
                  }}
                >
                  {skill !== undefined && (
                    <CategoryDropDown
                      formProps={skill}
                      setBackCategories={setBackCategories}
                      backCategories={backCategories}
                    />
                  )}
                  {moment !== undefined && (
                    <CategoryDropDown
                      formProps={moment}
                      setBackCategories={setBackCategories}
                      backCategories={backCategories}
                    />
                  )}
                  {sector !== undefined && (
                    <CategoryDropDown
                      formProps={sector}
                      setBackCategories={setBackCategories}
                      backCategories={backCategories}
                    />
                  )}
                  {error !== undefined && (
                    <appStyles.inputLabel
                      style={{ color: 'red', marginBottom: '0.5rem' }}
                    >
                      {error.message}
                    </appStyles.inputLabel>
                  )}
                </div>
              );
            }}
          />
        }
      </div>
    </div>
  );
}
