import React from 'react';
import { useFormContext } from 'react-hook-form';

import { FlexContainer } from '../../components/FlexContainer';
import MainFile from '../../components/FormComponent/MainFile';
import BasicContentTemplate, {
  BasicContentTemplateType,
} from '../BasicContentTemplate';

type KitsTematicsTemplateType = BasicContentTemplateType;

export default function KitsTematicsTemplate({
  title,
  view,
  content,
  isLoading,
  submit,
}: KitsTematicsTemplateType) {
  const { setValue, getValues, control } = useFormContext();

  return (
    <BasicContentTemplate
      title={title}
      view={view}
      submit={submit}
      content={content}
      isLoading={isLoading}
    >
      <FlexContainer gap="0.875rem" flexWrap="wrap">
        <MainFile
          getValues={getValues}
          titleCompany={'Arquivo'}
          setValue={setValue}
          control={control}
          content={content}
          view={view}
          permission="public"
        />
      </FlexContainer>
    </BasicContentTemplate>
  );
}
