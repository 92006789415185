import React from 'react';

import Heading from '../Heading';
import * as S from './styles';

export type TabsProps = {
  title?: string;
  labels: string[];
  activeStep: number;
  disabled?: boolean[];
  onSelect: (n) => void;
  children: React.ReactNode[] | string[];
};

export default function Tabs({
  title,
  labels,
  activeStep,
  onSelect,
  disabled,
  children,
}: TabsProps) {
  return (
    <S.Wrapper>
      {title && (
        <Heading level={3} size="xlarge" lineHeight="2rem">
          {title}
        </Heading>
      )}
      <S.List>
        {labels.map((label, i) => (
          <S.Tab
            key={`simple-tab-${i}`}
            type="button"
            aria-controls={`simple-tab-${i}`}
            active={activeStep === i}
            onClick={() => {
              onSelect(i);
            }}
            disabled={disabled?.length ? !disabled[i] : false}
          >
            {label}
          </S.Tab>
        ))}
      </S.List>
      <div key={`simple-sontent-${activeStep}`}>{children[activeStep]}</div>
    </S.Wrapper>
  );
}
