import styled, { css } from 'styled-components';

import { TableProps } from '.';

type WrapperProps = Pick<TableProps, 'width' | 'height'>;

export const Wrapper = styled.div<WrapperProps>`
  ${({ width, height }) => css`
    height: ${height};
    width: ${width};
  `}
`;
