import { IMetaDataProps } from '../common/interfaces/metaData';
import httpClient from '../http-client';
import { httpClientSebrae } from '../http-client/apiSebrae';
import { ICourse, ICourseParams } from '../models/course';

interface ICourseResponse {
  data: ICourse[];
  metaData: IMetaDataProps;
}

const getCourses = async (params?: ICourseParams) => {
  const courses = (
    await httpClient.get<ICourseResponse>(
      `/content/admin/list?types[]=course`,
      { params },
    )
  ).data;
  return courses;
};

const deleteCourses = async (contentId: string) => {
  await httpClient.delete(`content/${contentId}`);
};

const getSouthDate = async (contentId: string) => {
  const codPrograma: string =
    process.env.REACT_APP_SEBRAE_COD_PROGRAMA || '888';

  const courses = (
    await httpClientSebrae.get(
      `/v1/event/${codPrograma}/cod_programa/${contentId}/cod_turma`,
    )
  ).data;
  return courses;
};

export { deleteCourses, getCourses, getSouthDate };
