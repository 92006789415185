import styled, { css } from 'styled-components';

import theme from '../../../styles/theme';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
`;

type CardProps = {
  fullWidth?: boolean;
};

export const Card = styled.div<CardProps>`
  ${({ theme, fullWidth }) => css`
    background-color: ${theme.colors.white};
    box-shadow: ${theme.shadow.card_base};
    border-radius: ${theme.border.radius};
    border: 1px solid #edeff5;
    display: flex;
    flex-direction: column;

    ${fullWidth && `width: 100%`}
  `}
`;

export const CardHeader = styled.div`
  ${() => css`
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
    padding: 1.25rem 1.5rem;
  `}
`;

export const CardBody = styled.div`
  ${() => css`
    flex: 1 1 auto;
    gap: 1rem;
    min-height: 1px;
    padding: 1rem 1.5rem;
  `}
`;

export const Container = styled.div`
  flex: 1 0 auto;
`;

export const Divider = styled.hr`
  margin: 0;
  border-style: dashed;
  border-color: rgba(0, 0, 0, 0.12);
  border-width: 1px;
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
`;

export const CardItem = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 8px;
  padding: 10px 0;
  width: 100%;
`;

type CardItemTextProps = {
  fontSize?:
    | 'xxsmall'
    | 'xsmall'
    | 'small'
    | 'medium'
    | 'large'
    | 'xlarge'
    | 'xxlarge';
  fontWeight?: 'light' | 'normal' | 'semiBold' | 'bold';
  color?: keyof typeof theme.colors;
};

export const CardItemText = styled.span<CardItemTextProps>`
  ${({
    theme,
    fontWeight = 'normal',
    fontSize = 'xsmall',
    color = 'neutral_900',
  }) => css`
    color: ${theme.colors[color]};
    font-size: ${theme.font.sizes[fontSize]};
    font-weight: ${theme.font[fontWeight]};
  `}
`;
