import { zodResolver } from '@hookform/resolvers/zod';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';

import { collectionSchema } from '../../../../common/validations/collectionSchema';
import { createCollection } from '../../../../services/collections';
import CollectionTemplate from '../../../../templates/CollectionTemplate';
import { CollectionFormValues } from '../CollectionEdit';

export default function CollectionCreate() {
  const navigate = useNavigate();
  const methods = useForm<CollectionFormValues>({
    resolver: zodResolver(collectionSchema),
  });

  const submit = (data: CollectionFormValues) => {
    if (Object.keys(data).includes('image_name')) delete data['image_name'];
    if (Object.keys(data).includes('image_toltip')) delete data['image_toltip'];
    if (Object.keys(data).includes('image_file')) delete data['image_file'];
    if (Object.keys(data).includes('thumbnail_file'))
      delete data['thumbnail_file'];
    if (data['authors'] && !data['authors'].length) delete data['authors'];

    createCollection(data)
      .then(() => {
        Swal.fire({
          title: 'Coleção criada com sucesso',
          icon: 'success',
          customClass: {
            title: 'modal-title',
            htmlContainer: 'modal-html-container',
            confirmButton: 'btn-ok',
          },
          buttonsStyling: false,
        }).then(() => navigate(-1));
      })
      .catch(error => {
        Swal.fire({
          icon: 'error',
          title: 'Algo deu errado',
          iconColor: '#f5365c',
          text: error.message,
          customClass: {
            title: 'modal-title',
            htmlContainer: 'modal-html-container',
            confirmButton: 'btn-ok',
          },
          buttonsStyling: false,
        });
      });
  };

  return (
    <FormProvider {...methods}>
      <CollectionTemplate
        title="Criação da Coleção"
        submit={submit}
        view={false}
      />
    </FormProvider>
  );
}
