import Swal from 'sweetalert2';

export const warning = () => {
  Swal.fire({
    icon: 'error',
    iconColor: '#f5365c',
    title: 'Erro',
    text: 'A alteração pode ser prejudicial ao ranqueamento das ferramentas de busca.',
    buttonsStyling: false,
    customClass: {
      title: 'modal-title',
      htmlContainer: 'modal-html-container',
      confirmButton: 'btn-ok',
    },
  });
};

export const onCancel = () => {
  return Swal.fire({
    icon: 'question',
    title: 'Confirmação',
    html: 'Tem certeza que deseja cancelar as alterações?',
    showCancelButton: true,
    cancelButtonText: 'Cancelar',
    reverseButtons: true,
    focusConfirm: true,
    customClass: {
      title: 'modal-title',
      htmlContainer: 'modal-html-container',
      confirmButton: 'btn-ok',
      cancelButton: 'btn-cancel',
    },
    buttonsStyling: false,
  }).then(async result => {
    if (result.isConfirmed) {
      return true;
    } else {
      return false;
    }
  });
};

export const onError = error => {
  console.error(error);
  Swal.fire({
    icon: 'error',
    title: 'Erro ao enviar o formulário',
    text: 'Por favor, preencha o formulário corretamente.',
    customClass: {
      title: 'modal-title',
      htmlContainer: 'modal-html-container',
      confirmButton: 'btn-ok',
    },
    buttonsStyling: false,
  });
};
