import { Box, FormHelperText, Typography } from '@mui/material';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Swal from 'sweetalert2';

import * as appStyles from '../../../../AppStyle';
import ButtonGroup from '../../../../components/ButtonGroup';
import DraftEditor from '../../../../components/DraftEditor/DraftEditor';
import FilesComplementary from '../../../../components/FormComponent/FilesComplementary';
import UploadFile from '../../../../components/FormComponent/UploadFile/UploadFile';
import SambaMainFile from '../../../../components/SambaMainFile';
// Helpers
import toast from '../../../../helpers/toast';
import {
  createLesson,
  editLesson,
  getLesson,
  putLessonDuration,
} from '../../../../services/lessons';
import * as styles from './styles';

const CreateAndEditLesson: React.FC<any> = ({ type }: any) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [isEditing, setIsEditing] = React.useState(true);
  const [isLoading, setIsloading] = React.useState(true);
  const [isShowing, setIsShowing] = React.useState(false);
  const [lesson, setLesson] = React.useState<any>();
  const { handleSubmit, control, setValue, getValues } = useForm();

  const onError = () => {
    Swal.fire({
      title: 'Por favor, preencha o formulário corretamente.',
    });
  };

  const navigate = useNavigate();

  const getContent = async () => {
    setIsloading(true);
    const id = searchParams.get('id');
    if (id) {
      const response = await putLessonDuration(id);

      if (!response || response.duration === 0) {
        Swal.fire({
          title: `Atenção!\n\nO video está passando pelo processo de Encode no Sambavideos.`,
          icon: 'warning',
        });
      }

      await getLesson(id).then(result => {
        if (result.duration === 0 && response.duration !== 0) {
          setValue('duration', response.duration);
        }

        setLesson(result);
        setValue('title', result.title);
        setValue('description', result.description);
        setValue('resume', result.resume);
        setValue(
          'complement_contents',
          result.complement_contents?.map((el: any) => {
            return el.content_id;
          }),
        );
      });
    }
    setIsloading(false);
  };

  React.useEffect(() => {
    if (searchParams.get('id') != null) {
      getContent();
      if (searchParams.get('view') != null) {
        setIsShowing(true);
      }
      setIsEditing(true);
    } else {
      setIsEditing(false);
      setIsloading(false);
    }
  }, []);

  function onSubmit(data: any, status: any) {
    const id: string | null = searchParams.get('id');

    if (data['duration'] === 0) {
      delete data['duration'];
      data['pre_status'] = status;
    } else {
      data['status'] = status;
    }

    delete data['image_name'];
    delete data['image_toltip'];

    if (!isEditing) {
      createLesson(data)
        .then(() => {
          Swal.fire({
            title: 'Aula criado com sucesso',
            icon: 'success',
          });
          navigate(-1);
        })
        .catch(error => {
          if (error.response.data.errors) {
            Swal.fire({
              title: error.response.data.message,
              icon: 'error',
            });
            error.response.data.errors.map(el =>
              toast({
                message: el.message,
                type: 'warning',
              }),
            );
          } else {
            Swal.fire({
              title: 'Algo deu errado',
              icon: 'error',
            });
          }
        });
    } else {
      editLesson(id, data)
        .then(() => {
          Swal.fire({
            title: 'Aula alterada com sucesso',
            icon: 'success',
          });
          navigate(-1);
        })
        .catch(error => {
          if (error.response.data.errors) {
            Swal.fire({
              title: error.response.data.message,
              icon: 'error',
            });
            error.response.data.errors.map(el =>
              toast({
                message: el.message,
                type: 'warning',
              }),
            );
          } else {
            Swal.fire({
              title: 'Algo deu errado',
              icon: 'error',
            });
          }
        });
    }
  }

  return (
    <>
      {!isLoading && (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            height: 'min-content',
          }}
        >
          <styles.CardInput>
            <Typography variant="h4" style={{ margin: '1rem' }}>
              Aulas
            </Typography>
            <styles.ContentSeletor>
              <Box
                component="div"
                sx={{
                  '& .MuiTextField-root': { m: 0, width: '100%' },
                }}
              >
                <styles.Label>Título *</styles.Label>
                <Controller
                  name="title"
                  control={control}
                  rules={{
                    required: true,
                    maxLength: 70,
                  }}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <>
                      <styles.InputText
                        disabled={isShowing}
                        name="title"
                        value={value}
                        inputProps={{ maxLength: 70 }}
                        onChange={onChange}
                        required
                        id="outlined-required"
                      />
                      {value?.length >= 70 ? (
                        <FormHelperText id="outlined-required">
                          <appStyles.inputLabel
                            style={{ color: 'red', marginBottom: '0.5rem' }}
                          >
                            Tamanho máximo de 70 caracteres.
                          </appStyles.inputLabel>
                        </FormHelperText>
                      ) : (
                        ''
                      )}
                      {error !== undefined ? (
                        error.type === 'required' ? (
                          <appStyles.inputLabel
                            style={{ color: 'red', marginBottom: '0.5rem' }}
                          >
                            *Campo obrigatório.
                          </appStyles.inputLabel>
                        ) : (
                          <appStyles.inputLabel
                            style={{ color: 'red', marginBottom: '0.5rem' }}
                          >
                            Tamanho máximo de 70 caracteres.
                          </appStyles.inputLabel>
                        )
                      ) : (
                        <></>
                      )}
                    </>
                  )}
                />
              </Box>
            </styles.ContentSeletor>
            <styles.ContentSeletor>
              <styles.Label>Resumo *</styles.Label>
              <Box
                component="div"
                sx={{
                  '& .MuiTextField-root': { width: '100%' },
                }}
              >
                <Controller
                  name="resume"
                  control={control}
                  rules={{
                    required: true,
                    maxLength: 300,
                  }}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <>
                      <styles.InputTextArea
                        disabled={isShowing}
                        name="resume"
                        value={value}
                        onChange={onChange}
                        maxRows={3}
                        minRows={3}
                        inputProps={{ maxLength: 301 }}
                        multiline
                      />
                      {value?.length > 300 ? (
                        <FormHelperText id="outlined-required">
                          <appStyles.inputLabel
                            style={{ color: 'red', marginBottom: '0.5rem' }}
                          >
                            Tamanho máximo de 300 caracteres.
                          </appStyles.inputLabel>
                        </FormHelperText>
                      ) : (
                        ''
                      )}
                      {error !== undefined &&
                        (error.type === 'required' ? (
                          <appStyles.inputLabel
                            style={{ color: 'red', marginBottom: '0.5rem' }}
                          >
                            *Campo obrigatório.
                          </appStyles.inputLabel>
                        ) : (
                          <appStyles.inputLabel
                            style={{ color: 'red', marginBottom: '0.5rem' }}
                          >
                            Tamanho máximo de 300 caracteres.
                          </appStyles.inputLabel>
                        ))}
                    </>
                  )}
                />
              </Box>
            </styles.ContentSeletor>
            <styles.ContentSeletor>
              <styles.Label>Descrição *</styles.Label>
              <Controller
                name="description"
                control={control}
                rules={{
                  validate: {
                    required: fieldValue =>
                      (fieldValue &&
                        fieldValue.replace(/<[^>]+>/g, '').length > 1) ||
                      '*Campo obrigatório',
                  },
                }}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <>
                    <DraftEditor
                      name={'description'}
                      setValue={setValue}
                      value={value}
                    />
                    {error !== undefined && (
                      <appStyles.inputLabel
                        style={{ color: 'red', marginBottom: '0.5rem' }}
                      >
                        {error.message}
                      </appStyles.inputLabel>
                    )}
                  </>
                )}
              />
            </styles.ContentSeletor>
          </styles.CardInput>
          <styles.CardInput>
            <SambaMainFile
              label="Video"
              setValue={setValue}
              control={control}
              content={lesson}
              view={isShowing}
              type={'lesson'}
              getValues={getValues}
            />
          </styles.CardInput>

          <styles.ContentSeletor>
            <ButtonGroup
              content={lesson}
              type={'lesson'}
              view={isShowing}
              disabledButtons={true}
              handleSubmit={handleSubmit(onSubmit, onError)}
            />
          </styles.ContentSeletor>
        </div>
      )}
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          maxWidth: '100%',
        }}
      >
        <styles.CardInput>
          <FilesComplementary
            control={control}
            setValue={setValue}
            content={lesson}
            view={isShowing}
            permission="all"
          />
        </styles.CardInput>

        <styles.CardInput>
          <div style={{ padding: '22px' }}>
            <UploadFile
              label="Capa"
              type={'lesson'}
              getValues={getValues}
              setValue={setValue}
              control={control}
              imageReference={lesson?.image_file?.reference}
              imageId={lesson?.image_file?.file_id}
            />
          </div>
        </styles.CardInput>
      </div>
    </>
  );
};

export default CreateAndEditLesson;
