import httpClient from '../http-client';
import { IContent } from '../models/content';
import ArticleFromResponse from '../models/from-api-response/article';

interface IArticleResponseDto {
  data: ArticleFromResponse[];
  mataData: any;
}

const getArticles = async () => {
  const articles = (
    await httpClient.get<IArticleResponseDto>(
      `/content/admin/list?types[]=article`,
    )
  ).data;
  return articles.data;
};

const getArticle = async (contentId: string | null): Promise<IContent> => {
  const content = await httpClient.get(`content/${contentId}`);

  return content.data;
};

export { getArticle, getArticles };
