import { AddCircle } from '@mui/icons-material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Button } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';

import { deleteCategory, getCategories } from '../../services/categorization';
import * as categorizationStyles from './styles';

interface CategoryProps {
  category: any;
  setCategory: any;
}

export default function CategoryRow(props: CategoryProps) {
  const [expandedCategories, setExpandedCategories] = React.useState<string[]>(
    [],
  );
  const [openGrandParent, setOpenGrandParent] = React.useState<boolean>(false);
  const navigate = useNavigate();

  const { category, setCategory } = props;

  const mappedCategories = new Map();
  mappedCategories.set('skill', 'Desafio');
  mappedCategories.set('moment', 'Momento');
  mappedCategories.set('sector', 'Setor');

  const editCategorization = (categoryId: string, isGrandChild?: boolean) => {
    navigate(
      `/categorization/createAndEditCategorization?id=${categoryId}${
        isGrandChild ? '&grandChild=true' : ''
      }`,
    );
  };

  const showCategorization = (categoryId: string) => {
    navigate(
      `/categorization/createAndEditCategorization?id=${categoryId}&view=true`,
    );
  };

  const handleDelete = (categorytoDelete: any) => {
    deleteCategory(categorytoDelete)
      .then(response => {
        getCategories().then((data: any) => {
          if (category?.metaData.type === 'skill') {
            setCategory(data.skills);
          } else if (category?.metaData.type === 'moment') {
            setCategory(data.moments);
          } else if (category?.metaData.type === 'sector') {
            setCategory(data.sectors);
          }
        });
      })
      .catch(err => {
        Swal.fire({
          title: err?.response?.data?.message,
          icon: 'error',
        });
      });
  };

  const categoryRowTableHeader = (isGrandChild?) => {
    return (
      <>
        <categorizationStyles.tableColumn>
          <span style={{ color: 'rgb(19, 74, 79)' }}>
            <b>Título</b>
          </span>
        </categorizationStyles.tableColumn>
        <hr style={{ color: 'lightgrey', width: '0.5px' }} />
        <categorizationStyles.tableColumn>
          <span style={{ color: 'rgb(19, 74, 79)' }}>
            <b>NºVínculos</b>
          </span>
        </categorizationStyles.tableColumn>
        <hr style={{ color: 'lightgrey', width: '0.5px' }} />
        {!isGrandChild && (
          <>
            <categorizationStyles.tableColumn>
              <span style={{ color: 'rgb(19, 74, 79)' }}>
                <b>NºCategorias</b>
              </span>
            </categorizationStyles.tableColumn>
            <hr style={{ color: 'lightgrey', width: '0.5px' }} />{' '}
          </>
        )}
        <categorizationStyles.tableColumn>
          <span style={{ color: 'rgb(19, 74, 79)' }}>
            <b>Ações</b>
          </span>
        </categorizationStyles.tableColumn>
      </>
    );
  };
  const categoryRowTableData = (category: any, isGrandChild?) => {
    const title = category?.metaData
      ? mappedCategories.get(category?.metaData?.type)
      : category.name;
    const categoriesNumber = category?.metaData
      ? category?.metaData?.total
      : category.children.length;
    return (
      <>
        <categorizationStyles.tableColumn>
          <span style={{ color: '#28969f' }}>
            <b>{title}</b>
          </span>
        </categorizationStyles.tableColumn>
        <hr style={{ color: 'lightgrey', width: '0.5px' }} />
        <categorizationStyles.tableColumn>
          <span style={{ color: '#28969f' }}>
            <b>{category?.contents_count}</b>
          </span>
        </categorizationStyles.tableColumn>
        <hr style={{ color: 'lightgrey', width: '0.5px' }} />
        {!isGrandChild && (
          <>
            <categorizationStyles.tableColumn>
              <span style={{ color: '#28969f' }}>
                <b>{categoriesNumber}</b>
              </span>
            </categorizationStyles.tableColumn>
            <hr style={{ color: 'lightgrey', width: '0.5px' }} />
          </>
        )}
      </>
    );
  };

  const categoryRowActions = (category: any, grandChild?) => {
    return (
      <categorizationStyles.cellColumn>
        <categorizationStyles.cellRow>
          <Button
            color="error"
            variant="contained"
            size="small"
            style={{ height: '1.8rem', marginRight: '0.25rem' }}
            onClick={event => handleDelete(category)}
          >
            Excluir
          </Button>
        </categorizationStyles.cellRow>
        <categorizationStyles.cellRow>
          <Button
            variant="contained"
            onClick={() => showCategorization(category.category_id)}
            size="small"
            color="info"
            style={{
              height: '1.8rem',
              width: '4.5rem',
              marginTop: '1rem',
              marginRight: '2rem',
            }}
          >
            Exibir
          </Button>
          {grandChild ? (
            <Button
              color="warning"
              onClick={() => editCategorization(category.category_id, true)}
              variant="contained"
              size="small"
              style={{
                height: '1.8rem',
                width: '5.2rem',
                marginTop: '1rem',
                right: '1.4rem',
              }}
            >
              Editar
            </Button>
          ) : (
            <Button
              color="warning"
              onClick={() => editCategorization(category.category_id)}
              variant="contained"
              size="small"
              style={{
                height: '1.8rem',
                width: '5.2rem',
                marginTop: '1rem',
                right: '1.4rem',
              }}
            >
              Editar
            </Button>
          )}
        </categorizationStyles.cellRow>
      </categorizationStyles.cellColumn>
    );
  };

  if (category?.categories?.length > 0) {
    return (
      //parent 1
      <div
        style={{ display: 'flex', flexDirection: 'row' }}
        key={category.category_id}
      >
        {openGrandParent ? (
          <KeyboardArrowDownIcon
            color="primary"
            style={{
              marginTop: '4.5rem',
              marginBottom: 'auto',
              cursor: 'pointer',
            }}
            onClick={() => {
              setOpenGrandParent(false);
            }}
          />
        ) : (
          <ChevronRightIcon
            color="primary"
            style={{
              marginTop: '4.5rem',
              marginBottom: 'auto',
              cursor: 'pointer',
            }}
            onClick={() => {
              setOpenGrandParent(true);
            }}
          />
        )}
        <categorizationStyles.ContentInput
          key={mappedCategories.get(category?.metaData?.type)}
        >
          <categorizationStyles.tableChildHeader>
            {categoryRowTableHeader()}
          </categorizationStyles.tableChildHeader>

          <categorizationStyles.tableRow>
            {categoryRowTableData(category)}
            <>
              <categorizationStyles.cellColumn>
                <categorizationStyles.cellRow>
                  <div
                    style={{
                      margin: '1.5rem 1rem',
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      height: '40px',
                    }}
                  >
                    <Link
                      to={`/categorization/createAndEditCategorization?type=${category?.metaData?.type}`}
                      style={{ textDecoration: 'none', marginLeft: 'auto' }}
                    >
                      <Button
                        startIcon={<AddCircle />}
                        color="primary"
                        variant="contained"
                        size="small"
                      >
                        Adicionar novo
                      </Button>
                    </Link>
                  </div>
                </categorizationStyles.cellRow>
              </categorizationStyles.cellColumn>
            </>
          </categorizationStyles.tableRow>
          {openGrandParent ? (
            category.categories.map((childCategory: any) => {
              if (childCategory?.children?.length > 0) {
                return (
                  //parent 2
                  <div
                    key={childCategory.category_id}
                    style={{ display: 'flex', flexDirection: 'row' }}
                  >
                    {expandedCategories.find(currentCategory => {
                      return currentCategory === childCategory.category_id;
                    }) ? (
                      <KeyboardArrowDownIcon
                        color="primary"
                        style={{
                          marginTop: '4.5rem',
                          marginBottom: 'auto',
                          cursor: 'pointer',
                        }}
                        onClick={() => {
                          let currentExpandedCategories = expandedCategories;

                          const isExpanded = expandedCategories.find(
                            currentCategory => {
                              return (
                                currentCategory === childCategory.category_id
                              );
                            },
                          );

                          if (isExpanded) {
                            currentExpandedCategories =
                              currentExpandedCategories.filter(
                                currentCategory => {
                                  return (
                                    currentCategory !==
                                    childCategory.category_id
                                  );
                                },
                              );
                          } else {
                            currentExpandedCategories =
                              currentExpandedCategories.concat(
                                childCategory.category_id,
                              );
                          }
                          setExpandedCategories(currentExpandedCategories);
                        }}
                      />
                    ) : (
                      <ChevronRightIcon
                        color="primary"
                        style={{
                          marginTop: '4.5rem',
                          marginBottom: 'auto',
                          cursor: 'pointer',
                        }}
                        onClick={() => {
                          let currentExpandedCategories = expandedCategories;

                          const isExpanded = expandedCategories.find(
                            currentCategory => {
                              return (
                                currentCategory === childCategory.category_id
                              );
                            },
                          );

                          if (isExpanded) {
                            currentExpandedCategories =
                              currentExpandedCategories.filter(
                                currentCategory => {
                                  return (
                                    currentCategory !==
                                    childCategory.category_id
                                  );
                                },
                              );
                          } else {
                            currentExpandedCategories =
                              currentExpandedCategories.concat(
                                childCategory.category_id,
                              );
                          }
                          setExpandedCategories(currentExpandedCategories);
                        }}
                      />
                    )}
                    <categorizationStyles.ContentInput key={childCategory.name}>
                      {childCategory?.children?.length > 0 && (
                        <categorizationStyles.tableGranChildHeader>
                          {categoryRowTableHeader()}
                        </categorizationStyles.tableGranChildHeader>
                      )}

                      <categorizationStyles.tableRow>
                        {categoryRowTableData(childCategory)}
                        {categoryRowActions(childCategory)}
                      </categorizationStyles.tableRow>
                      {expandedCategories.find(currentCategory => {
                        return currentCategory === childCategory.category_id;
                      }) ? (
                        <>
                          {childCategory?.children?.length > 0 && (
                            <categorizationStyles.tableGranChildHeader>
                              {categoryRowTableHeader(true)}
                            </categorizationStyles.tableGranChildHeader>
                          )}
                          {childCategory.children.map(grandchild => {
                            //parent 3
                            return (
                              <categorizationStyles.ContentInput
                                key={grandchild.name}
                                style={{
                                  padding: '0',
                                  paddingBottom: '18px',
                                  paddingRight: '39px',
                                }}
                              >
                                <categorizationStyles.tableRow>
                                  {categoryRowTableData(grandchild, true)}
                                  {categoryRowActions(grandchild, true)}
                                </categorizationStyles.tableRow>
                              </categorizationStyles.ContentInput>
                            );
                          })}
                        </>
                      ) : (
                        <></>
                      )}
                    </categorizationStyles.ContentInput>
                  </div>
                );
              } else {
                return (
                  <div
                    key={childCategory.category_id}
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      marginLeft: '1.25rem',
                    }}
                  >
                    <categorizationStyles.ContentInput key={childCategory.name}>
                      <categorizationStyles.tableChildHeader>
                        {categoryRowTableHeader()}
                      </categorizationStyles.tableChildHeader>
                      <categorizationStyles.tableRow>
                        {categoryRowTableData(childCategory)}
                        {categoryRowActions(childCategory)}
                      </categorizationStyles.tableRow>
                    </categorizationStyles.ContentInput>
                  </div>
                );
              }
            })
          ) : (
            <>{}</>
          )}
        </categorizationStyles.ContentInput>
      </div>
    );
  } else {
    return <></>;
  }
}
