import {
  DeleteOutlineOutlined,
  EditOutlined,
  LayersOutlined,
  Link,
  VisibilityOutlined,
} from '@mui/icons-material';
import { Checkbox } from '@mui/material';
import { parseISO } from 'date-fns';
import compareAsc from 'date-fns/compareAsc';
import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';

import Button from '../../components/Button';
import { useGetContent, useTablePagination } from '../../hooks';
import useGetOptions from '../../hooks/useGetOptions';
import { IContent } from '../../models/content';
import {
  deleteContent,
  duplicateContent,
  getAdminContentList,
} from '../../services/contents';
import theme from '../../styles/theme';
import SearchContent from '../../templates/SearchContent';
import { FlexContainer } from '../../templates/SearchContent/styles';
import { datesSearch } from '../../utils';
import contentDatesFormat from '../../utils/contentDatesFormat';
import { handleSort } from '../../utils/handleSort';

const initialState = {
  search: '',
  types: [],
  categories: [],
  skills: [],
  moments: [],
  sectors: [],
  authors: [],
  collections: [],
  groups: [],
  status: '',
  visibility: '',
  is_featured: false,
  consumption_start_at: null,
  consumption_end_at: null,
  published_start_at: null,
  published_end_at: null,
  review_start_at: null,
  review_end_at: null,
};

export default function ContentPage() {
  const [contents, setContents] = useState<IContent[]>([]);
  const [orderBy, setOrderBy] = useState('PUBLISHED_AT');
  const [orderBySort, setOrderBySort] = useState('DESC');

  const { options } = useGetOptions();
  const navigate = useNavigate();
  const {
    paginationModel,
    rowCount,
    setPaginationModel,
    setRowCount,
    changePage,
    changePageSize,
  } = useTablePagination(15);
  const { loading, getVariedContents } = useGetContent(getAdminContentList);
  const methods = useForm({
    defaultValues: initialState,
  });

  const links = {
    article: '/content/article/edit',
    ebook: '/content/ebooks/edit',
    infographics: '/content/infographics/edit',
    thematic_kit: '/content/thematic-kits/edit',
    magazine: '/content/magazine/edit',
    spreadsheet: '/content/management-tools/edit',
    podcast: '/content/podcast/edit',
    research: '/content/research/edit',
    video: '/content/video/edit',
    course: '/lms/courses/edit',
    series: '/series-category/series/edit',
    course_sync: '/synchronous/course/edit',
    exclusive: '/content/exclusive/edit',
  };

  const showContent = useCallback(
    (contentId: string, type: string) => {
      navigate(`${links[type]}?id=${contentId}&view=true`);
    },
    [navigate],
  );

  const editContent = useCallback(
    (contentId: string, type: string) => {
      navigate(`${links[type]}?id=${contentId}`);
    },
    [navigate],
  );

  const removeContent = useCallback(async (contentId: string) => {
    Swal.fire({
      title: '<strong>Confirmação</strong>',
      html: 'Tem certeza que deseja remover este conteúdo?',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      focusConfirm: false,
    }).then(async result => {
      if (result.isConfirmed) {
        try {
          await deleteContent(contentId);

          Swal.fire({
            icon: 'success',
            title: 'Sucesso!',
            text: 'Conteúdo excluído com sucesso!',
          });

          await getVariedContents(setContents, setRowCount, {
            limit: paginationModel.pageSize,
            offset: paginationModel.page,
            order_by_sort: orderBySort,
            order_by: orderBy,
            types: [],
          });
        } catch (error: any) {
          Swal.fire({
            icon: 'error',
            title: 'Erro',
            text: `Erro ao excluir conteúdo. ${
              error.response && error.response.status === 423
                ? 'Este conteúdo já está associado a um módulo!'
                : error.message
            }`,
          });
        }
      }
    });
  }, []);

  const duplicate = useCallback(async (data: any) => {
    data = { ...data };

    const id: string = data['content_id'];
    Swal.fire({
      title: '<strong>Confirmação</strong>',
      html: 'Tem certeza que deseja duplicar este conteúdo?',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      focusConfirm: false,
    }).then(async result => {
      if (result.isConfirmed) {
        try {
          await duplicateContent(id);
          Swal.fire({
            icon: 'success',
            title: 'Sucesso!',
            text: 'Conteúdo duplicado com sucesso!',
          });

          await getVariedContents(setContents, setRowCount, {
            limit: paginationModel.pageSize,
            offset: paginationModel.page,
            order_by_sort: orderBySort,
            order_by: orderBy,
            types: [],
          });
        } catch (error: any) {
          Swal.fire({
            icon: 'error',
            title: 'Erro',
            text: `Erro ao duplicar conteúdo. ${error?.message}`,
          });
        }
      }
    });
  }, []);

  const columns = [
    {
      headerName: 'Título',
      field: 'title',
      minWidth: 200,
      getRowHeight: 100,
      flex: 2,
      renderCell: (cellValues: any) => (
        <p style={{ whiteSpace: 'normal' }}>{cellValues?.value}</p>
      ),
    },
    {
      headerName: 'Link',
      field: 'link',
      minWidth: 40,
      getRowHeight: 100,
      flex: 1,
      renderCell: ({ row }: any) => (
        <a
          href={`${process.env.REACT_APP_FRONT_BASE_URL}/content/${row.slug}`}
          target="_blank"
          rel="noreferrer"
          style={{ color: '#28969f' }}
        >
          <Link />
        </a>
      ),
    },
    {
      headerName: 'Coleções',
      field: 'collection',
      minWidth: 180,
      getRowHeight: 180,
      flex: 2,
      sortable: false,
      renderCell: (cellValues: any) =>
        cellValues?.row.collections !== undefined ? (
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            {cellValues?.row.collections?.map(element => (
              <span style={{ whiteSpace: 'normal' }} key={element.name}>
                {element.name}
              </span>
            ))}
          </div>
        ) : (
          <span></span>
        ),
    },
    {
      headerName: 'Criado',
      field: 'created_at',
      flex: 1,
      sortable: false,
      renderCell: (cellValues: any) => (
        <p style={{ whiteSpace: 'normal' }}>
          {moment(cellValues?.value).format('DD/MM/YYYY')}
        </p>
      ),
    },
    {
      headerName: 'Atualizado',
      field: 'updated_at',
      flex: 1,
      renderCell: (cellValues: any) => (
        <p style={{ whiteSpace: 'normal' }}>
          {moment(cellValues?.value).format('DD/MM/YYYY')}
        </p>
      ),
    },
    {
      headerName: 'Data Expiração',
      field: 'review_in',
      flex: 1,
      renderCell: (cellValues: any) => (
        <p style={{ whiteSpace: 'normal' }}>
          {cellValues?.value === null
            ? ''
            : moment(cellValues?.value).format('DD/MM/YYYY')}
        </p>
      ),
    },
    {
      headerName: 'Destaque',
      field: 'highlight',
      flex: 1,
      sortable: false,
      renderCell: (cellValues: any) => (
        <Checkbox
          disabled
          checked={
            moment(new Date()).isBefore(
              moment(cellValues.row.featured_end_at),
            ) &&
            moment(new Date()).isAfter(moment(cellValues.row.featured_start_at))
          }
          style={{ margin: 'auto' }}
        />
      ),
    },
    {
      headerName: 'Situação',
      field: 'status',
      flex: 1,
      renderCell: (cellValues: any) => (
        <span>
          {cellValues?.value === 'hidden'
            ? 'Ocultado'
            : cellValues?.value === 'draft'
            ? 'Rascunho'
            : 'Publicado'}
        </span>
      ),
    },
    {
      headerName: 'Status',
      field: 'status_review_in',
      flex: 1,
      sortable: false,
      renderCell: (cellValues: any) => {
        if (cellValues.row.review_in === null) return cellValues.row.review_in;

        const newDate = moment(new Date());
        const reviewInDate = moment(new Date(cellValues.row.review_in));
        if (moment(newDate).isBefore(moment(reviewInDate))) {
          return <span>Em dia</span>;
        } else {
          return <span>Atrasado</span>;
        }
      },
    },
    {
      headerName: 'Ações',
      field: 'actions',
      minWidth: 200,
      getRowHeight: 100,
      flex: 1,
      renderCell: (cellValues: any) => (
        <FlexContainer gap="0.4rem" flexWrap="wrap" justifyContent="flex-start">
          <Button
            onClick={() => removeContent(cellValues.id)}
            icon={<DeleteOutlineOutlined fontSize="inherit" />}
            color="secondary"
            title="Remover"
          />
          <Button
            onClick={() => duplicate(cellValues.row)}
            icon={<LayersOutlined fontSize="inherit" />}
            color="secondary"
            title="Duplicar"
          />
          <Button
            onClick={() => editContent(cellValues.id, cellValues.row.type)}
            icon={<EditOutlined fontSize="inherit" />}
            color="secondary"
            title="Editar"
          />
          <Button
            onClick={() => showContent(cellValues.id, cellValues.row.type)}
            icon={<VisibilityOutlined fontSize="inherit" />}
            color="secondary"
            title="Visualizar"
          />
        </FlexContainer>
      ),
    },
  ];

  const getContents = async () => {
    await getVariedContents(setContents, setRowCount, {
      limit: paginationModel.pageSize,
      offset: paginationModel.page,
      order_by_sort: orderBySort,
      order_by: orderBy,
    });
  };

  const clearData = data => {
    for (const item in data) {
      if (data[item] === null || data[item]?.length === 0) delete data[item];
    }

    if (data['skills']) delete data['skills'];
    if (data['moments']) delete data['moments'];
    if (data['sectors']) delete data['sectors'];

    return data;
  };

  const handleSubmit = async formData => {
    const data = clearData(formData);

    const consumptionsEndAtIsAfterStartAt = compareAsc(
      parseISO(data.consumption_end_at),
      parseISO(data.consumption_start_at),
    );

    const publicationEndAtIsAfterStartAt = compareAsc(
      parseISO(data.published_end_at),
      parseISO(data.published_start_at),
    );

    const expirationEndAtIsAfterStartAt = compareAsc(
      parseISO(data.review_end_at),
      parseISO(data.review_start_at),
    );

    if (consumptionsEndAtIsAfterStartAt === -1) {
      Swal.fire({
        icon: 'error',
        title: 'Erro no filtro do Período de Consumo',
        text: 'Período Consumo (Data Fim) não pode ser anterior a Período Consumo (Data Início)',
        confirmButtonColor: theme.colors.default,
      });

      return;
    }

    if (publicationEndAtIsAfterStartAt === -1) {
      Swal.fire({
        icon: 'error',
        title: 'Erro no filtro com Data de Publicação',
        text: 'Publicação(Até) não pode ser anterior a Publicação(De)',
        confirmButtonColor: theme.colors.default,
      });

      return;
    }

    if (expirationEndAtIsAfterStartAt === -1) {
      Swal.fire({
        icon: 'error',
        title: 'Erro no filtro com Data de Expiração',
        text: 'Expiração(Até) não pode ser anterior a Expiração(De)',
        confirmButtonColor: theme.colors.default,
      });

      return;
    }

    await getVariedContents(setContents, setRowCount, {
      limit: paginationModel.pageSize,
      offset: paginationModel.page,
      order_by_sort: orderBySort,
      order_by: orderBy,
      ...data,
    });
  };

  const {
    control: { _formValues },
  } = methods;

  const handleExport = () => {
    const data = clearData(_formValues);
    const dataValid = contentDatesFormat(data);

    if (!dataValid.status) {
      if (dataValid.key && dataValid.key !== '') {
        Swal.fire({
          icon: 'error',
          title: `Erro no filtro ${datesSearch[dataValid.key]}`,
          text: `${datesSearch[dataValid.key]} possui data inválida`,
          confirmButtonColor: theme.colors.default,
        });
        return;
      }
      Swal.fire({
        icon: 'error',
        title: `Erro no filtro de data`,
        text: `Filtro possui data inválida`,
        confirmButtonColor: theme.colors.default,
      });
      return;
    }

    getAdminContentList(dataValid.data)
      .then(() => {
        Swal.fire({
          title: 'Solicitação enviada com sucesso!',
          icon: 'success',
          text: `Solicitação recebida com sucesso, será enviado após o processamento para o email: ${dataValid.data?.email}`,
          customClass: {
            title: 'modal-title',
            htmlContainer: 'modal-html-container',
            confirmButton: 'btn-ok',
          },
          buttonsStyling: false,
        });
      })
      .catch(error => {
        console.error(error);
        Swal.fire({
          icon: 'error',
          iconColor: '#f5365c',
          title: 'Erro ao buscar a lista de conteúdos!',
          text: error.message || error,
          confirmButtonColor: '#5e72e4',
        });
      });
  };

  useEffect(() => {
    setPaginationModel(oldState => ({ ...oldState, page: 1 }));
    handleSubmit(_formValues);
  }, [paginationModel.pageSize, orderBy, orderBySort]);

  useEffect(() => {
    handleSubmit(_formValues);
  }, [paginationModel.page, orderBy, orderBySort]);

  const tableProps = {
    columns: columns,
    rows: contents,
    keyId: 'content_id',
    onPageChange: page => changePage(page),
    onPageSizeChange: pageSize => changePageSize(pageSize),
    page: paginationModel.page - 1,
    rowsPerPageOptions: [5, 10, 15, 20],
    pageSize: paginationModel.pageSize,
    rowCount,
    loading,
    onSortModelChange: sortModel =>
      handleSort({ changePage, setOrderBy, setOrderBySort, sortModel }),
  };

  return (
    <FormProvider {...methods}>
      <SearchContent
        handleExport={handleExport}
        handleSubmit={handleSubmit}
        optionList={options}
        reset={getContents}
        tableProps={tableProps}
      />
    </FormProvider>
  );
}
