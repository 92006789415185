import DeleteOutlineOutlined from '@mui/icons-material/DeleteOutlineOutlined';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import PersonIcon from '@mui/icons-material/Person';
import DatePicker from '@mui/lab/DatePicker';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import TextField from '@mui/material/TextField';
import React, { useEffect, useMemo, useState } from 'react';
import { createPortal } from 'react-dom';
import { useFormContext } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';

import Button from '../../components/Button';
import CustomRowsOverlay from '../../components/CustomRowsOverlay';
import CustomSelect from '../../components/CustomSelect';
import { FlexContainer } from '../../components/FlexContainer';
import Card from '../../components/FormComponent/Card';
import Form from '../../components/FormComponent/Form';
import CustomTextField from '../../components/FormComponent/TextField';
import ModeratorModal from '../../components/SimpleModal/ModeratorModal';
import UserModal from '../../components/SimpleModal/UserModal';
import Spinner from '../../components/Spinner';
import Table, { TableProps } from '../../components/Table';
import { IModerator } from '../../models/moderator';
import { IMinimalUser } from '../../models/user';
import {
  convertStringToCurrency,
  createHeaders,
  dealWithTitleChange,
  formatCpf,
  validateCPF,
} from '../../utils';
import * as S from './styles';

type ClassTemplateProps = {
  title: string;
  visibility?: boolean;
  isLoading: boolean;
  getClass: () => void;
  submit: (data) => Promise<void>;
};

type RowsProps = {
  moderators: IModerator[] | [];
  students: IMinimalUser[] | [];
};

export default function ClassTemplate({
  title,
  visibility,
  isLoading,
  getClass,
  submit,
}: ClassTemplateProps) {
  const [rows, setRows] = useState<RowsProps>({ moderators: [], students: [] });
  const [tableSelected, setTableSelected] = useState<'moderator' | 'student'>(
    'moderator',
  );
  const [modalOpen, setModalOpen] = useState({
    moderator: false,
    student: false,
  });
  const [classModerators, setClassModerators] = useState<string[]>([]);
  const [classUsers, setClassUsers] = useState<string[]>([]);
  const [bottomNavigationSelected, setBottomNavigationSelected] = useState(0);
  const [openWarningModal, setOpenWarningModal] = useState(true);

  const {
    watch,
    getValues,
    setValue,
    handleSubmit,
    setError,
    clearErrors,
    formState: { errors },
  } = useFormContext();
  const navigate = useNavigate();

  useEffect(() => {
    const moderators = getValues('moderators_selected');
    if (moderators?.length) {
      moderators.forEach(moderator => handleModeratorSelected(moderator));
    }
  }, [watch('moderators_selected')]);

  useEffect(() => {
    const users = getValues('users_selected');
    if (users?.length) {
      handleUserSelected(users);
    }
  }, [watch('users_selected')]);

  const audienceOptions = [
    { label: 'Aberto', value: 'open' },
    { label: 'Fechado', value: 'closed' },
  ];

  const statusOptions = [
    { label: 'Ativo', value: 'active' },
    { label: 'Inativo', value: 'inactive' },
  ];

  const handleModalOpen = () => {
    if (tableSelected === 'student' && !watch('sebrae_code_content')) {
      Swal.fire({
        icon: 'error',
        iconColor: '#f5365c',
        title: 'Selecione o código da turma primeiro',
        text: 'Para selecionar os alunos, é necessário informar o código da turma. Preencha esse campo para continuar com a seleção de alunos.',
        buttonsStyling: false,
        customClass: {
          title: 'modal-title',
          htmlContainer: 'modal-html-container',
          confirmButton: 'btn-ok',
        },
        confirmButtonText: 'Entendi',
      });
      return;
    }

    tableSelected === 'moderator'
      ? setModalOpen(prevState => ({ ...prevState, moderator: true }))
      : setModalOpen(prevState => ({ ...prevState, student: true }));
  };

  const handlePrice = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.currentTarget.value;

    if (!value) return '';

    if (Number(value) < 0) {
      setError('price', {
        message: 'O preço não pode ser um valor negativo',
        type: 'min',
      });

      return;
    }

    setValue('price', convertStringToCurrency(value));
  };

  const handleCpfInstructor = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = formatCpf(e.currentTarget.value) || '';
    const isValid = value ? validateCPF(value) : false;

    setValue('instructor_cpf', value);

    if (!isValid) {
      setError('instructor_cpf', {
        message: 'CPF inválido',
      });

      return;
    } else {
      clearErrors('instructor_cpf');
    }
  };

  function CustomToolbar() {
    return (
      <S.ToolBarWrapper>
        <BottomNavigation
          showLabels
          value={bottomNavigationSelected}
          onChange={(_, newValue) => {
            setBottomNavigationSelected(newValue);
          }}
        >
          <BottomNavigationAction
            className="navigation-action"
            label="Moderador"
            onClick={() => setTableSelected('moderator')}
            icon={<ManageAccountsIcon />}
          />
          <BottomNavigationAction
            className="navigation-action"
            label="Aluno"
            onClick={() => setTableSelected('student')}
            icon={<PersonIcon />}
          />
        </BottomNavigation>

        <Button
          type="button"
          variant="contained"
          onClick={handleModalOpen}
          disabled={visibility}
        >
          Selecionar
        </Button>
      </S.ToolBarWrapper>
    );
  }

  const components = {
    NoRowsOverlay: () => {
      const word = tableSelected === 'moderator' ? 'moderador' : 'aluno';
      return <CustomRowsOverlay title={`Nenhum ${word} escolhido`} />;
    },
    Toolbar: CustomToolbar,
  };

  const columns = useMemo(
    () => [
      createHeaders('Nome', 'name', 200),
      createHeaders('E-mail', 'email', 150, 2),
      {
        headerName: 'CPF',
        field: 'cpf',
        minWidth: 100,
        flex: 1,
        sortable: false,
        valueGetter: ({ row }) => formatCpf(row.cpf),
      },
      {
        headerName: 'Ações',
        field: 'actions',
        minWidth: 200,
        getRowHeight: 100,
        flex: 1,
        sortable: false,
        renderCell: cellValues => (
          <Button
            onClick={() =>
              tableSelected === 'moderator'
                ? handleRemoveModerator(cellValues.id)
                : handleRemoveUser(cellValues.id)
            }
            icon={<DeleteOutlineOutlined fontSize="inherit" />}
            color="secondary"
            title="Remover"
            disabled={visibility}
          />
        ),
      },
    ],
    [rows, tableSelected, visibility],
  );

  const tableProps: TableProps = {
    columns,
    rows: tableSelected === 'moderator' ? rows.moderators : rows.students,
    keyId: tableSelected === 'moderator' ? 'moderator_id' : 'user_id',
    rowsPerPageOptions: [10, 25, 50, 75, 100],
    loading: isLoading,
    disableColumnMenu: true,
  };

  const handleModeratorSelected = (moderator: IModerator) => {
    const moderators = [...classModerators, moderator.moderator_id];

    setRows(row => ({ ...row, moderators: [...row.moderators, moderator] }));
    setClassModerators(moderators);
    setValue('moderators', moderators);
  };

  const hasAvailableVacancies = (
    currentUsers: string[] | IMinimalUser[],
    vacancies: number,
  ) => {
    return currentUsers.length + 1 <= vacancies;
  };

  const showWarningModal = () => {
    return Swal.fire({
      icon: 'warning',
      title: 'Aviso: Limite de Vagas Excedido',
      text: 'O número de alunos inscritos ultrapassa o limite de vagas disponíveis para a turma',
      buttonsStyling: false,
      showCancelButton: true,
      customClass: {
        title: 'modal-title',
        htmlContainer: 'modal-html-container',
        confirmButton: 'btn-ok',
        cancelButton: 'btn-cancel',
      },
      confirmButtonText: 'Continuar',
      reverseButtons: true,
    }).then(response => response.isConfirmed);
  };

  const updateUserList = (users: IMinimalUser[]): IMinimalUser[] => {
    const updatedUsers = [...rows.students, ...users];
    const listWithoutDuplicates = updatedUsers.filter(
      (user, index, self) =>
        index === self.findIndex(item => item.user_id === user.user_id),
    );

    return listWithoutDuplicates;
  };

  const registerNewUsers = (users: IMinimalUser[]) => {
    const userListWithoutDuplicates = updateUserList(users);

    setRows(row => ({ ...row, students: userListWithoutDuplicates }));
    const userIds = userListWithoutDuplicates.map(user => user.user_id);
    setClassUsers(userIds);
    setValue('users', userIds);
  };

  const handleUserSelected = async (users: IMinimalUser[]) => {
    const vacancies = Number(watch('vacancies'));

    if (
      (!hasAvailableVacancies(classUsers, vacancies) ||
        !hasAvailableVacancies(users, vacancies)) &&
      modalOpen.student &&
      openWarningModal
    ) {
      const isConfirmed = await showWarningModal();

      if (isConfirmed) {
        registerNewUsers(users);
        setOpenWarningModal(false);
      }
    } else {
      registerNewUsers(users);
    }
  };

  const handleRemoveModerator = (moderatorId: string) => {
    const moderators = rows.moderators.filter(
      moderator => moderator.moderator_id !== moderatorId,
    );

    setClassModerators(moderators.map(moderator => moderator.moderator_id));
    setValue(
      'moderators',
      moderators.map(moderator => moderator.moderator_id),
    );
    setRows(rows => ({
      ...rows,
      moderators,
    }));
  };

  const handleRemoveUser = (userId: string) => {
    const users = rows.students.filter(student => student.user_id !== userId);

    setClassUsers(users.map(user => user.user_id));
    setValue(
      'users',
      users.map(user => user.user_id),
    );
    setRows(rows => ({
      ...rows,
      students: users,
    }));
  };

  const warning = () => {
    Swal.fire({
      icon: 'error',
      iconColor: '#f5365c',
      title: 'Erro',
      text: 'A alteração pode ser prejudicial ao ranqueamento das ferramentas de busca.',
      buttonsStyling: false,
      customClass: {
        title: 'modal-title',
        htmlContainer: 'modal-html-container',
        confirmButton: 'btn-ok',
      },
    });
  };

  const onError = error => {
    console.error(error);
    Swal.fire({
      icon: 'error',
      title: 'Erro ao enviar o formulário',
      text: 'Por favor, preencha o formulário corretamente.',
      customClass: {
        title: 'modal-title',
        htmlContainer: 'modal-html-container',
        confirmButton: 'btn-ok',
      },
      buttonsStyling: false,
    });
  };

  const onCancel = () => {
    if (visibility) {
      navigate(-1);
      return;
    }

    Swal.fire({
      icon: 'question',
      title: 'Confirmação',
      html: 'Tem certeza que deseja cancelar as alterações?',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      reverseButtons: true,
      focusConfirm: true,
      customClass: {
        title: 'modal-title',
        htmlContainer: 'modal-html-container',
        confirmButton: 'btn-ok',
        cancelButton: 'btn-cancel',
      },
      buttonsStyling: false,
    }).then(async result => {
      if (result.isConfirmed) {
        navigate(-1);
      }
    });
  };

  if (isLoading) return <Spinner />;

  return (
    <Card title={title}>
      <Form>
        <FlexContainer gap="1.5rem" alignItems="flex-end" flexWrap="wrap">
          <CustomTextField
            label="Código da Turma"
            name="sebrae_code"
            flex="1 0 10rem"
            disabled={visibility}
          />
          <Button
            type="button"
            variant="contained"
            onClick={getClass}
            disabled={visibility || !watch('sebrae_code')}
            style={{ marginBottom: errors['sebrae_code'] ? '22px' : '0' }}
          >
            Atualizar
          </Button>

          <CustomTextField
            label="Código do Conteúdo"
            name="sebrae_code_content"
            flex="1 0 10.75rem"
            disabled
            required
          />
          <CustomTextField
            label="Numero de Vagas"
            name="vacancies"
            flex="1 0 10.75rem"
            disabled
            type="number"
            required
          />
        </FlexContainer>

        <FlexContainer gap="0.875rem" flexWrap="wrap">
          <CustomTextField
            label="Nome"
            name="name"
            flex="1 0 13.75rem"
            disabled
            required
          />
          <CustomTextField
            label="Descrição"
            name="description"
            flex="1 0 13.75rem"
            disabled
            required
          />
          <CustomTextField
            label="Preço"
            name="price"
            flex="1 0 13.75rem"
            disabled
            onChange={handlePrice}
            min={0}
            required
          />
          <CustomTextField
            label="Duração"
            name="duration"
            flex="1 0 13.75rem"
            disabled
            type="time"
            required
          />
        </FlexContainer>

        <FlexContainer gap="0.875rem" flexWrap="wrap">
          <CustomTextField label="Data início" name="start_at" flex="1 0 15rem">
            <DatePicker
              disabled
              value={watch('start_at') || null}
              onChange={date => setValue('start_at', date)}
              renderInput={params => <TextField {...params} />}
            />
          </CustomTextField>
          <CustomTextField label="Data fim" name="finish_at" flex="1 0 15rem">
            <DatePicker
              disabled
              value={watch('finish_at') || null}
              onChange={date => setValue('finish_at', date)}
              renderInput={params => <TextField {...params} />}
            />
          </CustomTextField>
          <CustomTextField
            label="Status"
            name="status"
            flex="1 0 13.75rem"
            required
          >
            <CustomSelect
              id="status"
              isClearable
              isRtl={false}
              isSearchable
              options={statusOptions}
              onChange={option => {
                !option
                  ? setValue('status', null)
                  : setValue('status', option.value);
              }}
              value={statusOptions.find(
                requester => requester.value === watch('status'),
              )}
              placeholder="Selecione o status"
              thereIsError={Object.keys(errors).includes('status')}
              isDisabled
            />
          </CustomTextField>
          <CustomTextField
            label="Tipo Publico"
            name="audience"
            flex="1 0 13.75rem"
            required
          >
            <CustomSelect
              id="audience"
              isClearable
              isRtl={false}
              isSearchable
              options={audienceOptions}
              onChange={option => {
                !option
                  ? setValue('audience', null)
                  : setValue('audience', option.value);
              }}
              value={audienceOptions.find(
                requester => requester.value === watch('audience'),
              )}
              placeholder="Selecione o tipo de publico"
              thereIsError={Object.keys(errors).includes('audience')}
              isDisabled
            />
          </CustomTextField>
        </FlexContainer>

        <FlexContainer gap="0.875rem" flexWrap="wrap">
          <CustomTextField
            label="Nome do Instrutor"
            name="instructor_name"
            disabled
            flex="1 0 13.75rem"
            onChange={e => {
              const instructorSlug = dealWithTitleChange(e.target.value);
              setValue('instructor_slug', instructorSlug);
            }}
            required
          />
          <CustomTextField
            label="Slug do Instrutor"
            name="instructor_slug"
            flex="1 0 13.75rem"
            onClick={warning}
            disabled
            required
          />
          <CustomTextField
            label="CPF do Instrutor"
            name="instructor_cpf"
            flex="1 0 13.75rem"
            onChange={handleCpfInstructor}
            disabled
            required
          />
          <CustomTextField
            label="E-mail do Instrutor"
            name="instructor_email"
            flex="1 0 13.75rem"
            required
            disabled
          />
        </FlexContainer>

        <Table {...tableProps} mode="client" components={components} />

        <FlexContainer gap="0.875rem" flexWrap="wrap" justifyContent="flex-end">
          <Button
            type="button"
            variant="contained"
            color="secondary"
            onClick={onCancel}
          >
            {visibility ? 'Voltar' : 'Cancelar'}
          </Button>
          <Button
            type="button"
            variant="contained"
            color="success"
            onClick={() => handleSubmit(data => submit(data), onError)()}
            disabled={visibility}
            aria-disabled={visibility}
          >
            Publicar
          </Button>
        </FlexContainer>
      </Form>

      {modalOpen.moderator &&
        createPortal(
          <ModeratorModal
            isOpen={modalOpen.moderator}
            onCloseChange={() =>
              setModalOpen(prevState => ({ ...prevState, moderator: false }))
            }
            handleSelected={handleModeratorSelected}
            classModerator={classModerators}
            handleRemoveSelected={moderatorId =>
              handleRemoveModerator(moderatorId)
            }
          />,
          document.body,
        )}
      {modalOpen.student &&
        createPortal(
          <UserModal
            isOpen={modalOpen.student}
            onCloseChange={() =>
              setModalOpen(prevState => ({ ...prevState, student: false }))
            }
            handleSelected={handleUserSelected}
            handleDeselect={handleRemoveUser}
            classUser={classUsers}
          />,
          document.body,
        )}
    </Card>
  );
}
