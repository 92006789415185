import Swal from 'sweetalert2';

import { IMetaDataProps } from '../common/interfaces/metaData';
import { IParams } from '../common/interfaces/params';
import httpClient from '../http-client';
import TagFromResponse from '../models/from-api-response/tag';
import { ITag } from '../models/tag';

const getTag = async (tagId: string): Promise<TagFromResponse> => {
  const tags = (await httpClient.get(`tags/${tagId}`)).data;

  return tags;
};

const getAllTags = async (
  params?: IParams,
): Promise<{
  data: ITag[];
  metaData: IMetaDataProps;
}> => {
  const tags = (await httpClient.get(`/tag`, { params })).data;

  return { data: tags.tags, metaData: tags.metaData };
};

const createTag = async (newTag: {
  name: string;
  slug: string;
}): Promise<ITag | undefined> => {
  try {
    const createTagResponse = (await httpClient.post(`/tag`, newTag)).data;

    return createTagResponse;
  } catch (error: any) {
    if (error.response) {
      throw new Error(error.response.data.message);
    }

    Swal.fire({
      icon: 'error',
      title: 'Erro ao criar a tag',
      text: `${error.response.data.message}`,
    });

    throw new Error(error.message);
  }
};

const updateTag = async (tagId: string, newContentTab: any) => {
  await httpClient.put(`tags/${tagId}`, newContentTab);
};

const deleteTag = async (tagId: string) => {
  await httpClient.delete(`tags/${tagId}`);
};

const verifyTag = async (tagId: any, verify: boolean) => {
  await httpClient.put(`tags/${tagId}/verified`);
};

export { createTag, deleteTag, getAllTags, getTag, updateTag, verifyTag };
