import httpClient from '../http-client';
import {
  IReportListCount,
  MetaDataProps,
  ReportFromResponse,
  ReportListFromResponse,
} from '../models/from-api-response/report';

const getReports = async () => {
  const reports = await httpClient.get<ReportFromResponse>(`/reports`);

  return reports.data;
};

const getReportsList = async params => {
  const reports = (
    await httpClient.get<{
      data: ReportListFromResponse;
      metaData: MetaDataProps;
    }>(`/reports/list`, { params })
  ).data;

  return reports;
};

const getReportListCount = async () => {
  const response = await httpClient.get<IReportListCount>(
    '/reports/list-count/consumption',
  );

  return response.data;
};

export { getReportListCount, getReports, getReportsList };
