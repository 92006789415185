import httpClient from '../http-client';
import { IListRequest, IRequester } from '../models/requester';

export const getAllRequesters = async (): Promise<IListRequest> => {
  const requesters = (await httpClient.get(`/requester`)).data;

  return requesters;
};

export const createRequest = async (newRequest: {
  name: string;
}): Promise<IRequester> => {
  try {
    const createrequestResponse = (
      await httpClient.post(`requester`, newRequest)
    ).data;

    return createrequestResponse;
  } catch (error: any) {
    if (error.response) {
      throw new Error(error.response.data.message);
    }
    throw new Error(error.message);
  }
};
