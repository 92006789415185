import { IMethodology } from '../../models/SyncCourse';
import { MethodolyException } from '../exceptions/methodolyException';

function checksIntructor(
  methodoly: IMethodology,
  key: string,
  errorMessage: string,
) {
  let classCode: any[];

  if (key === 'email_instructor') {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    classCode = methodoly.events
      .filter(
        event =>
          event[key] === null || event[key] === '' || !re.test(event[key]),
      )
      .map(({ code }) => code);
  } else {
    classCode = methodoly.events
      .filter(event => event[key] === null || event[key] === '')
      .map(({ code }) => code);
  }

  if (classCode.length) {
    return `<b>${errorMessage}:</b> ${classCode.toString()} `;
  }

  return null;
}

function checksPrice(
  methodoly: IMethodology,
  key: string,
  errorMessage: string,
) {
  const classCode = methodoly.events
    .filter(event => event[key] === null || event[key] === 0)
    .map(({ code }) => code);

  if (classCode.length) {
    return `<b>${errorMessage}:</b> ${classCode.toString()} `;
  }

  return null;
}

function checksVacancies(
  methodoly: IMethodology,
  key: string,
  errorMessage: string,
) {
  const classCode = methodoly.events
    .filter(event => event[key] === null)
    .map(({ code }) => code);

  if (classCode.length) {
    return `<b>${errorMessage}:</b> ${classCode.toString()} `;
  }

  return null;
}

function checksField(
  methodoly: IMethodology,
  key: string,
  errorMessage: string,
) {
  const classCode = methodoly.events
    .filter(event => event[key] === null)
    .map(({ code }) => code);

  if (classCode.length) {
    return `<b>${errorMessage}:</b> ${classCode.toString()} `;
  }

  return null;
}

export function validationMethodology(methodoly: IMethodology) {
  const isError: string[] = [];

  const errorCpf = checksIntructor(
    methodoly,
    'cpf_instructor',
    'CPF do instrutor inválido nas turmas',
  );
  const errorEmail = checksIntructor(
    methodoly,
    'email_instructor',
    'Email do instrutor inválido',
  );
  const errorNameInstructor = checksIntructor(
    methodoly,
    'instructor',
    'Nome do instrutor inválido nas turmas',
  );
  const errorPrice = checksPrice(
    methodoly,
    'price',
    'Preço do curso inválido nas turmas',
  );
  const errorName = checksField(methodoly, 'name', 'Nome das turmas inválidas');
  const errorDescription = checksField(
    methodoly,
    'description',
    'Descrição das turmas inválidas',
  );
  const errorVacancies = checksVacancies(
    methodoly,
    'vacancies',
    'Turmas com vagas inválidas',
  );
  const errorDuration = checksField(
    methodoly,
    'duration',
    'Duração das turmas inválidas',
  );
  const errorStart = checksField(
    methodoly,
    'start',
    'Data de inicio das turmas inválidas',
  );
  const errorFinish = checksField(
    methodoly,
    'finish',
    'Data final das turmas inválidas',
  );
  const errorCode = checksField(
    methodoly,
    'code',
    'Código das turmas inválidas',
  );
  const errorStatus = checksField(
    methodoly,
    'status',
    'Status das turmas inválidas',
  );

  if (errorCpf) isError.push(errorCpf);
  if (errorEmail) isError.push(errorEmail);
  if (errorNameInstructor) isError.push(errorNameInstructor);
  if (errorPrice) isError.push(errorPrice);
  if (errorName) isError.push(errorName);
  if (errorDescription) isError.push(errorDescription);
  if (errorVacancies) isError.push(errorVacancies);
  if (errorDuration) isError.push(errorDuration);
  if (errorStart) isError.push(errorStart);
  if (errorFinish) isError.push(errorFinish);
  if (errorCode) isError.push(errorCode);
  if (errorStatus) isError.push(errorStatus);

  if (isError.length) {
    throw new MethodolyException(isError.join('<br><br>'));
  }

  return;
}
