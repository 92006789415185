import { Delete, ViewList } from '@mui/icons-material';
import {
  AppBar,
  Button,
  ButtonGroup,
  Modal,
  Toolbar,
  Typography,
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import React, { useEffect, useRef } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import {
  Control,
  Controller,
  FieldValues,
  UseFormSetValue,
} from 'react-hook-form';

import * as appStyles from '../../../AppStyle';
import { getAllEpisode } from '../../../services/episodeSerie';
import ContainerLesson from '../../Drag/ContainerLesson';
import * as styles from './styles';
import { StyledBox } from './styles';

interface ILesson {
  content: any;
  view: boolean;
  setValue: UseFormSetValue<FieldValues>;
  control: Control<FieldValues, any>;
}

const SeasonsSection: React.FC<ILesson> = ({
  content,
  view,
  setValue,
  control,
}) => {
  const [filterModalOpen, setFilterModalOpen] = React.useState<boolean>(false);

  const [selectedLesson, setSelectedLesson] = React.useState<any>([]);
  const [modalCollections, setModalLessons] = React.useState<any>([]);

  const ordersToRefFileLessons: any = useRef();
  ordersToRefFileLessons.current = selectedLesson;

  const fillLessons = () => {
    const EditingLessons = content.lessons;

    if (EditingLessons.length > 0) {
      setSelectedLesson(EditingLessons);
    }
  };

  useEffect(() => {
    if (content != null) {
      fillLessons();
    }
  }, []);

  const lessonsColumns = [
    {
      headerName: 'Titulo',
      field: 'title',
      minWidth: 220,
      flex: 1,
      renderCell: (cellValues: any) => (
        <p style={{ whiteSpace: 'normal' }}>{cellValues?.value}</p>
      ),
    },
    {
      headerName: 'Descrição',
      field: 'resume',
      minWidth: 220,
      flex: 2,
      renderCell: (cellValues: any) => (
        <p style={{ whiteSpace: 'normal' }}>{cellValues?.value}</p>
      ),
    },
    {
      headerName: 'Selecionar',
      field: '',
      minWidth: 180,
      flex: 1,
      renderCell: (cellValues: any) => {
        return (
          <Button
            variant="contained"
            size="small"
            onClick={() => {
              const currentLesson = selectedLesson;
              currentLesson.push(cellValues.row);
              setSelectedLesson(currentLesson);
              setFilterModalOpen(false);

              const LessonId: any[] = [];
              selectedLesson.forEach((lesson: any) => {
                LessonId.push(lesson.lesson_id);
              });

              setValue('lessons', LessonId);
            }}
          >
            Selecionar
          </Button>
        );
      },
    },
  ];
  React.useEffect(() => {
    getAllEpisode().then((result: any) => {
      setModalLessons(result.data);
    });
  }, []);

  const onChangeDeleteItem = (item: any) => () => {
    const updatedFileCollections = ordersToRefFileLessons.current.filter(
      e => e.lesson_id !== item.lesson_id,
    );
    setSelectedLesson(updatedFileCollections);
  };

  useEffect(() => {
    const LessonId: any[] = [];
    selectedLesson.forEach((lesson: any) => {
      LessonId.push(lesson.lesson_id);
    });

    setValue('lessons', LessonId);
  }, [selectedLesson]);

  return (
    <div style={{ display: 'flex', flexDirection: 'column', margin: '0.5rem' }}>
      <Typography variant="h4" style={{ marginTop: '1rem' }}>
        Episódios *
      </Typography>
      <Controller
        name="lessons"
        control={control}
        rules={{ required: selectedLesson.length === 0 ? true : false }}
        render={({ field: { onChange, value }, fieldState: { error } }) => {
          return (
            <>
              <DndProvider backend={HTML5Backend}>
                <ContainerLesson
                  item={selectedLesson}
                  setItem={setSelectedLesson}
                  onChangeDeleteItem={onChangeDeleteItem}
                />
              </DndProvider>

              {selectedLesson.length === 0 ? (
                <span
                  style={{
                    color: 'disable',
                    marginTop: '1rem',
                    marginBottom: '1rem',
                  }}
                >
                  Nenhuma aula selecionada
                </span>
              ) : (
                <></>
              )}
              {error !== undefined && selectedLesson.length === 0 ? (
                <appStyles.inputLabel
                  style={{ color: 'red', marginBottom: '0.5rem' }}
                >
                  *Campo obrigatório
                </appStyles.inputLabel>
              ) : (
                <></>
              )}
            </>
          );
        }}
      />
      <ButtonGroup
        style={{ boxShadow: '0px 0px' }}
        variant="contained"
        size="small"
      >
        <Button
          disabled={view}
          startIcon={<ViewList />}
          onClick={() => setFilterModalOpen(true)}
          style={{
            marginRight: 1,
          }}
        >
          <span>Listar</span>
        </Button>
        <Button
          disabled={view}
          onClick={() => {
            setSelectedLesson([]);
          }}
          startIcon={<Delete />}
          style={{
            backgroundColor: '#d73925',
            marginRight: 1,
          }}
        >
          <span>Remover Todos</span>
        </Button>
      </ButtonGroup>
      <Modal
        open={filterModalOpen}
        onClose={() => setFilterModalOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{ alignItems: 'center', justifyContent: 'center' }}
      >
        <StyledBox>
          <AppBar
            position="static"
            style={{ marginBottom: '1rem' }}
            sx={{ bgcolor: '#1565c0', height: '3.5rem' }}
          >
            <Toolbar>
              <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                Lista Episódios
              </Typography>
              <Button onClick={() => setFilterModalOpen(false)} color="inherit">
                x
              </Button>
            </Toolbar>
          </AppBar>
          <styles.dataGridHeight>
            <styles.dataGridDisplay>
              <styles.dataGridFlexGrow>
                <DataGrid
                  getRowId={row => row.lesson_id}
                  rowHeight={100}
                  aria-labelledby={'lesson_id'}
                  columns={lessonsColumns}
                  rows={modalCollections}
                />
              </styles.dataGridFlexGrow>
            </styles.dataGridDisplay>
          </styles.dataGridHeight>
        </StyledBox>
      </Modal>
    </div>
  );
};

export default SeasonsSection;
