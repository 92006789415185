import React from 'react';

import { ProfileContainer } from './style';

const Profile: React.FC = () => {
  return (
    <ProfileContainer>
      <h2>Seja Bem Vindo (a)</h2>
    </ProfileContainer>
  );
};

export default Profile;
