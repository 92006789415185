import { GridSortModel } from '@mui/x-data-grid';

type HandleSortTypes = {
  sortModel: GridSortModel;
  letterConverter?: 'toLowerCase' | 'toUpperCase';
  changePage: (page: number) => void;
  setOrderBy: (value: React.SetStateAction<any>) => void;
  setOrderBySort: (value: React.SetStateAction<any>) => void;
};

export function handleSort({
  changePage,
  sortModel,
  setOrderBy,
  setOrderBySort,
  letterConverter = 'toUpperCase',
}: HandleSortTypes) {
  if (!sortModel.length) return;

  changePage(0);

  setOrderBy(sortModel[0].field?.[letterConverter]());
  setOrderBySort(sortModel[0].sort?.toUpperCase() || 'DESC');
}
