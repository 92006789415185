import { Button, TextField } from '@mui/material';
import Card from '@mui/material/Card';
import styled, { css } from 'styled-components';

export const ButtonAddNew = styled(Button)`
  display: flex;
  margin-top: 8px;
  left: 5px;
  padding: 1px 20px;
`;

export const CardBottons = styled(Card)`
  flex-direction: 'row';
  display: flex;
  margin: 2rem;
  width: '100%';
  max-width: '100%';
`;

export const cellRow = styled.div`
  display: flex;
  flex-direction: row;
  margin: 0.5rem;
  height: 0px;
`;

export const cellColumn = styled.div`
  display: flex;
  flex-direction: column;
  height: 80px;
`;

export const Container = styled.div`
  .extra-materials {
    display: flex;
    background: var(--primary-color);
    color: white;
    outline: none;
    border: none;
    cursor: pointer;
    padding: 15px 20px;
    font-size: var(--font-small);
    font-weight: bold;
    border-radius: 5px;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.08),
      0px 4px 6px rgba(50, 50, 93, 0.1);
  }
`;

export const ContentInput = styled.div`
  width: 65rem;
  padding: 18px 20px;
  padding-bottom: 0 !important;
  flex-direction: row;
  justify-content: center;
`;

export const InputText = styled(TextField)`
  line-height: 1.42857143;
  color: #333;
  font-size: 5px;

  fieldset {
    height: 38px;
    border-color: #d2d6de;
    top: 7px;
  }
`;
export const Label = styled.label`
  display: inline-block;
  max-width: 100%;
  margin: 1rem;
  font-weight: 700;
  font-size: 16px;
  line-height: 1;
`;

export const Preview = styled.img`
  margin-top: auto;
  margin-bottom: auto;
  max-height: 200px;
`;
export const tableRow = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: auto;
  margin-bottom: auto;
  border: solid 0.5px gray;
  border-radius: 3px;
`;

export const tableColumn = styled.div`
  display: flex;
  flex-direction: column;
  margin: auto;
  width: 100px;
`;

export const tableHeader = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: auto;
  margin-bottom: 0;
  border: solid 0.5px gray;
  border-radius: 3px;
  margin-left: 5vh;
  margin-right: 30vh;

  background: lightgrey;
`;

export const tableChildHeader = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: auto;
  margin-bottom: 0;
  border: solid 0.5px gray;
  border-radius: 3px;
  margin-left: 0vh;
  margin-right: 0vh !important;
  margin-top: 2vh;
  background: lightgrey;
`;

export const tableGranChildHeader = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: auto;
  margin-bottom: 0;
  border: solid 0.5px gray;
  border-radius: 3px;
  margin-left: 0vh;
  margin-right: 0vh !important;
  margin-top: 2vh;
  background: lightgrey;
`;

const WrapperModifers = {
  open: () => css`
    transform: rotate(90deg);
  `,
  close: () => css`
    transform: rotate(0);
  `,
};

export const Wrapper = styled.div<{ isExpanded: boolean }>`
  ${({ isExpanded }) => css`
    display: flex;
    flex-direction: row;

    .icon {
      transition: transform 100ms ease-in-out;

      ${isExpanded && WrapperModifers.open()}
      ${!isExpanded && WrapperModifers.close()}
    }
  `}
`;
