import httpClient from '../http-client';
import ArticleFromResponse from '../models/from-api-response/article';

interface IEbooksResponseDto {
  data: ArticleFromResponse[];
  mataData: any;
}

const getManagements = async () => {
  const ebooks = (
    await httpClient.get<IEbooksResponseDto>(
      `/content/admin/list?types[]=spreadsheet`,
    )
  ).data;
  return ebooks.data;
};

const getManagement = async (contentId: string | null) => {
  const content = await httpClient.get(`content/${contentId}`);
  return content.data;
};

export { getManagement, getManagements };
