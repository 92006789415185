import styled, { css, DefaultTheme } from 'styled-components';

import { TextAreaProps } from '.';

export const Wrapper = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 0.875rem;
  position: relative;
  width: 100%;
`;

type TextAreaType = {
  thereIsError: boolean;
};

export const TextArea = styled.textarea<TextAreaType>`
  ${({ theme, thereIsError }) => css`
    background-color: ${theme.colors.white};
    border: 1px solid;
    border-color: ${thereIsError ? theme.colors.red_400 : 'hsl(0, 0%, 80%)'};
    border-radius: 0.25rem;
    box-shadow: 0 3px 2px rgba(233, 236, 239, 0.05);
    color: ${theme.colors.gray_600};
    font-size: ${theme.font.sizes.xsmall};
    height: 13.45rem;
    line-height: 1.5;
    padding: 0.625rem 0.75rem;
    resize: none;
    transition: all 0.15s ease-in-out;
    width: 100%;

    :focus {
      border-color: ${thereIsError
        ? theme.colors.red_400
        : theme.colors.default};
      box-shadow: 0 0 0 1px
          ${thereIsError ? theme.colors.red_400 : theme.colors.default},
        3px 4px 8px rgba(94, 114, 228, 0.1);
      outline: 0;
    }

    :hover {
      border-color: hsl(0, 0%, 70%);
    }

    :disabled {
      background-color: ${theme.colors.gray_200};
    }
  `}
`;

export const InvalidFeedback = styled.span`
  ${({ theme }) => css`
    color: ${theme.colors.red_400};
    font-size: 0.75rem;
    margin-top: -0.5rem;
  `}
`;

type LabelProps = Pick<TextAreaProps, 'required'>;

const labelModifiers = {
  true: (theme: DefaultTheme) => css`
    &::after {
      content: ' *';
      color: ${theme.colors.red_400};
    }
  `,
};

export const Label = styled.label<LabelProps>`
  ${({ theme, required }) => css`
    color: ${theme.colors.gray_700};
    font-size: ${theme.font.sizes.xsmall};
    font-weight: ${theme.font.semiBold};

    ${!!required && labelModifiers.true(theme)};
  `}
`;
