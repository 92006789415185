import httpClient from '../http-client';
import { ILesson, ILessonParams } from '../models/lesson';

interface EpisodeResponse {
  data: ILesson[];
  metaData: {
    total: number;
    limit?: number;
    offset?: number;
    search?: string;
  };
}

export const getAllEpisode = async (params?: ILessonParams) => {
  const episode = (
    await httpClient.get<EpisodeResponse>('/lesson?type=series', { params })
  ).data;
  return episode;
};

export const getEpisode = async (lessonId: string): Promise<ILesson> => {
  const episode = (await httpClient.get<ILesson>(`/lesson/${lessonId}`)).data;

  return episode;
};

export const editEpisode = async (id: string | null, data: any) => {
  await httpClient.put(`/lesson/${id}`, data);
};

export const createEpisode = async (body: any): Promise<ILesson> => {
  const episode = (await httpClient.post<ILesson>('/lesson', body)).data;

  return episode;
};

export const deleteEpisode = async (lessonId: string) => {
  await httpClient.delete(`lesson/${lessonId}`);
};
