import React from 'react';
import { createContext, ReactNode, useContext, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { IUser } from '../models/user';
import { signIn } from '../services/signIn';

export interface UserToken {
  api_token: string;
  user: IUser;
}

type loginProps = { email: string; password: string };

type AuthContext = {
  user: IUser | undefined;
  login: (data: loginProps) => Promise<void>;
  logout: () => void;
  loading: boolean;
};

type ProviderProps = {
  children: ReactNode;
};

export const Context = createContext({} as AuthContext);

export default function AuthProvider({ children }: ProviderProps) {
  const [user, setUser] = useState<IUser | undefined>(() => {
    const actualUser = localStorage.getItem('actual-sebraeplay-user');
    return actualUser ? JSON.parse(actualUser) : undefined;
  });
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const login = async (data: loginProps) => {
    setLoading(true);
    await signIn(data)
      .then(data => {
        setUser(data.user);

        localStorage.setItem('sebraeplay-api-token', data.api_token);
        localStorage.setItem(
          'actual-sebraeplay-user',
          JSON.stringify(data.user),
        );
        navigate('/profile');
      })
      .catch(() => setLoading(false));
    setLoading(false);
  };

  const logout = () => {
    setUser(undefined);
    localStorage.removeItem('sebraeplay-api-token');
    localStorage.removeItem('actual-sebraeplay-user');
    navigate('/login');
  };

  const value = useMemo(
    () => ({ user, login, logout, loading }),
    [user, login, logout, loading],
  );

  return <Context.Provider value={value}>{children}</Context.Provider>;
}

export function useAuth() {
  const { user, login, logout, loading } = useContext(Context);
  return { user, login, logout, loading };
}
