import { zodResolver } from '@hookform/resolvers/zod';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import { z } from 'zod';

import { ContentStatusType } from '../../../../common/types/contentStatusType';
import { managementToolsSchema } from '../../../../common/validations/managementToolsValidation';
import { useContentSubmit } from '../../../../hooks';
import { createContent } from '../../../../services/contents';
import ManagementToolsTemplate from '../../../../templates/ManagementToolsTemplate';
import { groups } from '../../../../utils';

export type ManagementToolsFormValues = z.infer<typeof managementToolsSchema>;

export default function ManagementToolsCreate() {
  const methods = useForm<ManagementToolsFormValues>({
    resolver: zodResolver(managementToolsSchema),
    defaultValues: {
      visibility: 'restricted',
      groups: groups,
    },
  });
  const navigate = useNavigate();

  const submit = (
    data: ManagementToolsFormValues,
    status: ContentStatusType,
  ) => {
    const { obj } = useContentSubmit({ data });

    if (data['antecipation'])
      obj['antecipation_groups'] = data['antecipation_groups']?.map(
        group => group.value,
      );
    if (data['topics'] !== undefined || data['topics'] !== null)
      obj['topics'] = data['topics']?.map(topic => topic.topic_id);

    obj['company_units'] = data['company_units'].map(company => company.value);
    obj['company_sizes'] = data['company_sizes'].map(company => company.value);
    obj['tags'] = data['tags'].map(tag => tag.tag_id);
    obj['status'] = status;
    obj['type'] = 'spreadsheet';

    createContent(obj)
      .then(() => {
        Swal.fire({
          title: 'Curso atualizado com sucesso',
          icon: 'success',
          customClass: {
            title: 'modal-title',
            htmlContainer: 'modal-html-container',
            confirmButton: 'btn-ok',
          },
          buttonsStyling: false,
        }).then(() => navigate(-1));
      })
      .catch(error => {
        Swal.fire({
          icon: 'error',
          title: 'Algo deu errado',
          iconColor: '#f5365c',
          text: error.message,
          customClass: {
            title: 'modal-title',
            htmlContainer: 'modal-html-container',
            confirmButton: 'btn-ok',
          },
          buttonsStyling: false,
        });
      });
  };

  return (
    <FormProvider {...methods}>
      <ManagementToolsTemplate
        title="Criação de conteúdo ferramenta de gestão"
        view={false}
        submit={submit}
      />
    </FormProvider>
  );
}
