import { Delete, HideSource, Save } from '@mui/icons-material';
import { Box } from '@mui/material';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
interface IBasicButtonGroup {
  content?: any;
  view: any;
  handleSubmit: any;
  setValue?: any;
  status?: any;
  notShowCancelModal?: boolean;
  closeModal?: any;
  disabledButtons?: boolean;
  isDataSul?: boolean;
  type?: string;
}
export default function BasicButtonGroup(props: IBasicButtonGroup) {
  const navigate = useNavigate();
  const {
    view,
    setValue,
    status,
    handleSubmit,
    notShowCancelModal,
    closeModal,
    disabledButtons,
    type,
    content,
    isDataSul,
  } = props;

  const returnPage = async () => {
    if (notShowCancelModal) {
      closeModal();
    } else {
      Swal.fire({
        title: '<strong>Confirmação</strong>',
        html: 'Tem certeza que deseja cancelar as alterações?',
        showCancelButton: true,
        cancelButtonText: 'Cancelar',
        focusConfirm: false,
      }).then(async result => {
        if (result.isConfirmed) {
          navigate(-1);
        }
      });
    }
  };

  return (
    <Box textAlign="center" marginBottom={'1rem'}>
      <ButtonGroup
        style={{ boxShadow: '0px 0px 0px', width: '100%' }}
        variant="contained"
        size="medium"
      >
        <Button
          disabled={type === 'course' ? !isDataSul : view}
          startIcon={<Save />}
          color="success"
          onClick={() => {
            handleSubmit('published');
          }}
          style={{ marginRight: 2 }}
        >
          <span>Publicar</span>
        </Button>
        <Button
          disabled={view}
          startIcon={<HideSource />}
          onClick={() => {
            Swal.fire({
              title: '<strong>Confirmação</strong>',
              html: 'Tem certeza que deseja ocultar este módulo?',
              showCancelButton: true,
              cancelButtonText: 'Cancelar',
              focusConfirm: false,
            }).then(async result => {
              if (result.isConfirmed) {
                handleSubmit('hidden');
              }
            });
          }}
          style={{ backgroundColor: '#463333', marginRight: 2 }}
        >
          <span>Ocultar</span>
        </Button>
        <Button
          disabled={view}
          startIcon={<Delete />}
          onClick={() => {
            returnPage();
          }}
          style={{ backgroundColor: '#dd4b39', marginRight: 2 }}
        >
          <span>Cancelar</span>
        </Button>
      </ButtonGroup>
    </Box>
  );
}
