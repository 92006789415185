/* eslint-disable react/display-name */
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import Select, {
  components,
  DropdownIndicatorProps,
  SelectInstance,
} from 'react-select';

import InvalidFeedback from '../../InvalidFeedback';
import * as S from './styles';

type MultiSelectProps = {
  label: string;
  name: string;
  placeholder?: string;
  options: any[];
  defaultValue?: any;
  isDisabled?: boolean;
  alternativeState?: string;
};
const DropdownIndicator = (props: DropdownIndicatorProps<true, true>) => {
  return (
    <components.DropdownIndicator {...props}>
      <ArrowDropDownIcon />
    </components.DropdownIndicator>
  );
};

export const MultiSelect = React.forwardRef<
  SelectInstance<true, true>,
  MultiSelectProps
>(
  (
    {
      label,
      options,
      name,
      defaultValue,
      placeholder = '',
      isDisabled = false,
      alternativeState,
    },
    ref,
  ) => {
    const {
      register,
      setValue,
      setError,
      clearErrors,
      formState: { errors },
    } = useFormContext();

    const isError = Object.keys(errors).includes(name);

    const handleChange = itens => {
      if (itens?.length > 5) {
        setError(name, {
          message:
            'Existe mais de 5 itens selecionados, por favor remova alguns itens.',
        });

        return;
      }

      if (isError) {
        clearErrors(name);
      }

      const values = itens.map(item => item.value);
      alternativeState !== undefined && setValue(alternativeState, values);
      setValue(name, values);
    };

    return (
      <S.Wrapper thereIsError={isError}>
        <S.Label>{label}</S.Label>

        <Select
          className="basic-multi-select"
          classNamePrefix="select"
          components={{ DropdownIndicator }}
          isMulti
          defaultValue={defaultValue}
          isClearable={true}
          isRtl={false}
          isSearchable={true}
          name={name}
          data-testid={name}
          options={options}
          placeholder={placeholder}
          onChange={handleChange}
          isDisabled={isDisabled}
          ref={ref}
          {...register}
        />
        {errors[name] && <InvalidFeedback message={errors[name].message} />}
      </S.Wrapper>
    );
  },
);
