import styled, { css, DefaultTheme } from 'styled-components';

export const Wrapper = styled.div`
  display: grid;
  background-color: rgba(237, 242, 244, 1);
  grid-template-columns: 6rem auto;
  grid-template-rows: 3rem auto 6rem;
  overflow: hidden;
  max-height: 100%;
  padding: 30px 30px 0;

  .app-cropper-overlay {
    color: rgba(237, 242, 244, 0.5);
  }

  &.app-cropper {
    height: 100%;
    width: 100%;
    max-height: 100%;
  }

  .fade {
    display: grid;
    flex-grow: 1;
    grid-template-columns: 1fr;
    grid-column: 2;
    grid-row: 2;
    min-height: 0;
  }
`;

export const NavTools = styled.div`
  ${({ theme }) => css`
    align-items: center;
    display: flex;
    grid-column: 1 / span 2;
    justify-content: space-between;
    padding-bottom: 1rem;
    max-height: 3rem;
    z-index: ${theme.layers.menu};
  `}
`;

export const NavMain = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    gap: 1rem;
    grid-column: 1;
    justify-content: center;
    position: absolute;

    top: calc(4rem + 0px);
    bottom: calc(4rem + 0px);
    z-index: ${theme.layers.menu};
  `}
`;

export const UtilFooter = styled.div`
  ${({ theme }) => css`
    align-items: center;
    display: flex;
    grid-column: 2;
    grid-row: 3;
    height: 6rem;
    justify-content: center;
    max-height: 6rem;
    padding: 1rem;
    z-index: ${theme.layers.menu};

    span {
      color: rgba(0, 0, 0, 0.9);
    }
  `}
`;

type ButtonProps = {
  isSelected?: boolean;
  variant: 'setting' | 'done' | 'round' | 'remove';
};

const buttonModifiers = {
  setting: (theme: DefaultTheme, isSelected) => css`
    background-color: ${isSelected ? 'rgba(0, 0, 0, 0.075)' : 'transparent'};
    box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.075);
    border-radius: 0.625rem;
    border: none;
    gap: 0.5rem;
    height: 4rem;
    width: 4rem;
  `,
  done: (theme: DefaultTheme) => css`
    background-color: ${theme.colors.yellow_400};
    border: none;
    border-radius: 9999rem;
    color: ${theme.colors.yellow_800};
    font-weight: ${theme.font.semiBold};
    padding: 0.5rem 0.75rem;
  `,
  round: () => css`
    background-color: rgba(0, 0, 0, 0.075);
    box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.075);
    border: none;
    border-radius: 9999rem;
    padding: 0.5rem 0.75rem;
    height: 2rem;
    width: 2rem;
  `,
  remove: (theme: DefaultTheme) => css`
    background-color: rgba(0, 0, 0, 0.075);
    box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.075);
    border: none;
    border-radius: 9999rem;
    padding: 0.5rem 0.75rem;
    height: 2rem;
    width: 2rem;

    &:hover {
      background-color: ${theme.colors.red_400};

      svg {
        color: ${theme.colors.white};
      }
    }
  `,
};

export const Button = styled.button<ButtonProps>`
  ${({ theme, isSelected, variant }) => css`
    align-items: center;
    color: rgba(0, 0, 0, 0.9);
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;

    & > svg {
      color: rgba(0, 0, 0, 0.8);
      font-size: 1rem;
    }

    & > span {
      font-size: ${theme.font.sizes.xxsmall};
    }

    ${!!variant && buttonModifiers[variant](theme, isSelected)}
  `}
`;

type SettingIconProps = {
  isSelected?: boolean;
};

export const SettingIcon = styled.button<SettingIconProps>`
  ${({ theme }) => css`
    align-items: center;
    background-color: transparent;
    border: none;
    cursor: pointer;
    display: flex;
    fill: ${theme.colors.neutral_800};
    height: 100%;
    outline: 0;
    padding: 5px 15px;
    stroke: ${theme.colors.neutral_800};
    transition: fill 0.5s, stroke 0.5s;
  `}
`;

export const InputDimension = styled.div`
  ${({ theme }) => css`
    align-items: center;
    background-color: rgba(0, 0, 0, 0.075);
    border: 1px solid rgba(0, 0, 0, 0.8);
    border-radius: ${theme.border.radius};
    display: inline-flex;
    max-width: 5rem;
    position: relative;
    width: 100%;

    & > input {
      background-color: transparent;
      border: 0;
      color: rgba(0, 0, 0, 0.9);
      font-size: ${theme.font.sizes.xxsmall};
      font-variant-numeric: tabular-nums;
      padding: 0.4rem 0 0.4rem 0.4rem;
      outline: 0;
      width: 100%;
    }

    & > input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
    }

    & > span {
      color: rgba(0, 0, 0, 0.9);
      font-size: ${theme.font.sizes.xxsmall};
      padding-right: 0.625rem;
    }
  `}
`;

type LoadingProps = {
  visible: boolean;
};

const loadingModifiers = {
  true: () => css`
    opacity: 1;
    visibility: visible;
  `,
  false: () => css`
    opacity: 0;
    visibility: hidden;
  `,
};

export const Loading = styled.div<LoadingProps>`
  ${({ theme, visible }) => css`
    align-items: center;
    color: rgba(0, 0, 0, 0.9);
    display: flex;
    font-size: ${theme.font.sizes.xsmall};
    flex-grow: 1;
    gap: 1rem;
    grid-column: 2;
    grid-row: 2;
    justify-content: center;
    min-height: 0;
    text-align: center;
    transition: 0.5s;

    .custom-loader {
      animation: s3 1s infinite linear;
      background: radial-gradient(farthest-side, rgba(0, 0, 0, 0.9) 94%, #0000)
          top/2px 2px no-repeat,
        conic-gradient(#0000 30%, rgba(0, 0, 0, 0.9));
      border-radius: 50%;
      height: 20px;
      width: 20px;

      -webkit-mask: radial-gradient(
        farthest-side,
        #0000 calc(100% - 2px),
        #000 0
      );
    }

    @keyframes s3 {
      100% {
        transform: rotate(1turn);
      }
    }

    ${!visible ? loadingModifiers.true() : loadingModifiers.false()};
  `}
`;
