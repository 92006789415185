import {
  Box,
  Checkbox,
  FormGroup,
  FormHelperText,
  Typography,
} from '@mui/material';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Swal from 'sweetalert2';

import * as appStyles from '../../../../AppStyle';
import ButtonGroup from '../../../../components/ButtonGroup';
import LessonSection from '../../../../components/FormComponent/Lesson';
// Helpers
import toast from '../../../../helpers/toast';
import {
  createModule,
  editModule,
  getModule,
} from '../../../../services/modules';
import * as styles from './styles';

const CreateAndEditModule: React.FC<any> = ({ type }: any) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [isEditing, setIsEditing] = React.useState(true);
  const [isLoading, setIsloading] = React.useState(true);
  const [isShowing, setIsShowing] = React.useState(false);
  const [module, setModule] = React.useState<any>();
  const { handleSubmit, control, setValue } = useForm();
  const [prerequisitetTrue, setPrerequisiteTrue] =
    React.useState<boolean>(false);
  const [prerequisitetFalse, setPrerequisiteFalse] =
    React.useState<boolean>(false);

  const onError = () => {
    Swal.fire({
      title: 'Por favor, preencha o formulário corretamente.',
    });
  };

  const navigate = useNavigate();

  const getContent = async () => {
    setIsloading(true);
    const id = searchParams.get('id');
    if (id) {
      await getModule(id).then((result: any) => {
        if (result.prerequisite) {
          setPrerequisiteTrue(true);
          setValue('prerequisite', result.prerequisite);
        } else {
          setPrerequisiteFalse(true);
          setValue('prerequisite', result.prerequisite);
        }
        setModule(result);
        setValue('title', result.title);
        setValue('resume', result.resume);
      });
    }
    setIsloading(false);
  };

  React.useEffect(() => {
    if (searchParams.get('id') != null) {
      getContent();
      if (searchParams.get('view') != null) {
        setIsShowing(true);
      }
      setIsEditing(true);
    } else {
      setIsEditing(false);
      setIsloading(false);
    }
  }, []);

  function onSubmit(data: any, status: any) {
    const id: string | null = searchParams.get('id');
    data = { ...data, status: status };
    if (!isEditing) {
      createModule(data)
        .then(() => {
          Swal.fire({
            title: 'Módulo criado com sucesso',
            icon: 'success',
          });
          navigate(-1);
        })
        .catch(error => {
          if (error.response.data.errors) {
            Swal.fire({
              title: error.response.data.message,
              icon: 'error',
            });
            error.response.data.errors.map(el =>
              toast({
                message: el.message,
                type: 'warning',
              }),
            );
          } else {
            Swal.fire({
              title: error.response.data.message,
              icon: 'error',
            });
          }
        });
    } else {
      editModule(id, data)
        .then(() => {
          Swal.fire({
            title: 'Módulo alterado com sucesso',
            icon: 'success',
          });
          navigate(-1);
        })
        .catch(error => {
          if (error.response.data.errors) {
            Swal.fire({
              title: error.response.data.message,
              icon: 'error',
            });
            error.response.data.errors.map(el =>
              toast({
                message: el.message,
                type: 'warning',
              }),
            );
          } else {
            Swal.fire({
              title: error.response.data.message,
              icon: 'error',
            });
          }
        });
    }
  }

  return (
    <>
      {!isLoading && (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            height: 'min-content',
          }}
        >
          <styles.CardInput>
            <div style={{ padding: '22px' }}>
              <Typography variant="h4" style={{ marginBottom: '22px' }}>
                Módulo
              </Typography>
              <styles.ContentSeletor>
                <Box
                  component="div"
                  sx={{
                    '& .MuiTextField-root': { width: '100%' },
                  }}
                >
                  <styles.Label>Título Módulo *</styles.Label>
                  <Controller
                    name="title"
                    control={control}
                    rules={{
                      required: true,
                      maxLength: 70,
                    }}
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <>
                        <styles.InputText
                          disabled={isShowing}
                          name="title"
                          value={value}
                          inputProps={{ maxLength: 70 }}
                          onChange={onChange}
                          required
                          id="outlined-required"
                        />
                        {value?.length >= 70 ? (
                          <FormHelperText id="outlined-required">
                            <appStyles.inputLabel
                              style={{ color: 'red', marginBottom: '0.5rem' }}
                            >
                              Tamanho máximo de 70 caracteres.
                            </appStyles.inputLabel>
                          </FormHelperText>
                        ) : (
                          ''
                        )}
                        {error !== undefined &&
                          (error.type === 'required' ? (
                            <appStyles.inputLabel
                              style={{ color: 'red', marginBottom: '0.5rem' }}
                            >
                              *Campo obrigatório.
                            </appStyles.inputLabel>
                          ) : (
                            <appStyles.inputLabel
                              style={{ color: 'red', marginBottom: '0.5rem' }}
                            >
                              Tamanho máximo de 70 caracteres.
                            </appStyles.inputLabel>
                          ))}
                      </>
                    )}
                  />
                </Box>
              </styles.ContentSeletor>
              <styles.ContentSeletor>
                <styles.Label>Descrição Módulo</styles.Label>
                <Box
                  component="div"
                  sx={{
                    '& .MuiTextField-root': { width: '100%' },
                  }}
                >
                  <Controller
                    name="resume"
                    control={control}
                    rules={{
                      required: false,
                      maxLength: 155,
                    }}
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <>
                        <styles.InputTextArea
                          disabled={isShowing}
                          name="resume"
                          value={value}
                          onChange={onChange}
                          inputProps={{ maxLength: 156 }}
                          multiline
                        />
                        {value?.length > 155 && (
                          <FormHelperText id="outlined-required">
                            <appStyles.inputLabel
                              style={{ color: 'red', marginBottom: '0.5rem' }}
                            >
                              Tamanho máximo de 155 caracteres.
                            </appStyles.inputLabel>
                          </FormHelperText>
                        )}
                        {error !== undefined && (
                          <appStyles.inputLabel
                            style={{ color: 'red', marginBottom: '0.5rem' }}
                          >
                            Tamanho máximo de 155 caracteres.
                          </appStyles.inputLabel>
                        )}
                      </>
                    )}
                  />
                </Box>
              </styles.ContentSeletor>
              <styles.ContentSeletor>
                <Box
                  component="div"
                  sx={{
                    '& .MuiTextField-root': { width: '100%' },
                  }}
                >
                  <styles.Label>O módulo tem pré-requisito? *</styles.Label>
                  <div>
                    <FormGroup style={{ flexDirection: 'row' }}>
                      <Controller
                        name="prerequisite"
                        control={control}
                        rules={{
                          validate: {
                            required: fieldValue => {
                              return (
                                prerequisitetTrue ||
                                (!prerequisitetTrue && prerequisitetFalse) ||
                                '*Campo obrigatório'
                              );
                            },
                          },
                        }}
                        render={({
                          field: { onChange, value },
                          fieldState: { error },
                        }) => (
                          <>
                            <div>
                              <Checkbox
                                checked={prerequisitetTrue}
                                onChange={event => {
                                  onChange(event);
                                  setPrerequisiteTrue(event.target.checked);
                                  setPrerequisiteFalse(!event.target.checked);
                                  setValue('prerequisite', true);
                                }}
                              />
                              <styles.Label>Sim</styles.Label>
                              {error !== undefined &&
                                !(
                                  prerequisitetTrue ||
                                  (!prerequisitetTrue && prerequisitetFalse)
                                ) && (
                                  <appStyles.inputLabel
                                    style={{
                                      color: 'red',
                                      marginBottom: '0.5rem',
                                    }}
                                  >
                                    *Campo obrigatório
                                  </appStyles.inputLabel>
                                )}
                            </div>
                          </>
                        )}
                      />

                      <Controller
                        name="prerequisite"
                        control={control}
                        rules={{
                          validate: {
                            required: fieldValue => {
                              return (
                                prerequisitetFalse ||
                                (!prerequisitetFalse && prerequisitetTrue) ||
                                '*Campo obrigatório'
                              );
                            },
                          },
                        }}
                        render={({
                          field: { onChange, value },
                          fieldState: { error },
                        }) => (
                          <>
                            <div>
                              <Checkbox
                                checked={prerequisitetFalse}
                                onChange={event => {
                                  onChange(event);
                                  setPrerequisiteFalse(event.target.checked);
                                  setPrerequisiteTrue(!event.target.checked);
                                  setValue('prerequisite', false);
                                }}
                              />
                              <styles.Label>Não</styles.Label>
                              {error !== undefined &&
                                !(
                                  prerequisitetFalse ||
                                  (!prerequisitetFalse && prerequisitetTrue)
                                ) && (
                                  <appStyles.inputLabel
                                    style={{
                                      color: 'red',
                                      marginBottom: '0.5rem',
                                      left: '0.9rem',
                                    }}
                                  >
                                    *Campo obrigatório
                                  </appStyles.inputLabel>
                                )}
                            </div>
                          </>
                        )}
                      />
                    </FormGroup>
                  </div>
                </Box>
              </styles.ContentSeletor>
            </div>
          </styles.CardInput>
          <styles.CardInput>
            <div style={{ padding: '22px' }}>
              <LessonSection
                content={module}
                view={isShowing}
                setValue={setValue}
                control={control}
              />
            </div>
          </styles.CardInput>

          <styles.ContentSeletor>
            <div style={{ padding: '22px' }}>
              <ButtonGroup
                content={module}
                type={'module'}
                view={isShowing}
                disabledButtons={true}
                handleSubmit={handleSubmit(onSubmit, onError)}
              />
            </div>
          </styles.ContentSeletor>
        </div>
      )}
    </>
  );
};

export default CreateAndEditModule;
