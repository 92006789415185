import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

import { KeyIds } from '../../../common/enum/keyIds';
import Button from '../../../components/Button/index';
import { FlexContainer } from '../../../components/FlexContainer';
import ContentList from '../../../components/FormComponent/ContentList';
import SyncModule from '../../../components/FormComponent/SyncModule';
import GenericDragAndDrop from '../../../components/GenericDragAndDrop';
import Loading from '../../../components/Loading';
import {
  createknowledges,
  getAllknowledges,
} from '../../../services/knowledges';
import * as S from './styles';

type SyncCourseStageThreeProps = {
  view: boolean;
  isLoading: boolean;
  content: any;
  onSubmit: () => void;
  onCancel: () => void;
  getFunc: (id: string) => Promise<void>;
  handleCloneState: React.Dispatch<React.SetStateAction<any[]>>;
};

export default function SyncCourseStageThree({
  view,
  content,
  onSubmit,
  onCancel,
  getFunc,
  isLoading,
  handleCloneState,
}: SyncCourseStageThreeProps) {
  const courseId = new URLSearchParams(location.search).get('id');
  const { setValue, control, getValues } = useFormContext();

  useEffect(() => {
    if (courseId)
      getFunc(courseId).then(() => {
        handleCloneState(state => {
          const arr = state;
          arr[2] = getValues();

          return arr;
        });
      });
  }, [courseId]);

  if (isLoading) return <Loading />;

  return (
    <S.Form>
      <SyncModule view={view} content={content} isRequired />

      <ContentList
        label="Conteúdos Complementares"
        view={view}
        setValue={setValue}
        control={control}
        content={content?.complement_contents}
        type={'lesson'}
      />

      <GenericDragAndDrop
        view={view}
        keyId={KeyIds.KNOWLEDGE}
        createItens={createknowledges}
        getItens={getAllknowledges}
        name="knowledges"
        label="Conhecimentos que você vai adquirir"
        needSlug
        required
      />

      <FlexContainer gap="0.875rem" flexWrap="wrap" flexDirection="row-reverse">
        <Button type="button" variant="contained" onClick={() => onSubmit()}>
          Salvar e Avançar
        </Button>
        <Button
          type="button"
          variant="contained"
          color="error"
          onClick={onCancel}
        >
          Cancelar
        </Button>
      </FlexContainer>
    </S.Form>
  );
}
