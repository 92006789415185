import httpClient from '../http-client';
import {
  IModerator,
  IModeratorsParams,
  IResponseListModeratorDTO,
} from '../models/moderator';
import { ModeratorFormValues } from '../pages/Users/ModeratorCreate';

export const listModerator = async (
  params?: IModeratorsParams,
): Promise<IResponseListModeratorDTO> => {
  const moderators = await httpClient
    .get('moderators', { params })
    .then(response => response.data);

  return moderators;
};

export const createModerator = async (
  data: ModeratorFormValues,
): Promise<IModerator> => {
  const moderator = await httpClient
    .post('moderators', data)
    .then(response => response.data);

  return moderator;
};

export const editModerator = async (
  data: Pick<ModeratorFormValues, 'name' | 'email'>,
  moderatorId: string,
): Promise<IModerator> => {
  const moderator = await httpClient
    .put(`moderators/${moderatorId}`, data)
    .then(response => response.data);

  return moderator;
};

export const getModeratorById = async (
  moderatorId: string,
): Promise<IModerator> => {
  const moderator = await httpClient
    .get(`moderators/${moderatorId}`)
    .then(response => response.data);

  return moderator;
};
