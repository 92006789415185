import httpClient from '../http-client';
import { ILesson, ILessonParams } from '../models/lesson';

interface ILessonResponse {
  data: ILesson[];
  metaData: {
    total: number;
    limit?: number;
    offset?: number;
    search?: string;
  };
}

interface IPutLessonDurationResponse {
  lesson_id: string;
  duration?: number;
}

export const getAllLessons = async (params?: ILessonParams) => {
  const lessons = (
    await httpClient.get<ILessonResponse>('/lesson?type=course', { params })
  ).data;
  return lessons;
};

export const getLesson = async (lessonId: string) => {
  const lesson = (await httpClient.get<ILesson>(`/lesson/${lessonId}`)).data;

  return lesson;
};

export const putLessonDuration = async (
  lessonId: string,
): Promise<IPutLessonDurationResponse> => {
  const lesson = (await httpClient.put<ILesson>(`/lesson/duration/${lessonId}`))
    .data;

  return lesson;
};

export const editLesson = async (id: string | null, data: any) => {
  await httpClient.put(`/lesson/${id}`, data);
};

export const createLesson = async (body: any) => {
  const lesson = (await httpClient.post<ILesson>('/lesson', body)).data;

  return lesson;
};

export const deleteLesson = async (lessonId: string) => {
  await httpClient.delete(`lesson/${lessonId}`);
};
