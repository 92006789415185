import { Delete, ViewList } from '@mui/icons-material';
import { Box, Modal } from '@mui/material';
import { DataGrid, GridToolbarQuickFilter } from '@mui/x-data-grid';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useFormContext } from 'react-hook-form';
import Swal from 'sweetalert2';

import { ICourseContent, IModuleSynchonous } from '../../../models/SyncCourse';
import { getSyncModule } from '../../../services/syncCourse';
import Button from '../../Button/index';
import ContainerLesson from '../../Drag/ContainerLesson';
import Heading from '../../Heading/index';
import * as S from './styles';

type SyncModuleProps = {
  isRequired?: boolean;
  view: boolean;
  content?: ICourseContent;
};

export default function SyncModule({
  isRequired,
  view,
  content,
}: SyncModuleProps): React.ReactElement {
  const [filterModalOpen, setFilterModalOpen] = useState<boolean>(false);
  const [selectedModules, setSelectedModules] = useState<any[]>([]);
  const [modalModules, setModalModules] = useState<IModuleSynchonous[] | []>(
    [],
  );

  const {
    setValue,
    formState: { errors },
  } = useFormContext();

  useEffect(() => {
    const { modules_sync } = content || {};
    if (modules_sync?.length) {
      setSelectedModules(modules_sync);
    }
  }, [content]);

  const ordersToRefFileModules: any = useRef();
  ordersToRefFileModules.current = selectedModules;

  const onChangeDeleteItem = (item: any) => () => {
    const updatedFileCollections = ordersToRefFileModules.current.filter(
      e => e.module_sync_id !== item.module_sync_id,
    );
    setSelectedModules(updatedFileCollections);
    const ModulesId: string[] = [];
    selectedModules.forEach((module: any) => {
      ModulesId.push(module.module_sync_id);
    });

    setValue('modules_sync', ModulesId);
  };

  function QuickSearchToolbar() {
    return (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          padding: '16px 8px',
          gap: '8px',
        }}
      >
        <span style={{ fontSize: '16px', color: 'rgba(0, 0, 0, 0.5)' }}>
          Search:
        </span>
        <GridToolbarQuickFilter
          sx={{
            height: '40px',
            width: '300px',
          }}
        />
      </Box>
    );
  }

  const modulesColumns = [
    {
      headerName: 'Titulo',
      field: 'title',
      minWidth: 220,
      flex: 1,
      renderCell: cellValues => (
        <p style={{ whiteSpace: 'normal' }}>{cellValues?.value}</p>
      ),
    },
    {
      headerName: 'Descrição',
      field: 'resume',
      minWidth: 220,
      flex: 1,
      renderCell: cellValues => (
        <p style={{ whiteSpace: 'normal' }}>{cellValues?.value}</p>
      ),
    },
    {
      headerName: 'Selecionar',
      field: '',
      minWidth: 180,
      flex: 1,
      renderCell: cellValues => {
        return (
          <Button
            variant="contained"
            onClick={() => {
              const row = cellValues.row;
              const currentModules = selectedModules;
              currentModules.push(row);

              setSelectedModules(currentModules);
              setFilterModalOpen(false);

              const ModulesId: string[] = [];
              selectedModules.forEach((module: any) => {
                ModulesId.push(module.module_sync_id);
              });

              setValue('modules_sync', ModulesId);
            }}
          >
            Selecionar
          </Button>
        );
      },
    },
  ];

  const getModules = useCallback(async () => {
    try {
      const modules = await getSyncModule();
      setModalModules(modules);
    } catch (error: any) {
      console.error(error);
      Swal.fire({
        icon: 'error',
        iconColor: '#f5365c',
        title: 'Erro ao buscar o cursos!',
        text: error.message || error,
        confirmButtonColor: '#5e72e4',
      });
    }
  }, []);

  useEffect(() => {
    getModules();
  }, []);

  return (
    <S.Wrapper>
      <Heading>{isRequired ? 'Módulos*' : 'Módulos'}</Heading>
      <DndProvider backend={HTML5Backend}>
        <ContainerLesson
          item={selectedModules}
          setItem={setSelectedModules}
          onChangeDeleteItem={onChangeDeleteItem}
        />
      </DndProvider>
      {selectedModules.length === 0 && (
        <S.TextContainer>
          <span>Nenhum módulo selecionado</span>
        </S.TextContainer>
      )}
      {errors['modules_sync'] && (
        <S.InvalidFeedback>{errors['modules_sync'].message}</S.InvalidFeedback>
      )}

      <S.ButtonContainer>
        <Button
          disabled={view}
          variant="contained"
          startIcon={<ViewList />}
          onClick={() => setFilterModalOpen(true)}
        >
          <span>Listar</span>
        </Button>
        <Button
          disabled={view}
          variant="contained"
          color="error"
          onClick={() => {
            setSelectedModules([]);
            setValue('modules_sync', []);
          }}
          startIcon={<Delete />}
        >
          <span>Remover Todos</span>
        </Button>
      </S.ButtonContainer>
      <Modal
        open={filterModalOpen}
        onClose={() => setFilterModalOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <S.ModalContent>
          <S.ModalHeader>
            <Heading>Lista Módulos</Heading>
            <Button onClick={() => setFilterModalOpen(false)}>x</Button>
          </S.ModalHeader>
          <DataGrid
            getRowId={row => row.module_sync_id}
            rowHeight={100}
            aria-labelledby={'module_sync_id'}
            columns={modulesColumns}
            rows={modalModules}
            disableColumnMenu
            components={{ Toolbar: QuickSearchToolbar }}
          />
        </S.ModalContent>
      </Modal>
    </S.Wrapper>
  );
}
