import { GridCellParams } from '@mui/x-data-grid';

export function getApplyFilterFnTitle(value: string) {
  const stringNormalized = value
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '');

  return (params: GridCellParams): boolean => {
    const paramsNormalized = params.value
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '');

    return paramsNormalized
      .toLowerCase()
      .includes(stringNormalized.toLowerCase());
  };
}
