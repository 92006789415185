import { zodResolver } from '@hookform/resolvers/zod';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import Swal from 'sweetalert2';

import { authorSchema } from '../../../common/validations/authorValidation';
import { socialLinks } from '../../../constants/authorSocialLinks';
import { IAuthor } from '../../../models/author';
import { AuthorFormValues } from '../../../pages/Records/Author/CreateAndEditAuthors';
import { createAuthor } from '../../../services/authors';
import AuthorTemplate from '../../../templates/AuthorTemplate';
import { removeTraitPoints } from '../../../utils';
import SimpleModal from '..';
import * as S from './styles';

type AddAuthorModalProps = {
  isOpen: boolean;
  onCloseChange: () => void;
  author?: IAuthor;
};

export default function AddAuthorModal({
  isOpen,
  onCloseChange,
  author,
}: AddAuthorModalProps): React.ReactElement {
  const methods = useForm<AuthorFormValues>({
    resolver: zodResolver(authorSchema),
    defaultValues: { social_links: socialLinks },
  });

  const { getValues } = methods;

  const submit = (
    data: AuthorFormValues,
    status: 'published' | 'draft',
    submitFunc,
  ) => {
    const obj = {};
    obj['status'] = status;
    obj['file_id'] = data.image_file_id;

    delete data['file_name'];
    delete data['file_toltip'];
    delete data['image_file_id'];
    delete data['image_file'];
    delete data['file_id'];

    Object.keys(data.social_links).forEach(key => {
      if (
        data.social_links[key] !== null &&
        data.social_links[key].length === 0
      ) {
        data.social_links[key] = null;
      }
    });

    if (data['occupation'] !== null && data['occupation']?.length === 0) {
      delete data['occupation'];
    }

    if (data['occupation']?.length !== 0 && data['type'] === 'company') {
      delete data['occupation'];
    }

    if (getValues('type') === 'company') {
      delete data['company_id'];
    }

    if (data['cpf'] && data['cpf'] !== undefined) {
      data['cpf'] = removeTraitPoints(data['cpf']);
    }

    if (
      (Object.keys(data).includes('email') && data['email'] === null) ||
      (Object.keys(data).includes('email') && data['email'] === '')
    ) {
      delete data['email'] === null;
    }

    if (
      (Object.keys(data).includes('cpf') && data['cpf'] === null) ||
      (Object.keys(data).includes('cpf') && data['cpf'] === '')
    ) {
      delete data['cpf'] === null;
    }

    if (Object.keys(data).includes('company')) {
      delete data['company'];
    }

    Object.assign(obj, data);
    submitFunc(obj);
  };

  const createSubmit = async data => {
    try {
      if (data['tooltip'] === null || data['tooltip'] === '') {
        data['tooltip'] = null;
      }

      await createAuthor(data).then(() => {
        Swal.fire({
          title: 'Autor criado com sucesso',
          icon: 'success',
          customClass: {
            title: 'modal-title',
            htmlContainer: 'modal-html-container',
            confirmButton: 'btn-ok',
          },
          buttonsStyling: false,
        });
        onCloseChange();
      });
    } catch (error: any) {
      Swal.fire({
        icon: 'error',
        title: 'Algo deu errado',
        iconColor: '#f5365c',
        text: error.response.data.message,
        buttonsStyling: false,
        customClass: {
          title: 'modal-title',
          htmlContainer: 'modal-html-container',
          confirmButton: 'btn-ok',
          cancelButton: 'btn-cancel',
        },
      });
    }
  };

  return (
    <SimpleModal
      isOpen={isOpen}
      onCloseChange={onCloseChange}
      title="Cadastrar Novo Autor"
    >
      <S.Wrapper>
        <FormProvider {...methods}>
          <AuthorTemplate
            submitHandler={(data, status) => submit(data, status, createSubmit)}
            returnPage={onCloseChange}
          />
        </FormProvider>
      </S.Wrapper>
    </SimpleModal>
  );
}
