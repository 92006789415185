import { DatePicker } from '@mui/lab';
import * as M from '@mui/material';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import Swal from 'sweetalert2';

import Button from '../../components/Button';
import CustomSelect from '../../components/CustomSelect';
import { FlexContainer } from '../../components/FlexContainer';
import Card from '../../components/FormComponent/Card';
import Form from '../../components/FormComponent/Form';
import TextField from '../../components/FormComponent/TextField';
import { formatCpf, validateCPF } from '../../utils';
import { formatCep } from '../../utils/mask';

type CreateUserTemplateProps = {
  submit?: (data) => Promise<void>;
  cancel: () => void;
  isView?: boolean;
};

export default function CreateUserTemplate({
  cancel,
  submit,
  isView,
}: CreateUserTemplateProps) {
  const { watch, handleSubmit, setValue, setError } = useFormContext();

  const genres = [
    { label: 'Feminino', value: 'feminino' },
    { label: 'Masculino', value: 'masculino' },
    { label: 'Prefiro não informar', value: 'prefiro não informar' },
  ];

  const cpfInputMask = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.target.value = formatCpf(e.target.value) || '';

    const isCpfValid = validateCPF(e.target.value);

    if (e.target.value.length === 14 && isCpfValid === false) {
      setError('cpf', { type: 'validate', message: 'CPF inválido' });
    }
  };

  const cepInputMask = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.target.value = formatCep(e.target.value) || '';
  };

  const numberInputMask = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.target.value = e.target.value.replace(/[^0-9]+/g, '');
  };

  const onlyLetters = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.target.value = e.target.value.replace(/[^A-Z a-z]+/g, '');
  };

  const onError = error => {
    console.error(error);
    Swal.fire({
      icon: 'error',
      title: 'Erro ao enviar o formulário',
      text: 'Por favor, preencha o formulário corretamente.',
      customClass: {
        title: 'modal-title',
        htmlContainer: 'modal-html-container',
        confirmButton: 'btn-ok',
      },
      buttonsStyling: false,
    });
  };

  return (
    <Card>
      <Form onSubmit={submit && handleSubmit(submit, onError)}>
        <FlexContainer gap="1rem" flexWrap="wrap">
          <TextField
            label="Nome"
            name="name"
            flex="1 0 13.75rem"
            disabled={isView}
            onChange={onlyLetters}
            required
          />
          <TextField
            label="E-mail"
            name="email"
            flex="1 0 13.75rem"
            disabled={isView}
            required
          />
          <TextField
            label="CPF"
            name="cpf"
            flex="1 0 13.75rem"
            maxLength={14}
            onChange={cpfInputMask}
            disabled={isView}
            required
          />
        </FlexContainer>

        <FlexContainer gap="1rem" flexWrap="wrap">
          <TextField
            label="CEP"
            name="zipcode"
            flex="1 0 11.75rem"
            maxLength={10}
            onChange={cepInputMask}
            disabled={isView}
          />
          <TextField
            label="Endereço"
            name="address"
            flex="1 0 20.75rem"
            disabled={isView}
          />
          <TextField
            label="Complemento"
            name="complement"
            flex="1 0 16.75rem"
            disabled={isView}
          />
          <TextField
            label="Número"
            name="number"
            flex="1 0 7rem"
            onChange={numberInputMask}
            disabled={isView}
          />
          <TextField
            label="Cidade"
            name="city"
            flex="1 0 13.75rem"
            disabled={isView}
          />
          <TextField
            label="Distrito"
            name="district"
            flex="1 0 13.75rem"
            disabled={isView}
          />
          <TextField label="UF" name="uf" flex="1 0 10rem" disabled={isView} />
        </FlexContainer>

        <FlexContainer gap="1rem" flexWrap="wrap">
          <TextField
            label="Data de Nascimento"
            name="birth_date"
            flex="1 0 13.75rem"
            disabled={isView}
          >
            <DatePicker
              value={watch('birth_date') || null}
              onChange={date => setValue('birth_date', date)}
              renderInput={params => <M.TextField {...params} />}
              disabled={isView}
            />
          </TextField>
          <TextField
            label="Gênero"
            name="gender"
            flex="1 0 13.75rem"
            disabled={isView}
          >
            <CustomSelect
              id="gender"
              isClearable
              isRtl={false}
              isSearchable
              options={genres}
              onChange={gender => {
                if (!gender) {
                  setValue('gender', null);
                } else {
                  setValue('gender', gender.value);
                }
              }}
              placeholder="Selecione o gênero"
              value={
                genres.find(gender => gender.value === watch('gender')) || null
              }
              isDisabled={isView}
            />
          </TextField>
          <TextField
            label="Telefone"
            name="phone_number"
            flex="1 0 13.75rem"
            maxLength={14}
            disabled={isView}
          />
        </FlexContainer>

        <FlexContainer gap="1rem" justifyContent="flex-end" flexWrap="wrap">
          <Button
            type="button"
            onClick={cancel}
            variant="contained"
            color={isView ? 'blue' : 'secondary'}
          >
            {isView ? 'Voltar' : 'Cancelar'}
          </Button>
          {!isView && (
            <Button type="submit" variant="contained" color="blue">
              Enviar
            </Button>
          )}
        </FlexContainer>
      </Form>
    </Card>
  );
}
