import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

import { CategoryResponse, CategoryType } from '../../../@types/categorization';
import { ICategory } from '../../../models/category';
import { getCategories } from '../../../services/categorization';
import Button from '../../Button/index';
import CategoryDropDown from '../../CategoryDropDown';
import { FlexContainer } from '../../FlexContainer';
import SimpleModal from '..';
import * as S from './styles';

type ImageEditingModalProps = {
  isOpen: boolean;
  isView: boolean;
  onCloseChange: () => void;
  handleSend?: () => void;
  categories: {
    backCategories: string[];
    setBackCategories: React.Dispatch<React.SetStateAction<string[]>>;
    setCategories: React.Dispatch<React.SetStateAction<ICategory[]>>;
    setChildrenCategories: React.Dispatch<React.SetStateAction<ICategory[]>>;
  };
  keyImageFile: string;
  keyImageName: string;
  keyImageTooltip: string;
};

export default function ImageEditingModal({
  isOpen,
  isView,
  onCloseChange,
  categories,
  keyImageFile,
  keyImageName,
  keyImageTooltip,
  handleSend,
}: ImageEditingModalProps): React.ReactElement {
  const [skill, setSkill] = React.useState<{
    category: CategoryType;
    setValue: any;
    setState: React.Dispatch<React.SetStateAction<any[]>>;
  }>();

  const {
    setValue,
    getValues,
    formState: { errors },
    clearErrors,
  } = useFormContext();
  const {
    backCategories,
    setBackCategories,
    setCategories,
    setChildrenCategories,
  } = categories;

  useEffect(() => {
    const name = getValues(keyImageName);
    const toltip = getValues(keyImageTooltip);

    if (!name) setValue(keyImageName, '');
    if (!toltip) setValue(keyImageTooltip, '');

    getCategories().then((data: CategoryResponse) => {
      setSkill({ setValue, category: data.skills, setState: setCategories });
    });
  }, []);

  useEffect(() => {
    if (Object.keys(errors).includes(keyImageFile)) {
      clearErrors(keyImageFile);
    }
  }, [backCategories]);

  return (
    <SimpleModal
      isOpen={isOpen}
      onCloseChange={onCloseChange}
      title="Adicione Categoria"
      width="51.875rem"
    >
      <S.Wrapper>
        <FlexContainer gap="0.875rem" flexDirection="column" flex="1 1 18rem">
          <CategoryDropDown
            setBackCategories={setBackCategories}
            backCategories={backCategories}
            setChildrenCategories={setChildrenCategories}
            formProps={skill}
          />
          {errors[keyImageFile] && errors[keyImageFile].categories && (
            <S.InvalidFeedback>
              {errors[keyImageFile]['categories'].message}
            </S.InvalidFeedback>
          )}

          <FlexContainer gap="0.875rem" alignSelf="flex-end">
            <Button
              variant="outlined"
              color="secondary"
              onClick={onCloseChange}
              disabled={isView}
            >
              <span>Cancelar</span>
            </Button>
            <Button variant="contained" onClick={handleSend} disabled={isView}>
              <span>Enviar</span>
            </Button>
          </FlexContainer>
        </FlexContainer>
      </S.Wrapper>
    </SimpleModal>
  );
}
