import React, { useCallback, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';

import { ContentType } from '../../../common/enum/contentType';
import SearchComponents from '../../../components/SearchComponents';
import { TableProps } from '../../../components/Table';
import { useGetContent, useTablePagination } from '../../../hooks';
import useContentColumns from '../../../hooks/useContentColumns';
import useSearchContents from '../../../hooks/useSearchContents';
import {
  deleteContent,
  duplicateContent,
  listContents,
} from '../../../services/contents';
import ContentTemplate from '../../../templates/ContentTemplate';
import { handleSort } from '../../../utils/handleSort';

const Infographics: React.FC = () => {
  const [content, setContent] = useState([] as any[]);
  const [orderBySort, setOrderBySort] = useState('DESC');
  const [orderBy, setOrderBy] = useState('UPDATED_AT');
  const [filter, setFilter] = useState<{ search: string } | undefined>();

  const navigate = useNavigate();
  const { handleParams } = useSearchContents();
  const { paginationModel, rowCount, setRowCount, changePage, changePageSize } =
    useTablePagination();
  const { getVariedContents, loading } = useGetContent(listContents);

  const handleRemove = useCallback(async (contentId: string) => {
    Swal.fire({
      title: '<strong>Confirmação</strong>',
      html: 'Tem certeza que deseja remover este conteúdo?',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      focusConfirm: false,
    }).then(async result => {
      if (result.isConfirmed) {
        try {
          await deleteContent(contentId);

          Swal.fire({
            icon: 'success',
            title: 'Sucesso!',
            text: 'Conteúdo excluído com sucesso!',
          });

          await getVariedContents(setContent, setRowCount, {
            limit: paginationModel.pageSize,
            offset: 1,
            order_by_sort: orderBySort,
            types: [ContentType['INFOGRAPHICS']],
          });
        } catch (error: any) {
          Swal.fire({
            icon: 'error',
            title: 'Erro',
            text: `Erro ao excluir conteúdo. ${
              error.response && error.response.status === 423
                ? 'Este conteúdo já está associado a um módulo!'
                : error.message
            }`,
          });
        }
      }
    });
  }, []);

  const handleDuplicate = useCallback(async (data: string) => {
    Swal.fire({
      title: '<strong>Confirmação</strong>',
      html: 'Tem certeza que deseja duplicar este conteúdo?',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      focusConfirm: false,
    }).then(async result => {
      if (result.isConfirmed) {
        try {
          await duplicateContent(data);
          Swal.fire({
            icon: 'success',
            title: 'Sucesso!',
            text: 'Conteúdo duplicado com sucesso!',
          });

          await getVariedContents(setContent, setRowCount, {
            limit: paginationModel.pageSize,
            offset: 1,
            order_by_sort: orderBySort,
            types: [ContentType['INFOGRAPHICS']],
          });
        } catch (error: any) {
          Swal.fire({
            icon: 'error',
            title: 'Erro',
            text: `Erro ao duplicar conteúdo. ${error?.message}`,
          });
        }
      }
    });
  }, []);

  const handleShow = useCallback(
    (contentId: string) => {
      navigate(`/content/infographics/edit?id=${contentId}&view=true`);
    },

    [navigate],
  );

  const handleEdit = useCallback(
    (contentId: string) => {
      navigate(`/content/infographics/edit?id=${contentId}`);
    },

    [navigate],
  );

  const { columns } = useContentColumns({
    handleRemove,
    handleDuplicate,
    handleEdit,
    handleShow,
    complementLink: 'content',
  });

  const breadcrumbs = [
    <Link key="profile" to="/">
      Home
    </Link>,
    <Link key="content" to="/content">
      Conteúdos
    </Link>,
    <span key="content-content">Infográficos</span>,
  ];

  const handleSearch = async filter => {
    const params = handleParams(
      {
        limit: paginationModel.pageSize,
        offset: 1,
        order_by_sort: orderBySort,
        order_by: orderBy,
        types: [ContentType['INFOGRAPHICS']],
      },
      filter,
    );

    await getVariedContents(setContent, setRowCount, params);
  };

  useEffect(() => {
    const params = handleParams(
      {
        limit: paginationModel.pageSize,
        offset: paginationModel.page,
        order_by_sort: orderBySort,
        order_by: orderBy,
        types: [ContentType['INFOGRAPHICS']],
      },
      { ...filter },
    );

    getVariedContents(setContent, setRowCount, params);
  }, [paginationModel.page, paginationModel.pageSize, orderBySort, orderBy]);

  const tableProps: TableProps = {
    columns: columns,
    rows: content,
    keyId: 'content_id',
    onPageChange: page => changePage(page),
    onPageSizeChange: pageSize => changePageSize(pageSize),
    page: paginationModel.page - 1,
    rowsPerPageOptions: [10, 25, 50, 75, 100],
    pageSize: paginationModel.pageSize,
    rowCount,
    loading,
    onSortModelChange: sortModel =>
      handleSort({ changePage, setOrderBy, setOrderBySort, sortModel }),
    disableColumnMenu: true,
  };

  return (
    <ContentTemplate
      key={'content-table'}
      pageName="infográficos"
      linkToCreatePage="/content/infographics/create"
      breadcrumbs={breadcrumbs}
      searchComponent={
        <SearchComponents
          handleSearch={filters => {
            setFilter(filters);
            handleSearch(filters);
            changePage(0);
          }}
          customPlaceholder="Busque pelo título"
        />
      }
      {...tableProps}
    />
  );
};

export default Infographics;
