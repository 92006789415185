/* eslint-disable react/display-name */
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import Select, {
  components,
  DropdownIndicatorProps,
  SelectInstance,
} from 'react-select';

import * as S from './styles';

type SingleSelectProps = {
  label: string;
  name: string;
  placeholder?: string;
  options: any[];
  defaultValue?: any;
  isClearable?: boolean;
};

const DropdownIndicator = (props: DropdownIndicatorProps<true, true>) => {
  return (
    <components.DropdownIndicator {...props}>
      <ArrowDropDownIcon />
    </components.DropdownIndicator>
  );
};

export const SingleSelect = React.forwardRef<
  SelectInstance<true, true>,
  SingleSelectProps
>(
  (
    {
      label,
      options,
      name,
      defaultValue,
      placeholder = '',
      isClearable = true,
    },
    ref,
  ) => {
    const { register, setValue } = useFormContext();

    const handleChange = item => {
      if (item === null) {
        setValue(name, '');

        return;
      }

      setValue(name, item.value);
    };

    return (
      <S.Wrapper>
        <S.Label>{label}</S.Label>

        <Select
          className="basic-single"
          classNamePrefix="select"
          components={{ DropdownIndicator }}
          defaultValue={defaultValue}
          isClearable={isClearable}
          isRtl={false}
          isSearchable={true}
          name={name}
          data-testid={name}
          options={options}
          placeholder={placeholder}
          onChange={option => handleChange(option)}
          ref={ref}
          {...register}
        />
      </S.Wrapper>
    );
  },
);
