import { AddCircle } from '@mui/icons-material';
import { DatePicker } from '@mui/lab';
import * as M from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { useFormContext } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { MultiValue } from 'react-select';
import { Option } from 'react-select/src/filters';
import Swal from 'sweetalert2';

import { DataOptionType } from '../../common/types/DataOption';
import { modalWithoutSchema } from '../../common/validations/modalWithoutSchema';
import Button from '../../components/Button/index';
import CustomSelect from '../../components/CustomSelect';
import DraftEditor from '../../components/DraftEditor/DraftEditor';
import { FlexContainer } from '../../components/FlexContainer';
import Authors from '../../components/FormComponent/Authors';
import Card from '../../components/FormComponent/Card';
import Categorization from '../../components/FormComponent/Categorization';
import ContentList from '../../components/FormComponent/ContentList';
import FilesComplementary from '../../components/FormComponent/FilesComplementary';
import Form from '../../components/FormComponent/Form';
import TextField from '../../components/FormComponent/TextField';
import Loading from '../../components/Loading';
import RegisterModal from '../../components/SimpleModal/RegisterModal';
import UploadCover from '../../components/UploadCover';
import { IContent } from '../../models/content';
import { StatusType } from '../../pages/Content/Exclusive/ExclusiveCreate';
import { createCompanyUnit, getAllCompanyUnit } from '../../services/company';
import { uploadFile } from '../../services/files';
import { createRequest, getAllRequesters } from '../../services/requester';
import { dealWithTitleChange, groups, visibilities } from '../../utils';

type ExclusiveTemplateType = {
  title: string;
  view: boolean;
  isLoading?: boolean;
  content?: IContent;
  submit: (data, status: StatusType) => void;
};

type OptionsToSelectType = {
  company_units: Option[] | [];
  requesters: Option[] | [];
};

type KeyModalType = 'companyUnits' | 'requester';

type Item = {
  [key: string]: any;
};

type Key = 'company_units' | 'requester';

export default function ExclusiveTemplate({
  title,
  view,
  content,
  isLoading,
  submit,
}: ExclusiveTemplateType) {
  const [optionsToSelect, setOptionsToSelect] = useState<OptionsToSelectType>({
    company_units: [],
    requesters: [],
  });
  const [openModal, setOpenModal] = useState({
    companyUnits: false,
    requester: false,
  });

  const {
    setValue,
    watch,
    getValues,
    formState: { errors },
    clearErrors,
    setError,
    control,
    handleSubmit,
  } = useFormContext();
  const navigate = useNavigate();
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const getOptionsForSelect = async () => {
    const fetchData = async (getDataFunc, key, keyId) => {
      const data = await getDataFunc();

      if (!data[key].length) return [];

      return data[key].map(item => ({
        label: item['name'],
        value: item[keyId],
      }));
    };

    const options = await Promise.all([
      fetchData(getAllCompanyUnit, 'companies_unit', 'company_unit_id'),
      fetchData(getAllRequesters, 'requesters', 'requester_id'),
    ]);

    setOptionsToSelect({
      company_units: options[0],
      requesters: options[1],
    });
  };

  useEffect(() => {
    if (fileInputRef.current && !isLoading) {
      fileInputRef.current.files = getValues('audio_file');
    }
  }, [getValues('audio_file'), isLoading]);

  const handleSelectChange = {
    'select-option': (key: string, option: MultiValue<readonly Option[]>) => {
      setValue(key, option);
    },
    'remove-value': (key: string, option: MultiValue<readonly Option[]>) => {
      setValue(key, option);
    },
    clear: (key: string, option: MultiValue<readonly Option[]>) => {
      setValue(key, option);
    },
  };

  const handleMuiltSelected = <T extends Item>(item: T, key: Key) => {
    const keyIds = getValues(key);

    keyIds === undefined
      ? setValue(key, [{ label: item.label, value: item.value }])
      : setValue(key, [...keyIds, { label: item.label, value: item.value }]);
  };

  const handleSelected = <T extends Item>(item: T, key: Key) => {
    setValue(key, { label: item.label, value: item.value });
  };

  const handleOpenModal = (key: KeyModalType) => {
    setOpenModal(prevState => ({ ...prevState, [key]: !openModal[key] }));
  };

  const warning = () => {
    Swal.fire({
      icon: 'error',
      iconColor: '#f5365c',
      title: 'Erro',
      text: 'A alteração pode ser prejudicial ao ranqueamento das ferramentas de busca.',
      customClass: {
        confirmButton: 'btn-ok',
      },
    });
  };

  const onError = error => {
    console.error(error);
    Swal.fire({
      icon: 'error',
      title: 'Erro ao enviar o formulário',
      text: 'Por favor, preencha o formulário corretamente.',
      customClass: {
        title: 'modal-title',
        htmlContainer: 'modal-html-container',
        confirmButton: 'btn-ok',
      },
      buttonsStyling: false,
    });
  };

  const onCancel = () => {
    Swal.fire({
      icon: 'question',
      title: 'Confirmação',
      html: 'Tem certeza que deseja cancelar as alterações?',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      reverseButtons: true,
      focusConfirm: true,
      customClass: {
        title: 'modal-title',
        htmlContainer: 'modal-html-container',
        confirmButton: 'btn-ok',
        cancelButton: 'btn-cancel',
      },
      buttonsStyling: false,
    }).then(async result => {
      if (result.isConfirmed) {
        navigate(-1);
      }
    });
  };

  const handleTitleChange = e => {
    setValue('page_title', e.target.value);
    const slug = dealWithTitleChange(e.target.value);
    setValue('slug', slug);

    if (Object.keys(errors).includes('page_title')) {
      clearErrors('page_title');
      clearErrors('slug');
    }
  };

  const handleClearAudioFile = async () => {
    try {
      setValue('audio_file', null);
      setValue('audio_file_id', null);
      if (fileInputRef.current) fileInputRef.current.value = '';
    } catch (e: any) {
      console.error(e);
      Swal.fire({
        icon: 'error',
        iconColor: '#D73E51',
        title: 'Erro ao enviar audio descrição',
        text: e.message,
        customClass: {
          confirmButton: 'btn-ok',
        },
      });
    }
  };

  const handleSendAudioFile = async (fileList: FileList | null) => {
    const file = fileList ? fileList['0'] : null;

    if (file) {
      const data = new FormData();

      data.append('file', file);
      data.append('permission', 'public');
      data.append('compress', 'true');

      try {
        const response = await uploadFile(data).then(res => res);

        setValue('audio_file', fileList);
        setValue('audio_file_id', response.file_id);
      } catch (e: any) {
        console.error(e);
        Swal.fire({
          icon: 'error',
          iconColor: '#D73E51',
          title: 'Erro ao enviar audio descrição',
          text: e.message,
          customClass: {
            confirmButton: 'btn-ok',
          },
        });
      }
    }
  };

  const handleAudioFile = async (e: React.FormEvent<HTMLInputElement>) => {
    e.preventDefault();

    const fileList = e.currentTarget.files;
    await handleSendAudioFile(fileList);
  };

  useEffect(() => {
    const reviewIn = getValues('review_in');
    if (reviewIn === undefined) {
      setValue('review_in', null);
    }

    getOptionsForSelect();
  }, []);

  if (isLoading) return <Loading />;

  return (
    <Card title={title}>
      <Form>
        <FlexContainer gap="0.875rem" flexWrap="wrap">
          <TextField
            label="Título da página"
            name="page_title"
            onChange={handleTitleChange}
            disabled={view}
            flex="1 0 13.75rem"
            required
          />
          <TextField
            label="Slug | URL"
            name="slug"
            onClick={warning}
            disabled={view}
            flex="1 0 13.75rem"
            required
          />
          <TextField
            label="Título"
            name="title"
            disabled={view}
            flex="1 0 13.75rem"
            required
          />
          <TextField
            label="Subtítulo"
            name="subtitle"
            disabled={view}
            flex="1 0 13.75rem"
            required
          />
        </FlexContainer>

        <FlexContainer gap="0.875rem" flexDirection="column">
          <TextField
            label="Resumo"
            name="resume"
            disabled={view}
            flex="1"
            required
          />
          <TextField label="Descrição" name="description" required>
            <DraftEditor
              name={'description'}
              setValue={setValue}
              value={watch('description')}
            />
          </TextField>

          <TextField label="Capa (Tamanho 1035x526px)" name="image_file_id">
            <UploadCover
              isView={view}
              sizes={{
                width: 1035,
                heigth: 526,
              }}
              showCategory
            />
          </TextField>
        </FlexContainer>

        <FlexContainer gap="0.875rem" flexWrap="wrap">
          <TextField
            label="Data de Revisão"
            name="review_in"
            flex="1 0 15rem"
            required
          >
            <DatePicker
              disabled={view}
              value={watch('review_in')}
              onChange={date => setValue('review_in', date)}
              renderInput={params => <M.TextField {...params} />}
            />
          </TextField>
          <TextField
            label="Unidade Solicitante"
            name="company_units"
            flex="1 0 13.75rem"
            required
          >
            <CustomSelect
              id="company_units"
              isMulti
              isClearable
              isRtl={false}
              isSearchable
              options={optionsToSelect.company_units}
              onChange={(values, action) => {
                handleSelectChange[action.action]('company_units', values);
              }}
              value={watch('company_units')}
              placeholder="Selecione as unidades"
              thereIsError={Object.keys(errors).includes('company_units')}
              isDisabled={view}
            />
            <div>
              <Button
                startIcon={<AddCircle />}
                variant="contained"
                type="button"
                onClick={() => handleOpenModal('companyUnits')}
                disabled={view}
              >
                Adicionar novo
              </Button>
            </div>
          </TextField>
          <TextField
            label="Nome Solicitante "
            name="requester"
            flex="1 0 13.75rem"
            required
          >
            <CustomSelect
              id="requester"
              isClearable
              isRtl={false}
              isSearchable
              options={optionsToSelect.requesters}
              onChange={(value, action) => {
                handleSelectChange[action.action]('requester', value);
                value && setValue('requester_id', value.value);
              }}
              value={optionsToSelect.requesters.find(
                requester => requester.value === watch('requester_id'),
              )}
              placeholder="Selecione o solicitante"
              thereIsError={Object.keys(errors).includes('requester')}
              isDisabled={view}
            />
            <div>
              <Button
                startIcon={<AddCircle />}
                variant="contained"
                type="button"
                onClick={() => handleOpenModal('requester')}
                disabled={view}
              >
                Adicionar novo
              </Button>
            </div>
          </TextField>
          <TextField
            label="Visibilidade"
            name="visibility"
            flex="1 0 13.75rem"
            required
          >
            <CustomSelect
              id="visibility"
              isClearable
              isRtl={false}
              isSearchable
              options={visibilities}
              onChange={(value, action) => {
                handleSelectChange[action.action]('visibility', value);
                value && setValue('visibility', value.value);

                if (
                  !!value &&
                  value.value === 'public' &&
                  getValues('groups').length
                ) {
                  setValue('groups', null);
                }
              }}
              value={visibilities.find(
                visibility => visibility.value === watch('visibility'),
              )}
              placeholder="Selecione a visibilidade do conteúdo"
              thereIsError={Object.keys(errors).includes('visibility')}
              isDisabled={view}
            />
          </TextField>
          {watch('visibility') === 'restricted' && (
            <TextField
              label="Grupos"
              name="groups"
              flex="1 0 13.75rem"
              required
            >
              <CustomSelect
                isMulti
                id="groups"
                isClearable
                isRtl={false}
                isSearchable
                options={groups}
                onChange={(values, action) => {
                  handleSelectChange[action.action]('groups', values);
                }}
                value={watch('groups')}
                placeholder="Selecione os grupos"
                thereIsError={Object.keys(errors).includes('group')}
                isDisabled={view}
              />
            </TextField>
          )}
        </FlexContainer>
      </Form>

      <FlexContainer gap="0.875rem" flexDirection="column">
        <Authors
          clearErrors={clearErrors}
          setError={setError}
          control={control}
          setValue={setValue}
          content={content}
          view={view}
        />
        <FilesComplementary
          control={control}
          setValue={setValue}
          content={content}
          view={view}
          permission="unlisted"
        />
        <ContentList
          label="Conteúdos Complementares"
          view={view}
          setValue={setValue}
          control={control}
          content={content?.complement_contents}
          type={'lesson'}
        />
        <Categorization
          control={control}
          setValue={setValue}
          content={content}
          view={view}
          type="skill"
        />
      </FlexContainer>

      <FlexContainer
        gap="0.875rem"
        flexWrap="wrap"
        flexDirection="row-reverse"
        justifyContent="center"
      >
        <Button
          type="button"
          variant="contained"
          color="success"
          onClick={() =>
            handleSubmit(data => submit(data, 'unlisted'), onError)()
          }
          disabled={view}
        >
          Publicar
        </Button>
        <Button
          type="button"
          variant="outlined"
          color="secondary"
          onClick={() => handleSubmit(data => submit(data, 'draft'), onError)()}
          disabled={view}
        >
          Rascunho
        </Button>
        <Button
          type="button"
          variant="contained"
          color="warning"
          onClick={() => {
            Swal.fire({
              title: '<strong>Confirmação</strong>',
              html: 'Tem certeza que deseja ocultar o curso?',
              showCancelButton: true,
              cancelButtonText: 'Cancelar',
              focusConfirm: false,
              buttonsStyling: false,
              reverseButtons: true,
              customClass: {
                confirmButton: 'btn-ok',
                cancelButton: 'btn-cancel',
                title: 'modal-title',
                htmlContainer: 'modal-html-container',
              },
            }).then(async result => {
              if (result.isConfirmed) {
                handleSubmit(data => submit(data, 'hidden'), onError)();
              }
            });
          }}
          disabled={view}
        >
          Ocultar
        </Button>
        <Button
          type="button"
          variant="contained"
          color="error"
          onClick={onCancel}
          disabled={view}
          aria-disabled={view}
        >
          Cancelar
        </Button>
      </FlexContainer>
      {openModal.companyUnits &&
        createPortal(
          <RegisterModal
            schema={modalWithoutSchema}
            isOpen={openModal.companyUnits}
            onCloseChange={() => handleOpenModal('companyUnits')}
            keyId={'company_unit_id'}
            funcCreate={createCompanyUnit}
            handleOptionChange={data =>
              handleMuiltSelected(data, 'company_units')
            }
            needSlug={false}
          />,
          document.body,
        )}
      {openModal.requester &&
        createPortal(
          <RegisterModal
            schema={modalWithoutSchema}
            isOpen={openModal.requester}
            onCloseChange={() => handleOpenModal('requester')}
            keyId={'requester_id'}
            funcCreate={createRequest}
            handleOptionChange={(data: DataOptionType) => {
              const requesters = [...optionsToSelect.requesters];
              requesters.push({
                label: data.label,
                value: data.value,
                data: data.data,
              });

              handleSelected(data, 'requester');
              setValue('requester_id', data.value);
              setOptionsToSelect(prev => ({ ...prev, requesters }));
            }}
            needSlug={false}
          />,
          document.body,
        )}
    </Card>
  );
}
