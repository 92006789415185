import { Button, InputLabel, TextField } from '@mui/material';
import Card from '@mui/material/Card';
import styled from 'styled-components';

export const CardBottons = styled(Card)`
  flex-direction: 'row';
  display: flex;
  margin: 2rem;
  width: '100%';
  max-width: '100%';
`;

export const InputText = styled(TextField)`
  line-height: 1.42857143;
  color: #333;
  font-size: 5px;

  fieldset {
    height: 38px;
    border-color: #d2d6de;
    top: 7px;
    left: 5px;
  }
`;
export const InputTextArea = styled(TextField)`
  line-height: 1.42857143;
  color: #333;
  font-size: 5px;

  fieldset {
    border-color: #d2d6de;
    top: 7px;
    left: 5px;
  }
`;
export const Label = styled.label`
  display: inline-block;
  max-width: 100%;
  margin: 0.5rem;
  font-weight: 700;
  font-size: 16px;
  line-height: 1;
`;

export const Preview = styled.img`
  margin-top: auto;
  margin-bottom: auto;
  max-height: 200px;
`;

export const ButtonAddNew = styled(Button)`
  display: flex;
  margin-top: 8px;
  left: 5px;
  padding: 1px 20px;
`;
export const inputLabel = styled(InputLabel)``;

export const column = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Title = styled.label`
  display: inline-block;
  max-width: 100%;

  text-align: center;
  font-weight: 800;
  font-size: 18px;
  line-height: 1;
  margin-top: 0.5rem;
  color: #444;
`;

export const row = styled.div`
  display: flex;
  flex-direction: row;
`;

export const rowIcon = styled.div`
  flex-direction: column;
`;

export const span = styled.p`
  max-width: 100%;
  text-align: end;
  font-weight: 900;
  font-size: 14px;
  background-color: #cccccc;
  color: #444;
`;

export const spanType = styled.p`
  max-width: 100%;
  font-weight: 900;
  font-size: 15px;
  margin-left: 0.2rem;
  color: #444;
  flex-direction: row;
  justify-content: space-between;
`;

export const ContentSeletor = styled.div`
  padding: 0px 15px;
  margin-bottom: 10px;
`;

export const ContentImg = styled.div`
  padding: 0px 15px;
  margin-bottom: 10px;
  align-self: center;
`;

export const spanRequired = styled.span`
  color: red;
`;
