import { zodResolver } from '@hookform/resolvers/zod';
import React, { useCallback, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';

import { moderatorSchema } from '../../../common/validations/moderatorValidation';
import Loading from '../../../components/Loading';
import { editModerator, getModeratorById } from '../../../services/moderator';
import ModeratorTemplate from '../../../templates/ModeratorTemplate';
import { formatCpf } from '../../../utils';
import { ModeratorFormValues } from '../ModeratorCreate';

export default function ModeratorEdit() {
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();
  const methods = useForm<ModeratorFormValues>({
    resolver: zodResolver(moderatorSchema),
  });

  const state = location.state as { moderator_id: string };

  const handleCancel = () => {
    Swal.fire({
      icon: 'question',
      title: 'Confirmação',
      html: 'Tem certeza que deseja cancelar o cadastro?',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      reverseButtons: true,
      focusConfirm: true,
      customClass: {
        title: 'modal-title',
        htmlContainer: 'modal-html-container',
        confirmButton: 'btn-ok',
        cancelButton: 'btn-cancel',
      },
      buttonsStyling: false,
    }).then(async result => {
      if (result.isConfirmed) {
        methods.reset();
        navigate(-1);
      }
    });
  };

  const handleSubmit = async (data: ModeratorFormValues) => {
    const obj = {} as Pick<ModeratorFormValues, 'name' | 'email'>;

    Object.assign(obj, data);
    delete obj['cpf'];

    editModerator(obj, state.moderator_id)
      .then(() => {
        Swal.fire({
          title: 'Informações do moderador atualizadas com sucesso',
          icon: 'success',
          customClass: {
            title: 'modal-title',
            htmlContainer: 'modal-html-container',
            confirmButton: 'btn-ok',
          },
          buttonsStyling: false,
        }).then(() => navigate(-1));
      })
      .catch(error => {
        Swal.fire({
          icon: 'error',
          title: 'Algo deu errado ao atualizado as informações do moderador',
          iconColor: '#f5365c',
          text: error.message,
          customClass: {
            title: 'modal-title',
            htmlContainer: 'modal-html-container',
            confirmButton: 'btn-ok',
          },
          buttonsStyling: false,
        });
      });
  };

  const getModerator = useCallback(
    async (moderatorId: string): Promise<void> => {
      setLoading(true);
      try {
        const moderator = await getModeratorById(moderatorId);
        const { setValue } = methods;

        setValue('name', moderator.name);
        setValue('email', moderator.email);
        setValue('cpf', formatCpf(moderator.cpf)!);
      } catch (error: any) {
        Swal.fire({
          icon: 'error',
          iconColor: '#f5365c',
          title: 'Erro ao buscar o moderador!',
          text: error.message || error,
          buttonsStyling: false,
          customClass: {
            confirmButton: 'btn-ok',
          },
        });
      } finally {
        setLoading(false);
      }
    },
    [],
  );

  useEffect(() => {
    getModerator(state.moderator_id);
  }, []);

  if (loading) return <Loading />;

  return (
    <FormProvider {...methods}>
      <ModeratorTemplate submit={handleSubmit} cancel={handleCancel} isEdit />
    </FormProvider>
  );
}
