import React from 'react';
import { useFormContext } from 'react-hook-form';

import { FlexContainer } from '../../components/FlexContainer';
import Episodes from '../../components/FormComponent/Episodes';
import MainFile from '../../components/FormComponent/MainFile';
import BasicContentTemplate, {
  BasicContentTemplateType,
} from '../BasicContentTemplate';

type PodcastTemplateType = BasicContentTemplateType;

export default function PodcastTemplate({
  title,
  view,
  content,
  isLoading,
  submit,
}: PodcastTemplateType) {
  const { setValue, getValues, control } = useFormContext();

  return (
    <BasicContentTemplate
      title={title}
      view={view}
      submit={submit}
      content={content}
      isLoading={isLoading}
    >
      <FlexContainer gap="0.875rem" flexDirection="column">
        <Episodes
          setValue={setValue}
          control={control}
          content={content}
          view={view}
        />
        <MainFile
          setValue={setValue}
          getValues={getValues}
          control={control}
          content={content}
          view={view}
          type={'podcast'}
          permission="public"
        />
      </FlexContainer>
    </BasicContentTemplate>
  );
}
