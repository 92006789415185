import {
  DeleteOutlineOutlined,
  EditOutlined,
  LayersOutlined,
  Link,
  VisibilityOutlined,
} from '@mui/icons-material';
import { format, isBefore } from 'date-fns';
import { isAfter } from 'date-fns';
import { useMemo } from 'react';

import Button from '../components/Button/index';
import { FlexContainer } from '../components/FlexContainer';
import CustomCheckbox from '../components/FormComponent/CustomCheckbox';

type useContentColumnsType = {
  handleRemove: (args) => void;
  handleDuplicate: (args) => void;
  handleEdit: (args) => void;
  handleShow: (args) => void;
  complementLink: string;
};

export default function useContentColumns({
  handleRemove,
  handleDuplicate,
  handleEdit,
  handleShow,
  complementLink,
}: useContentColumnsType) {
  const columns = useMemo(
    () => [
      {
        headerName: 'Título',
        field: 'title',
        minWidth: 200,
        getRowHeight: 100,
        flex: 2,
        renderCell: (cellValues: any) => (
          <p style={{ whiteSpace: 'normal' }}>{cellValues?.value}</p>
        ),
      },
      {
        headerName: 'Link',
        field: 'link',
        minWidth: 40,
        getRowHeight: 100,
        flex: 1,
        sortable: false,
        renderCell: ({ row }: any) => (
          <a
            href={`${process.env.REACT_APP_FRONT_BASE_URL}/${complementLink}/${row.slug}`}
            target="_blank"
            rel="noreferrer"
            style={{ color: '#28969f' }}
          >
            <Link />
          </a>
        ),
      },
      {
        headerName: 'Coleções',
        field: 'collection',
        minWidth: 180,
        getRowHeight: 180,
        flex: 2,
        sortable: false,
        renderCell: (cellValues: any) =>
          cellValues?.row.collections !== undefined ? (
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              {cellValues?.row.collections?.map(element => (
                <span style={{ whiteSpace: 'normal' }} key={element.name}>
                  {element.name}
                </span>
              ))}
            </div>
          ) : (
            <span></span>
          ),
      },
      {
        headerName: 'Criado',
        field: 'created_at',
        flex: 1,
        renderCell: (cellValues: any) => (
          <p style={{ whiteSpace: 'normal' }}>
            {format(new Date(cellValues?.value), 'dd/MM/yyyy')}
          </p>
        ),
      },
      {
        headerName: 'Atualizado',
        field: 'updated_at',
        flex: 1,
        renderCell: (cellValues: any) => (
          <p style={{ whiteSpace: 'normal' }}>
            {format(new Date(cellValues?.value), 'dd/MM/yyyy')}
          </p>
        ),
      },
      {
        headerName: 'Destaque',
        field: 'highlight',
        flex: 1,
        sortable: false,
        renderCell: (cellValues: any) => {
          const currentDate = new Date();
          const isBeforeFeaturedEnd = isBefore(
            currentDate,
            new Date(cellValues.row.featured_end_at),
          );
          const isAfterFeaturedStart = isAfter(
            currentDate,
            new Date(cellValues.row.featured_start_at),
          );

          const isChecked = isBeforeFeaturedEnd && isAfterFeaturedStart;
          return (
            <CustomCheckbox
              disabled
              checked={isChecked}
              style={{ margin: 'auto' }}
            />
          );
        },
      },
      {
        headerName: 'Situação',
        field: 'status',
        flex: 1,
        renderCell: (cellValues: any) => (
          <span>
            {cellValues?.value === 'hidden'
              ? 'Ocultado'
              : cellValues?.value === 'draft'
              ? 'Rascunho'
              : 'Publicado'}
          </span>
        ),
      },
      {
        headerName: 'Status',
        field: 'review_in',
        flex: 1,
        renderCell: (cellValues: any) => {
          const newDate = new Date();
          const reviewInDate = new Date(cellValues.row.review_in);

          if (isBefore(newDate, reviewInDate)) {
            return <span>Em dia</span>;
          } else {
            return <span>Atrasado</span>;
          }
        },
      },
      {
        headerName: 'Ações',
        field: 'actions',
        minWidth: 180,
        getRowHeight: 100,
        flex: 1,
        sortable: false,
        renderCell: (cellValues: any) => (
          <FlexContainer
            gap="0.4rem"
            flexWrap="wrap"
            justifyContent="flex-start"
          >
            <Button
              onClick={() => handleRemove(cellValues.id)}
              icon={<DeleteOutlineOutlined fontSize="inherit" />}
              color="secondary"
              title="Remover"
            />
            <Button
              onClick={() => handleDuplicate(cellValues.row)}
              icon={<LayersOutlined fontSize="inherit" />}
              color="secondary"
              title="Duplicar"
            />
            <Button
              onClick={() => handleEdit(cellValues.id)}
              icon={<EditOutlined fontSize="inherit" />}
              color="secondary"
              title="Editar"
            />
            <Button
              onClick={() => handleShow(cellValues.id)}
              icon={<VisibilityOutlined fontSize="inherit" />}
              color="secondary"
              title="Visualizar"
            />
          </FlexContainer>
        ),
      },
    ],
    [],
  );

  return { columns };
}
