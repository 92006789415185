import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2';

import Loading from '../../components/Loading';
import { ICountConsumption } from '../../models/from-api-response/report';
import { getReportListCount } from '../../services/reports';
import DashboardTemplate from '../../templates/Dashboard';

export default function Dashboard() {
  const [listConsumption, setListConsumption] = useState<
    ICountConsumption[] | []
  >([]);
  const [loading, setLoading] = useState(false);

  const getReport = async () => {
    try {
      setLoading(true);
      const listCount = await getReportListCount();

      setListConsumption(listCount.data);
    } catch (error: any) {
      console.error(error);
      Swal.fire({
        icon: 'error',
        iconColor: '#f5365c',
        title: 'Erro ao buscar o consumo!',
        html: error.message || error,
        confirmButtonColor: '#5e72e4',
        buttonsStyling: false,
        customClass: {
          htmlContainer: 'message-error-methodology modal-html-container',
          denyButton: 'btn-download-log',
          confirmButton: 'btn-ok',
          title: 'modal-title',
        },
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getReport();
  }, []);

  if (loading) return <Loading />;

  return <DashboardTemplate listConsumption={listConsumption} />;
}
