import compareAsc from 'date-fns/compareAsc';

export default function compareDate(
  initalDate: string,
  endDate: string,
): boolean {
  if (!initalDate || !endDate) return false;

  const dateLeft = new Date(initalDate);
  const dateRight = new Date(endDate);
  const comparison = compareAsc(dateLeft, dateRight);

  return comparison === 1;
}
