import { z } from 'zod';

const requiredMessage = 'Campo obrigatário';

export const userSchema = z.object({
  name: z.string().nonempty(requiredMessage),
  email: z.string().nonempty(requiredMessage),
  cpf: z.string().nonempty(requiredMessage),
  zipcode: z.string().optional(),
  complement: z.string().optional(),
  number: z.string().optional(),
  address: z.string().optional(),
  district: z.string().optional(),
  uf: z.string().optional(),
  city: z.string().optional(),
  birth_date: z.date().optional(),
  gender: z.string().optional(),
  phone_number: z.string().optional(),
});
