import z from 'zod';

import { categorySchema } from './categoryValidation';

export const imageSchema = z.object({
  file_id: z.string(),
  reference: z.string(),
  name: z.string(),
  original_name: z.string(),
  mime_type: z.string(),
  type: z.string(),
  size: z.string().or(z.number()),
  tooltip: z.any().nullable().optional(),
  permission: z.string(),
  created_at: z.any(),
  updated_at: z.any(),
  categories: z.array(categorySchema),
});
