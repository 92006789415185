import React, { HtmlHTMLAttributes } from 'react';

import * as S from './styles';

type FormProps = {
  children: React.ReactNode;
} & HtmlHTMLAttributes<HTMLFormElement>;

export default function Form({ children, ...rest }: FormProps) {
  return <S.Form {...rest}>{children}</S.Form>;
}
