import React from 'react';

export default function useTablePagination(pageSize?: number) {
  const [rowCount, setRowCount] = React.useState(0);
  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: pageSize || 25,
    page: 1,
  });

  const changePage = (page: number) => {
    if (page === 0) {
      setPaginationModel(oldState => ({ ...oldState, page: 1 }));
      return;
    }

    setPaginationModel(oldState => ({ ...oldState, page: page + 1 }));
  };

  const changePageSize = (pageSize: number) => {
    setPaginationModel({ page: 1, pageSize });
  };

  return {
    rowCount,
    paginationModel,
    setRowCount,
    setPaginationModel,
    changePageSize,
    changePage,
  };
}
