import React, { HTMLAttributes } from 'react';

import theme from '../../styles/theme';
import * as S from './styles';

export type HeadingProps = {
  color?: keyof typeof theme.colors;
  size?:
    | 'xxsmall'
    | 'xsmall'
    | 'small'
    | 'medium'
    | 'large'
    | 'xlarge'
    | 'xxlarge';
  fontWeight?: 400 | 600;
  lineHeight?: string | number;
  level?: 1 | 2 | 3 | 4 | 5 | 6;
  children: React.ReactNode;
} & HTMLAttributes<HTMLHeadingElement>;

export default function Heading({ children, ...props }: HeadingProps) {
  return <S.Heading {...props}>{children}</S.Heading>;
}
