import {
  Article,
  BlurLinear,
  Contactless,
  Delete,
  FilterAlt,
  InsertPhoto,
  Lyrics,
  ManageSearch,
  Videocam,
} from '@mui/icons-material';
import {
  AppBar,
  Box,
  Button,
  ButtonGroup,
  Modal,
  Toolbar,
  Typography,
} from '@mui/material';
import { DataGrid, GridToolbarQuickFilter, ptBR } from '@mui/x-data-grid';
import React, { useEffect, useRef } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Controller } from 'react-hook-form';
import { FaBook } from 'react-icons/fa';

import * as appStyles from '../../../AppStyle';
import {
  getContentsListEpisode,
  getContentsListFilter,
} from '../../../services/contents';
import { contentTypeMapping } from '../../../utils/contentTypeMapping';
import { getApplyFilterFnTitle } from '../../../utils/getApplyFilterFnTitle';
import Container from '../../Drag/Container';
import * as styles from './styles';

export default function ContentList(props: any) {
  const [filterModalOpen, setFilterModalOpen] = React.useState<boolean>(false);
  const [selectedContent, setSelectedContent] = React.useState<any[]>([]);
  const [content, setContent] = React.useState<any[]>([]);
  const [openFilter, setOpenFilter] = React.useState<boolean>(false);
  const [typeFilter, setTypeFilter] = React.useState<string>('');

  const ordersToRefFileContents: any = useRef();
  ordersToRefFileContents.current = selectedContent;

  const fillContents = () => {
    const editingContents = props.content;

    if (editingContents.length > 0) {
      setSelectedContent(editingContents);
    }
  };

  useEffect(() => {
    if (props.type !== 'lesson') {
      const ContentsId: string[] = [];
      selectedContent.forEach((content: any) => {
        ContentsId.push(content.content_id);
      });

      props.setValue('contents', ContentsId);
    }
  }, [selectedContent]);

  useEffect(() => {
    const contentIsIncluded = Object.keys(props).includes('content');

    if (
      contentIsIncluded &&
      props.content !== undefined &&
      props.content !== null
    ) {
      fillContents();
    }
  }, [props?.content]);

  useEffect(() => {
    getContentsListEpisode().then((result: any) => {
      setContent(result.data);
    });
  }, []);

  function handleSearchFilter(type?: string) {
    getContentsListFilter(type).then((el: any) => {
      setContent(el.data);
    });
  }

  function QuickSearchToolbar() {
    return (
      <Box
        sx={{
          p: 0.5,
          pb: 0,
        }}
      >
        <GridToolbarQuickFilter sx={{ width: 300 }} />
      </Box>
    );
  }

  const contentColumns = [
    {
      headerName: 'Título',
      field: 'title',
      getApplyQuickFilterFn: getApplyFilterFnTitle,
      minWidth: 200,
      flex: 2,
    },
    {
      headerName: 'Criado em',
      field: 'created_at',
      flex: 1,
      renderCell: (cellValues: any) => {
        return (
          cellValues &&
          cellValues?.value.substring(8, 10) +
            '/' +
            cellValues?.value.substring(5, 7) +
            '/' +
            cellValues?.value.substring(0, 4)
        );
      },
    },
    {
      headerName: 'Atualizado em',
      field: 'updated_at',
      minWidth: 150,
      flex: 1,
      renderCell: (cellValues: any) => {
        return (
          cellValues &&
          cellValues?.value.substring(8, 10) +
            '/' +
            cellValues?.value.substring(5, 7) +
            '/' +
            cellValues?.value.substring(0, 4)
        );
      },
    },
    {
      headerName: 'Tipo',
      field: 'type',
      minWidth: 150,
      flex: 1,
      renderCell: (cellValues: any) => (
        <p style={{ whiteSpace: 'normal' }}>
          {contentTypeMapping(cellValues?.value)}
        </p>
      ),
    },
    {
      headerName: 'Selecionar',
      field: '',
      minWidth: 150,
      flex: 1,
      renderCell: (cellValues: any) => {
        const isSelected = selectedContent.find(
          content => content.content_id === cellValues.id,
        );

        return (
          <Button
            variant="contained"
            size="small"
            disabled={!!isSelected}
            onClick={() => {
              setSelectedContent(oldState =>
                isSelected ? [...oldState] : [...oldState, cellValues.row],
              );
              setFilterModalOpen(false);

              const companiesId = selectedContent.map(
                content => content.content_id,
              );

              if (props.type === 'lesson') {
                props.setValue('complement_contents', [
                  ...companiesId,
                  cellValues.row.content_id,
                ]);
              } else {
                props.setValue('contents', companiesId);
              }
            }}
          >
            {isSelected ? 'Selecionado' : 'Selecionar'}
          </Button>
        );
      },
    },
  ];

  const handleDeleteItem = item => {
    const updatedContents = ordersToRefFileContents.current.filter(
      e => e.content_id !== item.content_id,
    );

    if (updatedContents) {
      const companiesId: any[] = [];
      updatedContents.forEach((company: any) => {
        companiesId.push(company.content_id);
      });
      if (props.type === 'lesson') {
        props.setValue('complement_contents', companiesId);
      } else {
        props.setValue('contents', companiesId);
      }
    }

    setSelectedContent(updatedContents);
  };

  function getModalStyle() {
    const top = 25;

    return {
      top: `${top}%`,
      margin: 'auto',
      width: '85%',
      height: '95%',
      backgroundColor: 'white',
      marginTop: '1vw',
      overflow: 'hidden',
      overflowY: 'scroll',
    } as React.CSSProperties;
  }

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        padding: '22px',
        width: '100%',
      }}
    >
      <Typography variant="h4">
        {props.label ? props.label : 'Conteúdos *'}
      </Typography>
      <Controller
        name={props.type === 'lesson' ? 'complement_contents' : 'contents'}
        control={props.control}
        rules={{ required: false }}
        render={({ field: { onChange, value }, fieldState: { error } }) => {
          return (
            <>
              <DndProvider backend={HTML5Backend}>
                <Container
                  type="content"
                  label="Conteudo"
                  item={selectedContent}
                  setItem={setSelectedContent}
                  onChangeDeleteItem={handleDeleteItem}
                  view={props.view}
                />
              </DndProvider>

              {selectedContent.length === 0 ? (
                <span style={{ color: 'disable', marginTop: '1rem' }}>
                  Nenhum contéudo selecionado
                </span>
              ) : (
                <></>
              )}
              {error !== undefined && selectedContent.length < 0 ? (
                <appStyles.inputLabel
                  style={{ color: 'red', marginBottom: '0.5rem' }}
                >
                  *Campo obrigatório
                </appStyles.inputLabel>
              ) : (
                <></>
              )}
            </>
          );
        }}
      />

      <ButtonGroup
        style={{ boxShadow: '0px 0px' }}
        variant="contained"
        size="small"
      >
        <Button
          disabled={props.view}
          onClick={() => {
            setSelectedContent([]);
            if (props.type === 'lesson') {
              props.setValue('complement_contents', []);
            } else {
              props.setValue('contents', []);
            }
          }}
          variant="contained"
          startIcon={<Delete />}
          color="error"
          style={{
            width: '12em',
            marginTop: '1rem',
            right: '0.1rem',
          }}
        >
          <span>Limpar</span>
        </Button>
        <Button
          disabled={props.view}
          startIcon={<ManageSearch />}
          onClick={() => setFilterModalOpen(true)}
          style={{
            backgroundColor: '#008d4c',
            width: '12em',
            marginTop: '1rem',
            right: '0.1rem',
          }}
        >
          <span>Listar</span>
        </Button>
      </ButtonGroup>

      <Modal
        open={filterModalOpen}
        onClose={() => setFilterModalOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{ alignItems: 'center', justifyContent: 'center' }}
      >
        <Box style={getModalStyle()}>
          <AppBar
            position="static"
            style={{ marginBottom: '1rem' }}
            sx={{ bgcolor: '#1565c0', height: '3.5rem' }}
          >
            <Toolbar>
              <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                Lista Conteúdo
              </Typography>
              <Button onClick={() => setFilterModalOpen(false)} color="inherit">
                x
              </Button>
            </Toolbar>
          </AppBar>
          <div>
            <div>
              <Button
                variant="contained"
                size="small"
                component="span"
                startIcon={<FilterAlt />}
                style={{
                  backgroundColor: '#1565c0',
                  marginLeft: '0.5rem',
                  marginBottom: '1rem',
                }}
                onClick={() =>
                  openFilter ? setOpenFilter(false) : setOpenFilter(true)
                }
              >
                <span>Filtros</span>
              </Button>
            </div>
            {openFilter && (
              <>
                <span style={{ marginLeft: '0.5rem' }}>Por tipo:</span>
                <Box
                  marginBottom={'0.5rem'}
                  marginLeft={'0.5rem'}
                  sx={{
                    display: 'flex',
                    '& > *': {
                      m: 1,
                    },
                  }}
                >
                  <ButtonGroup
                    size="small"
                    variant="contained"
                    aria-label="outlined primary button group"
                    style={{ boxShadow: '0px 0px 0px' }}
                  >
                    <div>
                      <div>
                        <Button
                          startIcon={<Lyrics />}
                          style={{
                            marginRight: 2,
                            backgroundColor:
                              typeFilter === 'course' ? '#3630b1' : '#8f8b8b',
                          }}
                          onClick={() => {
                            handleSearchFilter('course');
                            setTypeFilter('course');
                          }}
                        >
                          Curso
                        </Button>
                        <Button
                          startIcon={<Lyrics />}
                          style={{
                            marginRight: 2,
                            backgroundColor:
                              typeFilter === 'course_sync'
                                ? '#3630b1'
                                : '#8f8b8b',
                          }}
                          onClick={() => {
                            handleSearchFilter('course_sync');
                            setTypeFilter('course_sync');
                          }}
                        >
                          Curso Síncrono
                        </Button>
                        <Button
                          startIcon={<Article />}
                          style={{
                            marginRight: 2,
                            backgroundColor:
                              typeFilter === 'article' ? '#3630b1' : '#8f8b8b',
                          }}
                          onClick={() => {
                            handleSearchFilter('article');
                            setTypeFilter('article');
                          }}
                        >
                          Artigo
                        </Button>
                        <Button
                          startIcon={<FaBook />}
                          style={{
                            marginRight: 2,
                            backgroundColor:
                              typeFilter === 'ebook' ? '#3630b1' : '#8f8b8b',
                          }}
                          onClick={() => {
                            handleSearchFilter('ebook');
                            setTypeFilter('ebook');
                          }}
                        >
                          Ebook
                        </Button>
                        <Button
                          startIcon={<InsertPhoto />}
                          style={{
                            marginRight: 2,
                            backgroundColor:
                              typeFilter === 'infographics'
                                ? '#3630b1'
                                : '#8f8b8b',
                          }}
                          onClick={() => {
                            handleSearchFilter('infographics');
                            setTypeFilter('infographics');
                          }}
                        >
                          Infográfico
                        </Button>
                        <Button
                          startIcon={<Videocam />}
                          style={{
                            marginRight: 2,
                            backgroundColor:
                              typeFilter === 'thematic_kit'
                                ? '#3630b1'
                                : '#8f8b8b',
                          }}
                          onClick={() => {
                            handleSearchFilter('thematic_kit');
                            setTypeFilter('thematic_kit');
                          }}
                        >
                          Kits Temáticos
                        </Button>
                        <Button
                          startIcon={<Videocam />}
                          style={{
                            marginRight: 2,
                            backgroundColor:
                              typeFilter === 'magazine' ? '#3630b1' : '#8f8b8b',
                          }}
                          onClick={() => {
                            handleSearchFilter('magazine');
                            setTypeFilter('magazine');
                          }}
                        >
                          Revistas
                        </Button>
                      </div>
                      <div style={{ marginTop: '0.2rem' }}>
                        <Button
                          startIcon={<Videocam />}
                          style={{
                            marginRight: 2,
                            backgroundColor:
                              typeFilter === 'spreadsheet'
                                ? '#3630b1'
                                : '#8f8b8b',
                          }}
                          onClick={() => {
                            handleSearchFilter('spreadsheet');
                            setTypeFilter('spreadsheet');
                          }}
                        >
                          Ferramentas de gestão
                        </Button>
                        <Button
                          startIcon={<Videocam />}
                          style={{
                            marginRight: 2,
                            backgroundColor:
                              typeFilter === 'podcast' ? '#3630b1' : '#8f8b8b',
                          }}
                          onClick={() => {
                            handleSearchFilter('podcast');
                            setTypeFilter('podcast');
                          }}
                        >
                          Podcast
                        </Button>
                        <Button
                          startIcon={<Videocam />}
                          style={{
                            marginRight: 2,
                            backgroundColor:
                              typeFilter === 'research' ? '#3630b1' : '#8f8b8b',
                          }}
                          onClick={() => {
                            handleSearchFilter('research');
                            setTypeFilter('research');
                          }}
                        >
                          Pesquisas
                        </Button>
                        <Button
                          startIcon={<Videocam />}
                          style={{
                            marginRight: 2,
                            backgroundColor:
                              typeFilter === 'video' ? '#3630b1' : '#8f8b8b',
                          }}
                          onClick={() => {
                            handleSearchFilter('video');
                            setTypeFilter('video');
                          }}
                        >
                          Video
                        </Button>
                        <Button
                          startIcon={<Contactless />}
                          style={{
                            marginRight: 2,
                            backgroundColor:
                              typeFilter === 'series' ? '#3630b1' : '#8f8b8b',
                          }}
                          onClick={() => {
                            handleSearchFilter('series');
                            setTypeFilter('series');
                          }}
                        >
                          Séries
                        </Button>
                        <Button
                          startIcon={<BlurLinear />}
                          style={{
                            marginRight: 2,
                            backgroundColor:
                              typeFilter === '' ? '#3630b1' : '#8f8b8b',
                          }}
                          onClick={() => {
                            getContentsListEpisode().then((result: any) => {
                              setContent(result.data);
                            });
                            setTypeFilter('');
                          }}
                        >
                          Todos
                        </Button>
                      </div>
                    </div>
                  </ButtonGroup>
                </Box>
              </>
            )}
          </div>
          <styles.dataGridHeight>
            <styles.dataGridDisplay>
              <styles.dataGridFlexGrow>
                <DataGrid
                  disableColumnMenu
                  initialState={{
                    sorting: {
                      sortModel: [{ field: 'created_at', sort: 'desc' }],
                    },
                  }}
                  localeText={
                    ptBR.components.MuiDataGrid.defaultProps.localeText
                  }
                  getRowId={row => row.content_id}
                  rowHeight={100}
                  aria-labelledby={'content_id'}
                  columns={contentColumns}
                  components={{ Toolbar: QuickSearchToolbar }}
                  rows={content}
                />
              </styles.dataGridFlexGrow>
            </styles.dataGridDisplay>
          </styles.dataGridHeight>
        </Box>
      </Modal>
    </div>
  );
}
