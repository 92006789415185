import React from 'react';
import { useFormContext } from 'react-hook-form';
import Swal from 'sweetalert2';

import Button from '../../components/Button';
import Card from '../../components/FormComponent/Card';
import Form from '../../components/FormComponent/Form';
import TextField from '../../components/FormComponent/TextField';
import { formatCpf } from '../../utils';
import { FlexContainer } from '../SearchContent/styles';

type ModeratorTemplateProps = {
  submit: (data) => Promise<void>;
  cancel: () => void;
  isEdit?: boolean;
};

export default function ModeratorTemplate({
  cancel,
  submit,
  isEdit,
}: ModeratorTemplateProps) {
  const { handleSubmit } = useFormContext();

  const onError = error => {
    console.error(error);
    Swal.fire({
      icon: 'error',
      title: 'Erro ao enviar o formulário do moderador',
      text: 'Por favor, preencha o formulário corretamente.',
      customClass: {
        title: 'modal-title',
        htmlContainer: 'modal-html-container',
        confirmButton: 'btn-ok',
      },
      buttonsStyling: false,
    });
  };

  const cpfInputMask = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.target.value = formatCpf(e.target.value) || '';
  };

  return (
    <Card>
      <Form onSubmit={handleSubmit(submit, onError)}>
        <FlexContainer gap="1rem" flexWrap="wrap">
          <TextField label="Nome" name="name" flex="1 0 13.75rem" required />
          <TextField label="E-mail" name="email" flex="1 0 13.75rem" required />
          <TextField
            label="CPF"
            name="cpf"
            flex="1 0 13.75rem"
            maxLength={14}
            onChange={cpfInputMask}
            disabled={isEdit}
            required
          />
        </FlexContainer>

        <FlexContainer gap="1rem" justifyContent="flex-end" flexWrap="wrap">
          <Button
            type="button"
            onClick={cancel}
            variant="contained"
            color="secondary"
          >
            Cancelar
          </Button>

          <Button type="submit" variant="contained" color="blue">
            {isEdit ? 'Editar' : 'Criar'}
          </Button>
        </FlexContainer>
      </Form>
    </Card>
  );
}
