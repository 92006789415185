import httpClient from '../http-client';
import { httpClientSebrae } from '../http-client/apiSebrae';
import { ISeries, ISeriesParams } from '../models/series';

interface ISeriesResponse {
  data: ISeries[];
  metaData: any;
}

const getSeries = async (params?: ISeriesParams) => {
  const series = (
    await httpClient.get<ISeriesResponse>(
      `/content/admin/list?types[]=series`,
      { params },
    )
  ).data;
  return series;
};

const deleteSeries = async (contentId: string) => {
  await httpClient.delete(`content/${contentId}`);
};

const getSouthDate = async (contentId: string) => {
  const courses = (
    await httpClientSebrae.get(
      `/v1/event/888/cod_programa/${contentId}/cod_turma`,
    )
  ).data;
  return courses;
};

export { deleteSeries, getSeries, getSouthDate };
