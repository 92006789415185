import * as z from 'zod';

const AuthorTypesEnum = z.enum(['person', 'company']);
const StatusTypesEnum = z.enum(['draft', 'published']);

export const socialLinksSchema = z.object({
  facebook: z.string().nullable().optional(),
  instagram: z.string().nullable().optional(),
  linkedin: z.string().nullable().optional(),
  youtube: z.string().nullable().optional(),
  tiktok: z.string().nullable().optional(),
  twitter: z.string().nullable().optional(),
  site: z.string().nullable().optional(),
});

const companySchema = z.object({
  author_id: z.string().optional(),
  name: z.string().nullable().optional(),
  description: z.string().optional(),
  type: z.string().optional(),
  image: z.any().nullable().optional(),
  tooltip: z.string().nullable().optional(),
  file_id: z.string().nullable().optional(),
  company_id: z.string().nullable().optional(),
  occupation: z.any().nullable().optional(),
  social_links: z.any().nullable().optional(),
  created_at: z.any().nullable().optional(),
  updated_at: z.any().nullable().optional(),
  deleted_at: z.any().nullable().optional(),
  status: z.string().optional(),
  cpf: z.string().nullable().optional(),
  email: z.string().nullable().optional(),
  is_email_cpf_blocked: z.boolean().optional(),
  slug: z.string().nullable().optional(),
  file: z.any().nullable().optional(),
});

export const authorSchema = z
  .object({
    company: companySchema.nullable().optional(),
    type: AuthorTypesEnum.default('person').refine(
      value => value === 'person' || value === 'company',
      {
        message: 'Tipo do autor inválido',
      },
    ),
    name: z.string().nonempty('O campo "Nome Autor" é obrigatório'),
    description: z
      .string()
      .max(1000, {
        message: 'Esse campo não pode ter mais que 1000 caracteres',
      })
      .nonempty('O campo "Sobre Pessoa" é obrigatório'),
    tooltip: z.string().nullable().optional(),
    file_id: z.string().uuid().optional().nullable(),
    image_file_id: z.string().uuid().optional().nullable(),
    image_file: z.any(),
    status: StatusTypesEnum.default('published'),
    company_id: z.string().uuid().optional().nullable(),
    occupation: z
      .string()
      .max(70, { message: 'Esse campo não pode ter mais que 70 caracteres' })
      .optional(),
    cpf: z.string().optional().nullable(),
    email: z.string().optional().nullable(),
    social_links: socialLinksSchema,
    is_email_cpf_blocked: z.boolean().default(false),
    slug: z
      .string({ required_error: 'Campo obrigatório' })
      .nonempty('Campo obrigatório'),
  })
  .superRefine((data, ctx) => {
    if (
      (data.occupation === null || data.occupation?.length === 0) &&
      data.type === 'person'
    ) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'Campo obrigatório',
        path: ['occupation'],
      });
    }
  });
