import { zodResolver } from '@hookform/resolvers/zod';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import { z } from 'zod';

import { userSchema } from '../../../common/validations/userValidation';
import { createUser } from '../../../services/users';
import CreateUserTemplate from '../../../templates/CreateUserTemplate';
import { removeTraitPoints } from '../../../utils';

export type CreateUserFormValues = z.infer<typeof userSchema>;

export default function CreateUser() {
  const navigate = useNavigate();
  const methods = useForm<CreateUserFormValues>({
    resolver: zodResolver(userSchema),
  });

  const handleCancel = () => {
    Swal.fire({
      icon: 'question',
      title: 'Confirmação',
      html: 'Tem certeza que deseja cancelar o cadastro?',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      reverseButtons: true,
      focusConfirm: true,
      customClass: {
        title: 'modal-title',
        htmlContainer: 'modal-html-container',
        confirmButton: 'btn-ok',
        cancelButton: 'btn-cancel',
      },
      buttonsStyling: false,
    }).then(async result => {
      if (result.isConfirmed) {
        methods.reset();
        navigate(-1);
      }
    });
  };

  const handleSubmit = async (data: CreateUserFormValues) => {
    Object.keys(data).forEach(key => {
      if (!data[key]?.length && key !== 'birth_date') delete data[key];
    });

    if (!data['birth_date']) delete data['birth_date'];
    if (data['cpf']) data['cpf'] = removeTraitPoints(data['cpf'])!;
    if (data['zipcode']) data['zipcode'] = removeTraitPoints(data['zipcode'])!;
    if (data['phone_number'])
      data['phone_number'] = removeTraitPoints(data['phone_number'])!;

    await createUser(data)
      .then(() => {
        Swal.fire({
          title: 'Usuário criado com sucesso',
          icon: 'success',
          customClass: {
            title: 'modal-title',
            htmlContainer: 'modal-html-container',
            confirmButton: 'btn-ok',
          },
          buttonsStyling: false,
        }).then(() => navigate(-1));
      })
      .catch(error => {
        Swal.fire({
          icon: 'error',
          title: 'Algo deu errado ao criar o usuário',
          iconColor: '#f5365c',
          text: error.message,
          customClass: {
            title: 'modal-title',
            htmlContainer: 'modal-html-container',
            confirmButton: 'btn-ok',
          },
          buttonsStyling: false,
        });
      });
  };

  return (
    <FormProvider {...methods}>
      <CreateUserTemplate submit={handleSubmit} cancel={handleCancel} />
    </FormProvider>
  );
}
