import React from 'react';

import { LoadingContainer, LoadingContent } from './style';

const Loading: React.FC = () => {
  return (
    <LoadingContainer id="loading">
      <LoadingContent>
        <div className="spinner"></div>
        <div className="text">Carregando...</div>
      </LoadingContent>
    </LoadingContainer>
  );
};

export default Loading;
