import { uploadFile } from '../../services/files';

export const customUpload = (data, success, failed, progress) => {
  let mockResult = data.files.map(f => ({ name: f.name, src: './' }));

  let intervalId: any = -1;
  let currentProgress = 0;

  intervalId = setInterval(() => {
    if (currentProgress < 100) {
      currentProgress += 20;
      progress(currentProgress, mockResult[0]);
    }

    if (currentProgress === 100) {
      clearInterval(intervalId);
      const formData = new FormData();

      formData.append('file', data.files !== undefined ? data.files[0] : '');

      uploadFile(formData).then((resData: any) => {
        mockResult = [{ name: data.files[0].name, src: resData.reference }];
        success(mockResult /*, { retainSrc: true }*/);
      });
    }
  }, 1000);
};
