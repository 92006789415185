import { formatISO } from "date-fns";
import { FieldValues } from "react-hook-form/dist/types";

import { ContentDatesFormat } from "../@types/contentDatesFormat";

export default function contentDatesFormat(data: FieldValues): ContentDatesFormat {
  let currentKey = '';
  try {
    Object.keys(data).forEach(function(key) {
      currentKey = key;
      if (
        currentKey === 'consumption_start_at' ||
        currentKey === 'consumption_end_at' ||
        currentKey === 'published_start_at' ||
        currentKey === 'published_end_at' ||
        currentKey === 'review_start_at' ||
        currentKey === 'review_end_at') {
          if (data[currentKey]) {
            data[currentKey] = formatISO(new Date(data[currentKey]));
          }
        }
    });
    return {status: true, data};
  } catch (error: any) {
    return {status: false, key: currentKey};
  }
}