import { DatePicker } from '@mui/lab';
import * as M from '@mui/material';
import Pagination from '@mui/material/Pagination';
import {
  ArcElement,
  BarElement,
  CartesianScaleTypeRegistry,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LegendOptions,
  LinearScale,
  PluginOptionsByType,
  ScaleOptionsByType,
  Title,
  Tooltip,
} from 'chart.js';
import { _DeepPartialObject } from 'chart.js/types/utils';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Options } from 'chartjs-plugin-datalabels/types/options';
import { isAfter, isBefore } from 'date-fns';
import React, { useState } from 'react';
import { Bar, Pie } from 'react-chartjs-2';
import { createPortal } from 'react-dom';
import { useFormContext } from 'react-hook-form';
import Swal from 'sweetalert2';

import Breadcrumbs from '../../../components/Breadcrumbs';
import Button from '../../../components/Button';
import ContentSelector from '../../../components/ContentSelector';
import CustomSelect from '../../../components/CustomSelect';
import { FlexContainer } from '../../../components/FlexContainer';
import Card from '../../../components/FormComponent/Card';
import Form from '../../../components/FormComponent/Form';
import Input from '../../../components/FormComponent/Input';
import TextField from '../../../components/FormComponent/TextField';
import Loading from '../../../components/Loading';
import ContentsModal from '../../../components/SimpleModal/ContentsModal';
import Tabs from '../../../components/Tabs';
import {
  companySize,
  companyType,
  genres,
  progressStatus,
  types,
  userEducation,
} from '../../../constants/optionsToSelect';
import { useContentSelector } from '../../../hooks';
import {
  IContentUsersReportResponse,
  IProgressAverageByContent,
  IReportItem,
} from '../../../models/consumption';
import { ChartLabelsType } from '../../../pages/Reports/CourseAndSerieConsumptiom';
import theme from '../../../styles/theme';
import * as S from './styles';

ChartJS.register(
  ArcElement,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartDataLabels,
);

export type SelectValuesType = {
  cities: { label: string; value: string }[] | [];
  ufs: { label: string; value: string }[] | [];
  cnaes: { label: string; value: string }[] | [];
};

type CourseAndSerieTemplateProps = {
  breadcrumbs: React.ReactElement[];
  isLoading: boolean;
  chartLabels: ChartLabelsType;
  progressAverageByContent: IProgressAverageByContent[] | [];
  paginationCount: number;
  selectValues: SelectValuesType;
  reports: IContentUsersReportResponse;
  submitFilters: (data) => Promise<void>;
  handleClearForm: () => Promise<void>;
  handlePageSwitching: (page) => Promise<void>;
};

type PieOptions = {
  legend?: _DeepPartialObject<LegendOptions<'pie'>> | undefined;
  dataLabels: (obj?: [] | IReportItem[]) => _DeepPartialObject<Options>;
};

type BarOptions = {
  plugins: _DeepPartialObject<PluginOptionsByType<'bar'>>;
  scales: _DeepPartialObject<{
    [key: string]: ScaleOptionsByType<keyof CartesianScaleTypeRegistry>;
  }>;
};

export default function CourseAndSerieConsumptiomTemplate({
  breadcrumbs,
  submitFilters,
  isLoading,
  handleClearForm,
  paginationCount,
  handlePageSwitching,
  selectValues,
  reports,
  progressAverageByContent,
  chartLabels,
}: CourseAndSerieTemplateProps) {
  const [activeStep, setActiveStep] = useState(0);
  const [isOpen, setIsOpen] = useState(false);

  const {
    getValues,
    setValue,
    watch,
    handleSubmit,
    setError,
    clearErrors,
    formState: { errors },
  } = useFormContext();
  const { handleContentSelected, handleRemoveSelected, handleSelectedContent } =
    useContentSelector({ setValue, getValues });

  const onError = error => {
    console.error(error);
    Swal.fire({
      icon: 'error',
      title: 'Erro ao fazer o filtro',
      text: 'Ocorreu um erro ao filtrar a busca com os filtros selecionados, revise os filtros.',
      customClass: {
        title: 'modal-title',
        htmlContainer: 'modal-html-container',
        confirmButton: 'btn-ok',
      },
      buttonsStyling: false,
    });
  };

  const handleCreatedStartAt = (date: Date | null) => {
    if (date && isAfter(date, watch('created_at_end'))) {
      setError('created_at_start', {
        message:
          'A data publicação início não pode vir após a data publicação fim',
      });
      return;
    }

    if (Object.keys(errors).includes('created_at_start')) {
      clearErrors('created_at_start');
    }

    setValue('created_at_start', date);
  };

  const handleCreatedEndAt = (date: Date | null) => {
    if (date && isBefore(date, watch('created_at_start'))) {
      setError('created_at_end', {
        message:
          'A data publicação fim não pode ser anterior a data publicação início',
      });
      return;
    }

    if (Object.keys(errors).includes('created_at_end')) {
      clearErrors('created_at_end');
    }

    setValue('created_at_end', date);
  };

  const handleStepSelect = (currentStep: number) => {
    setActiveStep(currentStep);
  };

  const TabsProps = {
    labels: [
      'Média de Progresso',
      'Idade',
      'Gênero',
      'Status de Progresso',
      'Cidades',
      'UF Cliente',
      'Educação',
      'Tamanho Empresa',
      'Tipo Empresa',
      'CNAE',
      'Idade Empresa',
    ],
    activeStep,
    onSelect: handleStepSelect,
  };

  const chartBackground = [
    theme.colors.chart_green,
    theme.colors.chart_blue,
    theme.colors.chart_violet,
    theme.colors.chart_orange,
    theme.colors.chart_pink,
    theme.colors.chart_teal,
    theme.colors.chart_yellow,
  ];

  const pieOptions: PieOptions = {
    legend: {
      align: 'start',
      position: 'top',
      labels: {
        boxWidth: 14,
        usePointStyle: true,
      },
    },
    dataLabels: obj => ({
      display: true,
      labels: {
        index: {
          align: 'start',
          anchor: 'end',
          formatter: (value, { dataIndex }) => {
            if (!obj) return;

            const [key] = Object.keys(obj[dataIndex]);
            const percentage = obj[dataIndex][key].percent;

            return `${value} (${percentage}%)`;
          },
        },
      },
      color: '#212529',
      font: {
        weight: 'bold',
      },
    }),
  };

  const barOptions: BarOptions = {
    plugins: {
      datalabels: {
        display: true,
        align: 'end',
        anchor: 'end',
      },
      legend: {
        display: false,
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        stacked: true,
      },
    },
  };

  const addFloatingPoint = (n: number) => {
    return n.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
  };

  if (isLoading) return <Loading />;

  return (
    <S.Wrapper>
      <Breadcrumbs breadcrumbs={breadcrumbs} />

      <Card>
        <Form onSubmit={handleSubmit(submitFilters, onError)}>
          <FlexContainer gap="1rem" flexWrap="wrap">
            <TextField
              label="Status de Progresso"
              name="progress_status"
              flex="1 0 17rem"
            >
              <CustomSelect
                id="progress_status"
                isClearable
                isRtl={false}
                isMulti
                isSearchable
                options={progressStatus}
                onChange={progress => {
                  if (!progress) {
                    setValue('progress_status', null);
                  } else {
                    setValue(
                      'progress_status',
                      progress.map(el => el.value),
                    );
                  }
                }}
                placeholder="Selecione o status"
                value={
                  progressStatus.filter(status =>
                    watch('progress_status')?.includes(status.value),
                  ) || null
                }
              />
            </TextField>

            <TextField
              label="Média De Progresso"
              flex="1 0 17rem"
              name="progress_average"
            >
              <FlexContainer gap="1rem">
                <Input
                  value={getValues('progress_average_start')}
                  onChange={e =>
                    setValue('progress_average_start', Number(e.target.value))
                  }
                  type="number"
                  min={0}
                  max={1}
                  step={0.01}
                  thereIsError={
                    watch('progress_average_start') < 0 ||
                    watch('progress_average_start') > 1
                  }
                />
                <Input
                  value={getValues('progress_average_end')}
                  onChange={e =>
                    setValue('progress_average_end', Number(e.target.value))
                  }
                  type="number"
                  min={0}
                  max={1}
                  step={0.01}
                  thereIsError={
                    watch('progress_average_end') < 0 ||
                    watch('progress_average_end') > 1
                  }
                />
              </FlexContainer>
            </TextField>

            <TextField label="Progresso" name="progress" flex="1 0 17rem">
              <FlexContainer gap="1rem">
                <Input
                  value={getValues('progress_start')}
                  onChange={e =>
                    setValue('progress_start', Number(e.target.value))
                  }
                  type="number"
                  min={0}
                  max={1}
                  step={0.01}
                  thereIsError={
                    watch('progress_start') < 0 || watch('progress_start') > 1
                  }
                />
                <Input
                  value={getValues('progress_end')}
                  onChange={e =>
                    setValue('progress_end', Number(e.target.value))
                  }
                  type="number"
                  min={0}
                  max={1}
                  step={0.01}
                  thereIsError={
                    watch('progress_end') < 0 || watch('progress_end') > 1
                  }
                />
              </FlexContainer>
            </TextField>

            <TextField label="Conteúdos" name="contents" flex="1 0 20rem">
              <ContentSelector
                id="contents"
                value={watch('contents') || []}
                handleChange={handleSelectedContent}
                handleOpenMenu={() => setIsOpen(!isOpen)}
              />
            </TextField>

            <TextField label="Tipo do Conteúdo" name="type" flex="1 0 17rem">
              <CustomSelect
                id="type"
                isClearable
                isRtl={false}
                isSearchable
                options={types}
                onChange={type => {
                  if (!type) {
                    setValue('type', null);
                  } else {
                    setValue('type', type.value);
                  }
                }}
                placeholder="Selecione o tipo de conteúdo"
                value={types.find(type => type.value === watch('type')) || null}
              />
            </TextField>

            <TextField label="Gênero" name="gender" flex="1 0 17rem">
              <CustomSelect
                id="gender"
                isClearable
                isRtl={false}
                isSearchable
                options={genres}
                onChange={gender => {
                  if (!gender) {
                    setValue('gender', null);
                  } else {
                    setValue('gender', gender.value);
                  }
                }}
                placeholder="Selecione o gênero"
                value={
                  genres.find(gender => gender.value === watch('gender')) ||
                  null
                }
              />
            </TextField>

            <TextField label="Idade" name="age" flex="1 0 17rem">
              <FlexContainer gap="1rem">
                <Input
                  value={getValues('age_start')}
                  onChange={e => setValue('age_start', Number(e.target.value))}
                  type="number"
                  min={0}
                  thereIsError={watch('age_start') < 0}
                  placeholder="Idade mínima"
                />
                <Input
                  value={getValues('age_end')}
                  onChange={e => setValue('age_end', Number(e.target.value))}
                  type="number"
                  min={0}
                  thereIsError={watch('age_end') < 0}
                  placeholder="Idade máxima"
                />
              </FlexContainer>
            </TextField>

            <TextField label="Cidades" name="cities " flex="1 0 17rem">
              <CustomSelect
                id="cities"
                isClearable
                isMulti
                isRtl={false}
                isSearchable
                options={selectValues.cities}
                onChange={cities => {
                  if (!cities.length) {
                    setValue('cities', null);
                  } else {
                    const listOfCities = cities.map(city => city.value);
                    setValue('cities', listOfCities);
                  }
                }}
                placeholder="Selecione a cidade"
                value={
                  selectValues.cities.filter(city =>
                    watch('cities')?.some(item => item === city?.value),
                  ) || null
                }
              />
            </TextField>

            <TextField
              label="Criado em (Data Início)"
              name="created_at_start"
              flex="1 0 10rem"
            >
              <DatePicker
                value={watch('created_at_start') || null}
                onChange={handleCreatedStartAt}
                renderInput={params => <M.TextField {...params} />}
              />
            </TextField>

            <TextField
              label="Criado em (Data Fim)"
              name="created_at_end"
              flex="1 0 10rem"
            >
              <DatePicker
                value={watch('created_at_end') || null}
                onChange={handleCreatedEndAt}
                renderInput={params => <M.TextField {...params} />}
              />
            </TextField>

            <TextField label="UF Cliente" name="ufs" flex="1 0 17rem">
              <CustomSelect
                id="ufs"
                isClearable
                isMulti
                isRtl={false}
                isSearchable
                options={selectValues.ufs}
                onChange={ufs => {
                  if (!ufs.length) {
                    setValue('ufs', null);
                  } else {
                    const listOfUf = ufs.map(uf => uf.value);
                    setValue('ufs', listOfUf);
                  }
                }}
                placeholder="Selecione a UF"
                value={
                  selectValues.ufs.filter(uf =>
                    watch('ufs')?.some(item => item === uf?.value),
                  ) || null
                }
              />
            </TextField>

            <TextField label="Educação" name="education" flex="1 0 17rem">
              <CustomSelect
                id="education"
                isClearable
                isMulti
                isRtl={false}
                isSearchable
                options={userEducation}
                onChange={educations => {
                  if (!educations.length) {
                    setValue('education', null);
                  } else {
                    const listOfEducations = educations.map(
                      education => education.value,
                    );
                    setValue('education', listOfEducations);
                  }
                }}
                placeholder="Selecione o nível educacional"
                value={
                  userEducation.filter(education =>
                    watch('education')?.some(item => item === education?.value),
                  ) || null
                }
              />
            </TextField>

            <TextField
              label="Tamanho Empresa"
              name="company_size"
              flex="1 0 17rem"
            >
              <CustomSelect
                id="company_size"
                isClearable
                isMulti
                isRtl={false}
                isSearchable
                options={companySize}
                onChange={companySizes => {
                  if (!companySizes.length) {
                    setValue('company_size', null);
                  } else {
                    const listOfCompanySizes = companySizes.map(
                      company => company.value,
                    );
                    setValue('company_size', listOfCompanySizes);
                  }
                }}
                placeholder="Selecione o tamanho da empresa"
                value={
                  companySize.filter(company =>
                    watch('company_size')?.some(
                      item => item === company?.value,
                    ),
                  ) || null
                }
              />
            </TextField>

            <TextField
              label="Tipo empresa"
              name="company_type"
              flex="1 0 16rem"
            >
              <CustomSelect
                id="company_type"
                isClearable
                isMulti
                isRtl={false}
                isSearchable
                options={companyType}
                onChange={companytypes => {
                  if (!companytypes.length) {
                    setValue('company_type', null);
                  } else {
                    const listOfCompanytypes = companytypes.map(
                      company => company.value,
                    );
                    setValue('company_type', listOfCompanytypes);
                  }
                }}
                placeholder="Selecione o tipo de empresa"
                value={
                  companyType.filter(company =>
                    watch('company_type')?.some(
                      item => item === company?.value,
                    ),
                  ) || null
                }
              />
            </TextField>

            <TextField label="CNAE" name="cnaes" flex="1 0 14rem">
              <CustomSelect
                id="cnaes"
                isClearable
                isMulti
                isRtl={false}
                isSearchable
                options={selectValues.cnaes}
                onChange={cnaes => {
                  if (!cnaes.length) {
                    setValue('cnaes', null);
                  } else {
                    const listOfCnae = cnaes.map(cnae => cnae.value);
                    setValue('cnaes', listOfCnae);
                  }
                }}
                placeholder="Selecione o CNAE"
                value={
                  selectValues.cnaes.filter(cnae =>
                    watch('cnaes')?.some(item => item === cnae?.value),
                  ) || null
                }
              />
            </TextField>

            <TextField
              label="Idade Empresa"
              name="company_age"
              flex="1 0 16rem"
            >
              <FlexContainer gap="1rem">
                <Input
                  value={getValues('company_age_start')}
                  onChange={e =>
                    setValue('company_age_start', Number(e.target.value))
                  }
                  type="number"
                  min={0}
                  thereIsError={watch('company_age_start') < 0}
                  placeholder="Idade mínima"
                />
                <Input
                  value={getValues('company_age_end')}
                  onChange={e =>
                    setValue('company_age_end', Number(e.target.value))
                  }
                  type="number"
                  min={0}
                  thereIsError={watch('company_age_end') < 0}
                  placeholder="Idade máxima"
                />
              </FlexContainer>
            </TextField>
          </FlexContainer>

          <FlexContainer gap="1rem">
            <Button type="submit" variant="contained">
              Filtrar
            </Button>
            <Button
              type="button"
              variant="contained"
              color="secondary"
              onClick={handleClearForm}
            >
              Limpar
            </Button>
          </FlexContainer>
        </Form>
      </Card>

      <Tabs {...TabsProps}>
        <S.ChartWrapper>
          <div className="progress-average-chart">
            <Bar
              options={{
                layout: {
                  padding: { right: 32 },
                },
                indexAxis: 'y',
                maintainAspectRatio: false,
                plugins: {
                  datalabels: {
                    display: true,
                    align: 'end',
                    anchor: 'end',
                  },
                  legend: {
                    display: false,
                  },
                },
                elements: {
                  bar: {
                    backgroundColor: context => {
                      const value = context.raw as number;
                      const chart = context.chart;
                      const { chartArea } = chart;

                      if (!chartArea) {
                        return;
                      }

                      switch (true) {
                        case value >= 0 && value <= 0.25:
                          return theme.colors.red_200;
                        case value > 0.25 && value <= 0.5:
                          return theme.colors.chart_orange;
                        case value > 0.5 && value <= 0.75:
                          return theme.colors.chart_yellow;
                        default:
                          return theme.colors.chart_green;
                      }
                    },
                  },
                },
              }}
              data={{
                labels: progressAverageByContent?.map(content => content.title),
                datasets: [
                  {
                    data: progressAverageByContent?.map(
                      content => content.progress_average,
                    ),
                  },
                ],
              }}
            />
          </div>

          <FlexContainer width="100%" justifyContent="center">
            {paginationCount > 1 && (
              <Pagination
                count={paginationCount}
                onChange={(_, page) => handlePageSwitching(page)}
                shape="rounded"
              />
            )}
          </FlexContainer>
        </S.ChartWrapper>

        <S.ChartWrapper>
          <div className="age-chart">
            <Pie
              options={{
                maintainAspectRatio: false,
                plugins: {
                  datalabels: pieOptions.dataLabels(reports.reportAge),
                  legend: pieOptions.legend,
                },
              }}
              data={{
                labels: [
                  'Menor de 18',
                  'Jovens',
                  'Adultos',
                  'Idosos',
                  'Não Disponível',
                ],
                datasets: [
                  {
                    data: reports.reportAge?.map((age, i) => {
                      const total = age[chartLabels.ageLabels[i]]?.total;

                      return total && addFloatingPoint(total);
                    }),
                    backgroundColor: chartBackground,
                  },
                ],
              }}
            />
          </div>
        </S.ChartWrapper>

        <S.ChartWrapper>
          <div className="gender-chart">
            <Pie
              options={{
                maintainAspectRatio: false,
                plugins: {
                  datalabels: pieOptions.dataLabels(reports.reportGender),
                  legend: pieOptions.legend,
                },
              }}
              data={{
                labels: ['Feminino', 'Masculino', 'Outro', 'Não Disponível'],
                datasets: [
                  {
                    data: reports.reportGender?.map((gender, i) => {
                      const total = gender[chartLabels.genderLabels[i]]?.total;
                      return total && addFloatingPoint(total);
                    }),
                    backgroundColor: chartBackground,
                  },
                ],
              }}
            />
          </div>
        </S.ChartWrapper>

        <S.ChartWrapper>
          <div className="progress-status-chart">
            <Bar
              options={{
                maintainAspectRatio: false,
                plugins: barOptions.plugins,
                scales: barOptions.scales,
              }}
              data={{
                labels: [
                  'Interesse',
                  'Inscrito',
                  'Iniciado',
                  'Em Progresso',
                  'Concluído',
                ],
                datasets: [
                  {
                    data: Object.values(reports.progressStatusTotal || {}).map(
                      addFloatingPoint,
                    ),
                    backgroundColor: [theme.colors.chart_violet],
                  },
                ],
              }}
            />
          </div>
        </S.ChartWrapper>

        <S.ChartWrapper>
          <div className="progress-status-chart">
            <Bar
              options={{
                maintainAspectRatio: false,
                plugins: barOptions.plugins,
                scales: barOptions.scales,
              }}
              data={{
                labels: Object.keys(reports.reportCities || {})?.map(item =>
                  item === 'notAvailable' ? 'Não disponível' : item,
                ),
                datasets: [
                  {
                    data: Object.values(reports.reportCities || {}).map(
                      addFloatingPoint,
                    ),
                    backgroundColor: [theme.colors.chart_violet],
                  },
                ],
              }}
            />
          </div>
        </S.ChartWrapper>

        <S.ChartWrapper>
          <div className="progress-status-chart">
            <Bar
              options={{
                maintainAspectRatio: false,
                plugins: barOptions.plugins,
                scales: barOptions.scales,
              }}
              data={{
                labels: Object.keys(reports.reportUfs || {})?.map(item =>
                  item === 'notAvailable' ? 'Não disponível' : item,
                ),
                datasets: [
                  {
                    data: Object.values(reports.reportUfs || {}).map(
                      addFloatingPoint,
                    ),
                    backgroundColor: [theme.colors.chart_violet],
                  },
                ],
              }}
            />
          </div>
        </S.ChartWrapper>

        <S.ChartWrapper>
          <div className="age-chart">
            <Pie
              options={{
                maintainAspectRatio: false,
                plugins: {
                  datalabels: pieOptions.dataLabels(reports.reportEducation),
                  legend: pieOptions.legend,
                },
              }}
              data={{
                labels: userEducation
                  .filter(education =>
                    chartLabels.educationLabels.some(
                      label => label === education.value,
                    ),
                  )
                  .map(el => el.label),
                datasets: [
                  {
                    data: reports.reportEducation?.map((education, i) => {
                      const total =
                        education[chartLabels.educationLabels[i]]?.total;
                      return total && addFloatingPoint(total);
                    }),
                    backgroundColor: chartBackground,
                  },
                ],
              }}
            />
          </div>
        </S.ChartWrapper>

        <S.ChartWrapper>
          <div className="age-chart">
            <Pie
              options={{
                maintainAspectRatio: false,
                plugins: {
                  datalabels: pieOptions.dataLabels(reports.reportCompanySize),
                  legend: pieOptions.legend,
                },
              }}
              data={{
                labels: companySize
                  .filter(size =>
                    chartLabels.companySizesLabels.some(
                      label => label === size.value,
                    ),
                  )
                  .map(el => el.label),
                datasets: [
                  {
                    data: reports.reportCompanySize?.map((size, i) => {
                      const total =
                        size[chartLabels.companySizesLabels[i]]?.total;
                      return total && addFloatingPoint(total);
                    }),
                    backgroundColor: chartBackground,
                  },
                ],
              }}
            />
          </div>
        </S.ChartWrapper>

        <S.ChartWrapper>
          <div className="age-chart">
            <Bar
              options={{
                maintainAspectRatio: false,
                plugins: barOptions.plugins,
                scales: barOptions.scales,
              }}
              data={{
                labels: companyType
                  .filter(type =>
                    chartLabels.companyTypeLabels.some(
                      label => label === type.value,
                    ),
                  )
                  .map(el => el.label),
                datasets: [
                  {
                    data: reports.reportCompanyType?.map((type, i) => {
                      const total =
                        type[chartLabels.companyTypeLabels[i]]?.total;
                      return total && addFloatingPoint(total);
                    }),
                    backgroundColor: [theme.colors.chart_violet],
                  },
                ],
              }}
            />
          </div>
        </S.ChartWrapper>

        <S.ChartWrapper>
          <div className="age-chart">
            <Bar
              options={{
                maintainAspectRatio: false,
                plugins: barOptions.plugins,
                scales: barOptions.scales,
              }}
              data={{
                labels: chartLabels.cnaeLabels.map(label => {
                  if (label === 'notAvailable') return 'Não Disponível';

                  return label;
                }),
                datasets: [
                  {
                    data: reports.reportCnaes?.map((cnae, i) => {
                      const total = cnae[chartLabels.cnaeLabels[i]]?.total;
                      return total && addFloatingPoint(total);
                    }),
                    backgroundColor: [theme.colors.chart_violet],
                  },
                ],
              }}
            />
          </div>
        </S.ChartWrapper>

        <S.ChartWrapper>
          <div className="age-chart">
            <Pie
              options={{
                maintainAspectRatio: false,
                plugins: {
                  datalabels: pieOptions.dataLabels(reports.reportCompanyAge),
                  legend: pieOptions.legend,
                },
              }}
              data={{
                labels: [
                  'Até Seis Meses',
                  'Seis Meses a Dois Anos',
                  'Dois Anos a Quatro Anos',
                  'Quatro Anos a Seis Anos',
                  'Mais de 6 Anos',
                  'Não Disponível',
                ],
                datasets: [
                  {
                    data: reports.reportCompanyAge?.map((company, i) => {
                      const total =
                        company[chartLabels.companyAgeLabels[i]]?.total;
                      return total && addFloatingPoint(total);
                    }),
                    backgroundColor: chartBackground,
                  },
                ],
              }}
            />
          </div>
        </S.ChartWrapper>
      </Tabs>

      {isOpen &&
        createPortal(
          <ContentsModal
            isOpen={isOpen}
            onCloseChange={() => setIsOpen(!isOpen)}
            handleSelected={handleContentSelected}
            selectedContent={watch('contents') || []}
            handleRemoveSelected={handleRemoveSelected}
            displayType={false}
          />,
          document.body,
        )}
    </S.Wrapper>
  );
}
