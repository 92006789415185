import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    gap: ${theme.spacings.small};
    width: 100%;
  `}
`;

export const Header = styled.header`
  align-items: center;
  display: flex;
  justify-content: space-between;
`;
