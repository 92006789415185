import styled, { css, DefaultTheme } from 'styled-components';

import { CharacterTotalProps } from '.';

const characterTotalModifiers = {
  sucess: (theme: DefaultTheme) => css`
    background-color: ${theme.colors.green_200};
    color: ${theme.colors.green_400};
  `,
  warning: (theme: DefaultTheme) => css`
    background-color: ${theme.colors.yellow_200};
    color: ${theme.colors.yellow_400};
  `,
  error: (theme: DefaultTheme) => css`
    background-color: ${theme.colors.red_200};
    color: ${theme.colors.red_400};
  `,
};

export const Wrapper = styled.span<CharacterTotalProps>`
  ${({ theme, characterCount, limit }) => css`
    align-self: self-end;
    border-radius: 16px;
    font-size: ${theme.font.sizes.xxsmall};
    font-weight: ${theme.font.semiBold};
    padding: 6px;

    ${characterCount <= limit.sucess && characterTotalModifiers.sucess(theme)}
    ${characterCount > limit.warning && characterTotalModifiers.warning(theme)}
    ${characterCount > limit.error && characterTotalModifiers.error(theme)}
  `}
`;
