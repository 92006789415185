import { CloseOutlined } from '@mui/icons-material';
import React from 'react';

import * as S from './styles';

type BadgesProps = {
  children: React.ReactNode | string;
  color?: 'neutral' | 'blue' | 'yellow' | 'red' | 'teal';
  handleRemove: () => void;
};

export default function Badges({
  children,
  color = 'neutral',
  handleRemove,
}: BadgesProps) {
  return (
    <S.Wrapper color={color}>
      <S.Title color={color}>{children}</S.Title>
      <S.Button type="button" title="Remover Item" onClick={handleRemove}>
        <CloseOutlined fontSize="inherit" />
      </S.Button>
    </S.Wrapper>
  );
}
