import { Slugify } from '../helpers/slugify';

export function dealWithTitleChange(value: string) {
  let slugUrl: string = value;

  slugUrl = slugUrl.toLowerCase();
  slugUrl = slugUrl.replace(/ç/g, 'c');
  slugUrl = slugUrl.replace(/Ç/g, 'C');
  slugUrl = slugUrl.replace(/[áàãâä]/g, 'a');
  slugUrl = slugUrl.replace(/[éèêë]/g, 'e');
  slugUrl = slugUrl.replace(/[íìîï]/g, 'i');
  slugUrl = slugUrl.replace(/[óòõôö]/g, 'o');
  slugUrl = slugUrl.replace(/[úùûü]/g, 'u');
  slugUrl = slugUrl.replace(/[ÁÀÃÂÄ]/g, 'A');
  slugUrl = slugUrl.replace(/[ÉÈÊË]/g, 'E');
  slugUrl = slugUrl.replace(/[ÍÌÎÏ]/g, 'I');
  slugUrl = slugUrl.replace(/[ÓÒÕÔÖ]/g, 'O');
  slugUrl = slugUrl.replace(/[ÚÙÛÜ]/g, 'U');
  slugUrl = slugUrl.replace(' ', '-');

  return Slugify(slugUrl);
}
