import React, { useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { useFormContext, useWatch } from 'react-hook-form';
import { SelectInstance } from 'react-select';
import Swal from 'sweetalert2';

import CustomDatePicker from '../../components/CustomDatePicker';
import { FlexContainer } from '../../components/FlexContainer';
import CustomInput from '../../components/FormComponent/CustomInput';
import { MultiSelect } from '../../components/FormComponent/MultiSelect';
import { SingleSelect } from '../../components/FormComponent/SingleSelect';
import TextField from '../../components/FormComponent/TextField';
import SimpleModal from '../../components/SimpleModal';
import Table, { TableProps } from '../../components/Table';
import theme from '../../styles/theme';
import contentDatesFormat from '../../utils/contentDatesFormat';
import { datesSearch } from '../../utils/datesSearch';
import * as S from './styles';

type SearchContentProps = {
  columnToPdf?: any[];
  handleExport: () => void;
  handleSubmit: (data) => void;
  optionList: any;
  reset: () => void;
  tableProps: TableProps;
};

export default function SearchContent({
  handleExport,
  handleSubmit,
  optionList,
  reset,
  tableProps,
}: SearchContentProps) {
  const [isOpen, setIsOpen] = useState(false);

  const formatRef = useRef<SelectInstance<true, true> | null>(null);
  const skillsRef = useRef<SelectInstance<true, true> | null>(null);
  const collectionsRef = useRef<SelectInstance<true, true> | null>(null);
  const tagstRef = useRef<SelectInstance<true, true> | null>(null);
  const momentsRef = useRef<SelectInstance<true, true> | null>(null);
  const authorsRef = useRef<SelectInstance<true, true> | null>(null);
  const sectorsRef = useRef<SelectInstance<true, true> | null>(null);
  const groupsRef = useRef<SelectInstance<true, true> | null>(null);
  const situationRef = useRef<SelectInstance<true, true> | null>(null);
  const visibilityRef = useRef<SelectInstance<true, true> | null>(null);

  const methods = useFormContext();
  const { watch, getValues } = methods;

  const visibility = useWatch({ control: methods.control, name: 'visibility' });

  const onSubmit = data => {
    const dataValid = contentDatesFormat(data);

    if (data['email']) delete data['email'];

    if (!dataValid.status) {
      if (dataValid.key && dataValid.key !== '') {
        Swal.fire({
          icon: 'error',
          title: `Erro no filtro ${datesSearch[dataValid.key]}`,
          text: `${datesSearch[dataValid.key]} possui data inválida`,
          confirmButtonColor: theme.colors.default,
        });
        return;
      }
      Swal.fire({
        icon: 'error',
        title: `Erro no filtro de data`,
        text: `Filtro possui data inválida`,
        confirmButtonColor: theme.colors.default,
      });
      return;
    }

    handleSubmit(dataValid.data);
  };

  const handleCleaning = (refs: any[]) => {
    refs.forEach(ref => {
      ref.current.clearValue();
    });
  };

  const handleExportModal = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    if (visibility !== 'private') {
      methods.setValue('groups', []);
    }
  }, [visibility]);

  return (
    <S.Wrapper onSubmit={methods.handleSubmit(onSubmit)}>
      <S.FlexContainer gap="20px" flexWrap="wrap" height="none">
        <S.FlexContainer
          flexDirection="column"
          gap="10px"
          width="none"
          height="none"
          justifyContent="flex-start"
          flex="1 1 260px"
        >
          <CustomInput label="Palavra-Chave" name="search" />
          <MultiSelect
            label="Formato"
            name="types"
            options={optionList?.formats}
            ref={formatRef}
          />
          <MultiSelect
            label="Habilidades"
            name="skills"
            options={optionList?.skills}
            ref={skillsRef}
            alternativeState="categories"
          />
          <MultiSelect
            label="Coleções"
            name="collections"
            options={optionList?.collections}
            ref={collectionsRef}
          />
        </S.FlexContainer>

        <S.FlexContainer
          flexDirection="column"
          gap="10px"
          width="none"
          height="none"
          justifyContent="flex-start"
          flex="1 1 260px"
        >
          <MultiSelect
            label="Tag"
            name="tags"
            options={optionList?.tags}
            ref={tagstRef}
          />
          <SingleSelect
            label="Situação"
            name="status"
            options={optionList?.status}
            ref={situationRef}
          />
          <MultiSelect
            label="Momentos"
            name="moments"
            options={optionList?.moments}
            ref={momentsRef}
            alternativeState="categories"
          />
          <MultiSelect
            label="Autor"
            name="authors"
            options={optionList?.authors}
            ref={authorsRef}
          />
        </S.FlexContainer>

        <S.FlexContainer
          flexDirection="column"
          gap="10px"
          width="none"
          height="none"
          justifyContent="flex-start"
          flex="1 1 260px"
        >
          <CustomInput type="checkbox" label="Destaque" name="is_featured" />
          <MultiSelect
            label="Setores"
            name="sectors"
            options={optionList?.sectors}
            ref={sectorsRef}
            alternativeState="categories"
          />
          <SingleSelect
            label="Visibilidade"
            name="visibility"
            options={optionList?.visibility}
            ref={visibilityRef}
          />
          <MultiSelect
            label="Nome do Grupo"
            name="groups"
            options={optionList?.groups}
            isDisabled={visibility !== 'restricted'}
            ref={groupsRef}
          />
        </S.FlexContainer>
      </S.FlexContainer>

      <S.FlexContainer
        height="none"
        gap="10px"
        justifyContent="flex-start"
        flex={0}
      >
        <CustomDatePicker
          label={datesSearch.consumption_start_at}
          name="consumption_start_at"
        />
        <CustomDatePicker
          label={datesSearch.consumption_end_at}
          name="consumption_end_at"
        />
        <CustomDatePicker
          label={datesSearch.published_start_at}
          name="published_start_at"
        />
        <CustomDatePicker
          label={datesSearch.published_end_at}
          name="published_end_at"
        />
        <CustomDatePicker
          label={datesSearch.review_start_at}
          name="review_start_at"
        />
        <CustomDatePicker
          label={datesSearch.review_end_at}
          name="review_end_at"
        />
      </S.FlexContainer>

      <S.FlexContainer
        justifyContent="flex-start"
        gap="10px"
        height="none"
        flex={0}
      >
        <S.Button type="submit">filtrar</S.Button>
        <S.Button
          type="button"
          color="gray_500"
          onClick={() => {
            reset();
            methods.reset();
            handleCleaning([
              formatRef,
              skillsRef,
              collectionsRef,
              tagstRef,
              momentsRef,
              authorsRef,
              sectorsRef,
              groupsRef,
              situationRef,
              visibilityRef,
            ]);
          }}
        >
          limpar
        </S.Button>
        <S.Button type="button" onClick={handleExportModal}>
          exportar
        </S.Button>
      </S.FlexContainer>

      <Table {...tableProps} checkboxSelection={false} disableColumnMenu />

      {isOpen &&
        createPortal(
          <SimpleModal
            title="Solicitação de Relatório"
            isOpen={isOpen}
            onCloseChange={handleExportModal}
          >
            <FlexContainer gap="10px" flexDirection="column" width="100%">
              <TextField
                label="Digite o e-mail que irá receber o relatório"
                name="email"
              />
              <S.Button
                type="button"
                disabled={!watch('email')}
                onClick={() => {
                  handleExport();
                  handleExportModal();
                }}
              >
                Solicitar
              </S.Button>
            </FlexContainer>
          </SimpleModal>,
          document.body,
        )}
    </S.Wrapper>
  );
}
