import { AddCircle, Delete, ViewList } from '@mui/icons-material';
import React, { useEffect, useRef } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useForm } from 'react-hook-form';
import { useLocation, useSearchParams } from 'react-router-dom';
import Swal from 'sweetalert2';

import { IAuthor } from '../../../models/author';
import { getAuthor } from '../../../services/authors';
import Button from '../../Button/index';
import Container from '../../Drag/Container';
import { FlexContainer } from '../../FlexContainer';
import Heading from '../../Heading';
import AddAuthorModal from '../../SimpleModal/AddAuhtorModal';
import AuthorModal from '../../SimpleModal/AuthorModal';
//TO DO: REFATORAR NA PRÓXIMA SPRINT PARA SE ADEQUAR AO useFormContext
export default function Authors(props: any) {
  const [searchParams] = useSearchParams();
  const location = useLocation();
  const [modalOpen, setModalOpen] = React.useState<boolean>(false);
  const [listModalOpen, setListModalOpen] = React.useState<boolean>(false);
  const [selectedAuthors, setSelectedAuthors] = React.useState<IAuthor[]>([]);
  const [author, setAuthor] = React.useState<IAuthor>();

  const ordersToRefFileAuthors: any = useRef();
  ordersToRefFileAuthors.current = selectedAuthors;

  const getContent = async () => {
    await getAuthor(searchParams.get('id'))
      .then(result => {
        setAuthor(result);
      })
      .catch(err => {
        Swal.fire({
          title: 'algo deu errado',
          icon: 'warning',
        });
        return {};
      });
  };

  React.useEffect(() => {
    if (
      location.pathname.includes('author') &&
      searchParams.get('id') != null &&
      !props.content
    ) {
      getContent();
    } else {
      setValue('type', 'person');
    }
  }, []);

  const fillAuthors = () => {
    const editingAuthors = Object.keys(props.content).includes('authors')
      ? props.content.authors
      : [];

    if (editingAuthors.length > 0) {
      setSelectedAuthors(editingAuthors);
    }
  };

  useEffect(() => {
    const contentIsIncluded = Object.keys(props).includes('content');

    if (
      contentIsIncluded &&
      props.content !== undefined &&
      props.content !== null
    ) {
      fillAuthors();
    }
  }, [props.content]);

  const { setValue } = useForm();

  const handleSelected = (author: IAuthor) => {
    setSelectedAuthors(state => [...state, author]);

    const authorsId = selectedAuthors.map((author: any) => author.author_id);
    props.setValue('authors', authorsId);
  };

  const handleDeleteItem = item => {
    const updatedFileAuthors = ordersToRefFileAuthors.current.filter(
      e => e.author_id !== item.author_id,
    );
    setSelectedAuthors(updatedFileAuthors);
  };

  useEffect(() => {
    const authorsId: any[] = [];
    selectedAuthors.forEach((author: any) => {
      authorsId.push(author.author_id);
    });
    props.setValue('authors', authorsId);
  }, [selectedAuthors]);

  return (
    <>
      <FlexContainer
        gap="0.875rem"
        flexDirection="column"
        width="100%"
        alignItems="center"
      >
        <Heading level={2} style={{ alignSelf: 'self-start' }}>
          Autores
        </Heading>
        <DndProvider backend={HTML5Backend}>
          <Container
            item={selectedAuthors}
            setItem={setSelectedAuthors}
            onChangeDeleteItem={handleDeleteItem}
            view={props.view}
          />
        </DndProvider>
        {selectedAuthors.length === 0 && (
          <span
            style={{
              color: 'disable',
              margin: '1rem auto',
            }}
          >
            Nenhum autor selecionado
          </span>
        )}

        <FlexContainer gap="0.875rem" justifyContent="center" flexWrap="wrap">
          <Button
            type="button"
            variant="contained"
            color="info"
            disabled={props.view}
            startIcon={<ViewList />}
            onClick={() => setListModalOpen(true)}
          >
            <span>Listar</span>
          </Button>
          <Button
            type="button"
            variant="contained"
            disabled={props.view}
            startIcon={<AddCircle />}
            onClick={() => setModalOpen(true)}
          >
            <span> Adicionar novo</span>
          </Button>
          <Button
            type="button"
            variant="contained"
            color="error"
            disabled={props.view}
            onClick={() => {
              setSelectedAuthors([]);
              props.setValue('authors', []);
            }}
            startIcon={<Delete />}
          >
            <span>Remover Todos</span>
          </Button>
        </FlexContainer>
      </FlexContainer>

      {listModalOpen && (
        <AuthorModal
          key={'author-modal'}
          isOpen={listModalOpen}
          onCloseChange={() => setListModalOpen(false)}
          handleSelected={handleSelected}
          selectedAuthors={selectedAuthors}
        />
      )}

      {modalOpen && (
        <AddAuthorModal
          isOpen={modalOpen}
          onCloseChange={() => setModalOpen(false)}
          author={author}
        />
      )}
    </>
  );
}
