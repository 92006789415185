import {
  Box,
  Checkbox,
  FormGroup,
  FormHelperText,
  Typography,
} from '@mui/material';
import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Swal from 'sweetalert2';

import * as appStyles from '../../../../AppStyle';
import ButtonGroup from '../../../../components/ButtonGroup';
import ContentListEpisode from '../../../../components/ContentListEpisode';
import DraftEditor from '../../../../components/DraftEditor/DraftEditor';
import FilesComplementary from '../../../../components/FormComponent/FilesComplementary';
import SambaMainFile from '../../../../components/SambaMainFile';
// Helpers
import toast from '../../../../helpers/toast';
import {
  createEpisode,
  editEpisode,
  getEpisode,
} from '../../../../services/episodeSerie';
import { putLessonDuration } from '../../../../services/lessons';
import * as styles from './styles';

const CreateAndEditEpisode: React.FC<any> = ({ type }: any) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [isEditing, setIsEditing] = React.useState(true);
  const [isLoading, setIsloading] = React.useState(true);
  const [isShowing, setIsShowing] = React.useState(false);
  const [isShowContent, setIsShowContent] = React.useState(false);
  const [isShowVideo, setIsShowVideo] = React.useState(false);
  const [isShowAudio, setIsShowAudio] = React.useState(false);
  const [mediaType, setMediaType] = React.useState('');
  const [lesson, setLesson] = React.useState<any>();
  const [cleanEditor, setCleanEditor] = React.useState(false);
  const { handleSubmit, control, setValue, getValues } = useForm();
  const onError = () => {
    Swal.fire({
      title: 'Por favor, preencha o formulário corretamente.',
    });
  };
  const navigate = useNavigate();

  const getContent = async () => {
    setIsloading(true);
    const id = searchParams.get('id');
    if (id) {
      const response = await putLessonDuration(id);

      if (!response || response.duration === 0) {
        Swal.fire({
          title: `Atenção!\n\nO video está passando pelo processo de Encode no Sambavideos.`,
          icon: 'warning',
        });
      }

      await getEpisode(id).then(result => {
        if (result.duration === 0 && response.duration !== 0) {
          setValue('duration', response.duration);
        }

        switch (result?.media_type) {
          case 'video':
            setIsShowContent(false);
            setIsShowVideo(true);
            setIsShowAudio(false);
            setMediaType('video');
            break;

          case 'audio':
            setIsShowContent(false);
            setIsShowVideo(false);
            setIsShowAudio(true);
            setMediaType('audio');

            break;

          case 'content':
            setIsShowContent(true);
            setIsShowVideo(false);
            setIsShowAudio(false);
            setMediaType('content');
            break;

          default:
            setIsShowContent(false);
            setIsShowVideo(false);
            setIsShowAudio(false);
            break;
        }

        setLesson(result);
        setValue('title', result.title);
        setValue('description', result.description);
        setValue('resume', result.resume);
        setValue('media_type', result.media_type);
        setValue('content_id', result.content_id ? result.content_id : null);
        setValue(
          'complement_contents',
          result.complement_contents?.map((el: any) => {
            return el.content_id;
          }),
        );
      });
    }
    setIsloading(false);
  };
  React.useEffect(() => {
    if (searchParams.get('id') != null) {
      getContent();
      if (searchParams.get('view') != null) {
        setIsShowing(true);
      }
      setIsEditing(true);
    } else {
      setIsEditing(false);
      setIsloading(false);
    }
  }, []);

  function onSubmit(data: any, status: any) {
    const id: string | null = searchParams.get('id');

    if (data['duration'] === 0) {
      delete data['duration'];
      data['pre_status'] = status;
    } else {
      data['status'] = status;
    }

    switch (mediaType) {
      case 'video':
        data['media_type'] = 'video';
        data['content_id'] = null;
        break;

      case 'audio':
        data['media_type'] = 'audio';
        data['content_id'] = null;
        break;

      case 'content':
        data['media_type'] = 'content';
        data['reference'] = null;
        data['file_name'] = null;

        break;
      default:
        setIsShowContent(false);
        setIsShowVideo(false);
        setIsShowAudio(false);
        break;
    }

    if (
      data.description === '<p><br></p><figure>&nbsp;</figure>' ||
      data.description === '<p><br></p>'
    ) {
      data.description = null;
    }

    data = { ...data, status: status };

    delete data['image_name'];
    delete data['image_toltip'];
    if (!isEditing) {
      data = { ...data, status: status, type: 'series' };

      if (
        (data['media_type'] !== 'content' && data['reference'] === null) ||
        !data['media_type']
      ) {
        Swal.fire({
          title: 'Precisa selecionar um video, audio ou conteúdo',
          icon: 'error',
        });

        return;
      }
      createEpisode(data)
        .then(() => {
          Swal.fire({
            title: 'Episódio criado com sucesso',
            icon: 'success',
          });
          navigate(-1);
        })
        .catch(error => {
          if (error.response.data.errors) {
            Swal.fire({
              title: error.response.data.message,
              icon: 'error',
            });
            error.response.data.errors.map(el =>
              toast({
                message: el.message,
                type: 'warning',
              }),
            );
          } else {
            Swal.fire({
              title: 'Algo deu errado',
              icon: 'error',
            });
          }
        });
    } else {
      if (
        (data['media_type'] !== 'content' && data['reference'] === null) ||
        !data['media_type']
      ) {
        Swal.fire({
          title: 'Precisa selecionar um video, audio ou conteúdo',
          icon: 'error',
        });

        return;
      }

      editEpisode(id, data)
        .then(() => {
          Swal.fire({
            title: 'Episódio alterado com sucesso',
            icon: 'success',
          });
          navigate(-1);
        })
        .catch(error => {
          if (error.response.data.errors) {
            Swal.fire({
              title: error.response.data.message,
              icon: 'error',
            });
            error.response.data.errors.map(el =>
              toast({
                message: el.message,
                type: 'warning',
              }),
            );
          } else {
            Swal.fire({
              title: 'Algo deu errado',
              icon: 'error',
            });
          }
        });
    }
  }

  useEffect(() => {
    if (lesson) {
      setLesson({ ...lesson, reference: null, file_name: null });
    }
  }, [mediaType]);

  return (
    <>
      {!isLoading && (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            height: 'min-content',
          }}
        >
          <styles.CardInput>
            <div style={{ padding: '22px' }}>
              <Typography variant="h4" style={{ marginBottom: '22px' }}>
                Episódios
              </Typography>
              <styles.ContentSeletor>
                <Box
                  component="div"
                  sx={{
                    '& .MuiTextField-root': { width: '100%' },
                  }}
                >
                  <styles.Label>Título *</styles.Label>
                  <Controller
                    name="title"
                    control={control}
                    rules={{
                      required: true,
                      maxLength: 70,
                    }}
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <>
                        <styles.InputText
                          disabled={isShowing}
                          name="title"
                          value={value}
                          inputProps={{ maxLength: 70 }}
                          onChange={onChange}
                          required
                          id="outlined-required"
                        />
                        {value?.length >= 70 && (
                          <FormHelperText id="outlined-required">
                            <appStyles.inputLabel
                              style={{ color: 'red', marginBottom: '0.5rem' }}
                            >
                              Tamanho máximo de 70 caracteres.
                            </appStyles.inputLabel>
                          </FormHelperText>
                        )}
                        {error !== undefined &&
                          (error.type === 'required' ? (
                            <appStyles.inputLabel
                              style={{ color: 'red', marginBottom: '0.5rem' }}
                            >
                              *Campo obrigatório.
                            </appStyles.inputLabel>
                          ) : (
                            <appStyles.inputLabel
                              style={{ color: 'red', marginBottom: '0.5rem' }}
                            >
                              Tamanho máximo de 70 caracteres.
                            </appStyles.inputLabel>
                          ))}
                      </>
                    )}
                  />
                </Box>
              </styles.ContentSeletor>
              <styles.ContentSeletor>
                <styles.Label>Resumo *</styles.Label>
                <Box
                  component="div"
                  sx={{
                    '& .MuiTextField-root': { width: '100%' },
                  }}
                >
                  <Controller
                    name="resume"
                    control={control}
                    rules={{
                      required: true,
                      maxLength: 300,
                    }}
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <>
                        <styles.InputTextArea
                          disabled={isShowing}
                          name="resume"
                          value={value}
                          onChange={onChange}
                          maxRows={3}
                          minRows={3}
                          inputProps={{ maxLength: 301 }}
                          multiline
                        />
                        {value?.length > 300 && (
                          <FormHelperText id="outlined-required">
                            <appStyles.inputLabel
                              style={{ color: 'red', marginBottom: '0.5rem' }}
                            >
                              Tamanho máximo de 300 caracteres.
                            </appStyles.inputLabel>
                          </FormHelperText>
                        )}
                        {error !== undefined &&
                          (error.type === 'required' ? (
                            <appStyles.inputLabel
                              style={{ color: 'red', marginBottom: '0.5rem' }}
                            >
                              *Campo obrigatório.
                            </appStyles.inputLabel>
                          ) : (
                            <appStyles.inputLabel
                              style={{ color: 'red', marginBottom: '0.5rem' }}
                            >
                              Tamanho máximo de 300 caracteres.
                            </appStyles.inputLabel>
                          ))}
                      </>
                    )}
                  />
                </Box>
              </styles.ContentSeletor>
              <styles.ContentSeletor>
                <styles.Label>Descrição</styles.Label>
                <Controller
                  name="description"
                  control={control}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <>
                      <DraftEditor
                        name={'description'}
                        setValue={setValue}
                        value={value}
                        cleanEditor={cleanEditor}
                        handleCleanEditor={() => setCleanEditor(false)}
                      />
                      {error !== undefined && (
                        <appStyles.inputLabel
                          style={{ color: 'red', marginBottom: '0.5rem' }}
                        >
                          {error.message}
                        </appStyles.inputLabel>
                      )}
                    </>
                  )}
                />
              </styles.ContentSeletor>
            </div>
          </styles.CardInput>
          <styles.ContentSeletor>
            <Box
              component="div"
              sx={{
                '& .MuiTextField-root': { width: '100%' },
              }}
            >
              <div>
                <FormGroup style={{ flexDirection: 'row' }}>
                  <div>
                    <Checkbox
                      id="videos"
                      checked={isShowVideo}
                      onChange={() => {
                        setIsShowVideo(true);
                        setMediaType('video');
                        setIsShowAudio(false);
                        setIsShowContent(false);
                      }}
                    />
                    <styles.Label htmlFor="videos">Videos</styles.Label>
                  </div>

                  <div>
                    <Checkbox
                      id="audio"
                      checked={isShowAudio}
                      onChange={() => {
                        setIsShowAudio(true);
                        setMediaType('audio');
                        setIsShowVideo(false);
                        setIsShowContent(false);
                      }}
                    />
                    <styles.Label htmlFor="audio">Audio</styles.Label>
                  </div>

                  <div>
                    <Checkbox
                      id="content"
                      checked={isShowContent}
                      onChange={() => {
                        setIsShowContent(true);
                        setMediaType('content');
                        setIsShowVideo(false);
                        setIsShowAudio(false);
                      }}
                    />
                    <styles.Label htmlFor="content">Conteúdo</styles.Label>
                  </div>
                </FormGroup>
              </div>
            </Box>
          </styles.ContentSeletor>
          {isShowVideo && (
            <styles.CardInput>
              <SambaMainFile
                label="Video"
                setValue={setValue}
                control={control}
                content={lesson}
                view={isShowing}
                type={'lesson'}
                getValues={getValues}
              />
            </styles.CardInput>
          )}

          {isShowAudio && (
            <styles.CardInput>
              <SambaMainFile
                label="Audio"
                setValue={setValue}
                control={control}
                content={lesson}
                view={isShowing}
                type={'lesson'}
                getValues={getValues}
              />
            </styles.CardInput>
          )}

          {isShowContent && (
            <styles.CardInput>
              <ContentListEpisode
                label="Conteúdo"
                view={isShowing}
                setValue={setValue}
                control={control}
                content={lesson}
                type={'lesson'}
                handleCleanEditor={() => setCleanEditor(true)}
              />
            </styles.CardInput>
          )}

          <styles.ContentSeletor>
            <ButtonGroup
              content={lesson}
              type={'lesson'}
              view={isShowing}
              disabledButtons={true}
              handleSubmit={handleSubmit(onSubmit, onError)}
            />
          </styles.ContentSeletor>
        </div>
      )}
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          margin: '0.5rem',
          width: '100%',
          maxWidth: '50%',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            margin: '0.5rem',
            width: '100%',
            maxWidth: '100%',
          }}
        >
          <styles.CardInput>
            <FilesComplementary
              control={control}
              setValue={setValue}
              content={lesson}
              view={isShowing}
              permission="all"
            />
          </styles.CardInput>
        </div>
      </div>
    </>
  );
};

export default CreateAndEditEpisode;
