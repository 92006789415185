import { DeleteOutline } from '@mui/icons-material';
import { Button, Divider, Typography } from '@mui/material';
import update from 'immutability-helper';
import { useCallback } from 'react';

import { Card } from './Card';

const style = {
  width: '100%',
};
interface IContainer {
  item: any;
  setItem: any;
  label?: string;
  type?: string;
  onChangeDeleteItem?: any;
  view?: boolean;
}

const Container = (props: IContainer) => {
  const { item, setItem, type, onChangeDeleteItem, view } = props;

  const moveCard = useCallback(
    (dragIndex, hoverIndex) => {
      setItem(prevCards =>
        update(prevCards, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, prevCards[dragIndex]],
          ],
        }),
      );
    },
    [setItem],
  );

  const renderCard = useCallback(
    (card, index) => {
      return (
        <div style={style} key={`render-card-${index}`}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              width: '100%',
              justifyContent: 'space-between',
            }}
          >
            <Card
              key={card.collection_id ? card.collection_id : card.author_id}
              index={index}
              id={card.collection_id ? card.collection_id : card.author_id}
              name={
                type === 'fileComplementary' ? card.original_name : card.name
              }
              moveCard={moveCard}
            />
            <Button
              disabled={view}
              startIcon={<DeleteOutline />}
              onClick={() => onChangeDeleteItem(card)}
              style={{
                width: '12em',
                right: '0.1rem',
              }}
            ></Button>
          </div>
        </div>
      );
    },
    [moveCard],
  );

  const renderContentCard = useCallback(
    (card, index) => {
      return (
        <div style={style} key={`render-content-card-${index}`}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              width: '100%',
              justifyContent: 'space-between',
            }}
          >
            <Card
              key={card.content_id}
              index={index}
              id={card.content_id}
              name={card.title}
              moveCard={moveCard}
            />
            <Button
              disabled={view}
              startIcon={<DeleteOutline />}
              onClick={() => onChangeDeleteItem(card)}
              style={{
                width: '2rem',
                right: '0.1rem',
                flex: 1,
              }}
            />
          </div>
        </div>
      );
    },
    [moveCard],
  );

  return (
    <>
      <div style={style}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            justifyContent: 'space-between',
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              marginLeft: '1rem',
            }}
          >
            <Typography variant="subtitle1" style={{ marginLeft: '1rem' }}>
              {type === 'fileComplementary' ? 'Arquivo' : 'Titulo'}
            </Typography>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              marginRight: '2rem',
            }}
          >
            <Typography variant="subtitle1">Posição</Typography>
          </div>
        </div>
        <Divider
          orientation="horizontal"
          style={{ height: '0.25rem', backgroundColor: '#354371' }}
        />
        {type === 'content'
          ? item.map((card, i) => renderContentCard(card, i))
          : item.map((card, i) => renderCard(card, i))}
      </div>
    </>
  );
};
export default Container;
