import { AddPhotoAlternate } from '@mui/icons-material';
import React from 'react';
import { DropzoneState } from 'react-dropzone';

import * as S from './styles';

type FileInputProps = {
  dropzone: DropzoneState;
  text: string;
  id: string;
};

export default function FileInput({ dropzone, text, id }: FileInputProps) {
  const { getRootProps, getInputProps } = dropzone;

  return (
    <S.Wrapper>
      <S.Container {...getRootProps({ className: `dropzone disabled` })}>
        <input id={id} {...getInputProps()} />
        <AddPhotoAlternate fontSize="large" />
        <span>{text}</span>
      </S.Container>
    </S.Wrapper>
  );
}
