import { useEffect, useReducer } from 'react';

import { ContentType, TypeDictionary } from '../models/content';
import { listAuthors } from '../services/authors';
import { getCategories } from '../services/categorization';
import { getAllCollections } from '../services/collections';
import { getAllTags } from '../services/tags';

type DispatchProps = {
  type:
    | 'SET_CATEGORIES'
    | 'SET_COLLECTIONS'
    | 'SET_TAGS'
    | 'SET_AUTHORS'
    | 'SET_FORMAT'
    | 'SET_STATUS'
    | 'SET_VISIBILITY'
    | 'SET_GROUPS';
  payload?: any;
};

type StateProps = {
  formats: { label: string; value: string }[] | [];
  skills: { label: string; value: string }[] | [];
  collections: { label: string; value: string }[] | [];
  tags: { label: string; value: string }[] | [];
  status: { label: string; value: string }[] | [];
  moments: { label: string; value: string }[] | [];
  authors: { label: string; value: string }[] | [];
  sectors: { label: string; value: string }[] | [];
  visibility: { label: string; value: string }[] | [];
  groups: { label: string; value: string }[] | [];
};

const groups = [
  { label: 'Cliente - Pessoa Física', value: 'PHYSICAL' },
  {
    label: 'Cliente - Pessoa Jurídica',
    value: 'LEGAL',
  },
];

const visibility = [
  {
    label: 'Publico',
    value: 'public',
  },
  {
    label: 'Privado',
    value: 'restricted',
  },
];

const status = [
  {
    label: 'Ocultado',
    value: 'hidden',
  },
  {
    label: 'Rascunho',
    value: 'draft',
  },
  {
    label: 'Publicado',
    value: 'published',
  },
];

const formatList: { label: string; value: string }[] = [];

for (const type in ContentType) {
  const obj = { label: TypeDictionary[type], value: ContentType[type] };

  formatList.push(obj);
}

const INITIAL_VALUE: StateProps = {
  formats: formatList,
  skills: [],
  collections: [],
  tags: [],
  status: status,
  moments: [],
  authors: [],
  sectors: [],
  visibility: visibility,
  groups: groups,
};

function reducer(state: StateProps, action: DispatchProps) {
  const { type, payload } = action;

  switch (type) {
    case 'SET_CATEGORIES':
      return {
        ...state,
        ...payload,
      };
    case 'SET_COLLECTIONS':
      return {
        ...state,
        collections: payload,
      };
    case 'SET_TAGS':
      return {
        ...state,
        tags: payload,
      };
    case 'SET_AUTHORS':
      return {
        ...state,
        authors: payload,
      };
    case 'SET_FORMAT':
      return {
        ...state,
        formats: payload,
      };
    case 'SET_STATUS':
      return {
        ...state,
        status: payload,
      };
    case 'SET_VISIBILITY':
      return {
        ...state,
        visibility: payload,
      };
    case 'SET_GROUPS':
      return {
        ...state,
        groups: payload,
      };
    default:
      return state;
  }
}

export default function useGetOptions() {
  const [state, dispatch] = useReducer(reducer, INITIAL_VALUE);

  const getContents = async () => {
    try {
      const [categoryData, collectionsData, tagsData, authorsData] =
        await Promise.all([
          getCategories(),
          getAllCollections(),
          getAllTags(),
          listAuthors(),
        ]);

      dispatch({
        type: 'SET_CATEGORIES',
        payload: {
          skills: categoryData.skills.categories.map(skill => ({
            label: skill.name,
            value: skill.category_id,
          })),
          moments: categoryData.moments.categories.map(moment => ({
            label: moment.name,
            value: moment.category_id,
          })),
          sectors: categoryData.sectors.categories.map(sector => ({
            label: sector.name,
            value: sector.category_id,
          })),
        },
      });

      dispatch({
        type: 'SET_COLLECTIONS',
        payload: collectionsData.data.map(collection => ({
          label: collection.name,
          value: collection.collection_id,
        })),
      });

      dispatch({
        type: 'SET_TAGS',
        payload: tagsData.data.map(tag => ({
          label: tag.name,
          value: tag.tag_id,
        })),
      });

      dispatch({
        type: 'SET_AUTHORS',
        payload: authorsData.data.map(author => ({
          label: author.name,
          value: author.author_id,
        })),
      });
    } catch (error) {
      console.error('Erro:', error);
    }
  };

  useEffect(() => {
    getContents();
  }, []);

  return { options: state };
}
