const convertMilisecondsToHours = (mileseconds: number): string => {
  const ms = mileseconds % 1000;
  mileseconds = (mileseconds - ms) / 1000;
  const secs = mileseconds % 60;
  mileseconds = (mileseconds - secs) / 60;
  const mins = mileseconds % 60;
  const hrs = (mileseconds - mins) / 60;

  return hrs + 'h ' + ': ' + mins + 'm ' + ': ' + secs + 's';
};

export default convertMilisecondsToHours;
