import styled, { css } from 'styled-components';

export const Wrapper = styled.div``;

type CardProps = {
  fullWidth?: boolean;
};

export const Card = styled.div<CardProps>`
  ${({ theme, fullWidth }) => css`
    background-color: ${theme.colors.white};
    box-shadow: ${theme.shadow.card_base};
    border-radius: ${theme.border.radius};
    border: 1px solid #edeff5;
    display: flex;
    flex-direction: column;

    ${fullWidth && `width: 100%`}
  `}
`;

export const CardHeader = styled.div`
  ${() => css`
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
    padding: 1.25rem 1.5rem;
  `}
`;

export const CardBody = styled.div`
  ${() => css`
    flex: 1 1 auto;
    gap: 1rem;
    min-height: 1px;
    padding: 1rem 1.5rem;
  `}
`;
