import { z } from 'zod';

import { basicContentSchema } from './basicContentValitadion';

export const videoSchema = z
  .object({
    allow_download: z.boolean().nullable().optional(),
    duration: z.number().nullable().optional(),
    file_id: z.string().nullable().optional(),
    file_name: z.string().nullable().optional(),
    file_toltip: z.string().nullable().optional(),
    reference: z.string().nullable().optional(),
  })
  .and(basicContentSchema);
