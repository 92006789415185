import React from 'react';
import { useFormContext } from 'react-hook-form';

import MainFile from '../../components/FormComponent/MainFile';
import BasicContentTemplate, {
  BasicContentTemplateType,
} from '../BasicContentTemplate';

type InfographicsTemplate = BasicContentTemplateType;

export default function InfographicsTemplate({
  submit,
  title,
  view,
  content,
  isLoading,
}: InfographicsTemplate) {
  const { setValue, getValues, control } = useFormContext();

  return (
    <BasicContentTemplate
      title={title}
      view={view}
      submit={submit}
      content={content}
      isLoading={isLoading}
    >
      <MainFile
        getValues={getValues}
        titleCompany={'Arquivo'}
        setValue={setValue}
        control={control}
        content={content}
        view={view}
        permission="public"
      />
    </BasicContentTemplate>
  );
}
