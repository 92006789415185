import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  ${({ theme }) => css`
    align-items: center;
    display: flex;
    gap: 0.5rem;
    width: 100%;

    .react-select-container {
      flex: 2;
    }

    .react-select__control {
      background-color: ${theme.colors.white};
      border: 1px solid;
      border-color: hsl(0, 0%, 80%);
      border-radius: 0.25rem;
      box-shadow: 0 3px 2px rgba(233, 236, 239, 0.05);
    }

    .react-select__value-container {
      color: ${theme.colors.gray_600};
      font-size: ${theme.font.sizes.xsmall};
      padding: 10px 12px;
      line-height: 1.5;
    }

    .react-select__menu {
      font-size: ${theme.font.sizes.xsmall};
    }

    .react-select__control--is-focused {
      border-color: ${theme.colors.default};
      box-shadow: 0 0 0 1px ${theme.colors.default},
        3px 4px 8px rgba(94, 114, 228, 0.1);
      outline: 0;
    }

    .react-select__placeholder {
      color: ${theme.colors.gray_600};
      font-size: ${theme.font.sizes.xsmall};
      font-weight: ${theme.font.light};
      font-style: italic;
      line-height: 1.5;
    }

    .react-select__dropdown-indicator {
      padding: 10.5px 12px;
    }
  `}
`;

export const Button = styled.button`
  ${({ theme }) => css`
    background-color: transparent;
    border: 1px solid;
    border-color: ${theme.colors.neutral_400};
    border-radius: 0.25rem;
    color: ${theme.colors.neutral_700};
    cursor: pointer;
    font-size: 18px;
    padding: 10px 4px;

    &:hover {
      background-color: ${theme.colors.default};
      border-color: ${theme.colors.neutral_600};
      color: ${theme.colors.white};
    }

    &:active {
      background-color: ${theme.colors.default};
      border-color: ${theme.colors.neutral_500};
      color: ${theme.colors.white};
    }

    &:focus {
      outline: 2px solid;
      outline-color: ${theme.colors.blue_200};
    }
  `}
`;
