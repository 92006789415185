import { Close } from '@mui/icons-material';
import React, { forwardRef, HTMLAttributes } from 'react';
import { useFormContext } from 'react-hook-form';

import * as S from './styles';

type InputFileType = {
  fieldName: string;
  accept?: string;
  clear: () => void;
  dropFile: (e: React.DragEvent<HTMLDivElement>) => void;
} & HTMLAttributes<HTMLInputElement>;

function Input(
  { fieldName, clear, accept, dropFile, ...props }: InputFileType,
  ref,
) {
  const {
    watch,
    formState: { errors },
  } = useFormContext();

  const isError = Object.keys(errors).includes(fieldName);

  return (
    <>
      <S.Wrapper thereIsError={isError} onDrop={dropFile}>
        <input type="file" ref={ref} accept={accept} {...props} />
        {watch(fieldName)?.length > 0 && (
          <button
            type="button"
            aria-label="Remover arquivo"
            title="Remover arquivo"
            onClick={clear}
          >
            <Close fontSize="inherit" />
          </button>
        )}
      </S.Wrapper>
      {errors[fieldName] && (
        <S.InvalidFeedback>{errors[fieldName].message}</S.InvalidFeedback>
      )}
    </>
  );
}

const InputFile = forwardRef(Input);
export default InputFile;
