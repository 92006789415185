import { TextField } from '@mui/material';
import styled from 'styled-components';

export const ToolbarButton = styled.button`
  cursor: pointer;
  background: rgb(196, 195, 195);
  color: #ddd;
  font-size: 18px;
  border: 0;
  padding-top: 5px;
  vertical-align: bottom;
  height: 34px;
  width: 36px;
  border-radius: 4px;

  &:hover {
    background: #444;
  }
`;

export const ToolbarContainer = styled.div`
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
  justify-content: flex-start;
  padding: 4px;
`;

export const Label = styled.label`
  display: inline-block;
  max-width: 100%;

  font-weight: 700;
  font-size: 14px;
  line-height: 1;
  margin: 0.5rem;
`;

export const Title = styled.label`
  display: inline-block;
  max-width: 100%;

  text-align: center;
  font-weight: 800;
  font-size: 18px;
  line-height: 1;
  margin-top: 0.5rem;
  color: #444;
`;

export const InputText = styled(TextField)`
  margin: 0.25rem;
  line-height: 1.42857143;
  color: #333;
  font-size: 5px;

  fieldset {
    height: 38px;
    border-color: #d2d6de;
    top: 7px;
  }
`;
