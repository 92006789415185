import moment from 'moment';
import React from 'react';
import { useCallback, useEffect, useState } from 'react';

import { contentHistory } from '../../models/content';
import { getContentsEditHistory } from '../../services/contents';
import * as S from './styles';
interface LastEditedByProps {
  content_id: string;
}
export default function LastEditedBy({ content_id }: LastEditedByProps) {
  const [content, setContent] = useState<contentHistory>();
  const parseContentId = content_id.split('=').pop()
  const data: string = moment(content?.last_change_at).format(
    'DD-MM-YYYY - HH:mm:ss',
  );
  const getLastEditedBy = useCallback(async () => {
    if (parseContentId) {
      const contentData = await getContentsEditHistory(parseContentId);
      if (contentData) {
        setContent(contentData);
      }
    }
  }, [parseContentId]);

  useEffect(() => {
    getLastEditedBy();
  }, [parseContentId]);

  return (
    <>
      {content ? (
        <S.Title aria-label="title">
          {`Atualizado em ${data}`}
          <br /> {`por ${content?.user_name}`}
        </S.Title>
      ) : (<S.Title aria-label="notFound">Conteúdo não foi editado</S.Title>)}
      
    </>
  );
}
