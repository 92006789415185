import React from 'react';
import { useFormContext } from 'react-hook-form';

import { FlexContainer } from '../../components/FlexContainer';
import SambaMainFile from '../../components/SambaMainFile';
import BasicContentTemplate, {
  BasicContentTemplateType,
} from '../BasicContentTemplate';

type VideoTemplateType = BasicContentTemplateType;

export default function VideoTemplate({
  title,
  view,
  content,
  isLoading,
  submit,
}: VideoTemplateType) {
  const { setValue, getValues, control } = useFormContext();

  return (
    <BasicContentTemplate
      title={title}
      view={view}
      submit={submit}
      content={content}
      isLoading={isLoading}
    >
      <FlexContainer gap="0.875rem" flexWrap="wrap">
        <SambaMainFile
          setValue={setValue}
          control={control}
          content={content}
          view={view}
          type={'video'}
          getValues={getValues}
        />
      </FlexContainer>
    </BasicContentTemplate>
  );
}
