import { Close } from '@mui/icons-material';
import React, { useRef, useState } from 'react';

import { userFilterOptions } from '../../constants/optionsToSelect';
import { formatCnpj, formatCpf } from '../../utils';
import Button from '../Button';
import CustomSelect from '../CustomSelect';
import { FlexContainer } from '../FlexContainer';
import * as S from './styles';

type SearchComponentProps = {
  handleSearch: (filters) => Promise<void> | void;
  customPlaceholder?: string;
  options?: { label: string; value: string }[];
};

export default function SearchComponents({
  handleSearch,
  customPlaceholder,
  options,
}: SearchComponentProps) {
  const [selectedFilter, setSelectedFilter] = useState<{
    label: string;
    value: string;
  }>(userFilterOptions[0]);
  const [clear, setClear] = useState(false);

  const inputRef = useRef<HTMLInputElement>(null);

  const handleMaskInput = () => {
    if (!inputRef.current) return;
    if (inputRef.current.value.length === 0 && clear) setClear(false);

    const inputValue = inputRef.current.value;
    inputRef.current.value = applyMask(inputValue);
  };

  const submitSearch = () => {
    handleSearch({
      [selectedFilter.value]: inputRef.current?.value,
    });
  };

  const handleKeyDown = event => {
    if (event.key == 'Enter') {
      submitSearch();
    }

    if (inputRef.current?.value.length) {
      setClear(true);
    } else {
      setClear(false);
    }
  };

  const handleClear = () => {
    if (!inputRef.current?.value.length) return;

    inputRef.current.value = '';
    handleSearch({});
    setClear(false);
  };

  const applyMask = value => {
    if (selectedFilter.value === 'cpf') {
      return formatCpf(value);
    } else if (selectedFilter.value === 'cnpj') {
      return formatCnpj(value);
    } else {
      return value;
    }
  };

  return (
    <FlexContainer
      gap="1rem"
      alignItems="center"
      alignSelf="flex-end"
      justifyContent="flex-end"
      flexWrap="wrap"
      width="100%"
    >
      {options && (
        <S.Container>
          <CustomSelect
            id="filter"
            isRtl={false}
            isSearchable
            options={options}
            onChange={value => {
              if (inputRef.current) {
                setClear(false);
                inputRef.current.value = '';
              }
              if (value) setSelectedFilter(value);
            }}
            value={selectedFilter}
            thereIsError={false}
          />
        </S.Container>
      )}
      <S.Wrapper>
        <S.Input
          ref={inputRef}
          placeholder={
            options
              ? `Buscar pelo ${selectedFilter.label.toLocaleLowerCase()}`
              : customPlaceholder
          }
          onChange={handleMaskInput}
          onKeyDown={handleKeyDown}
        />
        {clear && (
          <button onClick={handleClear}>
            <Close fontSize="small" />
          </button>
        )}
      </S.Wrapper>
      <Button variant="contained" onClick={() => submitSearch()}>
        Buscar
      </Button>
    </FlexContainer>
  );
}
