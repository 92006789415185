import Swal from 'sweetalert2';

import { IMetaDataProps } from '../common/interfaces/metaData';
import { IParams } from '../common/interfaces/params';
import httpClient from '../http-client';
import KnowlefgesFromResponse from '../models/from-api-response/knowledges';
import { IKnowledge, IKnowledgeRequest } from '../models/knowledge';

const getknowledges = async (
  knowledgesId: string,
): Promise<KnowlefgesFromResponse> => {
  const knowledges = (await httpClient.get(`knowledges/${knowledgesId}`)).data;

  return knowledges;
};

const getAllknowledges = async (
  params?: IParams,
): Promise<{
  data: IKnowledge[];
  metaData: IMetaDataProps;
}> => {
  const knowledges = (await httpClient.get(`/knowledges`, { params })).data;

  return { data: knowledges.knowledges, metaData: knowledges.metaData };
};

const createknowledges = async (
  newTknowledges: IKnowledgeRequest,
): Promise<IKnowledge | undefined> => {
  try {
    const knowledges = (await httpClient.post(`/knowledges`, newTknowledges))
      .data;

    return knowledges;
  } catch (error: any) {
    if (error.response) {
      throw new Error(error.response.data.message);
    }

    Swal.fire({
      icon: 'error',
      title: 'Erro ao criar um conhecimento',
      text: `${error.response.data.message}`,
    });
    throw new Error(error.message);
  }
};

const updateknowledges = async (knowledgesId: string, newContentTab: any) => {
  await httpClient.put(`knowledges/${knowledgesId}`, newContentTab);
};

const deleteknowledges = async (knowledgesId: string) => {
  await httpClient.delete(`knowledges/${knowledgesId}`);
};

const verifyknowledges = async (knowledgesId: any, verify: boolean) => {
  await httpClient.put(`knowledges/${knowledgesId}/verified`);
};

export {
  createknowledges,
  deleteknowledges,
  getAllknowledges,
  getknowledges,
  updateknowledges,
  verifyknowledges,
};
