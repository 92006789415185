import React from 'react';
import { useFormContext } from 'react-hook-form';

import { IAuthor } from '../../../models/author';
import Button from '../../Button';
import { FlexContainer } from '../../FlexContainer';
import AuthorModal from '../../SimpleModal/AuthorModal';
import TextField from '../TextField';

type CompanyProps = {
  view: boolean;
};

export default function Company({ view }: CompanyProps) {
  const [filterModalOpen, setFilterModalOpen] = React.useState<boolean>(false);

  const { setValue, watch } = useFormContext();

  const handleSelected = (author: IAuthor) => {
    setValue('company_id', author.author_id);
    setValue('company', author);
  };

  return (
    <FlexContainer flexDirection="column" width="100%" gap="0.875rem">
      <TextField label="Empresa" name="company.name" disabled={true} />

      <FlexContainer gap="0.875rem">
        <Button
          type="button"
          variant="contained"
          onClick={() => setFilterModalOpen(true)}
          disabled={view}
        >
          Listar
        </Button>
        <Button
          type="button"
          onClick={() => {
            setValue('authors', []);
            setValue('company_id', null);
            setValue('company', { name: null });
          }}
          disabled={view}
        >
          Limpar
        </Button>
      </FlexContainer>

      {filterModalOpen && (
        <AuthorModal
          key={'author-modal'}
          isOpen={filterModalOpen}
          onCloseChange={() => setFilterModalOpen(false)}
          handleSelected={handleSelected}
          selectedAuthors={watch('company') ? [watch('company')] : []}
          companyOnly
        />
      )}
    </FlexContainer>
  );
}
