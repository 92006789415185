import styled, { css, DefaultTheme } from 'styled-components';

type ContainerProps = {
  isSelected: boolean;
};

const containerModifiers = {
  open: (theme: DefaultTheme) => css`
    color: ${theme.colors.teal_400};
  `,
  close: (theme: DefaultTheme) => css`
    color: ${theme.colors.neutral_800};
  `,
};

export const Container = styled.div<ContainerProps>`
  ${({ theme, isSelected }) => css`
    align-items: center;
    display: flex;
    font-size: ${theme.font.sizes.xsmall};
    gap: 1rem;
    padding: 1rem 1.5rem;

    svg {
      font-size: ${theme.font.sizes.xsmall};
    }

    ${isSelected && containerModifiers.open(theme)};
    ${!isSelected && containerModifiers.close(theme)};
  `}
`;
