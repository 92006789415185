export const visibilities = [
  {
    label: 'Publico',
    value: 'public',
  },
  {
    label: 'Privado',
    value: 'restricted',
  },
];
