import httpClient from '../http-client';

interface IResearchesResponseDto {
  data: any[];
  mataData: any;
}

export const getResearches = async () => {
  const researches = (
    await httpClient.get<IResearchesResponseDto>(
      `/content/admin/list?types[]=research`,
    )
  ).data;
  return researches.data;
};

export const getResearche = async (contentId: string | null) => {
  const content = await httpClient.get(`content/${contentId}`);
  return content.data;
};
