import styled, { css, DefaultTheme } from 'styled-components';

import { InputProps } from '.';

const inputModifiers = {
  standard: (theme: DefaultTheme, thereIsError: boolean) => css`
    border: 1px solid;
    border-color: ${thereIsError ? theme.colors.red_400 : 'hsl(0, 0%, 80%)'};
    border-radius: 0.25rem;
    box-shadow: 0 3px 2px rgba(233, 236, 239, 0.05);
    padding: 0.625rem 0.75rem;
    transition: all 0.15s ease-in-out;

    :focus {
      border-color: ${thereIsError
        ? theme.colors.red_400
        : theme.colors.default};
      box-shadow: 0 0 0 1px
          ${thereIsError ? theme.colors.red_400 : theme.colors.default},
        3px 4px 8px rgba(94, 114, 228, 0.1);
      outline: 0;
    }

    :hover {
      border-color: hsl(0, 0%, 70%);
    }

    :disabled {
      background-color: ${theme.colors.gray_200};
    }
  `,
  withIcon: () => css`
    border: none;
    outline: 0;
    padding: 0.625rem 0.75rem 0.625rem 0;
  `,
};

export const Input = styled.input<InputProps>`
  ${({ theme, thereIsError, withIcon, standard }) => css`
    background-color: ${theme.colors.white};
    color: ${theme.colors.neutral_600};
    font-size: ${theme.font.sizes.xsmall};
    position: relative;
    line-height: 1.5;
    width: 100%;

    &::placeholder {
      color: ${theme.colors.gray_600};
      font-size: ${theme.font.sizes.xsmall};
      font-weight: ${theme.font.light};
      font-style: italic;
      line-height: 1.5;
    }

    ${!!standard && inputModifiers.standard(theme, thereIsError!)};
    ${!!withIcon && inputModifiers.withIcon()};
  `}
`;
