import styled, { css, DefaultTheme } from 'styled-components';

export type WrapperProps = {
  color: 'neutral' | 'blue' | 'green' | 'red' | 'yellow';
};

const wrapperModifiers = {
  neutral: (theme: DefaultTheme) => css`
    background-color: #edeff5;
    color: ${theme.colors.neutral_800};
  `,
  blue: (theme: DefaultTheme) => css`
    background-color: ${theme.colors.blue_200};
    color: ${theme.colors.blue_500};
  `,
  green: (theme: DefaultTheme) => css`
    background-color: ${theme.colors.green_200};
    color: ${theme.colors.green_600};
  `,
  red: (theme: DefaultTheme) => css`
    background-color: ${theme.colors.red_100};
    color: ${theme.colors.red_600};
  `,
  yellow: (theme: DefaultTheme) => css`
    background-color: ${theme.colors.yellow_100};
    color: ${theme.colors.yellow_600};
  `,
};

export const Wrapper = styled.div<WrapperProps>`
  ${({ theme, color }) => css`
    align-items: center;
    border-radius: 32px;
    display: flex;
    justify-content: center;
    padding: 6px;
    text-align: center;

    ${!!color && wrapperModifiers[color](theme)}
  `}
`;

export const Label = styled.span`
  ${({ theme }) => css`
    font-size: ${theme.font.sizes.xsmall};
    font-weight: ${theme.font.semiBold};
    line-height: 1rem;
    white-space: normal;
  `}
`;
