import { z } from 'zod';

import { categorySchema } from './categoryValidation';
import { imageSchema } from './imageValidation';
import { optionSelectSchema } from './optionSelectValidation';
import { TagSchema } from './tagValidation';
import { TopiSchema } from './topicValidation';

export const basicContentSchema = z
  .object({
    antecipation: z.boolean().optional().default(false),
    antecipation_days: z.string().nullable().optional(),
    antecipation_groups: z.array(optionSelectSchema).nullable().optional(),
    audio_file: z.any().nullable().optional(),
    audio_file_id: z.string().nullable().optional(),
    authors: z.string().array().optional(),
    categories: z
      .array(z.union([z.string(), categorySchema]), {
        required_error: 'Este campo é obrigatório',
      })
      .refine(val => {
        const isArrayOfStrings = val.every(item => typeof item === 'string');
        const isArrayOfObjects = val.every(
          item => categorySchema.safeParse(item).success,
        );

        return isArrayOfStrings || isArrayOfObjects;
      }),
    company_sizes: z.array(optionSelectSchema, {
      required_error: 'Este campo é obrigatório',
    }),
    company_units: z
      .array(optionSelectSchema, {
        required_error: 'Este campo é obrigatório',
      })
      .superRefine((val, ctx) => {
        if (!val.length) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: 'Este campo é obrigatório',
          });
        }
      }),
    complement_files: z.string().array().optional(),
    description: z.string().nonempty('Este campo é obrigatório'),
    featured_start_at: z.date().nullable().optional(),
    featured_end_at: z.date().nullable().optional(),
    groups: z.array(optionSelectSchema).nullable().optional(),
    highlight: z.boolean().optional().default(false),
    image_file: imageSchema.nullable().optional(),
    image_file_id: z.string().uuid().nullable().default(null).optional(),
    image_name: z.string().nullable().default(null).optional(),
    image_toltip: z.string().nullable().default(null).optional(),
    metatags: z.any(),
    page_title: z
      .string()
      .nonempty('Este campo é obrigatório')
      .max(55, 'O título da página não poder conter mais que 55 caracteres'),
    published_start_at: z.date().nullable().optional(),
    published_end_at: z.date().nullable().optional(),
    requester_id: z.string().uuid().nonempty('Este campo é obrigatório'),
    requester: z.object(
      {
        label: z.string(),
        value: z.string(),
      },
      { required_error: 'Este campo é obrigatório' },
    ),
    resume: z
      .string()
      .nonempty('Este campo é obrigatório')
      .max(160, 'O resumo não poder conter mais que 160 caracteres'),
    review_in: z.date({ required_error: 'Este campo é obrigatório' }),
    slug: z
      .string()
      .nonempty('Este campo é obrigatório')
      .max(70, 'O slug da página não poder conter mais que 70 caracteres'),
    subtitle: z.string().nonempty('Este campo é obrigatório'),
    tags: z.array(TagSchema, { required_error: 'Este campo é obrigatório' }),
    title: z
      .string()
      .nonempty('Este campo é obrigatório')
      .max(70, 'O título não poder conter mais que 70 caracteres'),
    topics: z.array(TopiSchema).nullable().optional(),
    visibility: z.string({ required_error: 'Este campo é obrigatório' }),
  })
  .superRefine((val, ctx) => {
    if (val.visibility === 'restricted' && !val.groups?.length) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'Este campo é obrigatório',
        path: ['groups'],
      });
    }

    if (val.antecipation && !val.antecipation_days) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'Este campo é obrigatório',
        path: ['antecipation_days'],
      });
    }

    if (val.antecipation && Number(val.antecipation_days) < 1) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'Dias de antecipação não pode ser menor que 1',
        path: ['antecipation_days'],
      });
    }

    if (val.antecipation && !val.antecipation_groups?.length) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'Este campo é obrigatório',
        path: ['antecipation_groups'],
      });
    }

    if (val.highlight && !val.featured_start_at) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'Este campo é obrigatório',
        path: ['featured_start_at'],
      });
    }

    if (val.highlight && !val.featured_end_at) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'Este campo é obrigatório',
        path: ['featured_end_at'],
      });
    }
  });
