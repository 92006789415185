import { DatePicker } from '@mui/lab';
import * as M from '@mui/material';
import React from 'react';
import { useFormContext } from 'react-hook-form';

import Button from '../../../components/Button';
import Card from '../../../components/FormComponent/Card';
import Form from '../../../components/FormComponent/Form';
import TextField from '../../../components/FormComponent/TextField';
import { formatCnpj } from '../../../utils';
import { FlexContainer } from '../../AuthorTemplate/styles';

type CourseConsumptionEditTemplateProps = {
  submit: (data) => Promise<void>;
  cancel: () => void;
};

export default function CourseConsumptionEditTemplate({
  cancel,
  submit,
}: CourseConsumptionEditTemplateProps) {
  const { watch, handleSubmit, setValue } = useFormContext();

  const cnpjInputMask = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.target.value = formatCnpj(e.target.value) || '';
  };

  return (
    <Card>
      <Form onSubmit={handleSubmit(submit)}>
        <FlexContainer gap="1rem">
          <TextField
            label="Código de Integração"
            name="code_integration"
            flex="1 0 15rem"
            required
          />
          <TextField
            label="CNPJ da Empresa"
            name="company"
            flex="1 0 15rem"
            onChange={cnpjInputMask}
            maxLength={14}
          />
          <TextField
            label="Credencial"
            name="credential"
            flex="1 0 15rem"
            required
          />
          <TextField
            label="Data Inicial"
            name="date_hour_start"
            flex="1 0 10rem"
            required
          >
            <DatePicker
              value={watch('date_hour_start') || null}
              onChange={date => setValue('date_hour_start', date)}
              renderInput={params => <M.TextField {...params} />}
            />
          </TextField>
          <TextField
            label="Nome Realização"
            name="nome_realizacao"
            flex="1 0 15rem"
            required
          />
          <TextField
            label="Id Origem"
            name="course_origin_id"
            flex="1 0 15rem"
            type="number"
            required
          />
          <TextField
            label="Id Momento"
            name="momento_id"
            flex="1 0 15rem"
            type="number"
            required
          />
          <TextField
            label="Id Portifolio Disponibilização"
            name="portifolio_disponibilizacao_id"
            type="number"
            flex="1 0 15rem"
            required
          />
          <TextField
            label="Id do Tema"
            name="theme_id"
            type="number"
            flex="1 0 15rem"
            required
          />
          <TextField
            label="Tipo Realização"
            name="tipo_realizacao"
            flex="1 0 15rem"
            required
          />
          <TextField label="Titulo" name="title" flex="1 0 15rem" required />
          <TextField label="Tipo" name="type" flex="1 0 15rem" required />
        </FlexContainer>

        <FlexContainer gap="1rem" justifyContent="flex-end">
          <Button
            type="button"
            onClick={cancel}
            variant="contained"
            color="secondary"
          >
            Cancelar
          </Button>
          <Button type="submit" variant="contained" color="blue">
            Enviar
          </Button>
        </FlexContainer>
      </Form>
    </Card>
  );
}
