import styled, { css } from 'styled-components';

export const Card = styled.div`
  ${({ theme }) => css`
    border: 1px solid ${theme.colors.neutral_400};
    border-radius: ${theme.border.radius};
    display: flex;
    flex: 1 0;
    flex-basis: 0;
    flex-direction: column;
    width: 100%;
  `}
`;

export const CardHeader = styled.div`
  ${({ theme }) => css`
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);

    padding: 1.25rem 1.5rem;
  `}
`;

export const CardBody = styled.div`
  ${({ theme }) => css`
    flex: 1 0 auto;
    gap: 1rem;
    min-height: 1px;
    padding: 1.5rem;
  `}
`;
