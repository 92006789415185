import styled, { css } from 'styled-components';

import { CustomInputProps } from '.';

type InputProps = {
  variant?: 'default' | 'keyword';
};

type WrapperProps = Pick<CustomInputProps, 'type'>;

const wrapperModifiers = {
  text: () => css`
    align-items: stretch;
    flex-direction: column;
    gap: 10px;
  `,
  checkbox: () => css`
    align-items: center;
    flex-direction: row;
    gap: 10px;
    min-height: 67px;

    input {
      height: 16px;
      width: fit-content;
    }
  `,
};

export const Wrapper = styled.div<WrapperProps>`
  ${({ theme, type }) => css`
    display: flex;
    width: 100%;

    ${type ? wrapperModifiers[type] : wrapperModifiers.text}
  `}
`;

export const Label = styled.label`
  ${({ theme }) => css`
    color: ${theme.colors.gray_700};
    font-size: 14px;
  `}
`;

export const Input = styled.input<InputProps>`
  ${({ theme, variant }) => css`
    border: 0;
    background-color: transparent;
    box-shadow: 0 3px 2px rgb(233 236 239 / 5%);
    border: 1px solid ${theme.colors.gray_300};
    border-radius: 4px;
    color: ${theme.colors.gray_600};
    font-size: 14px;
    line-height: 1.5;
    padding: 10px 12px;
    transition: all 0.15s ease-in-out;
    width: 100%;

    :hover {
      border-color: ${theme.colors.gray};
    }

    :focus {
      outline-color: ${theme.colors.primary};
    }
  `}
`;
