import { removeTraitPoints } from '../utils';

type FiltersProps = {
  search?: string;
  cpf?: string;
  search_company?: string;
  email?: string;
  cnpj?: string;
};

export default function useSearchContents() {
  const handleParams = (params, filters: FiltersProps) => {
    const { search_company, cpf, search, cnpj, email } = filters;

    if (search?.length) params['search'] = search;
    if (email?.length) params['email'] = email;
    if (search_company?.length) params['search_company'] = search_company;
    if (cpf?.length) params['cpf'] = removeTraitPoints(cpf);
    if (cnpj?.length) params['cnpj'] = removeTraitPoints(cnpj);

    return params;
  };

  return {
    handleParams,
  };
}
