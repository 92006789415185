import React from 'react';

import * as S from './styles';

export type CharacterTotalProps = {
  characterCount: number;
  limit: {
    sucess: number;
    warning: number;
    error: number;
  };
};

export default function CharacterTotal({
  characterCount,
  limit,
}: CharacterTotalProps) {
  const text = characterCount >= 10 ? 'Caracteres' : 'Caractere';

  return (
    <S.Wrapper characterCount={characterCount} limit={limit}>
      {`${characterCount} ${text}`}
    </S.Wrapper>
  );
}
