import { InputHTMLAttributes } from 'react';
import React from 'react';
import { useFormContext } from 'react-hook-form';

import * as S from './styles';

export type CustomInputProps = {
  label: string;
  name: string;
  placeholder?: string;
} & InputHTMLAttributes<HTMLInputElement>;

export default function CustomInput({
  label,
  name,
  placeholder,
  ...props
}: CustomInputProps) {
  const { register } = useFormContext();

  return (
    <S.Wrapper type={props.type}>
      <S.Label htmlFor={name}>{label}</S.Label>
      <S.Input
        id={name}
        placeholder={placeholder}
        {...props}
        {...register(name)}
      />
    </S.Wrapper>
  );
}
