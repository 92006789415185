import axios from 'axios';
import { toast } from 'react-toastify';

export default function useDownloadFile() {
  const downloadFile = (reference: string, name: string) => {
    axios
      .get(`${reference}`, {
        responseType: 'blob',
        headers: {
          'Cache-Control': 'no-store, no-cache, must-revalidate',
          Pragma: 'no-cache',
          Expires: '0',
        },
      })
      .then(response => {
        if (response.status !== 200) {
          throw new Error('Erro ao fazer o download');
        }

        const blobUrl = URL.createObjectURL(response.data);
        const link = document.createElement('a');

        link.setAttribute('download', name);
        link.setAttribute('href', blobUrl);
        link.setAttribute('type', 'application/octet-stream');
        link.click();

        URL.revokeObjectURL(blobUrl);
      })
      .catch(error => {
        console.error(error);
        toast({
          message: error.message,
          type: 'error',
        });
      });
  };

  return { downloadFile };
}
