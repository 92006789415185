import React, { useCallback, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';

import Loading from '../../../components/Loading';
import { getUserById } from '../../../services/users';
import CreateUserTemplate from '../../../templates/CreateUserTemplate';
import { formatCep, formatCpf } from '../../../utils';
import { CreateUserFormValues } from '../CreateUser';

export default function ViewUser() {
  const [loading, setLoading] = useState(false);

  const methods = useForm<CreateUserFormValues>();
  const navigate = useNavigate();
  const location = useLocation();
  const state = location.state as { user_id: string };

  const getUser = useCallback(async (userId: string) => {
    try {
      const { setValue } = methods;
      const user = await getUserById(userId);

      setValue('name', user.name);
      setValue('email', user.email);
      setValue('cpf', formatCpf(user.cpf)!);

      user.zipcode && setValue('zipcode', formatCep(user.zipcode));
      user.address && setValue('address', user.address);
      user.complement && setValue('complement', user.complement);
      user.number && setValue('number', user.number);
      user.city && setValue('city', user.city);
      user.district && setValue('district', user.district);
      user.uf && setValue('uf', user.uf);
      user.district && setValue('district', user.district);
      user.birth_date && setValue('birth_date', new Date(user.birth_date));
      user.gender && setValue('gender', user.gender);
      user.phone_number && setValue('phone_number', user.phone_number);
    } catch (error: any) {
      Swal.fire({
        icon: 'error',
        iconColor: '#f5365c',
        title: 'Erro ao buscar o usuário!',
        text: error.message || error,
        buttonsStyling: false,
        customClass: {
          confirmButton: 'btn-ok',
        },
      });
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    getUser(state.user_id);
  }, []);

  if (loading) return <Loading />;

  return (
    <FormProvider {...methods}>
      <CreateUserTemplate isView={true} cancel={() => navigate(-1)} />
    </FormProvider>
  );
}
