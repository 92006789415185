import styled, { css } from 'styled-components';

export const CustomBreadcrumbs = styled.ol`
  ${() => css`
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    margin: 0;
    list-style: none;
    padding: 0;
  `}
`;

type ListItemProps = {
  separator?: string;
};

export const ListItem = styled.li<ListItemProps>`
  ${({ theme, separator = '/' }) => css`
    color: ${theme.colors.neutral_700};
    display: flex;

    a {
      color: inherit;
      text-decoration: none;
    }

    &:last-child {
      color: ${theme.colors.neutral_800};
    }

    &:not(:last-child):after {
      content: '${separator}';
      margin-left: 0.5rem;
      margin-right: 0.5rem;
    }
  `}
`;
