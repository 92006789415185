import { useMemo } from 'react';

import { KeyIds } from '../common/enum/keyIds';
import Button from '../components/Button/index';

export default function useListColumns(
  keyId: KeyIds,
  selectedItens: any[],
  handleSelected: (item) => void,
) {
  const columnsWithSlug = useMemo(
    () => [
      {
        headerName: 'Nome',
        field: 'name',
        flex: 2,
        renderCell: (cellValues: any) => <p>{cellValues?.value}</p>,
      },
      {
        headerName: 'Slug',
        field: 'slug',
        flex: 2,
        renderCell: (cellValues: any) => <p>{cellValues?.value}</p>,
      },
      {
        headerName: 'Selecionar',
        field: '',
        flex: 1,
        renderCell: (cellValues: any) => {
          return (
            <Button
              variant="contained"
              style={{ margin: '0 auto' }}
              disabled={
                !!selectedItens.find(
                  item => item[keyId] === cellValues.row[keyId],
                )
              }
              onClick={() => {
                handleSelected(cellValues.row);
              }}
            >
              Selecionar
            </Button>
          );
        },
      },
    ],
    [selectedItens],
  );

  const columnsWithoutSlug = useMemo(
    () => [
      {
        headerName: 'Nome',
        field: 'name',
        flex: 2,
        renderCell: (cellValues: any) => <p>{cellValues?.value}</p>,
      },
      {
        headerName: 'Selecionar',
        field: '',
        flex: 1,
        renderCell: (cellValues: any) => {
          return (
            <Button
              variant="contained"
              style={{ margin: '0 auto' }}
              disabled={
                !!selectedItens.find(
                  item => item[keyId] === cellValues.row[keyId],
                )
              }
              onClick={() => {
                handleSelected(cellValues.row);
              }}
            >
              Selecionar
            </Button>
          );
        },
      },
    ],
    [selectedItens],
  );

  return {
    columns: {
      knowledge_id: columnsWithSlug,
      topic_id: columnsWithoutSlug,
      tag_id: columnsWithSlug,
    },
  };
}
