import React, { InputHTMLAttributes } from 'react';
import { useFormContext } from 'react-hook-form';

import * as S from './styles';

export type TextAreaProps = {
  rows: number;
  maxLength: number;
  name: string;
  label: string;
} & InputHTMLAttributes<HTMLTextAreaElement>;

export default function TextArea({
  maxLength,
  rows,
  name,
  label,
  required,
  ...props
}: TextAreaProps) {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  const isTextAreaError = Object.keys(errors).includes(name);

  return (
    <S.Wrapper>
      <S.Label htmlFor={name} required={required}>
        {label}
      </S.Label>
      <S.TextArea
        id={name}
        rows={rows}
        maxLength={maxLength}
        thereIsError={isTextAreaError}
        {...register(name)}
        {...props}
      />
      {errors[name] && (
        <S.InvalidFeedback>{errors[name].message}</S.InvalidFeedback>
      )}
    </S.Wrapper>
  );
}
