/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';

export const TableBlock = props => {
  const [table, setTable] = React.useState({});

  useEffect(() => {
    //bigger than rowNumber
    if (props?.blockProps?.resizeData?.tableValues) {
      const notRows = Object.keys(
        props?.blockProps?.resizeData?.tableValues,
      ).filter(row => {
        return (
          parseInt(row) >
          parseInt(props?.blockProps?.resizeData?.rowsNumber) - 1
        );
      });

      const table = props?.blockProps?.resizeData?.tableValues;
      notRows.forEach(notRow => {
        delete table[notRow];
      });

      setTable(table);
    }
  }, [props?.blockProps?.tableValues]);

  return props?.blockProps?.resizeData?.colsNumber !== undefined ? (
    <table>
      <thead></thead>
      <tbody>
        {Object.keys(table).map(key => {
          const row = table[key];
          return (
            <tr key={key}>
              {Object.keys(row).map(rowKey => {
                if (
                  parseInt(rowKey) <=
                  parseInt(props.blockProps.resizeData.colsNumber)
                ) {
                  return <td key={rowKey}>{row[rowKey]}</td>;
                } else return <></>;
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  ) : (
    <></>
  );
};
