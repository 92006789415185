import {
  Box,
  Breadcrumbs,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
} from '@mui/material';
import { Link as MaterialLink, Typography } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import React, { useEffect, useState } from 'react';

import { MetaDataProps } from '../../../models/from-api-response/report';
import { getReportsList } from '../../../services/reports';
import * as styles from './styles';

export default function CoursesAndSeries() {
  const [orderBy, setOrderBy] = useState<string>('TITLE');
  const [orderBySort, setOrderBySort] = useState<string>('ASC');
  const [course, setCourse] = useState<boolean>(true);
  const [serie, setSerie] = useState<boolean>(true);
  const [search, setSearch] = useState<string>('');
  const [pageState, setPageState] = useState<any>({
    isLoading: false,
    data: [],
    total: 0,
    page: 1,
    pageSize: 10,
  });

  const getReport = async () => {
    setPageState(old => ({ ...old, isLoading: true }));
    const types: string[] = [];
    if (course) types.push('course');
    if (serie) types.push('series');
    const params: MetaDataProps = {
      offset: pageState.page,
      limit: pageState.pageSize,
      order_by: orderBy,
      order_by_sort: orderBySort,
      types,
    };
    if (search !== '') {
      params.search = search;
    }
    const localContents = await getReportsList(params);
    setPageState(old => ({
      ...old,
      isLoading: false,
      total: Number(localContents.metaData.total),
      data: localContents.data,
    }));
  };

  useEffect(() => {
    getReport();
  }, [pageState.page, pageState.pageSize]);

  const columns: GridColDef[] = [
    {
      field: 'title',
      headerName: 'Título',
      width: 400,
      sortable: false,
    },
    {
      field: 'type',
      headerName: 'Tipo',
      width: 200,
      sortable: false,
      renderCell: (cellValues: any) => (
        <span>{cellValues.value === 'course' ? 'Curso' : 'Série'}</span>
      ),
    },
    {
      field: 'subscribed',
      headerName: 'Inscritos',
      type: 'number',
      width: 200,
      sortable: false,
    },
    {
      field: 'in_progress',
      headerName: 'Em progresso',
      type: 'number',
      width: 200,
      sortable: false,
    },
    {
      field: 'finished',
      headerName: 'Finalizados',
      type: 'number',
      width: 200,
      sortable: false,
    },
  ];

  return (
    <styles.Container>
      <Breadcrumbs style={{ margin: '2rem 0' }}>
        <MaterialLink underline="hover" color="inherit" href="/">
          Home
        </MaterialLink>
        <MaterialLink underline="hover" color="inherit" href="/profile">
          Relatórios
        </MaterialLink>
        <MaterialLink
          underline="hover"
          color="text.primary"
          href="/reportList"
          aria-current="page"
        >
          Cursos e Séries
        </MaterialLink>
      </Breadcrumbs>

      <Typography
        variant="h5"
        gutterBottom
        component="div"
        color="primary"
        style={{ margin: '1.2rem 0' }}
      >
        Relatório de Cursos e Séries
      </Typography>

      <styles.Content>
        <Box sx={{ height: 400, width: '100%' }}>
          <Box marginBottom={3}>
            <FormControl sx={{ mr: 1, minWidth: 80, mb: 2 }}>
              <TextField
                id="outlined-basic"
                label="Pesquisa"
                variant="outlined"
                value={search}
                onChange={e => setSearch(e.target.value)}
                onKeyDown={e => {
                  if (e.key === 'Enter') {
                    e.preventDefault();
                    e.stopPropagation();
                    getReport();
                  }
                }}
              />
            </FormControl>
            <FormControl sx={{ minWidth: 80, mb: 2 }}>
              <InputLabel id="order-by">Ordenar por</InputLabel>
              <Select
                labelId="order-by"
                id="demo-simple-select"
                value={orderBy}
                label="Ordenar por"
                autoWidth
                onChange={e => setOrderBy(e.target.value as string)}
              >
                <MenuItem value={'TITLE'}>Título</MenuItem>
                <MenuItem value={'SUBSCRIBED'}>Inscritos</MenuItem>
                <MenuItem value={'IN_PROGRESS'}>Em Progresso</MenuItem>
                <MenuItem value={'FINISHED'}>Finalizados</MenuItem>
              </Select>
            </FormControl>
            <FormControl sx={{ minWidth: 80 }}>
              <InputLabel id="order-by-sort">Ordem</InputLabel>
              <Select
                labelId="order-by-sort"
                id="demo-simple-select"
                value={orderBySort}
                label="Ordem"
                autoWidth
                onChange={e => setOrderBySort(e.target.value as string)}
              >
                <MenuItem value={'ASC'}>Crescente</MenuItem>
                <MenuItem value={'DESC'}>Decrescente</MenuItem>
              </Select>
            </FormControl>
            <FormControl sx={{ mx: 1, verticalAlign: 'middle' }}>
              <Box
                sx={{ display: 'flex', flexDirection: 'row', minHeight: 50 }}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={course}
                      onChange={e => setCourse(e.target.checked)}
                    />
                  }
                  label="Cursos"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={serie}
                      onChange={e => setSerie(e.target.checked)}
                    />
                  }
                  label="Séries"
                />
              </Box>
            </FormControl>
            <Button variant="contained" onClick={getReport}>
              Buscar
            </Button>
          </Box>
          {pageState.data.length > 0 ? (
            <DataGrid
              getRowId={row => row['content_id']}
              autoHeight
              rows={pageState.data}
              rowCount={pageState.total}
              loading={pageState.isLoading}
              rowsPerPageOptions={[10, 30, 50, 70, 100]}
              pagination
              page={pageState.page - 1}
              pageSize={pageState.pageSize}
              paginationMode="server"
              disableColumnMenu
              disableColumnFilter
              disableColumnSelector
              onPageChange={newPage => {
                setPageState(old => ({ ...old, page: newPage + 1 }));
              }}
              onPageSizeChange={newPageSize =>
                setPageState(old => ({ ...old, pageSize: newPageSize }))
              }
              columns={columns}
            />
          ) : (
            <Stack height="100%" alignItems="center" justifyContent="center">
              Não encontrado
            </Stack>
          )}
        </Box>
      </styles.Content>
    </styles.Container>
  );
}
