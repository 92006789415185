import { TextField } from '@mui/material';
import Card from '@mui/material/Card';
import styled from 'styled-components';

export const CreateAndEditContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
  max-width: 1280px;
  height: min-content;
  span {
    margin: 10px;
  }
`;

export const CardInput = styled(Card)`
  margin: 0.8rem;
  width: '100%';
  max-width: '100%';
`;

export const Label = styled.label`
  display: inline-block;
  max-width: 100%;
  margin-bottom: 16px;

  font-weight: 700;
  font-size: 14px;
  line-height: 1;
`;

export const InputText = styled(TextField)`
  line-height: 1.42857143;
  color: #333;
  font-size: 5px;

  .MuiInputBase-input {
    padding: 16px 14px !important;
  }

  fieldset {
    height: 38px;
    border-color: #d2d6de;
    top: 7px;
  }
`;

export const InputTextArea = styled(TextField)`
  line-height: 1.42857143;
  color: #333;
  font-size: 5px;

  fieldset {
    border-color: #d2d6de;
    top: 7px;
    left: 5px;
  }
`;

export const ContentSelector = styled.div`
  padding: 0px 22px;
  margin-bottom: 22px;
`;
