import httpClient from '../http-client';

export interface ICompanySizeDto {
  companies_size: ICompanySize[] | [];
  total: number;
}

export interface ICompanySize {
  company_size_id: string;
  name: string;
  published_at: string;
  created_at: string;
  status: string;
  updated_at: string;
}

export interface ICompanyUnitDto {
  companies_unit: ICompanyUnit[];
  total: number;
}

export interface ICompanyUnit {
  company_unit_id: string;
  name: string;
  published_at: string;
  created_at: string;
  status: string;
  updated_at: string;
}

export const getAllCompanySize = async () => {
  const companiesSize: ICompanySizeDto = (
    await httpClient.get<ICompanySizeDto>(`/company_size`)
  ).data;
  return companiesSize;
};

export const getAllCompanyUnit = async () => {
  const companyUnit: ICompanyUnitDto = (
    await httpClient.get<ICompanyUnitDto>(`/company_unit`)
  ).data;
  return companyUnit;
};

export const createCompanyUnit = async (data): Promise<ICompanyUnit> => {
  try {
    const companyUnit = (await httpClient.post(`/company_unit`, data)).data;
    return companyUnit;
  } catch (error: any) {
    if (error.response) {
      throw new Error(error.response.data.message);
    }
    throw new Error(error.message);
  }
};
