import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 100%;

    .age-chart,
    .gender-chart,
    .progress-status-chart,
    .progress-average-chart {
      position: relative;
      height: 500px;
    }

    .progress-average-chart {
      margin-bottom: 1rem;
    }

    .Mui-disabled {
      color: ${theme.colors.neutral_400};
    }

    .MuiButtonBase-root {
      color: ${theme.colors.neutral_700};
    }

    .Mui-selected {
      background-color: ${theme.colors.blue_100};
      color: ${theme.colors.default};
    }
  `}
`;

export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  width: 100%;
`;

export const ChartWrapper = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.colors.white};
    border: 1px solid ${theme.colors.neutral_300};
    border-top: 0;
    border-radius: 0.5rem;
    border-top-right-radius: 0;
    border-top-left-radius: 0;
    box-shadow: ${theme.shadow.card};
    padding: 2rem 1rem;
    width: 100%;
  `}
`;
