import httpClient from '../http-client';
import { IModule, IModulesParams } from '../models/module';

interface SeasonResponse {
  data: IModule[];
  metaData: {
    total: number;
    limit?: number;
    offset?: number;
    search?: string;
  };
}

export const getAllSeason = async (params?: IModulesParams) => {
  const seasons = (
    await httpClient.get<SeasonResponse>('/module?type=series', { params })
  ).data;
  return seasons;
};

export const getSeason = async (moduleId: string): Promise<IModule> => {
  const season = (await httpClient.get<IModule>(`/module/${moduleId}`)).data;

  return season;
};

export const editSeason = async (id: string | null, data: any) => {
  await httpClient.put(`/module/${id}`, data);
};

export const createSeason = async (body: any): Promise<IModule> => {
  const season = (await httpClient.post<IModule>('/module', body)).data;

  return season;
};

export const deleteSeason = async (moduleId: string) => {
  await httpClient.delete(`/module/${moduleId}`);
};
