import { SearchOutlined } from '@mui/icons-material';
import { GridInitialStateCommunity } from '@mui/x-data-grid/models/gridStateCommunity';
import React, { useEffect, useMemo, useRef, useState } from 'react';

import { useGetContent, useTablePagination } from '../../../hooks';
import { IAuthor } from '../../../models/author';
import { listAuthors } from '../../../services/authors';
import { handleSort } from '../../../utils/handleSort';
import Button from '../../Button/index';
import CustomSelect from '../../CustomSelect';
import { FlexContainer } from '../../FlexContainer';
import Table, { TableProps } from '../../Table';
import SimpleModal from '..';
import * as S from './styles';

type AuthorModalProps = {
  isOpen: boolean;
  companyOnly?: boolean;
  onCloseChange: () => void;
  handleSelected: (author: IAuthor) => void;
  selectedAuthors: IAuthor[] | [];
};

const optionsToSelect = [
  { label: 'Empresa', value: 'company' },
  { label: 'Pessoa', value: 'person' },
];

export default function AuthorModal({
  isOpen,
  onCloseChange,
  handleSelected,
  selectedAuthors,
  companyOnly,
}: AuthorModalProps): React.ReactElement {
  const [rows, setRows] = useState<any[]>([]);
  const [selectedType, setSelectedType] = useState<{
    label: string;
    value: string;
  } | null>(optionsToSelect[0]);
  const [orderBySort, setOrderBySort] = useState('DESC');
  const [orderBy, setOrderBy] = useState('created_at');

  const inputRef = useRef<HTMLInputElement>(null);

  const { loading, getVariedContents } = useGetContent(listAuthors);
  const { paginationModel, rowCount, setRowCount, changePage, changePageSize } =
    useTablePagination();

  const initialStateTable: GridInitialStateCommunity = {
    sorting: {
      sortModel: [{ field: 'created_at', sort: 'desc' }],
    },
  };

  const columns = useMemo(
    () => [
      {
        headerName: 'Nome',
        field: 'name',
        minWidth: 220,
        flex: 2,
        renderCell: (cellValues: any) => (
          <p style={{ whiteSpace: 'normal' }}>{cellValues?.value}</p>
        ),
      },
      {
        headerName: 'Criado em',
        field: 'created_at',
        minWidth: 180,
        flex: 1,
        renderCell: (cellValues: any) => {
          return (
            cellValues.value.substring(8, 10) +
            '/' +
            cellValues.value.substring(5, 7) +
            '/' +
            cellValues.value.substring(0, 4)
          );
        },
      },
      {
        headerName: 'Atualizado em',
        field: 'updated_at',
        minWidth: 180,
        flex: 1,
        renderCell: (cellValues: any) => {
          return (
            cellValues.value.substring(8, 10) +
            '/' +
            cellValues.value.substring(5, 7) +
            '/' +
            cellValues.value.substring(0, 4)
          );
        },
      },
      {
        headerName: 'Tipo',
        field: 'type',
        minWidth: 180,
        flex: 1,
        renderCell: (cellValues: any) => {
          return cellValues.value === 'company' ? 'Empresa' : 'Pessoa';
        },
      },
      {
        headerName: 'Selecionar',
        field: '',
        minWidth: 160,
        flex: 1,
        renderCell: (cellValues: any) => {
          return (
            <div>
              <Button
                variant="contained"
                disabled={
                  !!selectedAuthors.find(
                    author => author.author_id === cellValues.row.author_id,
                  )
                }
                onClick={() => {
                  handleSelected(cellValues.row);
                  onCloseChange();
                }}
              >
                Selecionar
              </Button>
            </div>
          );
        },
      },
    ],
    [],
  );

  const params = {
    limit: paginationModel.pageSize,
    offset: paginationModel.page,
    type: selectedType?.value,
    order_by_sort: orderBySort,
    order_by: orderBy,
  };

  useEffect(() => {
    getVariedContents(setRows, setRowCount, params);
  }, [
    paginationModel.pageSize,
    paginationModel.page,
    selectedType,
    orderBy,
    orderBySort,
  ]);

  const tableProps: TableProps = {
    columns,
    rows,
    keyId: 'author_id',
    initialState: initialStateTable,
    onPageChange: page => changePage(page),
    onPageSizeChange: pageSize => changePageSize(pageSize),
    page: paginationModel.page - 1,
    rowsPerPageOptions: [10, 25, 50, 75, 100],
    pageSize: paginationModel.pageSize,
    rowCount,
    loading,
    onSortModelChange: sortModel =>
      handleSort({ changePage, setOrderBy, setOrderBySort, sortModel }),
  };

  return (
    <SimpleModal
      isOpen={isOpen}
      onCloseChange={onCloseChange}
      title="Lista de Autores"
    >
      <S.Wrapper>
        <FlexContainer gap="0.875rem">
          {!companyOnly && (
            <S.Label htmlFor="type">
              Tipo do Autor
              <CustomSelect
                id="type"
                isRtl={false}
                isSearchable
                options={optionsToSelect}
                onChange={value => setSelectedType(value)}
                value={selectedType}
                thereIsError={false}
                marginTop="0.875rem"
              />
            </S.Label>
          )}

          <S.Label htmlFor="search">
            Busca por Nome
            <FlexContainer gap="0.875rem" alignItems="flex-end">
              <S.Input id="search" ref={inputRef} />
              <S.SearchButton
                type="button"
                aria-label="Efetuar pesquisa"
                onClick={() => {
                  const searchParams = {
                    order_by_sort: orderBySort,
                    order_by: orderBy,
                  };
                  const search = inputRef.current?.value;
                  Object.assign(searchParams, params);

                  if (search !== undefined && search.length > 0)
                    searchParams['search'] = search;

                  getVariedContents(setRows, setRowCount, searchParams);
                }}
              >
                <SearchOutlined fontSize="inherit" />
              </S.SearchButton>
            </FlexContainer>
          </S.Label>
        </FlexContainer>

        <Table {...tableProps} width="90vw" height="100%" disableColumnMenu />
      </S.Wrapper>
    </SimpleModal>
  );
}
