import httpClient from '../http-client';
import { IModuleSync, IModuleSyncParams } from './../models/moduleSync';

interface ModuleResponse {
  data: IModuleSync[];
  metaData: {
    total: number;
    limit?: number;
    offset?: number;
    search?: string;
  };
}

export const getAllModules = async (params?: IModuleSyncParams) => {
  const modules = (
    await httpClient.get<ModuleResponse>('/module-sync', { params })
  ).data;
  return modules;
};

export const getModule = async (moduleId: string): Promise<IModuleSync> => {
  const module = (await httpClient.get<IModuleSync>(`/module-sync/${moduleId}`))
    .data;

  return module;
};

export const editModule = async (id: string | null, data: any) => {
  await httpClient.put(`/module-sync/${id}`, data);
};

export const createModule = async (body: any): Promise<IModuleSync> => {
  const module = (await httpClient.post<IModuleSync>('/module-sync', body))
    .data;

  return module;
};

export const deleteModule = async (moduleId: string) => {
  await httpClient.delete(`/module-sync/${moduleId}`);
};
