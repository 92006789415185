import { IMetaDataProps } from '../common/interfaces/metaData';
import httpClient from '../http-client';
import { ICollection, ICollectionParams } from '../models/collection';

interface IGetResponse {
  data: ICollection[];
  metaData: IMetaDataProps;
}

export const getCollection = async (collectionId: string | null) => {
  const collection = (
    await httpClient.get<ICollection>(`collections/${collectionId}`)
  ).data;
  return collection;
};

export const getAllCollections = async (params?: ICollectionParams) => {
  const collections = (
    await httpClient.get<IGetResponse>('collections', { params })
  ).data;
  return collections;
};

export const createCollection = async (newCollection: any) => {
  const createCollectionResponse = (
    await httpClient.post<any>(`collections`, newCollection)
  ).data;
  return createCollectionResponse;
};

export const updateCollection = async (colelctionId: string, item: any) => {
  await httpClient.put(`collections/${colelctionId}`, item);
};

export const deleteCollection = async (collectionId: string) => {
  await httpClient.delete(`collections/${collectionId}`);
};
