import styled, { css, DefaultTheme } from 'styled-components';

import { ButtonType, IconType } from '.';

type ButtonRootType = Pick<ButtonType, 'variant' | 'color' | 'fullWidth'>;

type ButtonIconRootType = Pick<IconType, 'variant' | 'color'>;

const buttonModifiers = {
  contained: (theme: DefaultTheme, color) => css`
    background-color: ${theme.colors[`${color}_400`]};
    color: ${theme.colors.white};
    max-width: max-content;

    &:hover {
      background-color: ${theme.colors[`${color}_500`]};
    }

    &:active {
      background-color: ${theme.colors[`${color}_600`]};
    }

    &:focus {
      background-color: ${theme.colors[`${color}_500`]};
      outline: 2px solid;
      outline-color: ${theme.colors[`${color}_200`]};
    }

    &:disabled {
      background-color: ${theme.colors.blue_200};
    }
  `,
  outlined: (theme: DefaultTheme, color) => css`
    background-color: transparent;
    border: 1px solid;
    border-color: ${theme.colors[`${color}_400`]};
    color: ${theme.colors[`${color}_400`]};
    max-width: max-content;

    &:hover {
      border-color: ${theme.colors[`${color}_500`]};
      color: ${theme.colors[`${color}_500`]};
    }

    &:active {
      background-color: ${theme.colors[`${color}_100`]};
      border-color: ${theme.colors[`${color}_400`]};
      color: ${theme.colors[`${color}_400`]};
    }

    &:focus {
      outline: 2px solid;
      outline-color: ${theme.colors[`${color}0`]};
    }

    &:disabled {
      background-color: ${theme.colors[`${color}_300`]};
      color: ${theme.colors[`${color}_500`]};
    }
  `,
  fullWidth: () => css`
    max-width: none;
    width: 100%;
  `,
  default: (theme: DefaultTheme) => css`
    background-color: ${theme.colors.default};
    max-width: max-content;

    &:disabled {
      opacity: 0.6;
    }
  `,
  secondary: (theme: DefaultTheme) => css`
    background-color: transparent;
    border: 1px solid;
    border-color: ${theme.colors.neutral_400};
    color: ${theme.colors.neutral_700};
    max-width: max-content;

    &:hover {
      border-color: ${theme.colors.neutral_600};
      color: ${theme.colors.neutral_800};
    }

    &:active {
      background-color: ${theme.colors.neutral_100};
      border-color: ${theme.colors.neutral_500};
      color: ${theme.colors.neutral_800};
    }

    &:focus {
      outline: 2px solid;
      outline-color: ${theme.colors.blue_200};
    }

    &:disabled {
      background-color: ${theme.colors.neutral_300};
      color: ${theme.colors.neutral_500};
    }
  `,
  info: (theme: DefaultTheme) => css`
    background-color: ${theme.colors.info_400};
    max-width: max-content;

    &:hover {
      background-color: ${theme.colors.info_500};
    }

    &:active {
      background-color: ${theme.colors.info_600};
    }

    &:focus {
      background-color: ${theme.colors.info_500};
      outline: 2px solid;
      outline-color: ${theme.colors.info_200};
    }

    &:disabled {
      background-color: ${theme.colors.info_200};
    }
  `,
};

export const ButtonRoot = styled.button<ButtonRootType>`
  ${({ theme, variant, color, fullWidth }) => css`
    align-items: center;
    border-radius: 0.25rem;
    border: 0;
    cursor: pointer;
    display: flex;
    font-size: ${theme.font.sizes.xsmall};
    font-weight: ${theme.font.semiBold};
    flex-grow: 1;
    flex-shrink: 1;
    gap: 0.5rem;
    justify-content: center;
    padding: 0.625rem 0.75rem;
    line-height: 1.5;
    min-width: 4rem;
    transition: width 0.5s ease-in;

    & > svg {
      height: 1rem;
      width: 1rem;
    }

    ${!!variant && color !== 'secondary'
      ? buttonModifiers[variant](theme, color)
      : buttonModifiers.secondary(theme)}

    ${!!fullWidth && buttonModifiers.fullWidth()}
  `}
`;

export const ButtonIconRoot = styled.button<ButtonIconRootType>`
  ${({ theme, color, variant }) => css`
    align-items: center;
    border-radius: 0.25rem;
    cursor: pointer;
    display: flex;
    flex-shrink: 0;
    justify-content: center;
    padding: 0.5rem;

    ${!!variant && color !== 'secondary'
      ? buttonModifiers[variant](theme, color)
      : buttonModifiers.secondary(theme)}
  `}
`;
