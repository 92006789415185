/* eslint-disable @typescript-eslint/ban-ts-comment */
import '@draft-js-plugins/alignment/lib/plugin.css';
import 'draft-js/dist/Draft.css';

import createAlignmentPlugin from '@draft-js-plugins/alignment';
import createLinkPlugin from '@draft-js-plugins/anchor';
import {
  BlockquoteButton,
  BoldButton,
  HeadlineThreeButton,
  HeadlineTwoButton,
  ItalicButton,
  OrderedListButton,
  UnderlineButton,
  UnorderedListButton,
} from '@draft-js-plugins/buttons';
import createBlockDndPlugin from '@draft-js-plugins/drag-n-drop';
import createDragNDropUploadPlugin from '@draft-js-plugins/drag-n-drop-upload';
import Editor, { composeDecorators } from '@draft-js-plugins/editor';
import createFocusPlugin from '@draft-js-plugins/focus';
import createImagePlugin from '@draft-js-plugins/image';
import createResizeablePlugin from '@draft-js-plugins/resizeable';
import createToolbarPlugin from '@draft-js-plugins/static-toolbar';
import createVideoPlugin from '@draft-js-plugins/video';
import AddCircle from '@mui/icons-material/AddCircle';
import CollectionsIcon from '@mui/icons-material/Collections';
import GridOnIcon from '@mui/icons-material/GridOn';
import ImageIcon from '@mui/icons-material/Image';
import ImportExportIcon from '@mui/icons-material/ImportExport';
import LinkIcon from '@mui/icons-material/Link';
import RedoIcon from '@mui/icons-material/Redo';
import UndoIcon from '@mui/icons-material/Undo';
import VideoLibraryIcon from '@mui/icons-material/VideoLibrary';
import { Box, Button, Modal } from '@mui/material';
import { CardActionArea } from '@mui/material';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import {
  AtomicBlockUtils,
  ContentState,
  convertFromRaw,
  EditorState,
  Modifier,
} from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import htmlToDraft from 'html-to-draftjs';
import React, { useEffect, useRef } from 'react';

import * as appStyles from '../../AppStyle';
// Helpers
import toast from '../../helpers/toast';
import { getImages, uploadFile } from '../../services/files';
// import { Button } from '../Button';
import buttonStyles from './buttonStyles.module.css';
import { customUpload } from './customUpload';
import editorStyles from './editorStyles.module.css';
import * as styles from './styles';
import { TableBlock } from './TableBlock';
import toolbarStyles from './toolbarStyles.module.css';

const videoDecorator = composeDecorators();

const focusPlugin = createFocusPlugin();
const resizeablePlugin = createResizeablePlugin();
const blockDndPlugin = createBlockDndPlugin();
const alignmentPlugin = createAlignmentPlugin();
const linkPlugin = createLinkPlugin({ linkTarget: '_blank' });
const decorator = composeDecorators(
  resizeablePlugin.decorator,
  focusPlugin.decorator,
  blockDndPlugin.decorator,
);
const imagePlugin = createImagePlugin({ decorator });
const videoPlugin = createVideoPlugin({ decorator: videoDecorator });
const dragNDropFileUploadPlugin = createDragNDropUploadPlugin({
  //@ts-ignore
  handleUpload: customUpload,
  //@ts-ignore
  addImage: imagePlugin.addImage,
});

const { AlignmentTool } = alignmentPlugin;

const initialState = {
  entityMap: {},
  blocks: [],
};

interface DraftEditorProps {
  setValue: any;
  name: string;
  value: string;
  isEditing?: any;
  validationName?: string;
  cleanEditor?: boolean;
  handleCleanEditor?: () => void;
}

const DraftEditor = (props: DraftEditorProps) => {
  const [editorState, setEditorState] = React.useState(() =>
    EditorState.createWithContent(convertFromRaw(initialState)),
  );
  const [urlValue, setUrlValue] = React.useState('');
  const [urlImageValue, setUrlImageValue] = React.useState('');
  const [showVideoField, setShowVideoField] = React.useState(false);
  const [showImageField, setShowImageField] = React.useState(false);
  const [showimageOptions, setShowImageOptions] = React.useState(false);
  const [collectionModalOpen, setCollectionModalOpen] = React.useState(false);
  const [tableModalOpen, setTableModalOpen] = React.useState(false);
  const [rowsNumber, setRowsNumber] = React.useState(0);
  const [colsNumber, setColsNumber] = React.useState(0);
  const [imagePage, setImagePage] = React.useState(0);
  const [images, setImages] = React.useState<any[]>([]);
  const [filter, setFilter] = React.useState<string>('');

  const [tableValues, setTableValues] = React.useState<any>({
    0: { 0: '', 1: '', 2: '', 3: '' },
    1: { 0: '', 1: '', 2: '', 3: '' },
    2: { 0: '', 1: '', 2: '', 3: '' },
    3: { 0: '', 1: '', 2: '', 3: '' },
    4: { 0: '', 1: '', 2: '', 3: '' },
    5: { 0: '', 1: '', 2: '', 3: '' },
    6: { 0: '', 1: '', 2: '', 3: '' },
    7: { 0: '', 1: '', 2: '', 3: '' },
  });
  const [loading, setLoading] = React.useState(false);

  const editorRef = useRef<any>(null);

  const ref = useRef<any>(null);

  const [hasFilled, setHasFilled] = React.useState(false);

  const {
    setValue,
    name,
    value,
    isEditing,
    validationName,
    cleanEditor,
    handleCleanEditor,
  } = props;

  const [{ plugins, Toolbar }] = React.useState(() => {
    const toolbarPlugin = createToolbarPlugin({
      theme: { buttonStyles, toolbarStyles },
    });
    const { Toolbar } = toolbarPlugin;
    const plugins = [
      alignmentPlugin,
      blockDndPlugin,
      dragNDropFileUploadPlugin,
      focusPlugin,
      imagePlugin,
      linkPlugin,
      resizeablePlugin,
      toolbarPlugin,
      videoPlugin,
    ];
    return {
      plugins,
      Toolbar,
    };
  });

  const blockRenderer = React.useCallback(block => {
    if (block.getType() === 'atomic') {
      return {
        component: TableBlock,
        editable: false,
      };
    }
    return null;
  }, []);

  const insertTableBlock = editorState => {
    const contentState = editorState.getCurrentContent();
    const contentStateWithEntity = contentState.createEntity(
      'TABLE',
      'IMMUTABLE',
      {
        tableValues,
        colsNumber,
        rowsNumber, // data
      },
    );
    const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
    const newEditorState = EditorState.set(editorState, {
      currentContent: contentStateWithEntity,
    });
    return AtomicBlockUtils.insertAtomicBlock(newEditorState, entityKey, ' ');
  };

  const insertTable = _event => {
    setEditorState(insertTableBlock(editorState));
    setColsNumber(0);
    setRowsNumber(0);
    setTableValues({
      0: { 0: '', 1: '', 2: '', 3: '' },
      1: { 0: '', 1: '', 2: '', 3: '' },
      2: { 0: '', 1: '', 2: '', 3: '' },
      3: { 0: '', 1: '', 2: '', 3: '' },
      4: { 0: '', 1: '', 2: '', 3: '' },
      5: { 0: '', 1: '', 2: '', 3: '' },
      6: { 0: '', 1: '', 2: '', 3: '' },
      7: { 0: '', 1: '', 2: '', 3: '' },
    });
    setTableModalOpen(false);
  };

  const handleCollectionsClick = () => {
    setCollectionModalOpen(true);
    setLoading(true);
    getImages(imagePage).then((images: any) => {
      setImages(images?.data);
      setLoading(false);
    });
  };

  const MAX_LENGTH = 160;
  const handleBeforeInput = () => {
    const currentContent = editorState.getCurrentContent();
    const currentContentLength = currentContent?.getPlainText('')?.length;

    if (currentContentLength > MAX_LENGTH - 1) {
      alert('Você pode digitar no máximo 160 caracteres');
      return 'handled';
    }
  };

  const getLengthOfSelectedText = (editorState: EditorState) => {
    const currentContent = editorState.getCurrentContent();

    const currentContentLength = currentContent?.getPlainText('')?.length;

    const length = currentContentLength;

    return length === 0 ? 0 : length - 2;
  };

  useEffect(() => {
    if (
      props.name === 'resume' &&
      getLengthOfSelectedText(editorState) >= 160
    ) {
      alert('Você pode digitar no máximo 160 caracteres');
    }
    if (
      props.validationName === 'descriptionAuthorEmp' &&
      getLengthOfSelectedText(editorState) >= 601
    ) {
      alert('Você pode digitar no máximo 600 caracteres.');
    }
    if (
      props.validationName === 'descriptionAuthorPerson' &&
      getLengthOfSelectedText(editorState) >= 601
    ) {
      alert('Você pode digitar no máximo 600 caracteres.');
    }
  }, [getLengthOfSelectedText(editorState)]);

  const blockStyleFn = block => {
    let alignment = 'left';

    block.findStyleRanges(e => {
      if (e.hasStyle('center')) {
        alignment = 'center';
      }
      if (e.hasStyle('right')) {
        alignment = 'right';
      }
    });
    block.style = { alignment: alignment };
    return `editor-alignment-${alignment}`;
  };

  const alignmentStyles = ['left', 'right', 'center'];

  const applyAlignment = (event, newStyle) => {
    event.stopPropagation();
    const styleForRemove = alignmentStyles.filter(style => style !== newStyle);
    const currentContent = editorState.getCurrentContent();
    const selection = editorState.getSelection();
    const focusBlock = currentContent.getBlockForKey(selection.getFocusKey());
    const anchorBlock = currentContent.getBlockForKey(selection.getAnchorKey());
    const isBackward = selection.getIsBackward();

    const selectionMerge = {
      anchorOffset: 0,
      focusOffset: focusBlock.getLength(),
    };

    if (isBackward) {
      selectionMerge.anchorOffset = anchorBlock.getLength();
    }
    const finalSelection = selection.merge(selectionMerge);
    const finalContent = styleForRemove.reduce(
      (content, style) =>
        Modifier.removeInlineStyle(content, finalSelection, style),
      currentContent,
    );
    const modifiedContent = Modifier.applyInlineStyle(
      finalContent,
      finalSelection,
      newStyle,
    );
    const nextEditorState = EditorState.push(
      editorState,
      modifiedContent,
      'change-inline-style',
    );
    setEditorState(nextEditorState);
  };

  const options: any = {
    entityStyleFn: entity => {
      const entityType = entity?.getType()?.toLowerCase();

      if (entityType === 'image') {
        const data = entity.getData();
        return {
          element: 'img',
          attributes: {
            src: data?.src,
            width: data.width ? data.width + 'px' : '',
          },
        };
      }

      if (entityType === 'draft-js-video-plugin-video') {
        const data = entity.getData();
        return {
          element: 'iframe',
          attributes: {
            src: data?.src.replace('youtu.be/', 'youtube.com/embed/'),
          },
        };
      }
      if (entityType === 'link') {
        const data = entity.getData();
        return {
          element: 'a',
          attributes: { href: data?.url, target: '_blank' },
        };
      }
    },
  };

  const editorStateFromHtml = (
    html: string,
    edDecorator = composeDecorators(),
  ) => {
    if (html === null) {
      return EditorState.createEmpty(edDecorator);
    }

    const blocksFromHtml = htmlToDraft(html, (nodeName: any, node: any) => {
      if (nodeName === 'img') {
        return {
          type: 'image',
          data: { src: node.src, type: 'image' },
        };
      }

      if (nodeName === 'figure') {
        if (!node.children.length) {
          return null;
        }

        let caption = '',
          src = '';
        const captionNode = node.children[0];
        if (captionNode !== undefined) {
          caption = captionNode.innerHTML;
        }
        const blockNode = node.children[0];
        if (blockNode !== undefined) {
          src = blockNode.src;
        }

        const type = node.children[0].tagName.toLowerCase();

        if (type === 'iframe') {
          return {
            type: 'draft-js-video-plugin-video',
            data: { src: src, type: 'iframe', caption: caption },
          };
        }
        if (type === 'img') {
          return {
            type: 'image',
            data: { src: src, type: 'image' },
          };
        }
      }
      if (nodeName === 'br') {
        return {
          type: '',
        };
      }
    });

    const { contentBlocks, entityMap } = blocksFromHtml;

    const contentState = ContentState.createFromBlockArray(
      contentBlocks,
      entityMap,
    );

    return EditorState.createWithContent(contentState);
  };

  useEffect(() => {
    const valueAux = value?.replace(/<(figure)[^>]*>&nbsp;<\/?figure>/g, '');
    if (value && cleanEditor) {
      setEditorState(editorStateFromHtml(valueAux));
      setHasFilled(true);
    }
    !!handleCleanEditor && handleCleanEditor();
  }, [cleanEditor]);

  React.useEffect(() => {
    const html = stateToHTML(editorState.getCurrentContent(), options);

    const valueAux = value?.replace(/<(figure)[^>]*>&nbsp;<\/?figure>/g, '');

    if (value && html.replace(/<[^>]+>/g, '').length === 0) {
      setEditorState(editorStateFromHtml(valueAux));
      setHasFilled(true);
    }
  }, [value]);

  return (
    <>
      <div className={editorStyles.editor}>
        <div style={{ padding: '16px' }}>
          <Editor
            readOnly={isEditing}
            blockRendererFn={blockRenderer}
            ref={(editor: any) => (editorRef.current = editor)}
            editorState={editorState}
            handleBeforeInput={props.name === 'resume' ? handleBeforeInput : {}}
            blockStyleFn={blockStyleFn}
            onChange={(newEditorState: EditorState) => {
              let html = stateToHTML(editorState.getCurrentContent(), options);

              if (!(value && !hasFilled)) {
                html = html.replaceAll('\n', '');
                setValue(name, html);
                setEditorState(newEditorState);
              }
            }}
            plugins={plugins}
          />
        </div>
        <AlignmentTool />
        <Toolbar>
          {externalProps => (
            <styles.ToolbarContainer>
              <BoldButton {...externalProps} />
              <ItalicButton {...externalProps} />
              <UnderlineButton {...externalProps} />
              <HeadlineTwoButton {...externalProps} />
              <HeadlineThreeButton {...externalProps} />
              <UnorderedListButton {...externalProps} />
              <OrderedListButton {...externalProps} />
              <BlockquoteButton {...externalProps} />
              <div
                style={{ display: 'inline-block' }}
                onClick={event => {
                  event?.stopPropagation();
                }}
              >
                <linkPlugin.LinkButton {...externalProps} />
              </div>

              <div style={{ display: 'inline-block' }}>
                <styles.ToolbarButton
                  type="button"
                  onClick={event => {
                    event.stopPropagation();
                    setEditorState(EditorState.undo(editorState));
                  }}
                >
                  <UndoIcon style={{ color: 'rgb(46, 46, 46)' }} />
                </styles.ToolbarButton>
              </div>
              <div style={{ display: 'inline-block' }}>
                <styles.ToolbarButton
                  type="button"
                  onClick={event => {
                    event.stopPropagation();
                    setEditorState(EditorState.redo(editorState));
                  }}
                >
                  <RedoIcon style={{ color: 'rgb(46, 46, 46)' }} />
                </styles.ToolbarButton>
              </div>
              <div style={{ display: 'inline-block' }}>
                {showVideoField ? (
                  <div>
                    <input
                      onClick={event => {
                        event.stopPropagation();
                      }}
                      onChange={event => setUrlValue(event?.target.value)}
                      value={urlValue}
                      placeholder="Insira o link do video"
                    />
                    <Button
                      onClick={event => {
                        event.stopPropagation();
                        setEditorState(
                          videoPlugin.addVideo(editorState, { src: urlValue }),
                        );
                      }}
                      type="button"
                    >
                      <AddCircle />
                    </Button>
                  </div>
                ) : (
                  <></>
                )}
                <styles.ToolbarButton
                  type="button"
                  onClick={() => setShowVideoField(!showVideoField)}
                >
                  <VideoLibraryIcon style={{ color: 'rgb(46, 46, 46)' }} />
                </styles.ToolbarButton>
              </div>
              <div style={{ display: 'inline-block' }}>
                {showImageField ? (
                  <div>
                    <input
                      onClick={event => {
                        event.stopPropagation();
                      }}
                      onChange={event => {
                        setUrlImageValue(event?.target.value);
                      }}
                      value={urlImageValue}
                      placeholder="Insira o link da imagem"
                    />
                    <Button
                      onClick={event => {
                        event.stopPropagation();
                        setEditorState(
                          imagePlugin.addImage(editorState, urlImageValue, {
                            src: urlImageValue,
                          }),
                        );
                      }}
                      type="button"
                    >
                      <AddCircle />
                    </Button>
                  </div>
                ) : (
                  <></>
                )}
                {showimageOptions ? (
                  <div style={{ background: '#848484' }}>
                    <Button onClick={handleCollectionsClick} type="button">
                      <CollectionsIcon style={{ color: 'black' }} />
                    </Button>
                    <Button
                      onClick={_event => setShowImageField(!showImageField)}
                      type="button"
                    >
                      <LinkIcon style={{ color: 'black' }} />
                    </Button>
                    <label htmlFor="contained-button-draft">
                      <input
                        accept="image/*"
                        id="contained-button-draft"
                        type="file"
                        style={{ display: 'none' }}
                        onChange={(event: any) => {
                          event.stopPropagation();

                          const formData = new FormData();

                          formData.append(
                            'file',
                            event.target.files !== undefined
                              ? event.target.files[0]
                              : '',
                          );

                          uploadFile(formData).then((resData: any) => {
                            setEditorState(
                              imagePlugin.addImage(
                                editorState,
                                resData.reference,
                                { src: resData.reference },
                              ),
                            );
                          });
                        }}
                      />
                      <Button component="span">
                        <ImportExportIcon style={{ color: 'black' }} />
                      </Button>
                    </label>
                  </div>
                ) : (
                  <></>
                )}
                <styles.ToolbarButton
                  type="button"
                  onClick={() => {
                    setShowImageOptions(!showimageOptions);
                    setShowImageField(false);
                  }}
                >
                  <ImageIcon style={{ color: 'rgb(46, 46, 46)' }} />
                </styles.ToolbarButton>
              </div>
              <div style={{ display: 'inline-block' }}>
                <styles.ToolbarButton
                  type="button"
                  onClick={_event => {
                    setTableModalOpen(true);
                  }}
                >
                  <GridOnIcon style={{ color: 'rgb(46, 46, 46)' }} />
                </styles.ToolbarButton>
              </div>
            </styles.ToolbarContainer>
          )}
        </Toolbar>
        <Modal
          open={collectionModalOpen}
          onClose={() => setCollectionModalOpen(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            style={{
              backgroundColor: 'white',
              position: 'absolute',
              top: 'calc(50% - 200px)',
              left: 'calc(50% - 300px)',
              width: '600px',
              height: '450px',
              display: 'flex',
              flexDirection: 'column',
              borderRadius: '5px',
            }}
          >
            <appStyles.column>
              <styles.Title>Imagens salvas</styles.Title>
              <styles.InputText
                placeholder="Filtrar por nome"
                onClick={event => {
                  event.stopPropagation();
                }}
                onChange={(event: any) => {
                  setLoading(true);
                  if (event.target.value !== '') {
                    setFilter(event.target.value);
                    getImages(imagePage, 6, event?.target?.value).then(
                      images => {
                        setImages(images?.data);
                      },
                    );
                    setImagePage(0);
                  } else {
                    getImages(imagePage).then(images => {
                      setImages(images?.data);
                    });
                    setFilter('');
                  }
                  setLoading(false);
                }}
              />
              {!loading ? (
                <>
                  <appStyles.row>
                    {images.slice(0, images.length / 2).map(image => {
                      return (
                        <Card
                          key={`card-first-images${image.reference}`}
                          sx={{
                            width: 150,
                            margin: 'auto',
                            marginTop: '0.5rem',
                          }}
                          onClick={(event: any) => {
                            event.stopPropagation();
                            setEditorState(
                              imagePlugin.addImage(
                                editorState,
                                image.reference,
                                { src: image.reference },
                              ),
                            );
                            setCollectionModalOpen(false);

                            event.currentTarget.value = null;
                            event.target.blur();
                            editorRef.current.editor.blur();
                          }}
                        >
                          <CardActionArea>
                            <CardMedia
                              component="img"
                              height="140"
                              image={image.reference}
                            />
                          </CardActionArea>
                        </Card>
                      );
                    })}
                  </appStyles.row>
                  <appStyles.row>
                    {images
                      .slice(images.length / 2, images.length)
                      .map(image => {
                        return (
                          <Card
                            key={`card-second-images${image.reference}`}
                            sx={{
                              width: 150,
                              margin: 'auto',
                              marginTop: '0.5rem',
                            }}
                            onClick={(event: any) => {
                              event.stopPropagation();
                              setEditorState(
                                imagePlugin.addImage(
                                  editorState,
                                  image.reference,
                                  { src: image.reference },
                                ),
                              );
                              setCollectionModalOpen(false);
                            }}
                          >
                            <CardActionArea>
                              <CardMedia
                                component="img"
                                height="140"
                                width="150"
                                image={image.reference}
                              />
                            </CardActionArea>
                          </Card>
                        );
                      })}
                  </appStyles.row>
                </>
              ) : (
                <>Carregando imagens ...</>
              )}
              <appStyles.row>
                <div style={{ margin: 'auto', marginTop: '0.5rem' }}>
                  <Button
                    variant="contained"
                    disabled={imagePage === 0}
                    onClick={event => {
                      event.stopPropagation();
                      if (imagePage > 0) {
                        setLoading(true);
                        if (filter !== '') {
                          getImages(imagePage - 1, 6, filter).then(images => {
                            setImages(images?.data);
                            setImagePage(imagePage - 1);
                          });
                        } else {
                          getImages(imagePage - 1).then(images => {
                            setImages(images?.data);
                            setImagePage(imagePage - 1);
                          });
                        }
                        setLoading(false);
                      }
                    }}
                  >
                    {'<'}
                  </Button>
                  <Button variant="contained">{imagePage}</Button>
                  <Button
                    variant="contained"
                    onClick={event => {
                      setLoading(true);
                      if (filter !== '') {
                        getImages(imagePage + 1, 6, filter)
                          .then(images => {
                            if (images?.data.length > 0) {
                              setImages(images?.data);
                              setImagePage(imagePage + 1);
                            } else {
                              toast({
                                message: 'Última página',
                                type: 'warning',
                              });
                            }
                          })
                          .catch(_error => {
                            toast({
                              message: 'Algo deu errado',
                              type: 'error',
                            });
                          });
                      } else {
                        getImages(imagePage + 1)
                          .then(images => {
                            if (images?.data.length > 0) {
                              setImages(images?.data);
                              setImagePage(imagePage + 1);
                            } else {
                              toast({
                                message: 'Última página',
                                type: 'warning',
                              });
                            }
                          })
                          .catch(_error => {
                            toast({
                              message: 'Algo deu errado',
                              type: 'warning',
                            });
                          });
                      }
                      setLoading(false);
                      event.stopPropagation();
                    }}
                  >
                    {'>'}
                  </Button>
                </div>
              </appStyles.row>
            </appStyles.column>
          </Box>
        </Modal>

        <Modal
          open={tableModalOpen}
          onClose={() => setTableModalOpen(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            style={{
              backgroundColor: 'white',
              position: 'absolute',
              top: 'calc(50% - 200px)',
              left: 'calc(50% - 300px)',
              width: '600px',
              height: '400px',
              display: 'flex',
              flexDirection: 'column',
              borderRadius: '5px',
            }}
          >
            <appStyles.column>
              <styles.Label>Quantidade de linhas</styles.Label>
              <appStyles.InputText
                value={rowsNumber}
                type="number"
                onClick={(event: any) => {
                  event.stopPropagation();
                }}
                onChange={(event: any) => {
                  if (event?.target.value <= 8) {
                    setRowsNumber(event?.target?.value);
                  }
                }}
              />
              <styles.Label>Quantidade de colunas</styles.Label>
              <appStyles.InputText
                value={colsNumber}
                type="number"
                onClick={(event: any) => {
                  event.stopPropagation();
                }}
                onChange={(event: any) => {
                  if (event?.target.value <= 4) {
                    setColsNumber(event?.target?.value);
                  }
                }}
              />
            </appStyles.column>
            {Array.from({ length: rowsNumber }, () => null).map(
              (_el, rowIndex) => {
                return (
                  // eslint-disable-next-line react/jsx-key
                  <appStyles.row>
                    {Array.from({ length: colsNumber }, () => null).map(
                      (_el, colIndex) => (
                        <div key={rowIndex.toString() + colIndex.toString()}>
                          <input
                            key={colIndex}
                            onClick={event => {
                              event.stopPropagation();
                            }}
                            onChange={event => {
                              const tableValuesCopy = tableValues;

                              tableValuesCopy[rowIndex][colIndex] =
                                event.target.value;
                              setTableValues(tableValuesCopy);
                            }}
                          />
                        </div>
                      ),
                    )}
                  </appStyles.row>
                );
              },
            )}
            <div style={{ margin: 'auto 0.5rem 0.5rem auto' }}>
              <Button
                variant="contained"
                // color="success"
                onClick={event => {
                  event.stopPropagation();
                  insertTable(event);
                }}
              >
                Criar
              </Button>
            </div>
          </Box>
        </Modal>
      </div>
      {props.validationName === 'descriptionAuthorEmp' &&
      getLengthOfSelectedText(editorState) > 600 ? (
        <div style={{ textAlign: 'start' }}>
          <span style={{ color: 'red' }}>
            Tamanho deve ser inferior a 600 caracteres.
          </span>
        </div>
      ) : (
        ''
      )}
      {props.validationName === 'descriptionAuthorPerson' &&
      getLengthOfSelectedText(editorState) > 600 ? (
        <div style={{ textAlign: 'start' }}>
          <span style={{ color: 'red' }}>
            Tamanho deve ser inferior a 600 caracteres.
          </span>
        </div>
      ) : (
        ''
      )}

      {props.name === 'resume' &&
      getLengthOfSelectedText(editorState) >= 160 ? (
        <div style={{ textAlign: 'start' }}>
          <span style={{ color: 'red' }}>
            Tamanho deve ser inferior a 160 caracteres
          </span>
        </div>
      ) : (
        ''
      )}
      <div style={{ textAlign: 'end', paddingTop: '16px' }}>
        <span style={{ color: '#777676' }}>
          {getLengthOfSelectedText(editorState)} caracteres
        </span>
      </div>
    </>
  );
};

export default DraftEditor;
