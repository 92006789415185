import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  max-height: 600px;
  overflow-y: scroll;
  overflow-x: hidden;
  position: relative;
  width: 100%;
`;

export const ImageContainer = styled.div`
  ${({ theme }) => css`
    align-items: center;
    background-color: rgb(234, 234, 239);
    border: 1px solid ${theme.colors.neutral_400};
    border-radius: 0.25rem;
    display: flex;
    flex-direction: column;
    height: 21.25rem;
    justify-content: space-between;
    width: 22.81rem;
    flex: 1 1 22.81rem;
  `}
`;

export const Button = styled.button`
  ${({ theme }) => css`
    background-color: ${theme.colors.white};
    border-radius: 0.25rem;
    border: 1px solid ${theme.colors.neutral_400};
    cursor: pointer;
    color: ${theme.colors.neutral_800};
    height: 2rem;
    padding: 8px;
    right: 0.25rem;
    top: 0.25rem;
    width: 2rem;

    svg {
      font-size: ${theme.font.sizes.xxsmall};
    }

    :hover {
      background-color: ${theme.colors.neutral_75};
    }
  `}
`;

export const InfoContainer = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.colors.neutral_75};
    border-radius: 0.25rem;
    display: flex;
    gap: 1.5rem;
    height: 100%;
    padding: 1rem 1rem;
    width: 100%;
  `}
`;

export const Container = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-end;
  gap: 0.25rem;
  height: 3.25rem;
  padding: 0 0.75rem;
  width: 100%;
`;

export const Preview = styled.img`
  height: 100%;
  width: 100%;
`;

export const Label = styled.label`
  ${({ theme }) => css`
    font-size: ${theme.font.sizes.xxsmall};
    font-weight: ${theme.font.semiBold};
    text-transform: uppercase;
  `}
`;

export const Span = styled.span`
  ${({ theme }) => css`
    font-size: ${theme.font.sizes.xxsmall};
  `}
`;

export const InvalidFeedback = styled.span`
  ${({ theme }) => css`
    color: ${theme.colors.red_400};
    font-size: 0.75rem;
    margin-top: -0.5rem;
    text-align: center;
  `}
`;

type SettingContainerProps = {
  isOpen: boolean;
};

const settingContainerModifiers = {
  open: () => css`
    opacity: 1;
    transform: translateX(0);
  `,
  close: () => css`
    opacity: 0;
    transform: translateX(-100%);
  `,
};

export const SettingContainer = styled.div<SettingContainerProps>`
  ${({ theme, isOpen }) => css`
    align-items: center;
    background-color: ${theme.colors.white};
    display: flex;
    justify-content: center;
    transition: transform 0.5s;
    z-index: 2;

    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;

    ${isOpen ? settingContainerModifiers.open : settingContainerModifiers.close}
  `}
`;

export const InputSize = styled.input`
  ${({ theme }) => css`
    border: 0;
    border-bottom: 1px solid hsl(0, 0%, 80%);
    color: ${theme.colors.neutral_600};
    font-size: ${theme.font.sizes.large};
    padding: 0.5rem 1rem;
    text-align: center;
    width: 125px;
  `}
`;
