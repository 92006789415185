import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Typography } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import React from 'react';

import * as styles from '../FormComponent/Content/styles';
import * as S from './styles';
interface CategoryProps {
  formProps?: FormProps;
  backCategories: any;
  setBackCategories: any;
  setChildrenCategories?: any;
  type?: 'skill' | 'moment' | 'sector';
}

interface FormProps {
  content?: any;
  setValue?: any;
  view?: boolean;
  setState?: any;
  category: any;
}

export default function CategoryDropDown(props: CategoryProps) {
  const { backCategories, setBackCategories, setChildrenCategories } = props;
  const [expandedCategories, setExpandedCategories] = React.useState<string[]>(
    [],
  );
  const [openGrandParent, setOpenGrandParent] = React.useState<boolean>(false);

  const mappedCategories = new Map();
  mappedCategories.set('skill', 'Desafio');
  mappedCategories.set('moment', 'Momento');
  mappedCategories.set('sector', 'Setor');

  React.useEffect(() => {
    if (props.formProps?.content?.categories?.length > 0) {
      const categoriesId: string[] = [];
      props.formProps?.content.categories.forEach((category: any) => {
        categoriesId.push(category?.category_id);
      });
      setBackCategories(categoriesId);
    }
  }, [props.formProps?.content]);

  React.useEffect(() => {
    if (!props.formProps?.setState) {
      props.formProps?.setValue('categories', backCategories);
    } else {
      let allCategory = [];
      props.formProps?.category?.categories?.forEach(element => {
        allCategory = allCategory.concat(element);
        element?.children?.forEach(child => {
          allCategory = allCategory.concat(child);
        });
      });

      allCategory = allCategory.filter((element: any) =>
        backCategories.includes(element?.category_id),
      );

      props.formProps?.setState(allCategory);
    }
  }, [backCategories]);

  if (props?.formProps?.category?.categories?.length > 0) {
    return (
      //parent 1
      <S.Wrapper
        key={props.formProps?.category?.category_id}
        isExpanded={openGrandParent}
      >
        <ChevronRightIcon
          className="icon"
          style={{
            cursor: 'pointer',
          }}
          onClick={() => {
            setOpenGrandParent(!openGrandParent);
          }}
        />
        <styles.ContentInput key={props.formProps?.category.metaData.type}>
          <Typography
            variant="subtitle1"
            sx={{ fontWeight: 'bold', cursor: 'pointer' }}
            onClick={() => {
              setOpenGrandParent(!openGrandParent);
            }}
          >
            {mappedCategories.get(props.formProps?.category.metaData.type)}
          </Typography>
          {openGrandParent &&
            props.formProps?.category?.categories.map((childCategory: any) => {
              if (childCategory?.children?.length > 0) {
                return (
                  //parent 2
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                    }}
                    key={childCategory.category_id}
                  >
                    <div
                      style={{
                        alignItems: 'center',
                        display: 'flex',
                        flexDirection: 'row',
                      }}
                    >
                      {expandedCategories.find(currentCategory => {
                        return currentCategory === childCategory.category_id;
                      }) ? (
                        <div>
                          <KeyboardArrowDownIcon
                            style={{
                              cursor: 'pointer',
                            }}
                            onClick={() => {
                              let currentExpandedCategories =
                                expandedCategories;
                              const isExpanded = expandedCategories.find(
                                currentCategory => {
                                  return (
                                    currentCategory ===
                                    childCategory.category_id
                                  );
                                },
                              );
                              if (isExpanded) {
                                currentExpandedCategories =
                                  currentExpandedCategories.filter(
                                    currentCategory => {
                                      return (
                                        currentCategory !==
                                        childCategory.category_id
                                      );
                                    },
                                  );
                              } else {
                                currentExpandedCategories =
                                  currentExpandedCategories.concat(
                                    childCategory.category_id,
                                  );
                              }
                              setExpandedCategories(currentExpandedCategories);
                            }}
                          />
                        </div>
                      ) : (
                        <div>
                          <ChevronRightIcon
                            style={{
                              cursor: 'pointer',
                            }}
                            onClick={() => {
                              let currentExpandedCategories =
                                expandedCategories;
                              const isExpanded = expandedCategories.find(
                                currentCategory => {
                                  return (
                                    currentCategory ===
                                    childCategory.category_id
                                  );
                                },
                              );
                              if (isExpanded) {
                                currentExpandedCategories =
                                  currentExpandedCategories.filter(
                                    currentCategory => {
                                      return (
                                        currentCategory !==
                                        childCategory.category_id
                                      );
                                    },
                                  );
                              } else {
                                currentExpandedCategories =
                                  currentExpandedCategories.concat(
                                    childCategory.category_id,
                                  );
                              }
                              setExpandedCategories(currentExpandedCategories);
                            }}
                          />
                        </div>
                      )}
                      <styles.ContentInput key={childCategory.name}>
                        <Checkbox
                          checked={
                            backCategories.find(
                              el => el === childCategory.category_id,
                            ) !== undefined
                          }
                          key={childCategory.name}
                          disabled={props.formProps?.view}
                          onChange={event => {
                            let backendCategories = backCategories;
                            if (event.target.checked === true) {
                              backendCategories = backendCategories.concat(
                                childCategory.category_id,
                              );
                              setBackCategories(backendCategories);
                            } else {
                              backendCategories = backendCategories?.filter(
                                filterCategory =>
                                  filterCategory !== childCategory.category_id,
                              );
                              const children = childCategory?.children;
                              if (children && children.length > 0) {
                                children.forEach(child => {
                                  backendCategories = backendCategories?.filter(
                                    filterCategory =>
                                      filterCategory !== child.category_id,
                                  );
                                });
                              }
                              setBackCategories(backendCategories);
                            }
                          }}
                        />
                        <styles.Label>{childCategory.name}</styles.Label>
                      </styles.ContentInput>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        padding: '0 44px',
                      }}
                    >
                      {expandedCategories.find(currentCategory => {
                        return currentCategory === childCategory.category_id;
                      }) &&
                        childCategory.children.map(grandchild => {
                          //parent 3
                          return (
                            <styles.ContentInput key={grandchild.name}>
                              <Checkbox
                                checked={
                                  backCategories.find(
                                    el => el === grandchild.category_id,
                                  ) !== undefined
                                }
                                key={grandchild.name}
                                onChange={event => {
                                  let backendCategories = backCategories;
                                  if (event.target.checked === true) {
                                    backendCategories =
                                      backendCategories.concat(
                                        grandchild.category_id,
                                      );
                                    if (
                                      !backendCategories.includes(
                                        childCategory.category_id,
                                      )
                                    ) {
                                      backendCategories =
                                        backendCategories.concat(
                                          childCategory.category_id,
                                        );
                                    }

                                    setChildrenCategories &&
                                      setChildrenCategories(prev => [
                                        ...prev,
                                        grandchild,
                                      ]);
                                    setBackCategories(backendCategories);
                                  } else {
                                    backendCategories =
                                      backendCategories?.filter(
                                        filterCategory =>
                                          filterCategory !==
                                          grandchild.category_id,
                                      );

                                    setBackCategories(backendCategories);
                                    setChildrenCategories &&
                                      setChildrenCategories(prev =>
                                        prev.filter(
                                          category =>
                                            category.category_id !==
                                            grandchild.category_id,
                                        ),
                                      );
                                  }
                                }}
                              />
                              <styles.Label>{grandchild.name}</styles.Label>
                            </styles.ContentInput>
                          );
                        })}
                    </div>
                  </div>
                );
              } else {
                return (
                  <styles.ContentInput key={childCategory.name}>
                    <Checkbox
                      checked={
                        backCategories.find(
                          el => el === childCategory.category_id,
                        ) !== undefined
                      }
                      key={childCategory.name}
                      disabled={props.formProps?.view}
                      onChange={event => {
                        let backendCategories = backCategories;
                        if (event.target.checked === true) {
                          backendCategories = backendCategories.concat(
                            childCategory.category_id,
                          );
                          setBackCategories(backendCategories);
                        } else {
                          backendCategories = backendCategories?.filter(
                            filterCategory =>
                              filterCategory !== childCategory.category_id,
                          );
                          setBackCategories(backendCategories);
                        }
                      }}
                    />
                    <styles.Label>{childCategory.name}</styles.Label>
                  </styles.ContentInput>
                );
              }
            })}
        </styles.ContentInput>
      </S.Wrapper>
    );
  } else {
    return (
      <styles.ContentInput key={props.formProps?.category?.metaData.title}>
        <Checkbox
          checked={openGrandParent}
          key={props.formProps?.category?.metaData.title}
          disabled={true}
        />
        <styles.Label>{props.formProps?.category?.name}</styles.Label>
      </styles.ContentInput>
    );
  }
}
