import httpClient from '../http-client';
import {
  ICreateUserResponse,
  IInfoUser,
  IListUser,
  IListUserFilter,
  IListUserResponse,
} from '../models/user';
import { CreateUserFormValues } from '../pages/Users/CreateUser';

const getActualUser = async () => {
  return (await httpClient.get('users/me')).data;
};

const getAllUsers = async (filters?: any) => {
  if (filters) {
    for (const param of Object.keys(filters)) {
      if (!filters[param]) {
        delete filters[param];
      }
    }
  }

  const users = (await httpClient.get<any>(`users/`, { params: filters })).data;

  if (users) {
    return users?.users;
  }
};

const getUsersForSelect = async () => {
  const users = (await httpClient.get<any>(`users/`)).data;

  if (users?.users && users?.users.length) {
    return users?.users.map((user: any) => ({
      value: user.user_id,
      label: user.name,
    }));
  }

  return [] as { value: string; label: string }[];
};

const updateUserPermission = async (user_id: string) => {
  await httpClient.patch(`users/set-user-admin`, { user_id });
};

const importUsersFromFile = (formData: FormData) => {
  return httpClient
    .post('/users/import', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    .then(({ data }) => data);
};

const listUsers = async (params?: IListUserFilter): Promise<IListUser> => {
  const data = await httpClient
    .get<IListUserResponse>('/users', { params })
    .then(resp => resp.data);

  return { data: data.users, metaData: data.metaData };
};

const getUserById = async (userId: string): Promise<IInfoUser> => {
  const user = await httpClient.get(`/users/${userId}`).then(resp => resp.data);

  return user;
};

const createUser = async (
  body: CreateUserFormValues,
): Promise<ICreateUserResponse> => {
  const user = await httpClient
    .post('/users/minimal', body)
    .then(resp => resp.data);

  return user;
};

export {
  createUser,
  getActualUser,
  getAllUsers,
  getUserById,
  getUsersForSelect,
  importUsersFromFile,
  listUsers,
  updateUserPermission,
};
