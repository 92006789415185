export enum ContentType {
  ARTICLE = 'article',
  COURSE = 'course',
  COURSE_SYNC = 'course_sync',
  EBOOK = 'ebook',
  EXCLUSIVE = 'exclusive',
  INFOGRAPHICS = 'infographics',
  MAGAZINE = 'magazine',
  PODCAST = 'podcast',
  RESEARCH = 'research',
  SERIES = 'series',
  SPREADSHEET = 'spreadsheet',
  THEMATIC_KIT = 'thematic_kit',
  VIDEO = 'video',
}
