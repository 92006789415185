import { VisibilityOutlined } from '@mui/icons-material';
import { format } from 'date-fns';
import React, { useEffect, useMemo, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import { UserFiltersType } from '../../../common/types/filtersType';
import Button from '../../../components/Button';
import { FlexContainer } from '../../../components/FlexContainer';
import CustomCheckbox from '../../../components/FormComponent/CustomCheckbox';
import SearchComponents from '../../../components/SearchComponents';
import { TableProps } from '../../../components/Table';
import { userFilterOptions } from '../../../constants/optionsToSelect';
import { useGetContent, useTablePagination } from '../../../hooks';
import useSearchContents from '../../../hooks/useSearchContents';
import { IListUser } from '../../../models/user';
import { listUsers } from '../../../services/users';
import ContentTemplate from '../../../templates/ContentTemplate';
import { formatCpf } from '../../../utils';
import { handleSort } from '../../../utils/handleSort';

type OrderBySortType = 'ASC' | 'DESC';
type OrderByType = 'name' | 'created_at' | 'last_login';

export default function UserList() {
  const [users, setUsers] = useState([] as IListUser[]);
  const [orderBySort, setOrderBySort] = useState<OrderBySortType>('ASC');
  const [orderBy, setOrderBy] = useState<OrderByType>('name');
  const [filters, setFilters] = useState<UserFiltersType | undefined>();

  const { paginationModel, rowCount, setRowCount, changePage, changePageSize } =
    useTablePagination();
  const { getVariedContents, loading } = useGetContent(listUsers);
  const navigate = useNavigate();
  const { handleParams } = useSearchContents();

  const breadcrumbs = [
    <Link key="profile" to="/">
      Home
    </Link>,
    <span key="list-users">Lista de Usuários</span>,
  ];

  const columns = useMemo(
    () => [
      {
        headerName: 'Nome',
        field: 'name',
        minWidth: 200,
        flex: 2,
        valueGetter: ({ row }) => row?.name,
      },
      {
        headerName: 'CPF',
        field: 'cpf',
        minWidth: 100,
        flex: 1,
        sortable: false,
        valueGetter: ({ row }) => formatCpf(row?.cpf),
      },
      {
        headerName: 'E-mail',
        field: 'email',
        minWidth: 200,
        flex: 2,
        sortable: false,
        valueGetter: ({ row }) => row?.email,
      },
      {
        headerName: 'Criado',
        field: 'created_at',
        flex: 1,
        valueGetter: ({ row }) =>
          format(new Date(row?.created_at), 'dd/MM/yyyy'),
      },
      {
        headerName: 'Usuário Admin',
        field: 'is_admin',
        flex: 1,
        sortable: false,
        renderCell: (cellValues: any) => {
          return (
            <CustomCheckbox
              disabled
              checked={cellValues?.value}
              style={{ margin: 'auto' }}
            />
          );
        },
      },
      {
        headerName: 'Usuário Moderador',
        field: 'moderator_id',
        flex: 1,
        sortable: false,
        renderCell: (cellValues: any) => {
          return (
            <CustomCheckbox
              disabled
              checked={cellValues?.value !== null}
              style={{ margin: 'auto' }}
            />
          );
        },
      },
      {
        headerName: 'Usuário Instrutor',
        field: 'author_id',
        flex: 1,
        sortable: false,
        renderCell: (cellValues: any) => {
          return (
            <CustomCheckbox
              disabled
              checked={cellValues?.value !== null}
              style={{ margin: 'auto' }}
            />
          );
        },
      },
      {
        headerName: 'Ações',
        field: 'actions',
        getRowHeight: 100,
        flex: 1,
        sortable: false,
        renderCell: (cellValues: any) => (
          <FlexContainer
            gap="0.4rem"
            flexWrap="wrap"
            justifyContent="flex-start"
          >
            <Button
              onClick={() => {
                navigate(`/users/view`, {
                  state: { user_id: cellValues.row['user_id'] },
                });
              }}
              icon={<VisibilityOutlined fontSize="inherit" />}
              color="secondary"
              title="Visualizar informações do usuário"
            />
          </FlexContainer>
        ),
      },
    ],
    [],
  );

  const handleSearch = async filters => {
    const params = handleParams(
      {
        limit: paginationModel.pageSize,
        offset: 1,
      },
      filters,
    );

    await getVariedContents(setUsers, setRowCount, params);
  };

  const tableProps: TableProps = {
    columns: columns,
    rows: users,
    keyId: 'user_id',
    onPageChange: page => changePage(page),
    onPageSizeChange: pageSize => changePageSize(pageSize),
    page: paginationModel.page - 1,
    rowsPerPageOptions: [10, 25, 50, 75, 100],
    pageSize: paginationModel.pageSize,
    rowCount,
    loading,
    onSortModelChange: sortModel =>
      handleSort({
        changePage,
        setOrderBy,
        setOrderBySort,
        sortModel,
        letterConverter: 'toLowerCase',
      }),
    disableColumnMenu: true,
  };

  useEffect(() => {
    const params = handleParams(
      {
        limit: paginationModel.pageSize,
        offset: paginationModel.page,
        order_by_sort: orderBySort,
        order_by: orderBy,
      },
      { ...filters },
    );
    getVariedContents(setUsers, setRowCount, params);
  }, [paginationModel.page, paginationModel.pageSize, orderBySort, orderBy]);

  return (
    <ContentTemplate
      pageName="lista de usuários"
      key={'user-list-table'}
      linkToCreatePage="/users/create"
      breadcrumbs={breadcrumbs}
      searchComponent={
        <SearchComponents
          handleSearch={filters => {
            setFilters(filters);
            handleSearch(filters);
            changePage(0);
          }}
          options={userFilterOptions}
        />
      }
      {...tableProps}
    />
  );
}
