import React from 'react';

import * as S from './styles';

type InvalidFeedbackProps = {
  message: string;
};
export default function InvalidFeedback({ message }: InvalidFeedbackProps) {
  return <S.Feedback>{message}</S.Feedback>;
}
