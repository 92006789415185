import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import React, { HTMLAttributes, useState } from 'react';

import Heading from '../../Heading/index';
import LastEditedBy from '../../LastEditedBy';
import * as S from './styles';

export type CardProps = {
  title?: string;
  children: React.ReactNode;
  isExpandable?: boolean;
  contentId?: string;
} & HTMLAttributes<HTMLDivElement>;
export default function Card({
  title,
  children,
  isExpandable = false,
  contentId,
  ...props
}: CardProps): React.ReactElement {
  const [open, setOpen] = useState<boolean>(false);

  const handleClick = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    event.preventDefault();
    setOpen(!open);
  };

  const renderChildrenExpandable = () => {
    return open && children;
  };

  return (
    <S.Card isExpandable={isExpandable} {...props}>
      {title && (
        <S.CardHeader
          isOpen={open}
          isExpandable={isExpandable}
          onClick={handleClick}
        >
          <Heading size="xlarge" lineHeight="2rem">
            {title}
          </Heading>
          {contentId && <LastEditedBy content_id={contentId} />}
          {isExpandable && <KeyboardArrowDownIcon />}
        </S.CardHeader>
      )}
      <S.CardBody isOpen={open} isExpandable={isExpandable}>
        {isExpandable === true ? renderChildrenExpandable() : children}
      </S.CardBody>
    </S.Card>
  );
}
