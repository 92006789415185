import { Box, FormHelperText, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';

import * as appStyles from '../../../../AppStyle';
import ButtonGroup from '../../../../components/ButtonGroup';
import DraftEditor from '../../../../components/DraftEditor/DraftEditor';
import FilesComplementary from '../../../../components/FormComponent/FilesComplementary';
import { complementFiles } from '../../../../models/synchronous/complementFiles';
import { ILessonSync } from '../../../../models/synchronous/lessonSync';
import {
  createLessonSync,
  editLessonSync,
  getLessonSync,
} from '../../../../services/lessonsSyncs';
// Helpers
import * as S from './styles';

export const CreateAndEditLessonSync = (): JSX.Element => {
  const [searchParams] = useSearchParams();
  const [isEditing, setIsEditing] = useState(true);
  const [isLoading, setIsloading] = useState(true);
  const [isShowing, setIsShowing] = useState(false);
  const [lessonSync, setLessonSync] = useState<ILessonSync>();
  const { handleSubmit, control, setValue, getValues } = useForm();

  const navigate = useNavigate();

  useEffect(() => {
    if (searchParams.get('id') != null) {
      getContent();
      if (searchParams.get('view') != null) {
        setIsShowing(true);
      }
      setIsEditing(true);
    } else {
      setIsEditing(false);
      setIsloading(false);
    }
  }, []);

  const getContent = async () => {
    setIsloading(true);
    const id = searchParams.get('id');
    if (id) {
      await getLessonSync(id).then(result => {
        setLessonSync(result);
        setValue('title', result.title);
        setValue('description', result.description);
        setValue('resume', result.resume);
        setValue(
          'complement_files',
          result.complement_files?.map((el: complementFiles) => {
            return el.file_id;
          }),
        );
      });
    }
    setIsloading(false);
  };

  function onSubmit(data: any, status: any) {
    const id: string | null = searchParams.get('id');
    data['status'] = status;

    if (!isEditing) {
      createLessonSync(data)
        .then(() => {
          Swal.fire({
            title: 'Aula criado com sucesso',
            icon: 'success',
          });
          navigate(-1);
        })
        .catch(error => {
          if (error.response.data.errors) {
            Swal.fire({
              title: error.response.data.message,
              icon: 'error',
            });
            error.response.data.errors.map(el =>
              toast({
                message: el.message,
                type: 'warning',
              }),
            );
          } else {
            Swal.fire({
              title: 'Algo deu errado',
              icon: 'error',
            });
          }
        });
    } else {
      editLessonSync(id, data)
        .then(() => {
          Swal.fire({
            title: 'Aula alterada com sucesso',
            icon: 'success',
          });
          navigate(-1);
        })
        .catch(error => {
          if (error.response.data.errors) {
            Swal.fire({
              title: error.response.data.message,
              icon: 'error',
            });
            error.response.data.errors.map(el =>
              toast({
                message: el.message,
                type: 'warning',
              }),
            );
          } else {
            Swal.fire({
              title: 'Algo deu errado',
              icon: 'error',
            });
          }
        });
    }
  }

  const onError = () => {
    Swal.fire({
      title: 'Por favor, preencha o formulário corretamente.',
    });
  };

  return (
    <>
      {!isLoading && (
        <S.CreateAndEditContentContainer>
          <S.CardInput>
            <Typography variant="h4" style={{ margin: '1rem' }}>
              Aula Sícrona
            </Typography>
            <S.ContentSelector>
              <Box
                component="div"
                sx={{
                  '& .MuiTextField-root': { m: 0, width: '100%' },
                }}
              >
                <S.Label>Título *</S.Label>
                <Controller
                  name="title"
                  control={control}
                  rules={{
                    required: true,
                    maxLength: 70,
                  }}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <>
                      <S.InputText
                        disabled={isShowing}
                        name="title"
                        value={value}
                        inputProps={{ maxLength: 70 }}
                        onChange={onChange}
                        required
                        id="outlined-required"
                      />
                      {value?.length >= 70 ? (
                        <FormHelperText id="outlined-required">
                          <appStyles.inputLabel
                            style={{ color: 'red', marginBottom: '0.5rem' }}
                          >
                            Tamanho máximo de 70 caracteres.
                          </appStyles.inputLabel>
                        </FormHelperText>
                      ) : (
                        ''
                      )}
                      {error !== undefined ? (
                        error.type === 'required' ? (
                          <appStyles.inputLabel
                            style={{ color: 'red', marginBottom: '0.5rem' }}
                          >
                            *Campo obrigatório.
                          </appStyles.inputLabel>
                        ) : (
                          <appStyles.inputLabel
                            style={{ color: 'red', marginBottom: '0.5rem' }}
                          >
                            Tamanho máximo de 70 caracteres.
                          </appStyles.inputLabel>
                        )
                      ) : (
                        <></>
                      )}
                    </>
                  )}
                />
              </Box>
            </S.ContentSelector>
            <S.ContentSelector>
              <S.Label>Resumo *</S.Label>
              <Box
                component="div"
                sx={{
                  '& .MuiTextField-root': { width: '100%' },
                }}
              >
                <Controller
                  name="resume"
                  control={control}
                  rules={{
                    required: true,
                    maxLength: 300,
                  }}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <>
                      <S.InputTextArea
                        disabled={isShowing}
                        name="resume"
                        value={value}
                        onChange={onChange}
                        maxRows={3}
                        minRows={3}
                        inputProps={{ maxLength: 301 }}
                        multiline
                      />
                      {value?.length > 300 ? (
                        <FormHelperText id="outlined-required">
                          <appStyles.inputLabel
                            style={{ color: 'red', marginBottom: '0.5rem' }}
                          >
                            Tamanho máximo de 300 caracteres.
                          </appStyles.inputLabel>
                        </FormHelperText>
                      ) : (
                        ''
                      )}
                      {error !== undefined &&
                        (error.type === 'required' ? (
                          <appStyles.inputLabel
                            style={{ color: 'red', marginBottom: '0.5rem' }}
                          >
                            *Campo obrigatório.
                          </appStyles.inputLabel>
                        ) : (
                          <appStyles.inputLabel
                            style={{ color: 'red', marginBottom: '0.5rem' }}
                          >
                            Tamanho máximo de 300 caracteres.
                          </appStyles.inputLabel>
                        ))}
                    </>
                  )}
                />
              </Box>
            </S.ContentSelector>
            <S.ContentSelector>
              <S.Label>Descrição </S.Label>
              <Controller
                name="description"
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <>
                    <DraftEditor
                      name={'description'}
                      setValue={setValue}
                      value={value}
                    />
                    {error !== undefined && (
                      <appStyles.inputLabel
                        style={{ color: 'red', marginBottom: '0.5rem' }}
                      >
                        {error.message}
                      </appStyles.inputLabel>
                    )}
                  </>
                )}
              />
            </S.ContentSelector>
          </S.CardInput>

          <S.CardInput>
            <FilesComplementary
              control={control}
              setValue={setValue}
              content={lessonSync}
              view={isShowing}
              permission="all"
            />
          </S.CardInput>
          <S.ContentSelector>
            <ButtonGroup
              content={lessonSync}
              type={'lessonSync'}
              view={isShowing}
              disabledButtons={true}
              handleSubmit={handleSubmit(onSubmit, onError)}
            />
          </S.ContentSelector>
        </S.CreateAndEditContentContainer>
      )}
    </>
  );
};

export default CreateAndEditLessonSync;
