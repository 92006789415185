import { AddCircle } from '@mui/icons-material';
import React, { forwardRef } from 'react';
import { useNavigate } from 'react-router-dom';

import Breadcrumbs from '../../components/Breadcrumbs';
import Button from '../../components/Button/index';
import Heading from '../../components/Heading/index';
import Table, { TableProps } from '../../components/Table';
import * as S from './styles';

type ContentTemplateProps = {
  linkToCreatePage: string;
  pageName:
    | 'artigos'
    | 'e-books'
    | 'Ferramentas de Gestão'
    | 'infográficos'
    | 'kits temáticos'
    | 'pesquisas'
    | 'podcasts'
    | 'revistas'
    | 'vídeos'
    | 'coleções'
    | 'autores'
    | 'aulas'
    | 'módulos'
    | 'cursos'
    | 'episódios'
    | 'temporadas'
    | 'séries'
    | 'aulas síncronas'
    | 'módulos síncronos'
    | 'conteúdo exclusivo'
    | 'lista de usuários'
    | 'lista de moderadores'
    | 'lista de turmas'
    | 'cursos síncronos';
  breadcrumbs: React.ReactElement[];
  searchComponent?: React.ReactNode;
} & TableProps;

export default forwardRef(function ContentTemplate(
  props: ContentTemplateProps,
) {
  const { linkToCreatePage, pageName, breadcrumbs, searchComponent, ...rest } =
    props;

  const navigate = useNavigate();

  const handleClick = () => {
    navigate(linkToCreatePage);
  };

  return (
    <S.Wrapper>
      <Breadcrumbs breadcrumbs={breadcrumbs} />
      <S.Header>
        <Heading
          style={{ textTransform: 'capitalize' }}
          level={1}
          size="xlarge"
          fontWeight={600}
          lineHeight="2rem"
        >
          {pageName}
        </Heading>

        <Button
          startIcon={<AddCircle />}
          variant="contained"
          onClick={handleClick}
        >
          Adicionar novo
        </Button>
      </S.Header>

      {searchComponent && searchComponent}

      <Table height="100%" {...rest} />
    </S.Wrapper>
  );
});
