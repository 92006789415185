import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  ${({ theme }) => css`
    color: ${theme.colors.gray_700};
    cursor: pointer;
    font-size: ${theme.font.sizes.xsmall};
    font-weight: ${theme.font.semiBold};
    height: 100%;
    padding: 0.5rem;
    width: 100%;
  `}
`;

export const Container = styled.div`
  ${() => css`
    align-items: center;
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    gap: 1rem;
    text-align: center;
    width: 100%;
  `}
`;
