import {
  DeleteOutlineOutlined,
  EditOutlined,
  VisibilityOutlined,
} from '@mui/icons-material';
import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';

import Button from '../../../components/Button';
import { FlexContainer } from '../../../components/FlexContainer';
import SearchComponents from '../../../components/SearchComponents';
import { TableProps } from '../../../components/Table';
import { searchNamePlaceholder } from '../../../constants/placeHolder';
import { useGetContent, useTablePagination } from '../../../hooks';
import useSearchContents from '../../../hooks/useSearchContents';
import { IModuleSync } from '../../../models/moduleSync';
import { deleteModule, getAllModules } from '../../../services/modulesSync';
import ContentTemplate from '../../../templates/ContentTemplate';
import { handleSort } from '../../../utils/handleSort';

const ModuleSynchronous: React.FC = () => {
  const [modules, setModules] = useState([] as IModuleSync[]);
  const [orderBySort, setOrderBySort] = useState('DESC');
  const [orderBy, setOrderBy] = useState('created_at');
  const [filter, setFilter] = useState<{ search: string } | undefined>();

  const navigate = useNavigate();
  const { handleParams } = useSearchContents();
  const { getVariedContents, loading } = useGetContent(getAllModules);
  const { paginationModel, rowCount, setRowCount, changePage, changePageSize } =
    useTablePagination();

  const removeModule = useCallback(async (moduleId: string) => {
    Swal.fire({
      title: '<strong>Confirmação</strong>',
      html: 'Tem certeza que deseja remover este módulo?',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      focusConfirm: false,
    }).then(async result => {
      if (result.isConfirmed) {
        try {
          await deleteModule(moduleId);

          Swal.fire({
            icon: 'success',
            title: 'Sucesso!',
            text: 'Módulo excluído com sucesso!',
          });

          await getVariedContents(setModules, setRowCount, {
            limit: paginationModel.pageSize,
            offset: paginationModel.page,
            order_by_sort: orderBySort,
            order_by: orderBy,
          });
        } catch (error: any) {
          Swal.fire({
            title: error.response.data.message,
            icon: 'error',
          });
        }
      }
    });
  }, []);

  const showModule = useCallback(
    (contentId: string) => {
      navigate(`/synchronous/modules/edit?id=${contentId}&view=true`);
    },

    [navigate],
  );

  const editModule = useCallback(
    (contentId: string) => {
      navigate(`/synchronous/modules/edit?id=${contentId}`);
    },

    [navigate],
  );

  const columns = [
    {
      headerName: 'Título',
      field: 'title',
      minWidth: 200,
      getRowHeight: 100,
      flex: 2,
      renderCell: (cellValues: any) => (
        <p style={{ whiteSpace: 'normal' }}>{cellValues.value}</p>
      ),
    },
    {
      headerName: 'Descrição',
      field: 'resume',
      minWidth: 200,
      getRowHeight: 100,
      flex: 2,
      sortable: false,
      renderCell: (cellValues: any) => (
        <div
          style={{ whiteSpace: 'pre-wrap' }}
          className="description"
          dangerouslySetInnerHTML={{
            __html: cellValues.value,
          }}
        />
      ),
    },
    {
      headerName: 'Situação',
      field: 'status',
      flex: 1,
      renderCell: (cellValues: any) => (
        <span>
          {cellValues.value === 'hidden'
            ? 'Ocultado'
            : cellValues.value === 'draft'
            ? 'Rascunho'
            : 'Publicado'}
        </span>
      ),
    },
    {
      headerName: 'Criado',
      field: 'created_at',
      flex: 1,
      renderCell: (cellValues: any) => (
        <p style={{ whiteSpace: 'normal' }}>
          {moment(cellValues?.value).format('DD/MM/YYYY')}
        </p>
      ),
    },
    {
      headerName: 'Atualizado',
      field: 'updated_at',
      flex: 1,
      renderCell: (cellValues: any) => (
        <p style={{ whiteSpace: 'normal' }}>
          {moment(cellValues?.value).format('DD/MM/YYYY')}
        </p>
      ),
    },
    {
      headerName: 'Ações',
      field: 'actions',
      minWidth: 200,
      getRowHeight: 100,
      flex: 1,
      sortable: false,
      renderCell: (cellValues: any) => (
        <FlexContainer gap="0.4rem" flexWrap="wrap" justifyContent="flex-start">
          <Button
            onClick={() => removeModule(cellValues.id)}
            icon={<DeleteOutlineOutlined fontSize="inherit" />}
            color="secondary"
            title="Remover"
          />
          <Button
            onClick={() => editModule(cellValues.id)}
            icon={<EditOutlined fontSize="inherit" />}
            color="secondary"
            title="Editar"
          />
          <Button
            onClick={() => showModule(cellValues.id)}
            icon={<VisibilityOutlined fontSize="inherit" />}
            color="secondary"
            title="Visualizar"
          />
        </FlexContainer>
      ),
    },
  ];

  const breadcrumbs = [
    <Link key="profile" to="/">
      Home
    </Link>,
    <span key="sync">Curso Síncrono</span>,
    <span key="sync-module">Módulos Síncronos</span>,
  ];

  const handleSearch = async filter => {
    const params = handleParams(
      {
        limit: paginationModel.pageSize,
        offset: 1,
        order_by_sort: orderBySort,
        order_by: orderBy,
      },
      filter,
    );

    await getVariedContents(setModules, setRowCount, params);
  };

  useEffect(() => {
    const params = handleParams(
      {
        limit: paginationModel.pageSize,
        offset: paginationModel.page,
        order_by_sort: orderBySort,
        order_by: orderBy,
      },
      { ...filter },
    );

    getVariedContents(setModules, setRowCount, params);
  }, [paginationModel.page, paginationModel.pageSize, orderBySort, orderBy]);

  const tableProps: TableProps = {
    columns: columns,
    rows: modules,
    keyId: 'module_sync_id',
    onPageChange: page => changePage(page),
    onPageSizeChange: pageSize => changePageSize(pageSize),
    page: paginationModel.page - 1,
    rowsPerPageOptions: [10, 25, 50, 75, 100],
    pageSize: paginationModel.pageSize,
    rowCount,
    loading,
    disableColumnMenu: true,
    onSortModelChange: sortModel =>
      handleSort({
        changePage,
        setOrderBy,
        setOrderBySort,
        sortModel,
        letterConverter: 'toLowerCase',
      }),
  };

  return (
    <ContentTemplate
      key={'sync-module-table'}
      pageName="módulos síncronos"
      linkToCreatePage="/synchronous/modules/create"
      breadcrumbs={breadcrumbs}
      searchComponent={
        <SearchComponents
          handleSearch={filters => {
            setFilter(filters);
            handleSearch(filters);
            changePage(0);
          }}
          customPlaceholder={searchNamePlaceholder}
        />
      }
      {...tableProps}
    />
  );
};

export default ModuleSynchronous;
