import styled, { css, DefaultTheme } from 'styled-components';

type FlexContainerProps = {
  alignItems?: 'center' | 'flex-start' | 'flex-end';
  flexDirection?: 'row' | 'column';
  flexWrap?: 'nowrap' | 'wrap';
  justifyContent?: 'center' | 'flex-start' | 'flex-end' | 'space-between';
  gap?: string;
  height?: string;
  width?: string;
  padding?: string;
  flex?: string | number;
  background?: string;
};

type ButtonProps = {
  color?: 'blue' | 'gray_500';
};

type ModalWrapperProps = {
  isOpen: boolean;
};

export const Wrapper = styled.form`
  display: flex;
  flex-direction: column;
  gap: 20px;
  height: fit-content;
  width: 100%;
`;

export const FlexContainer = styled.div<FlexContainerProps>`
  ${({
    width = '100%',
    height = '100%',
    gap,
    flexDirection = 'row',
    alignItems = 'center',
    justifyContent = 'center',
    flexWrap = 'wrap',
    flex = 1,
    padding = '0',
    background,
  }) => css`
    align-items: ${alignItems};
    background-color: ${background};
    display: flex;
    flex-direction: ${flexDirection};
    flex: ${flex};
    justify-content: ${justifyContent};
    gap: ${gap};
    flex-wrap: ${flexDirection === 'column' ? 'nowrap' : flexWrap};
    height: ${height};
    padding: ${padding};
    transition: all 100ms;
    width: ${width};
  `}
`;

const ButtonModifiers = {
  blue: (theme: DefaultTheme) => css`
    background-color: ${theme.colors.teal_400};
    color: ${theme.colors.white};
  `,
  gray_500: (theme: DefaultTheme) => css`
    background-color: ${theme.colors.neutral_100};
    color: ${theme.colors.gray_900};
  `,
};

export const Button = styled.button<ButtonProps>`
  ${({ theme, color = 'blue' }) => css`
    border: 0;
    border-radius: 4px;
    cursor: pointer;
    flex: 1 1 auto;
    font-size: 14px;
    font-weight: ${theme.font.semiBold};
    line-height: 1.5;
    max-width: 160px;
    text-transform: uppercase;
    padding: 8px;

    ${ButtonModifiers[color](theme)}
  `}
`;

export const Overlay = styled.div`
  ${({ theme }) => css`
    background-color: rgba(0, 0, 0, 0.16);
    z-index: ${theme.layers.overlay};

    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  `}
`;

export const ModalContent = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.colors.white};
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    height: max-content;
    justify-content: center;
    position: relative;
    padding: 20px;
    right: 0;
    width: 500px;
    z-index: ${theme.layers.alwaysOnTop};

    h1 {
      color: ${theme.colors.gray_800};
      font-size: 22px;
      text-align: center;
    }
  `}
`;

export const CloseButton = styled.button`
  background-color: transparent;
  border: 0;

  position: absolute;
  right: 20px;

  svg {
    color: #6c6e6f;
  }
`;

const modalWrapperModifiers = {
  open: () => css`
    opacity: 1;
    pointer-events: auto;
    transform: translate(0);
  `,
  close: () => css`
    opacity: 0;
    pointer-events: none;
    transform: translate(-36px);
  `,
};

export const ModalWrapper = styled.div<ModalWrapperProps>`
  ${({ theme, isOpen }) => css`
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 4;

    transition: transform 0.3s ease-out, opacity ${theme.transition.default};

    ${Overlay},
    ${ModalContent} {
      ${isOpen && modalWrapperModifiers.open()};
      ${!isOpen && modalWrapperModifiers.close()};
    }
  `}
`;
