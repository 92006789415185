import httpClient from '../http-client';
import { IModule, IModulesParams } from '../models/module';

interface ModuleResponse {
  data: IModule[];
  metaData: {
    total: number;
    limit?: number;
    offset?: number;
    search?: string;
  };
}

export const getAllModules = async (params?: IModulesParams) => {
  const modules = (
    await httpClient.get<ModuleResponse>('/module?type=course', { params })
  ).data;
  return modules;
};

export const getModule = async (moduleId: string): Promise<IModule> => {
  const module = (await httpClient.get<IModule>(`/module/${moduleId}`)).data;

  return module;
};

export const editModule = async (id: string | null, data: any) => {
  await httpClient.put(`/module/${id}`, data);
};

export const createModule = async (body: any): Promise<IModule> => {
  const module = (await httpClient.post<IModule>('/module', body)).data;

  return module;
};

export const deleteModule = async (moduleId: string) => {
  await httpClient.delete(`/module/${moduleId}`);
};
