import React from 'react';

import * as S from './styles';

type BreadcrumbsProps = {
  breadcrumbs: React.ReactElement[];
};
export default function Breadcrumbs({ breadcrumbs }: BreadcrumbsProps) {
  return (
    <S.CustomBreadcrumbs aria-label="breadcrumb">
      {breadcrumbs.map((el, i) => (
        <S.ListItem separator="›" key={`li-${i}`}>
          {el}
        </S.ListItem>
      ))}
    </S.CustomBreadcrumbs>
  );
}
