import {
  AddCircle,
  Delete,
  HideSource,
  Save,
  SaveAs,
} from '@mui/icons-material';
import { DatePicker } from '@mui/lab';
import * as M from '@mui/material';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { renderToString } from 'react-dom/server';
import { useFormContext } from 'react-hook-form';
import { FiLoader } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';
import { MultiValue } from 'react-select';
import { Option } from 'react-select/src/filters';
import Swal from 'sweetalert2';

import { KeyIds } from '../../common/enum/keyIds';
import { ContentStatusType } from '../../common/types/contentStatusType';
import { DataOptionType } from '../../common/types/DataOption';
import { modalWithoutSchema } from '../../common/validations/modalWithoutSchema';
import Button from '../../components/Button';
import CustomSelect from '../../components/CustomSelect';
import DraftEditor from '../../components/DraftEditor/DraftEditor';
import { FlexContainer } from '../../components/FlexContainer';
import Authors from '../../components/FormComponent/Authors';
import Card from '../../components/FormComponent/Card';
import Categorization from '../../components/FormComponent/Categorization';
import FilesComplementary from '../../components/FormComponent/FilesComplementary';
import Form from '../../components/FormComponent/Form';
import MetaTag from '../../components/FormComponent/MetaTag';
import TextField from '../../components/FormComponent/TextField';
import GenericDragAndDrop from '../../components/GenericDragAndDrop';
import InputFile from '../../components/InputFile';
import Loading from '../../components/Loading';
import RegisterModal from '../../components/SimpleModal/RegisterModal';
import UploadCover from '../../components/UploadCover';
import { IContent } from '../../models/content';
import {
  createCompanyUnit,
  getAllCompanySize,
  getAllCompanyUnit,
  ICompanySize,
} from '../../services/company';
import { uploadFile } from '../../services/files';
import { createRequest, getAllRequesters } from '../../services/requester';
import { createTag, getAllTags } from '../../services/tags';
import { createTopics, getAllTopics } from '../../services/topics';
import { dealWithTitleChange, groups, visibilities } from '../../utils';

export type BasicContentTemplateType = {
  title: string;
  view: boolean;
  isLoading?: boolean;
  content?: IContent;
  isShowAudioDescription?: boolean;
  children?: React.ReactNode | string;
  submit: (data, status: ContentStatusType) => void;
};

type OptionsToSelectType = {
  company_units: Option[] | [];
  requesters: Option[] | [];
};

type KeyModalType = 'companyUnits' | 'requester';

type Item = {
  [key: string]: any;
};

type Key = 'company_units' | 'requester';

type CompanySizeOptionType = {
  value: string;
  label: string;
};

export default function BasicContentTemplate({
  title,
  view,
  content,
  isLoading,
  children,
  isShowAudioDescription,
  submit,
}: BasicContentTemplateType): JSX.Element {
  const [loadingAudio, setLoadingAudio] = useState(false);
  const [optionsToSelect, setOptionsToSelect] = useState<OptionsToSelectType>({
    company_units: [],
    requesters: [],
  });
  const [openModal, setOpenModal] = useState({
    companyUnits: false,
    requester: false,
  });
  const [mappedCompanySize, setMappedCompanySize] = useState<
    CompanySizeOptionType[] | []
  >([]);

  const {
    setValue,
    watch,
    getValues,
    formState: { errors },
    clearErrors,
    setError,
    control,
    handleSubmit,
  } = useFormContext();
  const navigate = useNavigate();
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const hasMainFile =
    (watch('file_id') && watch('file')?.type === 'application') || false;

  const getCompanySize = async (): Promise<CompanySizeOptionType[] | []> => {
    try {
      const companySize = await getAllCompanySize();
      const allCompanySize = companySize.companies_size.map(
        (companySize: ICompanySize) => {
          return {
            value: companySize?.company_size_id,
            label: companySize?.name,
          };
        },
      );
      return allCompanySize;
    } catch (e: any) {
      Swal.fire({
        icon: 'error',
        iconColor: '#D73E51',
        title: 'Erro ao obter os tamanhos das empresas',
        text: e.message,
        customClass: {
          confirmButton: 'btn-ok',
        },
      });
      return [];
    }
  };

  const getOptionsForSelect = async () => {
    const fetchData = async (getDataFunc, key, keyId) => {
      const data = await getDataFunc();

      if (!data[key].length) return [];

      return data[key].map(item => ({
        label: item['name'],
        value: item[keyId],
      }));
    };

    const options = await Promise.all([
      fetchData(getAllCompanyUnit, 'companies_unit', 'company_unit_id'),
      fetchData(getAllRequesters, 'requesters', 'requester_id'),
    ]);

    setOptionsToSelect({
      company_units: options[0],
      requesters: options[1],
    });
  };

  useEffect(() => {
    getCompanySize().then(resp => setMappedCompanySize(resp));
    getOptionsForSelect();
  }, []);

  useEffect(() => {
    if (fileInputRef.current && !isLoading) {
      fileInputRef.current.files = getValues('audio_file');
    }
  }, [getValues('audio_file'), isLoading]);

  const handleTitleChange = e => {
    setValue('page_title', e.target.value);
    const slug = dealWithTitleChange(e.target.value);
    setValue('slug', slug);

    if (Object.keys(errors).includes('page_title')) {
      clearErrors('page_title');
      clearErrors('slug');
    }
  };

  const handleSelectChange = {
    'select-option': (key: string, option: MultiValue<readonly Option[]>) => {
      setValue(key, option);
    },
    'remove-value': (key: string, option: MultiValue<readonly Option[]>) => {
      setValue(key, option);
    },
    clear: (key: string, option: MultiValue<readonly Option[]>) => {
      setValue(key, option);
    },
  };

  const handleOpenModal = (key: KeyModalType) => {
    setOpenModal(prevState => ({ ...prevState, [key]: !openModal[key] }));
  };

  const handleMuiltSelected = <T extends Item>(item: T, key: Key) => {
    const keyIds = getValues(key);

    keyIds === undefined
      ? setValue(key, [{ label: item.label, value: item.value }])
      : setValue(key, [...keyIds, { label: item.label, value: item.value }]);
  };

  const handleSelected = <T extends Item>(item: T, key: Key) => {
    setValue(key, { label: item.label, value: item.value });
  };

  const handleHighlight = (bool: boolean) => {
    setValue('featured_start_at', null);
    setValue('featured_end_at', null);
    setValue('highlight', bool);
  };

  const handleAdvancePublication = (bool: boolean) => {
    setValue('antecipation_days', null);
    setValue('antecipation_groups', null);
    setValue('antecipation', bool);
  };

  const handleClearAudioFile = async () => {
    try {
      setValue('audio_file', null);
      setValue('audio_file_id', null);
      if (fileInputRef.current) fileInputRef.current.value = '';
    } catch (e: any) {
      console.error(e);
      Swal.fire({
        icon: 'error',
        iconColor: '#D73E51',
        title: 'Erro ao remover o audio descrição',
        text: e.message,
        customClass: {
          confirmButton: 'btn-ok',
        },
      });
    }
  };

  const handleSendAudioFile = async (fileList: FileList | null) => {
    setLoadingAudio(true);
    const file = fileList ? fileList['0'] : null;

    const modal = Swal.mixin({
      showConfirmButton: false,
    });

    modal.fire({
      html: '<span style="color: #525f7f; font-size: 20px;">Carregando o áudio<br/>Aguarde...</span>',
      iconHtml: renderToString(
        <FiLoader style={{ color: '#525f7f', fontSize: '96px' }} />,
      ),
      showCloseButton: true,
      customClass: {
        icon: 'no-border',
      },
    });

    if (file) {
      const data = new FormData();

      data.append('file', file);
      data.append('permission', 'public');
      data.append('compress', 'true');

      try {
        const response = await uploadFile(data, {
          onDownloadProgress: function () {
            modal.close();
          },
        }).then(res => res);

        setValue('audio_file', fileList);
        setValue('audio_file_id', response.file_id);
      } catch (e: any) {
        console.error(e);

        if (fileInputRef.current) fileInputRef.current.value = '';
        setValue('audio_file', null);
        setValue('audio_file_id', null);

        Swal.fire({
          icon: 'error',
          iconColor: '#D73E51',
          title: 'Erro ao enviar audio descrição',
          text: e.message,
          customClass: {
            confirmButton: 'btn-ok',
          },
        });
      } finally {
        setLoadingAudio(false);
      }
    }
  };

  const handleAudioFile = async (e: React.FormEvent<HTMLInputElement>) => {
    e.preventDefault();

    const fileList = e.currentTarget.files;
    await handleSendAudioFile(fileList);
  };

  const verifyIfContentIsCreated = useMemo(() => {
    return !content?.content_id;
  }, [content]);

  const onError = error => {
    console.error(error);
    Swal.fire({
      icon: 'error',
      title: 'Erro ao enviar o formulário',
      text: 'Por favor, preencha o formulário corretamente.',
      customClass: {
        title: 'modal-title',
        htmlContainer: 'modal-html-container',
        confirmButton: 'btn-ok',
      },
      buttonsStyling: false,
    });
  };

  const warning = () => {
    Swal.fire({
      icon: 'error',
      iconColor: '#D73E51',
      title: 'Erro',
      text: 'A alteração pode ser prejudicial ao ranqueamento das ferramentas de busca.',
      customClass: {
        confirmButton: 'btn-ok',
      },
    });
  };

  const onCancel = () => {
    Swal.fire({
      icon: 'question',
      title: 'Confirmação',
      html: 'Tem certeza que deseja cancelar as alterações?',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      reverseButtons: true,
      focusConfirm: true,
      customClass: {
        title: 'modal-title',
        htmlContainer: 'modal-html-container',
        confirmButton: 'btn-ok',
        cancelButton: 'btn-cancel',
      },
      buttonsStyling: false,
    }).then(async result => {
      if (result.isConfirmed) {
        navigate(-1);
      }
    });
  };

  if (isLoading) return <Loading />;

  return (
    <Card title={title} contentId={content ? content.content_id : undefined}>
      <Form>
        <FlexContainer gap="0.875rem" flexWrap="wrap">
          <TextField
            label="Título da página"
            name="page_title"
            onChange={handleTitleChange}
            disabled={view}
            flex="1 0 13.75rem"
            required
          />
          <TextField
            label="Slug | URL"
            name="slug"
            onClick={warning}
            disabled={view}
            flex="1 0 13.75rem"
            required
          />
          <TextField
            label="Título"
            name="title"
            disabled={view}
            flex="1 0 13.75rem"
            required
          />
          <TextField
            label="Subtítulo"
            name="subtitle"
            disabled={view}
            flex="1 0 13.75rem"
            required
          />
        </FlexContainer>
        <FlexContainer gap="0.875rem" flexDirection="column">
          <TextField
            label="Resumo"
            name="resume"
            disabled={view}
            flex="1"
            required
          />
          <TextField label="Descrição" name="description" required>
            <DraftEditor
              name={'description'}
              setValue={setValue}
              value={watch('description')}
            />
          </TextField>

          {(isShowAudioDescription || hasMainFile) && (
            <TextField
              label="Audio da descrição (.mp3)"
              name="audio_file"
              disabled={view}
              flex="1 0"
            >
              <InputFile
                ref={fileInputRef}
                fieldName="audio_file"
                accept=".mp3"
                clear={handleClearAudioFile}
                onChange={handleAudioFile}
                dropFile={e => {
                  handleSendAudioFile(e.dataTransfer.files);
                }}
              />
            </TextField>
          )}

          <TextField label="Capa (Tamanho 1035x526px)" name="image_file_id">
            <UploadCover
              isView={view}
              sizes={{
                width: 1035,
                heigth: 526,
              }}
              showCategory
            />
          </TextField>
        </FlexContainer>
        <FlexContainer gap="0.875rem" flexWrap="wrap">
          <GenericDragAndDrop
            view={view}
            keyId={KeyIds.TOPIC}
            createItens={createTopics}
            getItens={getAllTopics}
            name="topics"
            label="Tópicos"
          />
          <GenericDragAndDrop
            view={view}
            keyId={KeyIds.TAGS}
            createItens={createTag}
            getItens={getAllTags}
            name="tags"
            label="Tags"
            needSlug
            required
          />
        </FlexContainer>
        <FlexContainer gap="0.875rem" flexWrap="wrap">
          <TextField
            label="Data de Revisão"
            name="review_in"
            flex="1 0 15rem"
            required
          >
            <DatePicker
              disabled={view}
              value={watch('review_in')}
              onChange={date => setValue('review_in', date)}
              renderInput={params => <M.TextField {...params} />}
            />
          </TextField>
          <TextField
            label="Visibilidade"
            name="visibility"
            flex="1 0 13.75rem"
            required
          >
            <CustomSelect
              id="visibility"
              isClearable
              isRtl={false}
              isSearchable
              options={visibilities}
              onChange={(value, action) => {
                handleSelectChange[action.action]('visibility', value);
                value && setValue('visibility', value.value);

                if (
                  !!value &&
                  value.value === 'public' &&
                  getValues('groups').length
                ) {
                  setValue('groups', null);
                }
              }}
              value={visibilities.find(
                visibility => visibility.value === watch('visibility'),
              )}
              placeholder="Selecione a visibilidade do conteúdo"
              thereIsError={Object.keys(errors).includes('visibility')}
              isDisabled={view}
            />
          </TextField>
          <TextField
            label="Unidade Solicitante"
            name="company_units"
            flex="1 0 13.75rem"
            required
          >
            <CustomSelect
              id="company_units"
              isMulti
              isClearable
              isRtl={false}
              isSearchable
              options={optionsToSelect.company_units}
              onChange={(values, action) => {
                handleSelectChange[action.action]('company_units', values);
              }}
              value={watch('company_units')}
              placeholder="Selecione as unidades"
              thereIsError={Object.keys(errors).includes('company_units')}
              isDisabled={view}
            />
            <div>
              <Button
                startIcon={<AddCircle />}
                variant="contained"
                type="button"
                onClick={() => handleOpenModal('companyUnits')}
                disabled={view}
              >
                Adicionar novo
              </Button>
            </div>
          </TextField>
          <TextField
            label="Nome Solicitante "
            name="requester"
            flex="1 0 13.75rem"
            required
          >
            <CustomSelect
              id="requester"
              isClearable
              isRtl={false}
              isSearchable
              options={optionsToSelect.requesters}
              onChange={(value, action) => {
                handleSelectChange[action.action]('requester', value);
                value && setValue('requester_id', value.value);
              }}
              value={optionsToSelect.requesters.find(
                requester => requester.value === watch('requester_id'),
              )}
              placeholder="Selecione o solicitante"
              thereIsError={Object.keys(errors).includes('requester')}
              isDisabled={view}
            />
            <div>
              <Button
                startIcon={<AddCircle />}
                variant="contained"
                type="button"
                onClick={() => handleOpenModal('requester')}
                disabled={view}
              >
                Adicionar novo
              </Button>
            </div>
          </TextField>

          {watch('visibility') === 'restricted' && (
            <TextField
              label="Grupos"
              name="groups"
              flex="1 0 13.75rem"
              required
            >
              <CustomSelect
                isMulti
                id="groups"
                isClearable
                isRtl={false}
                isSearchable
                options={groups}
                onChange={(values, action) => {
                  handleSelectChange[action.action]('groups', values);
                }}
                value={watch('groups')}
                placeholder="Selecione os grupos"
                thereIsError={Object.keys(errors).includes('group')}
                isDisabled={view}
              />
            </TextField>
          )}
        </FlexContainer>

        <FlexContainer gap="0.875rem" flexDirection="row" width="100%">
          <FlexContainer gap="0.875rem" flexDirection="column" width="100%">
            <TextField label="Destaque" name="highlight" direction="row">
              <M.Checkbox
                id="highlight"
                checked={getValues('highlight')}
                onChange={event => handleHighlight(event.target.checked)}
                disabled={view}
              />
            </TextField>

            {watch('highlight') && (
              <FlexContainer gap="0.857rem" flexWrap="wrap" width="100%">
                <TextField
                  label="Data Início Destaque"
                  name="featured_start_at"
                  flex="1 0 15rem"
                  required
                >
                  <DatePicker
                    disabled={view}
                    value={watch('featured_start_at') || null}
                    onChange={date => setValue('featured_start_at', date)}
                    renderInput={params => <M.TextField {...params} />}
                  />
                </TextField>
                <TextField
                  label="Data Fim Destaque"
                  name="featured_end_at"
                  flex="1 0 15rem"
                  required
                >
                  <DatePicker
                    disabled={view}
                    value={watch('featured_end_at') || null}
                    onChange={date => setValue('featured_end_at', date)}
                    renderInput={params => <M.TextField {...params} />}
                  />
                </TextField>
              </FlexContainer>
            )}
          </FlexContainer>

          <FlexContainer gap="0.875rem" flexDirection="column" width="100%">
            <TextField
              label="Antecipar publicação"
              name="antecipation"
              direction="row"
            >
              <M.Checkbox
                id="antecipation"
                checked={getValues('antecipation')}
                onChange={event =>
                  handleAdvancePublication(event.target.checked)
                }
                disabled={view}
              />
            </TextField>

            {watch('antecipation') && (
              <FlexContainer gap="0.857rem" flexWrap="wrap" width="100%">
                <TextField
                  label="Dias de antecipação"
                  name="antecipation_days"
                  flex="1 0 13.75rem"
                  type="number"
                  onChange={e =>
                    setValue('antecipation_days', Number(e.target.value))
                  }
                  min={0}
                  required
                />
                <TextField
                  label="Grupos"
                  name="antecipation_groups"
                  flex="1 0 13.75rem"
                  required
                >
                  <CustomSelect
                    isMulti
                    id="antecipation_groups"
                    isClearable
                    isRtl={false}
                    isSearchable
                    options={groups}
                    onChange={(values, action) => {
                      handleSelectChange[action.action](
                        'antecipation_groups',
                        values,
                      );
                    }}
                    value={watch('antecipation_groups')}
                    placeholder="Selecione os grupos"
                    thereIsError={Object.keys(errors).includes(
                      'antecipation_groups',
                    )}
                    isDisabled={view}
                  />
                </TextField>
              </FlexContainer>
            )}
          </FlexContainer>
        </FlexContainer>

        <FlexContainer gap="0.875rem" flexWrap="wrap">
          <TextField
            label="Data publicação início"
            name="published_start_at"
            flex="1 0 15rem"
          >
            <DatePicker
              disablePast={verifyIfContentIsCreated}
              disabled={view}
              value={watch('published_start_at') || null}
              onChange={date => setValue('published_start_at', date)}
              renderInput={params => <M.TextField {...params} />}
            />
          </TextField>
          <TextField
            label="Data publicação fim"
            name="published_end_at"
            flex="1 0 15rem"
          >
            <DatePicker
              disablePast={verifyIfContentIsCreated}
              disabled={view}
              value={watch('published_end_at') || null}
              onChange={date => setValue('published_end_at', date)}
              renderInput={params => <M.TextField {...params} />}
            />
          </TextField>
          <TextField
            label="Portes"
            name="company_sizes"
            flex="1 0 13.75rem"
            required
          >
            <CustomSelect
              isMulti
              id="company_sizes"
              isClearable
              isRtl={false}
              isSearchable
              options={mappedCompanySize}
              onChange={(values, action) => {
                handleSelectChange[action.action]('company_sizes', values);
              }}
              value={watch('company_sizes')}
              placeholder="Selecione o porte da empresa"
              thereIsError={Object.keys(errors).includes('company_sizes')}
              isDisabled={view}
            />
          </TextField>
        </FlexContainer>

        <FlexContainer gap="0.875rem" flexWrap="wrap">
          <Authors
            clearErrors={clearErrors}
            setError={setError}
            control={control}
            setValue={setValue}
            content={content}
            view={view}
          />
          <FilesComplementary
            control={control}
            setValue={setValue}
            content={content}
            view={view}
            permission="public"
          />
          <Categorization
            control={control}
            setValue={setValue}
            content={content}
            view={view}
          />
          <MetaTag
            control={control}
            setValue={setValue}
            content={content}
            view={view}
          />
        </FlexContainer>

        {children}
      </Form>

      <FlexContainer gap="0.875rem" flexWrap="wrap" justifyContent="center">
        <Button
          type="button"
          variant="contained"
          color="blue"
          onClick={() =>
            handleSubmit(data => submit(data, 'published'), onError)()
          }
          startIcon={<Save />}
          disabled={view || loadingAudio}
        >
          Publicar
        </Button>
        <Button
          type="button"
          variant="contained"
          color="orange"
          onClick={() =>
            handleSubmit(data => submit(data, 'exclusive_series'), onError)()
          }
          startIcon={<Save />}
          disabled={view || loadingAudio}
        >
          Publicar como Exclusivo
        </Button>
        <Button
          type="button"
          variant="outlined"
          onClick={() => handleSubmit(data => submit(data, 'draft'), onError)()}
          disabled={view || loadingAudio}
          startIcon={<SaveAs />}
        >
          Salvar Rascunho
        </Button>
        <Button
          type="button"
          variant="contained"
          color="info"
          startIcon={<HideSource />}
          onClick={() => {
            Swal.fire({
              title: '<strong>Confirmação</strong>',
              html: 'Tem certeza que deseja ocultar o curso?',
              showCancelButton: true,
              cancelButtonText: 'Cancelar',
              focusConfirm: false,
              buttonsStyling: false,
              reverseButtons: true,
              customClass: {
                confirmButton: 'btn-ok',
                cancelButton: 'btn-cancel',
                title: 'modal-title',
                htmlContainer: 'modal-html-container',
              },
            }).then(async result => {
              if (result.isConfirmed) {
                handleSubmit(data => submit(data, 'hidden'), onError)();
              }
            });
          }}
          disabled={view || loadingAudio}
        >
          Ocultar
        </Button>
        <Button
          type="button"
          variant="contained"
          color="secondary"
          onClick={onCancel}
          disabled={view}
          aria-disabled={view}
          startIcon={<Delete />}
        >
          Cancelar
        </Button>
      </FlexContainer>

      {openModal.companyUnits &&
        createPortal(
          <RegisterModal
            schema={modalWithoutSchema}
            isOpen={openModal.companyUnits}
            onCloseChange={() => handleOpenModal('companyUnits')}
            keyId={'company_unit_id'}
            funcCreate={createCompanyUnit}
            handleOptionChange={data =>
              handleMuiltSelected(data, 'company_units')
            }
            needSlug={false}
          />,
          document.body,
        )}
      {openModal.requester &&
        createPortal(
          <RegisterModal
            schema={modalWithoutSchema}
            isOpen={openModal.requester}
            onCloseChange={() => handleOpenModal('requester')}
            keyId={'requester_id'}
            funcCreate={createRequest}
            handleOptionChange={(data: DataOptionType) => {
              const requesters = [...optionsToSelect.requesters];
              requesters.push({
                label: data.label,
                value: data.value,
                data: data.data,
              });

              handleSelected(data, 'requester');
              setValue('requester_id', data.value);
              setOptionsToSelect(prev => ({ ...prev, requesters }));
            }}
            needSlug={false}
          />,
          document.body,
        )}
    </Card>
  );
}
