import { z } from 'zod';

import { basicContentSchema } from './basicContentValitadion';

export const kitsTematicSchema = z
  .object({
    allow_download: z.boolean().nullable().optional(),
    file_id: z.string().nullable().optional(),
    file_name: z.string().nullable().optional(),
    file_toltip: z.string().nullable().optional(),
    show_page_flip: z.any().nullable().optional(),
  })
  .and(basicContentSchema);
