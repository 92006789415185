import styled, { css } from 'styled-components';

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  width: 100%;
`;

type FlexContainerProps = {
  alignItems?: 'center' | 'flex-start' | 'flex-end';
  flexDirection?: 'row' | 'row-reverse' | 'column' | 'column-reverse';
  flexWrap?: 'nowrap' | 'wrap' | 'wrap-reverse';
  gap?: string;
  margin?: string;
  justifyContent?:
    | 'center'
    | 'flex-start'
    | 'flex-end'
    | 'space-between'
    | 'space-around'
    | 'space-evenly';
};

export const FlexContainer = styled.div<FlexContainerProps>`
  ${({
    alignItems = 'center',
    flexDirection = 'row',
    flexWrap = 'wrap',
    gap = 'none',
    justifyContent = 'flex-start',
    margin,
  }) => css`
    align-items: ${alignItems};
    display: flex;
    flex-direction: ${flexDirection};
    flex-wrap: ${flexDirection.includes('column') ? 'nowrap' : flexWrap};
    gap: ${gap};
    justify-content: ${justifyContent};
    margin: ${margin};
    width: 100%;
  `}
`;

export const Label = styled.label`
  ${({ theme }) => css`
    color: ${theme.colors.gray_700};
    font-size: ${theme.font.sizes.xsmall};
    font-weight: ${theme.font.semiBold};
    text-transform: capitalize;
  `}
`;
