// import List from '@mui/material/List';
// import ListItemButton from '@mui/material/ListItemButton';
// import ListItemIcon from '@mui/material/ListItemIcon';
// import ListItemText from '@mui/material/ListItemText';
import React from 'react';
// import { cloneElement } from 'react';
import { Link } from 'react-router-dom';

import type menuItem from '../../../@types/menuItem';
import * as S from './styles';

interface MenuItemProps extends menuItem {
  index?: number;
}

const MenuItem = ({ icon, label, path, index }: MenuItemProps) => {
  const localPath = window.location.pathname;

  const isSelected = (path: string) => {
    return localPath.toLowerCase().includes(path.toLowerCase());
  };

  return (
    <Link
      data-testid="menu-item"
      to={path}
      key={index}
      style={{ textDecoration: 'none' }}
    >
      <S.Container isSelected={isSelected(path)}>
        {icon}
        <span>{label}</span>
      </S.Container>
    </Link>
  );
};

export default MenuItem;
