import 'react-image-crop/dist/ReactCrop.css';

import {
  ContentCut,
  Delete,
  ImageSearch,
  Send,
  SendAndArchive,
} from '@mui/icons-material';
import ImageIcon from '@mui/icons-material/Image';
import SendAndArchiveIcon from '@mui/icons-material/SendAndArchive';
import {
  AppBar,
  Button,
  ButtonGroup,
  Card,
  Divider,
  Modal,
  Stack,
  Toolbar,
  Typography,
} from '@mui/material';
import Box from '@mui/material/Box';
import React from 'react';
import { Controller } from 'react-hook-form';
import ReactCrop from 'react-image-crop';

import * as appStyles from '../../../AppStyle';
// Helpers
import toast from '../../../helpers/toast';
import { getCategories } from '../../../services/categorization';
import { uploadFile } from '../../../services/files';
import CategoryDropDown from '../../CategoryDropDown';
import * as styles from './styles';
import { CropButton } from './styles';

interface UploadFileProps {
  imageReference: string;
  imageId: string;
  setValue: any;
  control: any;
  isRequired?: boolean;
  label?: string;
  isView?: boolean;
  formName?: string;
  formToltip?: string;
  getValues?: any;
  type?: string;
}

const UploadFile = (props: UploadFileProps) => {
  const {
    imageReference,
    isRequired,
    imageId,
    setValue,
    getValues,
    control,
    label,
    isView,
    formName,
    formToltip,
    type,
  } = props;

  const [modalOpen, setModalOpen] = React.useState<boolean>(false);
  const [openModalImg, setopenModalImg] = React.useState<boolean>(false);
  const [isEditing, setIsEditing] = React.useState<boolean>(false);
  const [files, setFiles] = React.useState<FileList>();
  const [imagePreview, setImagePreview] = React.useState<any>();
  const [modalPreview, setModalPreview] = React.useState<any>();
  const [hasSended, setHasSended] = React.useState<boolean>(false);
  const [image, setImage] = React.useState<any>(null);
  const [crop, setCrop] = React.useState<any>({
    aspect: 16 / 9,
  });
  const [ativeCrops, setAtiveCrop] = React.useState<boolean>(false);
  const [result, setResult] = React.useState<any>(null);
  const [category, setCategory] = React.useState<any[]>([]);
  const [skill, setSkill] = React.useState<any>();
  const [fileName, setFileName] = React.useState<string>();
  const [fileTolTip, setFileTolTip] = React.useState<string>();
  const [backCategories, setBackCategories] = React.useState<string[]>([]);

  React.useEffect(() => {
    getCategories().then((data: any) => {
      setSkill({ ...props, category: data.skills, setState: setCategory });
    });
  }, []);

  React.useEffect(() => {
    if (imageReference) {
      setImagePreview(imageReference);
      setIsEditing(true);
      setValue('image_file_id', imageId);
    }
  }, [imageReference]);

  function getModalStyle() {
    const top = 25;

    return {
      top: `${top}%`,
      margin: 'auto',
      width: '85%',
      height: '95%',
      backgroundColor: 'white',
      marginTop: '1vw',
      overflow: 'hidden',
      overflowY: 'scroll',
    } as React.CSSProperties;
  }
  function onImageLoad(e) {
    setImage(e.currentTarget);
  }
  const handleCrop = async () => {
    setopenModalImg(true);
    try {
      const canvas = document.createElement('canvas');
      const scaleX = image.naturalWidth / image.width;
      const scaleY = image.naturalHeight / image.height;
      canvas.width = crop.width;
      canvas.height = crop.height;
      const ctx: any = canvas.getContext('2d');
      ctx.drawImage(
        image,
        crop.x * scaleX,
        crop.y * scaleY,
        crop.width * scaleX,
        crop.height * scaleY,
        0,
        0,
        crop.width,
        crop.height,
      );
      const base64Image = canvas.toDataURL('image/jpeg', 1);
      setImagePreview(base64Image !== undefined ? base64Image : '');

      setResult(base64Image);

      const url = base64Image;

      fetch(url)
        .then(res => res.blob())
        .then(blob => {
          const file = new File([blob], 'text.png', { type: 'image/png' });
          const formData = new FormData();
          formData.append('file', file !== undefined ? file : '');
          if (category.length > 1) {
            let categoryCopy: any[] = [];
            categoryCopy = category;
            categoryCopy.forEach(el =>
              formData.append('categories[]', el.category_id),
            );
          } else {
            toast({
              message: 'É necessario escolher pelo menos uma categoria filha.',
              type: 'warning',
            });
          }
          if (category.length > 1) {
            uploadFile(formData).then(data => {
              const reader = new FileReader();
              if (files !== undefined) {
                reader.readAsDataURL(files[0]);
              }

              setValue('image_file_id', data.file_id);
              setopenModalImg(false);
              setModalOpen(false);

              setHasSended(true);
              setModalOpen(false);
            });
          }
        });
    } catch (e: any) {
      toast({
        message: e.message,
        type: 'warning',
      });
    }
  };

  const handleSend = async () => {
    const formData = new FormData();

    if (category.length > 1 && files !== undefined) {
      const file = files[0];

      if (file.type.includes('image/svg+xml')) {
        const canvas = document.createElement('canvas');
        canvas.width = image.naturalWidth;
        canvas.height = image.naturalHeight;
        const ctx = canvas.getContext('2d');

        ctx?.drawImage(image, 0, 0, image.naturalWidth, image.naturalHeight);

        const base64Image = canvas.toDataURL('image/png', 1);
        const res = await fetch(base64Image);
        const blob = await res.blob();
        const pngFile = new File([blob], 'text.png', { type: 'image/png' });
        formData.append('file', pngFile);
      } else {
        const blob = file.slice(0, file.size, file.type);
        const newFile = new File(
          [blob],
          fileName + '.' + files[0].name.split('.')[1],
          { type: file.type },
        );
        formData.append('file', newFile);
      }

      let categoryCopy: any[] = [];
      categoryCopy = category;
      categoryCopy.forEach(el =>
        formData.append('categories[]', el.category_id),
      );
    } else {
      toast({
        message: 'É necessario escolher pelo menos uma categoria filha.',
        type: 'warning',
      });
    }

    if (category.length > 1) {
      uploadFile(formData).then(data => {
        const reader = new FileReader();
        if (files !== undefined) {
          reader.readAsDataURL(files[0]);
        }
        reader.onloadend = (e: any) => {
          setImagePreview(reader.result !== undefined ? reader.result : '');
        };
        setValue('image_file_id', data.file_id);
        setHasSended(true);
        setModalOpen(false);
      });
      setModalOpen(false);
    }
  };

  const ativeCrop = async item => {
    if (item === true) {
      const ative = {
        aspect: 1.7777777777777777,
        height: 204.1875,
        unit: 'px',
        width: 363,
        x: 123.921875,
        y: 57.5,
      };
      setAtiveCrop(true);
      setCrop(ative);
    } else {
      const ative = {
        aspect: 1.7777777777777777,
        height: 0,
        unit: 'px',
        width: 0,
        x: 0,
        y: 0,
      };
      setAtiveCrop(false);
      setCrop(ative);
    }
  };

  const deleteFile = async () => {
    setValue('image_file_id', null);
    setImagePreview(undefined);
    setFiles(undefined);
  };

  React.useEffect(() => {
    if (modalOpen) {
      if (props.getValues) {
        if (!fileName && props.getValues('title') === undefined) {
          setFileName('');
          setFileTolTip('');
        } else {
          setFileName('Sebrae Minas - ' + props.getValues('title'));
          setFileTolTip('Sebrae Minas - ' + props.getValues('title'));
        }
      }
    }
  }, [modalOpen]);

  return (
    <styles.ContentSeletor>
      <styles.Label>{label}</styles.Label>

      {files !== undefined && <styles.Span>{files[0].name}</styles.Span>}

      {files !== undefined ||
        (isEditing && <styles.Preview src={imagePreview} />)}

      <Controller
        name={'image_file_id'}
        control={control}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <>
            <ButtonGroup
              style={{
                boxShadow: '0px 0px',
                display: 'flex',
                justifyContent: 'center',
              }}
              variant="contained"
              size="small"
            >
              <Button
                disabled={isView}
                startIcon={<SendAndArchiveIcon />}
                variant="contained"
                color={hasSended ? 'success' : 'primary'}
                size="small"
                onClick={() => setModalOpen(true)}
                style={{
                  backgroundColor: '#008d4c',
                  left: 2,
                }}
              >
                <a>Enviar Imagem</a>
              </Button>
              <Button
                disabled={isView}
                onClick={() => {
                  deleteFile();
                }}
                startIcon={<Delete />}
                style={{
                  backgroundColor: '#d73925',
                  marginLeft: 4,
                }}
              >
                <span>Deletar</span>
              </Button>
            </ButtonGroup>
            {error !== undefined ? (
              <appStyles.inputLabel
                style={{ color: 'red', marginBottom: '0.5rem' }}
              >
                *Campo obrigatório
              </appStyles.inputLabel>
            ) : (
              <></>
            )}
          </>
        )}
      />
      <Modal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{ alignItems: 'center', justifyContent: 'center' }}
      >
        <>
          <Box style={getModalStyle()}>
            <AppBar
              position="static"
              style={{ marginBottom: '1rem' }}
              sx={{ bgcolor: '#008d4c', height: '3.5rem' }}
            >
              <Toolbar>
                <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                  Mídia
                </Typography>
                <Button onClick={() => setModalOpen(false)} color="inherit">
                  x
                </Button>
              </Toolbar>
            </AppBar>

            {files !== undefined ? (
              <>
                <styles.ContentImg>
                  <div>
                    <ReactCrop crop={crop} onChange={setCrop}>
                      <img
                        alt="Crop me"
                        src={modalPreview}
                        onLoad={onImageLoad}
                      />
                    </ReactCrop>
                  </div>
                  <div
                    style={{
                      flexDirection: 'column',
                      justifyContent: 'space-between',
                    }}
                  >
                    <Card
                      style={{
                        width: '18rem',

                        marginRight: '4rem',
                      }}
                    >
                      <span>Dimensões</span>
                      <Divider
                        orientation="horizontal"
                        style={{
                          height: '0.25rem',
                          backgroundColor: '#354371',
                          marginBottom: '0.8rem',
                        }}
                      />
                      <div
                        style={{
                          marginLeft: '1rem',
                        }}
                      >
                        <div
                          style={{
                            marginBottom: '0.5rem',
                          }}
                        >
                          X: {parseFloat(crop.x).toFixed(2)}
                        </div>
                        <div
                          style={{
                            marginBottom: '0.5rem',
                          }}
                        >
                          Y: {parseFloat(crop.y).toFixed(2)}
                        </div>
                        <div
                          style={{
                            marginBottom: '0.5rem',
                          }}
                        >
                          Largura: {parseFloat(crop.width).toFixed(2)}
                        </div>
                        <div
                          style={{
                            marginBottom: '0.5rem',
                          }}
                        >
                          Tamanho: {parseFloat(crop.height).toFixed(2)}
                        </div>
                        <div
                          style={{
                            marginBottom: '0.5rem',
                          }}
                        >
                          Unidade: {crop.unit}
                        </div>
                      </div>
                    </Card>
                  </div>
                </styles.ContentImg>
              </>
            ) : (
              <ImageIcon
                color="disabled"
                fontSize="large"
                sx={{ height: '6em' }}
                style={{
                  marginLeft: 'auto',
                  marginRight: 'auto',
                  width: '6em',
                }}
              />
            )}
            {image && (
              <styles.ContentSeletor>
                <br />
                <styles.Label style={{ marginTop: '1.5rem' }}>
                  Nome da Capa
                </styles.Label>
                <br />
                <Controller
                  name={formName ? formName : 'image_name'}
                  control={control}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <>
                      <styles.InputText
                        disabled={isView}
                        name={formName ? formName : 'image_name'}
                        value={value}
                        onChange={(event: any) => {
                          setFileName(event.target.value);
                          onChange(event);
                        }}
                        required
                        id="outlined-required"
                      />
                      {error !== undefined ? (
                        <appStyles.inputLabel
                          style={{ color: 'red', marginBottom: '0.5rem' }}
                        >
                          *Campo obrigatório
                        </appStyles.inputLabel>
                      ) : (
                        <></>
                      )}
                    </>
                  )}
                />
                <br />
                <styles.Label style={{ marginTop: '1.5rem' }}>
                  Tooltip Capa
                </styles.Label>
                <br />
                <Controller
                  name={formToltip ? formToltip : 'image_toltip'}
                  control={control}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <>
                      <styles.InputText
                        disabled={isView}
                        name={formToltip ? formToltip : 'image_toltip'}
                        value={value}
                        onChange={(event: any) => {
                          setFileTolTip(event.target.value);
                          onChange(event);
                        }}
                        required
                        id="outlined-required"
                      />
                      {error !== undefined ? (
                        <appStyles.inputLabel
                          style={{ color: 'red', marginBottom: '0.5rem' }}
                        >
                          *Campo obrigatório
                        </appStyles.inputLabel>
                      ) : (
                        <></>
                      )}
                    </>
                  )}
                />

                <CategoryDropDown
                  setBackCategories={setBackCategories}
                  backCategories={backCategories}
                  formProps={skill}
                />
              </styles.ContentSeletor>
            )}
            <Stack
              direction="row"
              alignItems="center"
              spacing={2}
              style={{
                marginLeft: 'auto',
                marginRight: 'auto',
                marginBottom: '0.5rem',
              }}
            >
              <Modal
                open={openModalImg}
                onClose={() => setopenModalImg(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box
                  style={{
                    display: 'flex',
                    position: 'absolute',
                    top: 'calc(55% - 150px)',
                    left: 'calc(55% - 150px)',
                    borderRadius: '5px',
                    backgroundColor: 'white',
                  }}
                >
                  <div>
                    <img src={result} alt="cropped image" />
                  </div>
                </Box>
              </Modal>

              <label htmlFor="contained-button-file">
                <input
                  accept="image/*"
                  id="contained-button-file"
                  type="file"
                  style={{ display: 'none' }}
                  onChange={(event: any) => {
                    if (event.target.files[0]?.type?.includes('image/')) {
                      setFiles(event.target.files);
                      const reader = new FileReader();
                      reader.readAsDataURL(event.target.files[0]);
                      reader.onloadend = (e: any) => {
                        setModalPreview(
                          reader.result !== undefined ? reader.result : '',
                        );
                      };
                    } else {
                      toast({
                        message: 'É permitido selecionar somente imagens.',
                        type: 'warning',
                      });
                    }
                  }}
                />
                <div style={{ marginLeft: '1rem' }}>
                  <Button
                    startIcon={<ImageSearch />}
                    size="small"
                    disabled={isView}
                    variant="contained"
                    component="span"
                  >
                    Buscar Arquivos
                  </Button>
                </div>
              </label>

              {image && (
                <Button
                  startIcon={<ContentCut />}
                  disabled={isView}
                  variant="contained"
                  size="small"
                  color="warning"
                  component="span"
                  onClick={() =>
                    crop.x > 0 || ativeCrops
                      ? ativeCrop(false)
                      : ativeCrop(true)
                  }
                >
                  {crop.x > 0 || ativeCrops
                    ? 'Desativar Corte'
                    : 'Ativar Corte'}
                </Button>
              )}
              {crop.height > 0 && (
                <CropButton
                  disabled={category.length < 1}
                  startIcon={<SendAndArchive />}
                  color="success"
                  size="small"
                  variant="contained"
                  onClick={handleCrop}
                >
                  Cortar e enviar
                </CropButton>
              )}
              {image && (
                <Button
                  startIcon={<Send />}
                  disabled={isView || category.length < 1}
                  variant="contained"
                  size="small"
                  color="success"
                  component="span"
                  onClick={handleSend}
                >
                  Enviar sem corte
                </Button>
              )}
            </Stack>
          </Box>
        </>
      </Modal>
    </styles.ContentSeletor>
  );
};

export default UploadFile;
