import { DatePicker } from '@mui/lab';
import * as M from '@mui/material';
import React from 'react';
import { useFormContext } from 'react-hook-form';

import Button from '../../../components/Button';
import Card from '../../../components/FormComponent/Card';
import Form from '../../../components/FormComponent/Form';
import TextField from '../../../components/FormComponent/TextField';
import { formatCnpj, formatCpf } from '../../../utils';
import { FlexContainer } from '../../SearchContent/styles';

type ConsumptionEditTemplateProps = {
  submit: (data) => Promise<void>;
  cancel: () => void;
};

export default function ConsumptionEditTemplate({
  cancel,
  submit,
}: ConsumptionEditTemplateProps) {
  const { watch, handleSubmit, getValues, setValue } = useFormContext();

  const cpfInputMask = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.target.value = formatCpf(e.target.value) || '';
  };

  const cnpjInputMask = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.target.value = formatCnpj(e.target.value) || '';
  };

  return (
    <Card>
      <Form onSubmit={handleSubmit(submit)}>
        <FlexContainer gap="1rem">
          <TextField label="Tipo" name="type" flex="1 0 15rem" maxLength={14} />
          <TextField label="Titulo" name="title" flex="1 0 15rem" />
          <TextField
            label="Nome Realização"
            name="nome_realizacao"
            flex="1 0 15rem"
          />
          <TextField label="Descrição" name="description" flex="1 0 15rem" />
          <TextField
            label="CPF do Cliente"
            name="customer"
            flex="1 0 15rem"
            maxLength={14}
            onChange={cpfInputMask}
            disabled
          />
          <TextField
            label="CPF Responsável"
            name="cpf_responsavel"
            flex="1 0 15rem"
          />
          <TextField label="Credencial" name="credential" flex="1 0 15rem" />
          {!!getValues('company') && (
            <TextField
              label="CNPJ da Empresa"
              name="company"
              flex="1 0 15rem"
              onChange={cnpjInputMask}
              maxLength={14}
            />
          )}
          <TextField
            label="Data Inicial"
            name="date_hour_start"
            flex="1 0 10rem"
          >
            <DatePicker
              value={watch('date_hour_start') || null}
              onChange={date => setValue('date_hour_start', date)}
              renderInput={params => <M.TextField {...params} />}
            />
          </TextField>
          <TextField label="Data Final" name="date_hour_end" flex="1 0 10rem">
            <DatePicker
              value={watch('date_hour_end') || null}
              onChange={date => setValue('date_hour_end', date)}
              renderInput={params => <M.TextField {...params} />}
            />
          </TextField>
          <TextField
            label="Carga Horária"
            name="carga_horaria"
            flex="1 0 15rem"
            type="number"
          />
          <TextField label="Instrumento" name="instrumento" flex="1 0 15rem" />
          <TextField label="Id do Tema" name="theme_id" flex="1 0 15rem" />
          <TextField
            label="Id Origem"
            name="origin_id"
            flex="1 0 15rem"
            type="number"
          />
          <TextField
            label="Código do Projeto"
            name="cod_projeto"
            flex="1 0 15rem"
          />
          <TextField
            label="Código de Integração"
            name="code_integration"
            flex="1 0 15rem"
          />
          <TextField label="Código de Ação" name="cod_acao" flex="1 0 15rem" />
        </FlexContainer>

        <FlexContainer gap="1rem" justifyContent="flex-end">
          <Button
            type="button"
            onClick={cancel}
            variant="contained"
            color="secondary"
          >
            Cancelar
          </Button>
          <Button type="submit" variant="contained" color="blue">
            Enviar
          </Button>
        </FlexContainer>
      </Form>
    </Card>
  );
}
