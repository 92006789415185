import styled, { css } from 'styled-components';

import { SimpleModalProps } from '.';

export type ModalWrapperProps = {
  isOpen: boolean;
};

export const Overlay = styled.div`
  ${({ theme }) => css`
    background-color: rgba(0, 0, 0, 0.16);
    z-index: ${theme.layers.overlay};

    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  `}
`;

type ModalContentProps = Pick<SimpleModalProps, 'width'>;

const modalContentModifiers = {
  determinesWith: (width: string) => css`
    width: ${width};
  `,
};

export const ModalContent = styled.div<ModalContentProps>`
  ${({ theme, width }) => css`
    background-color: ${theme.colors.white};
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    justify-content: flex-start;
    position: relative;
    padding: 20px;
    right: 0;
    z-index: ${theme.layers.alwaysOnTop};

    ${width && modalContentModifiers.determinesWith(width)};
  `}
`;

export const CloseButton = styled.button`
  background-color: transparent;
  border: 0;
  cursor: pointer;

  position: absolute;
  right: 20px;

  svg {
    color: #6c6e6f;
  }
`;

const modalWrapperModifiers = {
  open: () => css`
    opacity: 1;
    pointer-events: auto;
    transform: translate(0);
  `,
  close: () => css`
    opacity: 0;
    pointer-events: none;
    transform: translate(-36px);
  `,
};

export const ModalWrapper = styled.div<ModalWrapperProps>`
  ${({ theme, isOpen }) => css`
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: ${theme.layers.modal};

    transition: transform 0.3s ease-out, opacity ${theme.transition.default};

    ${Overlay},
    ${ModalContent} {
      ${isOpen && modalWrapperModifiers.open()};
      ${!isOpen && modalWrapperModifiers.close()};
    }
  `}
`;

export const ModalHeader = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  min-width: 20rem;
  padding-bottom: 1rem;
`;

export const ModalBody = styled.div`
  height: 100%;
  position: relative;
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
`;

export const ButtonContainer = styled.div`
  display: flex;
  gap: 1rem;
`;
