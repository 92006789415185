import Swal from 'sweetalert2';

import { IMetaDataProps } from '../common/interfaces/metaData';
import { IParams } from '../common/interfaces/params';
import httpClient from '../http-client';
import { ITopic } from '../models/topic';

const getTopics = async (topicsId: string): Promise<any> => {
  const topics = (await httpClient.get(`topic/${topicsId}`)).data;

  return topics;
};

const getAllTopics = async (
  params?: IParams,
): Promise<{
  data: ITopic[];
  metaData: IMetaDataProps;
}> => {
  const topics = (await httpClient.get(`/topic`, { params })).data;

  return { data: topics.topics, metaData: topics.metaData };
};

const createTopics = async (newtopic: {
  name: string;
}): Promise<ITopic | undefined> => {
  try {
    const createtopicsResponse = (await httpClient.post(`topic`, newtopic))
      .data;

    return createtopicsResponse;
  } catch (error: any) {
    if (error.response) {
      throw new Error(error.response.data.message);
    }

    Swal.fire({
      icon: 'error',
      title: 'Erro ao criar o tópico',
      text: `${error.response.data.message}`,
    });

    throw new Error(error.message);
  }
};

const updateTopics = async (topicsId: string, newContentTab: any) => {
  await httpClient.put(`topic/${topicsId}`, newContentTab);
};

const deleteTopics = async (topicsId: string) => {
  await httpClient.delete(`topic/${topicsId}`);
};

const verifyTopics = async (topicsId: any, verify: boolean) => {
  await httpClient.put(`topic/${topicsId}/verified`);
};

export {
  createTopics,
  deleteTopics,
  getAllTopics,
  getTopics,
  updateTopics,
  verifyTopics,
};
