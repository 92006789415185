import { zodResolver } from '@hookform/resolvers/zod';
import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import { z } from 'zod';

import { collectionSchema } from '../../../../common/validations/collectionSchema';
import { ICollection } from '../../../../models/collection';
import {
  getCollection,
  updateCollection,
} from '../../../../services/collections';
import CollectionTemplate from '../../../../templates/CollectionTemplate';

export type CollectionFormValues = z.infer<typeof collectionSchema>;

export default function CollectionEdit() {
  const [loading, setLoading] = useState(false);
  const [content, setContent] = useState<ICollection>();

  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const methods = useForm<CollectionFormValues>({
    resolver: zodResolver(collectionSchema),
  });

  const getContent = async (id: string | undefined): Promise<void> => {
    if (!id) {
      Swal.fire({
        icon: 'error',
        iconColor: '#f5365c',
        title: 'Erro ao buscar a coleção!',
        text: 'O id passado na url é inválido',
        buttonsStyling: false,
        customClass: {
          title: 'modal-title',
          htmlContainer: 'modal-html-container',
          confirmButton: 'btn-ok',
        },
      }).then(() => navigate('/content/exclusive'));
      return;
    }

    setLoading(true);
    try {
      const { setValue } = methods;
      const resp = await getCollection(id);

      setValue(
        'authors',
        resp.authors.map(author => author.author_id),
      );
      setValue('name', resp.name);
      setValue('slug', resp.slug);
      setValue(
        'contents',
        resp.contents.map(content => content.content_id),
      );
      setValue('description', resp.description);
      setValue(
        'featured_start_at',
        resp.featured_start_at !== null
          ? new Date(resp.featured_start_at)
          : null,
      );
      setValue(
        'featured_end_at',
        resp.featured_end_at !== null ? new Date(resp.featured_end_at) : null,
      );
      setValue('thumbnail_file_id', resp.thumbnail_file_id);
      setValue('thumbnail_file', resp.thumbnail_file);
      setValue('thumbnail_name', resp.thumbnail_name);
      setValue('thumbnail_tooltip', resp.thumbnail_tooltip);
      setValue('image_file', resp.image_file);
      setValue('image_file_id', resp.image_file_id);
      setValue('image_name', resp.image_name);
      setValue('image_toltip', resp.image_toltip);
      setValue('resume', resp.resume);
      setValue('categories', resp.categories);

      setContent(resp);
    } catch (error: any) {
      console.error(error);
      Swal.fire({
        icon: 'error',
        iconColor: '#f5365c',
        title: 'Erro ao buscar o conteúdo!',
        text: error.message || error,
        customClass: {
          confirmButton: 'btn-ok',
        },
      });
    } finally {
      setLoading(false);
    }
  };

  const submit = (data: CollectionFormValues) => {
    if (Object.keys(data).includes('image_name')) delete data['image_name'];
    if (Object.keys(data).includes('image_toltip')) delete data['image_toltip'];
    if (Object.keys(data).includes('image_file')) delete data['image_file'];
    if (Object.keys(data).includes('thumbnail_file'))
      delete data['thumbnail_file'];
    if (data['authors'] && !data['authors'].length) delete data['authors'];

    updateCollection(searchParams.get('id')!, data)
      .then(() => {
        Swal.fire({
          title: 'Coleção Alterada com sucesso',
          icon: 'success',
          customClass: {
            title: 'modal-title',
            htmlContainer: 'modal-html-container',
            confirmButton: 'btn-ok',
          },
          buttonsStyling: false,
        }).then(() => navigate(-1));
      })
      .catch(error => {
        Swal.fire({
          icon: 'error',
          title: 'Algo deu errado',
          iconColor: '#f5365c',
          text: error.message,
          customClass: {
            title: 'modal-title',
            htmlContainer: 'modal-html-container',
            confirmButton: 'btn-ok',
          },
          buttonsStyling: false,
        });
      });
  };

  useEffect(() => {
    getContent(searchParams.get('id')!);
  }, []);

  return (
    <FormProvider {...methods}>
      <CollectionTemplate
        title="Edição da Coleção"
        isLoading={loading}
        view={searchParams.get('view') === 'true'}
        content={content}
        submit={submit}
      />
    </FormProvider>
  );
}
