import { Breadcrumbs, Link as MaterialLink, Typography } from '@mui/material';
import React from 'react';

import CategoryRow from '../../../components/CategoryRow';
import { getCategories } from '../../../services/categorization';

export default function CategorizationPage() {
  const [skills, setSkills] = React.useState<any>();
  const [moments, setMoments] = React.useState<any>();
  const [sectors, setSectors] = React.useState<any>();

  React.useEffect(() => {
    getCategories().then((data: any) => {
      setSkills(data.skills);
      setMoments(data.moments);
      setSectors(data.sectors);
    });
  }, []);

  return (
    <div style={{ width: '100%' }}>
      <Breadcrumbs style={{ margin: '2rem 0' }}>
        <MaterialLink underline="hover" color="inherit" href="/">
          Home
        </MaterialLink>
        <MaterialLink underline="hover" color="inherit" href="/profile">
          Cadastros
        </MaterialLink>
        <MaterialLink
          underline="hover"
          color="text.primary"
          href="/Author"
          aria-current="page"
        >
          Categorização
        </MaterialLink>
      </Breadcrumbs>
      <Typography
        variant="h5"
        gutterBottom
        component="div"
        color="primary"
        style={{ margin: '1.2rem 0' }}
      >
        Categorização
      </Typography>

      <hr style={{ color: 'lightgray' }} />
      <div
        style={{ display: 'flex', flexDirection: 'column', margin: '0.5rem' }}
      >
        <form
          noValidate
          style={{ display: 'flex', flexDirection: 'column', margin: '1rem' }}
        >
          <CategoryRow category={skills} setCategory={setSkills} />
          <CategoryRow category={sectors} setCategory={setSectors} />
          <CategoryRow category={moments} setCategory={setMoments} />
        </form>
      </div>
    </div>
  );
}
