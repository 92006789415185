import styled, { css, DefaultTheme } from 'styled-components';

export const Wrapper = styled.div`
  ${({ theme }) => css`
    height: 100%;
    width: 100%;
    position: relative;

    & > h1 {
      border-bottom: 1px solid ${theme.colors.neutral_300};
      padding: 24px 0;
    }
  `}
`;

export const List = styled.div`
  ${({ theme }) => css`
    border-bottom: 1px solid ${theme.colors.neutral_300};
    display: flex;
    padding-top: 0.75rem;
    position: relative;
  `}
`;

type TabProps = {
  active: boolean;
};

const TabModiffiers = {
  true: (theme: DefaultTheme) => css`
    border-bottom: 2px solid white;
    color: ${theme.colors.default};
    font-weight: ${theme.font.semiBold};
    margin-bottom: -1px;
  `,
  false: (theme: DefaultTheme) => css`
    color: ${theme.colors.neutral_500};
    box-shadow: rgba(143, 149, 178, 0.3) 0px -16px 26px -26px inset;
  `,
};

export const Tab = styled.button<TabProps>`
  ${({ theme, active, disabled }) => css`
    background-color: ${theme.colors.white};
    border: 1px solid ${theme.colors.neutral_300};
    border-bottom: 0;
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
    cursor: ${disabled ? 'not-allowed' : 'pointer'};
    font-size: ${theme.font.sizes.xxsmall};
    line-height: 1rem;
    padding: 1rem;

    ${!!active && TabModiffiers.true(theme)}
    ${!active && TabModiffiers.false(theme)};
  `}
`;
