import { zodResolver } from '@hookform/resolvers/zod';
import React, { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import { z } from 'zod';

import { authorSchema } from '../../../../common/validations/authorValidation';
import Card from '../../../../components/FormComponent/Card';
import { socialLinks } from '../../../../constants/authorSocialLinks';
import { IAuthor } from '../../../../models/author';
import {
  createAuthor,
  getAuthor,
  updateAuthor,
} from '../../../../services/authors';
import AuthorTemplate from '../../../../templates/AuthorTemplate';
import { formatCpf, removeTraitPoints } from '../../../../utils';

export type AuthorFormValues = z.infer<typeof authorSchema>;

export default function CreateAndEditAuthors() {
  const [author, setAuthor] = React.useState<IAuthor>();

  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const methods = useForm<AuthorFormValues>({
    resolver: zodResolver(authorSchema),
    defaultValues: { social_links: socialLinks },
  });

  const authorId = new URLSearchParams(location.search).get('id');
  const view = new URLSearchParams(location.search).get('view');
  const { setValue, getValues } = methods;

  const getContent = async () => {
    await getAuthor(searchParams.get('id')).then(result => {
      setAuthor(result);
      setValue('file_id', result.file_id);
      setValue('type', result.type);
      setValue('name', result.name);
      setValue('email', result.email);
      setValue('cpf', formatCpf(result.cpf!));
      setValue('image_file', result.file);
      setValue('image_file_id', result.file_id);
      setValue('description', result.description);
      setValue('tooltip', result.tooltip);
      setValue('occupation', result.occupation || '');
      setValue('is_email_cpf_blocked', result.is_email_cpf_blocked || false);
      setValue('social_links', !result.social_links ? {} : result.social_links);
      setValue('slug', result.slug);
      setValue('company', result.company);
      setValue('company_id', result.company_id);
    });
  };

  useEffect(() => {
    if (searchParams.get('id') !== null) {
      getContent();
    } else {
      setValue('type', 'person');
    }
  }, []);

  const submit = (
    data: AuthorFormValues,
    status: 'published' | 'draft',
    submitFunc,
  ) => {
    const obj = {};
    obj['status'] = status;
    obj['file_id'] = data.image_file_id;

    delete data['file_name'];
    delete data['file_toltip'];
    delete data['image_file_id'];
    delete data['image_file'];
    delete data['file_id'];
    delete data['company'];

    Object.keys(data.social_links).forEach(key => {
      if (
        data.social_links[key] !== null &&
        data.social_links[key].length === 0
      ) {
        data.social_links[key] = null;
      }
    });

    if (data['occupation'] !== null && data['occupation']?.length === 0) {
      delete data['occupation'];
    }

    if (data['occupation']?.length !== 0 && data['type'] === 'company') {
      delete data['occupation'];
    }

    if (getValues('type') === 'company') {
      delete data['company_id'];
    }

    if (data['cpf'] && data['cpf'] !== undefined) {
      data['cpf'] = removeTraitPoints(data['cpf']);
    }

    if (
      (Object.keys(data).includes('email') && data['email'] === null) ||
      (Object.keys(data).includes('email') && data['email'] === '')
    ) {
      delete data['email'] === null;
    }

    if (
      (Object.keys(data).includes('cpf') && data['cpf'] === null) ||
      (Object.keys(data).includes('cpf') && data['cpf'] === '')
    ) {
      delete data['cpf'] === null;
    }

    Object.assign(obj, data);
    submitFunc(obj);
  };

  const createSubmit = async data => {
    try {
      if (data['tooltip'] === null || data['tooltip'] === '') {
        data['tooltip'] = null;
      }

      await createAuthor(data).then(() => {
        Swal.fire({
          title: 'Autor criado com sucesso',
          icon: 'success',
        });
        navigate(-1);
      });
    } catch (error: any) {
      Swal.fire({
        icon: 'error',
        title: 'Algo deu errado',
        text: error.response.data.message,
        iconColor: '#f5365c',
        confirmButtonColor: '#5e72e4',
      });
    }
  };

  const editSubmit = async data => {
    try {
      data['tooltip'] = data.name;
      await updateAuthor(authorId!, data).then(() => {
        Swal.fire({
          title: 'Autor alterado com sucesso',
          icon: 'success',
        });
        navigate(-1);
      });
    } catch (error: any) {
      Swal.fire({
        icon: 'error',
        title: 'Algo deu errado',
        text: error.response.data.message,
        iconColor: '#f5365c',
        confirmButtonColor: '#5e72e4',
      });
    }
  };

  const returnPage = async () => {
    Swal.fire({
      title: '<strong>Confirmação</strong>',
      html: 'Tem certeza que deseja cancelar as alterações??',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      focusConfirm: false,
    }).then(async result => {
      if (result.isConfirmed) {
        navigate(-1);
      }
    });
  };

  return (
    <Card title="Cadastro / Edição do Autor">
      <FormProvider {...methods}>
        <AuthorTemplate
          view={view === 'true'}
          submitHandler={(data, status) => {
            authorId
              ? submit(data, status, editSubmit)
              : submit(data, status, createSubmit);
          }}
          returnPage={returnPage}
        />
      </FormProvider>
    </Card>
  );
}
