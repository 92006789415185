import styled, { css } from 'styled-components';

export const Wrapper = styled.section`
  align-items: center;
  display: flex;
  justify-content: center;
  height: 100vh;
  width: 100vw;
`;

export const LoginForm = styled.form`
  ${({ theme }) => css`
    border-radius: 0.5rem;
    box-shadow: ${theme.shadow.elevetion_3};
    background-color: ${theme.colors.primary};
    color: ${theme.colors.white};
    display: flex;
    flex-direction: column;
    margin: 1rem;
    max-width: 25rem;
    padding: 2rem;
    width: 100%;

    img {
      align-self: center;
    }
  `}
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  margin-top: 2.5rem;
`;
