import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;

    .select__control {
      border: 0;
      border-bottom: 1px solid;
      border-color: ${theme.colors.gray_300};
      border-radius: 0;

      :hover {
        border-color: ${theme.colors.gray};
      }
    }

    .select__control--is-focused {
      border-radius: 4px;
      border-color: ${theme.colors.primary};
      box-shadow: 0 0 0 2px ${theme.colors.primary};
    }

    .select__indicator {
      color: ${theme.colors.gray_700};
    }

    .select__indicator-separator {
      display: none;
    }

    .select__menu {
      background-color: ${theme.colors.white};
      z-index: 2;
    }
  `}
`;

export const Label = styled.label`
  ${({ theme }) => css`
    color: ${theme.colors.gray_700};
    font-size: 14px;
  `}
`;
