import httpClient from "../http-client";
export interface IEpisode{
    file_id: string;
    name?: string;
    description?: string;
    duration?: number;
}

export const createEpisode = async (episode: IEpisode): Promise<any> => {
    const uploadFileResponse = (
      await httpClient.post(`episodes`, episode)
    ).data;
  
    return uploadFileResponse;
}