import styled from 'styled-components';
import { css } from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const TextContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 2rem;
`;

export const ButtonContainer = styled.div`
  display: flex;
  gap: 0.5rem;
`;

export const ModalContent = styled.div`
  background-color: white;
  display: flex;
  flex-direction: column;
  height: 95%;
  margin: 1vw auto;
  width: 85%;
`;

export const InvalidFeedback = styled.span`
  ${({ theme }) => css`
    color: ${theme.colors.red_400};
    font-size: 0.75rem;
    margin-bottom: 1rem;
  `}
`;

export const ModalHeader = styled.div`
  ${({ theme }) => css`
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding: 1.25rem;

    button {
      background-color: transparent;
      border: 0;
      color: ${theme.colors.gray};
      cursor: pointer;
      font-size: 1.25rem;
      font-weight: ${theme.font.semiBold};
    }
  `}
`;
