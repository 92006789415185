import { IContentFile } from '../models/content';

export async function loadFileToInput(fileData: IContentFile | undefined) {
  if (!fileData) return null;

  const response = await fetch(fileData.reference);
  const blob = await response.blob();
  const file = new File([blob], fileData.original_name, {
    type: fileData.mime_type,
  });

  const dataTransfer = new DataTransfer();
  dataTransfer.items.add(file);

  return dataTransfer.files;
}
