import { z } from 'zod';

import { categorySchema } from './categoryValidation';
import { imageSchema } from './imageValidation';
import { knowledgesSchema } from './knowledgesValidation';
import { optionSelectSchema } from './optionSelectValidation';
import { TagSchema } from './tagValidation';
import { TopiSchema } from './topicValidation';

export const serieSchema = z
  .object({
    allow_download: z.boolean().nullable().optional(),
    authors: z.array(z.string()),
    categories: z
      .array(z.union([z.string(), categorySchema]), {
        required_error: 'Este campo é obrigatório',
      })
      .refine(val => {
        const isArrayOfStrings = val.every(item => typeof item === 'string');
        const isArrayOfObjects = val.every(
          item => categorySchema.safeParse(item).success,
        );

        return isArrayOfStrings || isArrayOfObjects;
      }),
    company_units: z
      .array(optionSelectSchema, {
        required_error: 'Este campo é obrigatório',
      })
      .superRefine((val, ctx) => {
        if (!val.length) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: 'Este campo é obrigatório',
          });
        }
      }),
    complement_files: z.string().array().optional(),
    complement_contents: z.any().array().optional(),
    description: z.string().nonempty('Este campo é obrigatório'),
    duration: z.number().nullable().optional(),
    featured_start_at: z.date().nullable().optional(),
    featured_end_at: z.date().nullable().optional(),
    file_name: z.any().nullable().optional(),
    file_toltip: z.any().nullable().optional(),
    groups: z.array(optionSelectSchema).nullable().optional(),
    image_file: imageSchema.nullable().optional(),
    image_file_id: z.string().uuid().nullable().default(null).optional(),
    image_name: z.string().nullable().default(null).optional(),
    image_toltip: z.string().nullable().default(null).optional(),
    knowledges: z.array(knowledgesSchema, {
      required_error: 'Este campo é obrigatório',
    }),
    modules: z.array(z.string()).superRefine((val, ctx) => {
      if (!val.length) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Este campo é obrigatório',
        });
      }
    }),
    page_title: z
      .string()
      .nonempty('Este campo é obrigatório')
      .max(48, 'O título da página não poder conter mais que 48 caracteres'),
    published_start_at: z
      .date({ required_error: 'Este campo é obrigatório' })
      .nullable(),
    published_end_at: z
      .date({ required_error: 'Este campo é obrigatório' })
      .nullable(),
    review_in: z.date({
      required_error: 'Este campo é obrigatório',
      invalid_type_error: 'Data inválida',
    }),
    resume: z
      .string()
      .nonempty('Este campo é obrigatório')
      .max(155, 'O resumo não poder conter mais que 155 caracteres'),
    requester_id: z.string().uuid().nonempty('Este campo é obrigatório'),
    requester: z.object(
      {
        label: z.string(),
        value: z.string(),
      },
      { required_error: 'Este campo é obrigatório' },
    ),
    slug: z
      .string()
      .nonempty('Este campo é obrigatório')
      .max(65, 'O slug da página não poder conter mais que 65 caracteres'),
    subtitle: z
      .string()
      .nonempty('Este campo é obrigatório')
      .max(
        160,
        'O subtítulo da página não poder conter mais que 160 caracteres',
      ),
    reference: z.string().optional().nullable(),
    tags: z.array(TagSchema, { required_error: 'Este campo é obrigatório' }),
    title: z
      .string()
      .nonempty('Este campo é obrigatório')
      .max(70, 'O título do curso não poder conter mais que 70 caracteres'),
    topics: z.array(TopiSchema, { required_error: 'Este campo é obrigatório' }),
    visibility: z.string({ required_error: 'Este campo é obrigatório' }),
  })
  .superRefine((val, ctx) => {
    if (val.visibility === 'restricted' && !val.groups?.length) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'Este campo é obrigatório',
        path: ['groups'],
      });
    }
  });
