import { zodResolver } from '@hookform/resolvers/zod';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import { z } from 'zod';

import { moderatorSchema } from '../../../common/validations/moderatorValidation';
import { createModerator } from '../../../services/moderator';
import ModeratorTemplate from '../../../templates/ModeratorTemplate';
import { removeTraitPoints } from '../../../utils';

export type ModeratorFormValues = z.infer<typeof moderatorSchema>;

export default function ModeratorCreate() {
  const navigate = useNavigate();
  const methods = useForm<ModeratorFormValues>({
    resolver: zodResolver(moderatorSchema),
  });

  const handleCancel = () => {
    Swal.fire({
      icon: 'question',
      title: 'Confirmação',
      html: 'Tem certeza que deseja cancelar o cadastro?',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      reverseButtons: true,
      focusConfirm: true,
      customClass: {
        title: 'modal-title',
        htmlContainer: 'modal-html-container',
        confirmButton: 'btn-ok',
        cancelButton: 'btn-cancel',
      },
      buttonsStyling: false,
    }).then(async result => {
      if (result.isConfirmed) {
        methods.reset();
        navigate(-1);
      }
    });
  };

  const handleSubmit = async (data: ModeratorFormValues) => {
    data['cpf'] = removeTraitPoints(data['cpf'])!;

    createModerator(data)
      .then(() => {
        Swal.fire({
          title: 'Moderador criado com sucesso',
          icon: 'success',
          customClass: {
            title: 'modal-title',
            htmlContainer: 'modal-html-container',
            confirmButton: 'btn-ok',
          },
          buttonsStyling: false,
        }).then(() => navigate(-1));
      })
      .catch(error => {
        Swal.fire({
          icon: 'error',
          title: 'Algo deu errado ao criar o moderador',
          iconColor: '#f5365c',
          text: error.message,
          customClass: {
            title: 'modal-title',
            htmlContainer: 'modal-html-container',
            confirmButton: 'btn-ok',
          },
          buttonsStyling: false,
        });
      });
  };

  return (
    <FormProvider {...methods}>
      <ModeratorTemplate submit={handleSubmit} cancel={handleCancel} />
    </FormProvider>
  );
}
