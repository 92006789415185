import { AddCircle, Article, Delete, Save } from '@mui/icons-material';
import { DateTimePicker, LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import {
  Box,
  ButtonGroup,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Modal,
  TextField,
  Typography,
} from '@mui/material';
import moment from 'moment';
import React, { useEffect } from 'react';

import Button from '../../Button';
import { FlexContainer } from '../../FlexContainer';
import * as styles from './styles';

const ITEM_HEIGHT = 20;
const ITEM_PADDING_TOP = 100;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const currencies = ['none', 'standard', 'large'];

export default function MainFile(props: any) {
  const [modalOpen, setModalOpen] = React.useState<boolean>(false);
  const [edit, setEdit] = React.useState<boolean>(false);
  const [containItem, setContainItem] = React.useState<boolean>(false);
  const [checkedIndex, setCheckedIndex] = React.useState<boolean>(false);
  const [checkedFollow, setCheckedFollow] = React.useState<boolean>(false);
  const [indexFollow, setCheckedIndexFollow] = React.useState<boolean>(false);
  const [noIndexFollow, setCheckedNoIndexFollow] =
    React.useState<boolean>(false);
  const [noSnippet, setCheckedNoSnippet] = React.useState<boolean>(false);
  const [noArchive, setCheckedNoArchive] = React.useState<boolean>(false);
  const [noImageIndex, setCheckedNoImageIndex] = React.useState<boolean>(false);
  const [max_snippet, setMax_snippet] = React.useState<number>();
  const [max_video_preview, setMax_video_preview] = React.useState<number>();
  const [max_image_preview, setMax_image_preview] = React.useState<string>();
  const [newDataMetaTag, setDataMetaTag] = React.useState<string>();
  const [newData, setData] = React.useState<Date | null>(new Date());
  const [save, setSave] = React.useState<any>({});

  const fillTags = () => {
    const editingTags = props.content.metatags;

    const metatag = save;
    metatag.index = editingTags.index;
    metatag.follow = editingTags.follow;
    metatag.index_follow = editingTags.index_follow;
    metatag.no_index_follow = editingTags.no_index_follow;
    metatag.no_snippet = editingTags.no_snippet;
    metatag.no_archive = editingTags.no_archive;
    metatag.no_image_index = editingTags.no_image_index;
    metatag.unavailable_after = editingTags.unavailable_after;
    metatag.max_snippet = editingTags.max_snippet;
    metatag.max_video_preview = editingTags.max_video_preview;
    metatag.max_image_preview = editingTags.max_image_preview;

    handleChangeIndex(metatag.index);
    handleChangeFollow(metatag.follow);
    handleChangeIndexFollow(metatag.index_follow);
    handleChangeNoIndexFollow(metatag.no_index_follow);
    handleChangeNoSnippet(metatag.no_snippet);
    handleChangeINoArchive(metatag.no_archive);
    handleChangeNoImageIndex(metatag.no_image_index);
    onDatePicked(metatag.unavailable_after);
    setMax_snippet(metatag.max_snippet);
    setMax_video_preview(metatag.max_video_preview);
    setMax_image_preview(metatag.max_image_preview);

    setContainItem(true);
  };

  useEffect(() => {
    if (!!props?.content && Object.keys(props?.content).includes('metatags')) {
      fillTags();
    }
  }, []);

  const handleChangeIndex = event => {
    setCheckedIndex(event);
  };

  const handleChangeFollow = event => {
    setCheckedFollow(event);
  };

  const handleChangeIndexFollow = event => {
    setCheckedIndexFollow(event);
  };
  const handleChangeNoIndexFollow = event => {
    setCheckedNoIndexFollow(event);
  };

  const handleChangeNoSnippet = event => {
    setCheckedNoSnippet(event);
  };

  const handleChangeINoArchive = event => {
    setCheckedNoArchive(event);
  };

  const handleChangeNoImageIndex = event => {
    setCheckedNoImageIndex(event);
  };

  const onDatePicked = event => {
    setData(event);
    const newDate = event;
    const data: any = moment(newDate).format('YYYY-MM-DD HH:MM:SS');
    setDataMetaTag(data);
  };

  const saveMetaTag = async () => {
    const metatag = save;
    metatag.index = checkedIndex;
    metatag.follow = checkedFollow;
    metatag.index_follow = indexFollow;
    metatag.no_index_follow = noIndexFollow;
    metatag.no_snippet = noSnippet;
    metatag.no_archive = noArchive;
    metatag.no_image_index = noImageIndex;
    metatag.unavailable_after = newDataMetaTag;
    metatag.max_snippet = max_snippet;
    metatag.max_video_preview = max_video_preview;
    metatag.max_image_preview = max_image_preview;

    setSave(metatag);
  };

  const handleSave = async () => {
    saveMetaTag();
    props.setValue('metatags', save);
    setModalOpen(false);
    setContainItem(true);
  };

  const handleDelete = async () => {
    setSave({});
    props.setValue('metatags', {});
    setContainItem(false);
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        padding: '22px',
        width: '100%',
      }}
    >
      <Typography variant="h5" style={{ marginBottom: '16px' }}>
        Metatag Robots
      </Typography>
      <Box
        component="div"
        sx={{
          '& .MuiTextField-root': { width: '100%' },
        }}
      >
        <ListItemButton
          style={{ background: '#c9c9c9', marginBottom: '0.8rem' }}
          onClick={() => {
            setModalOpen(true);
            setEdit(true);
          }}
        >
          <ListItemIcon>
            <Article />
          </ListItemIcon>
          {containItem ? (
            <ListItemText primary="Metatag" />
          ) : (
            <ListItemText primary="Nenhum registro." />
          )}
        </ListItemButton>
      </Box>
      <FlexContainer gap="0.875rem">
        <FlexContainer gap="0.875rem">
          {containItem && (
            <Button
              variant="outlined"
              color="secondary"
              type="button"
              disabled={props.view}
              onClick={() => handleDelete()}
              startIcon={<Delete />}
            >
              <span>Deletar</span>
            </Button>
          )}
          <Button
            variant="contained"
            type="button"
            disabled={props.view}
            startIcon={<AddCircle />}
            onClick={() => {
              setModalOpen(true);
              setEdit(false);
            }}
          >
            <span>Adicionar novo</span>
          </Button>
        </FlexContainer>
      </FlexContainer>

      <Modal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          style={{
            backgroundColor: 'white',
            position: 'absolute',
            top: '10%',
            left: '15%',
            height: '80%',
            width: '70%',
            display: 'block',
            overflow: 'scroll',
          }}
        >
          <form
            style={{
              display: 'flex',
              flexDirection: 'column',
              margin: '0.5rem',
              padding: '0.8rem',
            }}
          >
            <Box
              component="div"
              sx={{
                '& .MuiTextField-root': { m: 0, width: '100%' },
              }}
            >
              <styles.Label>
                {edit ? 'Editar Metatag Robots' : 'Metatag Robots'}
              </styles.Label>
              <Divider
                orientation="horizontal"
                style={{
                  height: '0.25rem',
                  backgroundColor: '#354371',
                  width: '75%',
                  marginBottom: 10,
                }}
              />
            </Box>
            <styles.ContentSeletor>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={checkedIndex}
                      onChange={event =>
                        handleChangeIndex(event.target.checked)
                      }
                    />
                  }
                  label={
                    <styles.Label>
                      {'Indexar a página? (index/noindex)'}
                    </styles.Label>
                  }
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={checkedFollow}
                      onChange={event =>
                        handleChangeFollow(event.target.checked)
                      }
                    />
                  }
                  label={
                    <styles.Label>
                      {'Seguir os links? (follow/nofollow)'}
                    </styles.Label>
                  }
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={noIndexFollow}
                      onChange={event =>
                        handleChangeNoIndexFollow(event.target.checked)
                      }
                    />
                  }
                  label={
                    <styles.Label>
                      {'Equivalente a ' +
                        "'noindex, nofollow'" +
                        '.' +
                        '(none)'}
                    </styles.Label>
                  }
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={indexFollow}
                      onChange={event =>
                        handleChangeIndexFollow(event.target.checked)
                      }
                    />
                  }
                  label={
                    <styles.Label>
                      {'Equivalente a ' + "'index, follow'" + '.' + '(all)'}
                    </styles.Label>
                  }
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={noSnippet}
                      onChange={event =>
                        handleChangeNoSnippet(event.target.checked)
                      }
                    />
                  }
                  label={
                    <styles.Label>
                      {
                        'Não exibe um snippet de texto ou visualização de vídeo nos resultados da pesquisa. (nosnippet)'
                      }
                    </styles.Label>
                  }
                />
              </FormGroup>
              <Box
                component="div"
                sx={{
                  '& .MuiTextField-root': { m: 0, width: '100%' },
                }}
              >
                <styles.Label>
                  Limita o comprimento do snippet de texto da página a X
                  caracteres. (max-snippet)
                </styles.Label>

                <styles.InputText
                  type="number"
                  name="max_snippet"
                  value={max_snippet}
                  onChange={event => {
                    const num = Number(event.target.value);
                    setMax_snippet(num);
                  }}
                  required
                  id="outlined-required"
                />
              </Box>
              <Box
                component="div"
                sx={{
                  '& .MuiTextField-root': { m: 0, width: '100%' },
                }}
              >
                <styles.Label>
                  Limita o tamanho de qualquer imagem associada a esta página.
                  (max-image-preview)
                </styles.Label>

                <FormControl sx={{ m: 1, width: '100%' }}>
                  <styles.SelectorSelect
                    labelId="demo-multiple-name-label"
                    id="demo-multiple-name"
                    name="max_image_preview"
                    value={max_image_preview}
                    onChange={event => {
                      const item = String(event.target.value);
                      setMax_image_preview(item);
                    }}
                    MenuProps={MenuProps}
                  >
                    {currencies.map(name => (
                      <styles.MenuItemSelector key={name} value={name}>
                        {name}
                      </styles.MenuItemSelector>
                    ))}
                  </styles.SelectorSelect>
                </FormControl>
              </Box>
              <Box
                component="div"
                sx={{
                  '& .MuiTextField-root': { m: 0, width: '100%' },
                }}
              >
                <styles.Label>
                  Limita qualquer visualização de vídeo associada à página a X
                  segundos. (max-video-preview)
                </styles.Label>
                <styles.InputText
                  name="max_video_preview"
                  type="number"
                  value={max_video_preview}
                  onChange={event => {
                    const num = Number(event.target.value);
                    setMax_video_preview(num);
                  }}
                  required
                  id="outlined-required"
                />

                <FormControlLabel
                  control={
                    <Checkbox
                      checked={noImageIndex}
                      onChange={event =>
                        handleChangeNoImageIndex(event.target.checked)
                      }
                    />
                  }
                  label="Não mostra um link em cache nos resultados da pesquisa sobre a página. (noarchive)"
                />

                <styles.Label>
                  Permite especificar a data e a hora exatas para a interrupção
                  do rastreamento e da indexação da página. (unavailable_after)
                </styles.Label>
                <FormControl sx={{ m: 1, width: '28%', right: '0.5rem' }}>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DateTimePicker
                      label="Data"
                      value={newData}
                      onChange={newValue => onDatePicked(newValue)}
                      renderInput={params => <TextField {...params} />}
                    />
                  </LocalizationProvider>
                </FormControl>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={noArchive}
                      onChange={event =>
                        handleChangeINoArchive(event.target.checked)
                      }
                    />
                  }
                  label="Não exibe a página como ponto de referência para uma imagem nos resultados da pesquisa de Imagens do Google. (noimageindex)"
                />
              </Box>
            </styles.ContentSeletor>
            {edit ? (
              <ButtonGroup
                variant="contained"
                size="small"
                style={{ margin: 'auto' }}
              >
                <Button
                  startIcon={<Save />}
                  onClick={() => handleSave()}
                  variant="contained"
                  color="success"
                >
                  <a>Atualizar e sair</a>
                </Button>
              </ButtonGroup>
            ) : (
              <ButtonGroup
                variant="contained"
                size="small"
                style={{ margin: 'auto' }}
              >
                <Button
                  startIcon={<AddCircle />}
                  onClick={() => handleSave()}
                  variant="contained"
                  color="success"
                >
                  <a>Criar</a>
                </Button>
              </ButtonGroup>
            )}
          </form>
        </Box>
      </Modal>
    </div>
  );
}
