import CloseIcon from '@mui/icons-material/Close';
import React from 'react';

import Heading from '../Heading/index';
import * as S from './styles';

export type SimpleModalProps = {
  children: React.ReactNode;
  onCloseChange: () => void;
  title: string;
  width?: string;
} & S.ModalWrapperProps;

export default function SimpleModal({
  children,
  onCloseChange,
  title,
  isOpen,
  width,
}: SimpleModalProps): React.ReactElement {
  return (
    <S.ModalWrapper isOpen={isOpen}>
      <S.ModalContent aria-hidden={!open} width={width}>
        <S.ModalHeader>
          <Heading size="medium" lineHeight="1.5rem">
            {title}
          </Heading>
          <S.CloseButton onClick={onCloseChange}>
            <CloseIcon />
          </S.CloseButton>
        </S.ModalHeader>
        <S.ModalBody>{children}</S.ModalBody>
      </S.ModalContent>

      <S.Overlay aria-hidden={!open} onClick={onCloseChange} />
    </S.ModalWrapper>
  );
}
