import httpClient from '../http-client';

interface IVideoResponseDto {
  data: any[];
  mataData: any;
}

export const getVideos = async () => {
  const videos = (
    await httpClient.get<IVideoResponseDto>(`/content/admin/list?types[]=video`)
  ).data;
  return videos.data;
};

export const getVideo = async (contentId: string | null) => {
  const content = await httpClient.get(`content/${contentId}`);
  return content.data;
};
