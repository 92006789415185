import { zodResolver } from '@hookform/resolvers/zod';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import { z } from 'zod';

import { contentExclusiveSchema } from '../../../../common/validations/contentExclusiveValidation';
import { useContentSubmit } from '../../../../hooks/index';
import { createContent } from '../../../../services/contents';
import ExclusiveTemplate from '../../../../templates/ExclusiveTemplate';
import { groups } from '../../../../utils';

export type ContentExclusiveFormValues = z.infer<typeof contentExclusiveSchema>;
export type StatusType = 'unlisted' | 'hidden' | 'draft';

export default function ExclusiveCreate() {
  const methods = useForm<ContentExclusiveFormValues>({
    resolver: zodResolver(contentExclusiveSchema),
    defaultValues: {
      visibility: 'restricted',
      groups: groups,
    },
  });
  const navigate = useNavigate();

  const submit = (data: ContentExclusiveFormValues, status: string) => {
    const { obj } = useContentSubmit({ data });

    obj['company_units'] = data['company_units'].map(company => company.value);
    obj['status'] = status;
    obj['type'] = 'exclusive';

    createContent(obj)
      .then(() => {
        Swal.fire({
          title: 'Curso atualizado com sucesso',
          icon: 'success',
          customClass: {
            title: 'modal-title',
            htmlContainer: 'modal-html-container',
            confirmButton: 'btn-ok',
          },
          buttonsStyling: false,
        }).then(() => navigate(-1));
      })
      .catch(error => {
        Swal.fire({
          icon: 'error',
          title: 'Algo deu errado',
          iconColor: '#f5365c',
          text: error.message,
          customClass: {
            title: 'modal-title',
            htmlContainer: 'modal-html-container',
            confirmButton: 'btn-ok',
          },
          buttonsStyling: false,
        });
      });
  };

  return (
    <FormProvider {...methods}>
      <ExclusiveTemplate
        title="Cadastro de Conteúdo Exclusivo"
        view={false}
        content={undefined}
        submit={submit}
      />
    </FormProvider>
  );
}
