import {
  AccountBox,
  Delete,
  Facebook,
  Instagram,
  LinkedIn,
  Save,
  SaveAs,
  Twitter,
  YouTube,
} from '@mui/icons-material';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { FaTiktok } from 'react-icons/fa';
import { SingleValue } from 'react-select';
import Swal from 'sweetalert2';

import Button from '../../components/Button/index';
import CharacterTotal from '../../components/CharacterTotal';
import CustomSelect from '../../components/CustomSelect';
import Company from '../../components/FormComponent/Company';
import TextField from '../../components/FormComponent/TextField';
import TextArea from '../../components/TextArea';
import UploadCover from '../../components/UploadCover';
import { dealWithTitleChange } from '../../utils';
import { formatCpf } from '../../utils';
import * as S from './styles';

export type AuthorTemplateProps = {
  view?: boolean;
  submitHandler: (data, status) => void;
  returnPage: () => void;
};

type Option = { label: string; value: string };

export default function AuthorTemplate({
  view = false,
  submitHandler,
  returnPage,
}: AuthorTemplateProps): React.ReactElement {
  const {
    setValue,
    watch,
    handleSubmit,
    formState: { errors },
  } = useFormContext();

  const limit = {
    sucess: 900,
    warning: 900,
    error: 1000,
  };

  const types = [
    { label: 'Pessoa', value: 'person' },
    { label: 'Empresa', value: 'company' },
  ];

  const handleSelectChange = {
    'select-option': (key: string, option: SingleValue<Option>) => {
      setValue(key, option?.value);
    },
    'remove-value': (key: string, option: SingleValue<Option>) => {
      setValue(key, option?.value);
    },
    clear: (key: string, option: SingleValue<Option>) => {
      setValue(key, option?.value);
    },
  };

  const onError = error => {
    console.error(error);
    Swal.fire({
      icon: 'error',
      title: 'Erro ao enviar o formulário',
      text: 'Por favor, preencha o formulário corretamente.',
      buttonsStyling: false,
      customClass: {
        title: 'modal-title',
        htmlContainer: 'modal-html-container',
        confirmButton: 'btn-ok',
      },
    });
  };

  const warning = () => {
    Swal.fire({
      icon: 'error',
      iconColor: '#f5365c',
      title: 'Erro',
      text: 'A alteração pode ser prejudicial ao ranqueamento das ferramentas de busca.',
      buttonsStyling: false,
      customClass: {
        title: 'modal-title',
        htmlContainer: 'modal-html-container',
        confirmButton: 'btn-ok',
      },
    });
  };

  const characterCount = watch('description')?.length || 0;

  return (
    <S.Form>
      <S.FlexContainer gap="0.875rem">
        <TextField
          label={watch('type') === 'Empresa' ? 'Nome Empresa' : 'Nome Autor'}
          name="name"
          onChange={e => {
            const authorSlug = dealWithTitleChange(e.target.value);
            setValue('slug', authorSlug);
          }}
          disabled={view}
          required
        />
        <TextField
          label="Slug | URL"
          name="slug"
          onClick={warning}
          disabled={view}
          required
        />
        <TextField label="Tipo" name="type" required>
          <CustomSelect
            id="type"
            isClearable
            isRtl={false}
            isSearchable
            options={types}
            onChange={(value, action) => {
              handleSelectChange[action.action]('type', value);
            }}
            value={types.find(type => type.value === watch('type'))}
            placeholder="Selecione o tipo de autor"
            thereIsError={Object.keys(errors).includes('type')}
            isDisabled={view}
          />
        </TextField>
      </S.FlexContainer>

      {watch('type') === 'person' && (
        <S.FlexContainer gap="0.875rem" margin="0.875rem 0 0">
          <TextField
            label="E-mail"
            name="email"
            disabled={view || watch('is_email_cpf_blocked')}
          />

          <TextField
            label="CPF"
            name="cpf"
            maxLength={14}
            disabled={view || watch('is_email_cpf_blocked')}
            onChange={e => {
              setValue('cpf', formatCpf(e.target.value));
            }}
          />
        </S.FlexContainer>
      )}

      <S.FlexContainer margin="0.875rem 0" gap="0.875rem">
        <S.FlexContainer flexDirection="column" gap="0.875rem">
          <TextArea
            label={
              watch('type') === 'company' ? 'Sobre Empresa' : 'Sobre Pessoa'
            }
            rows={4}
            maxLength={1001}
            name="description"
            required
          />

          <CharacterTotal characterCount={characterCount} limit={limit} />

          {watch('type') === 'person' && <Company view={view} />}

          {watch('type') === 'person' && (
            <TextField
              label="Ocupação/Cargo"
              name="occupation"
              disabled={view}
              required
            />
          )}
        </S.FlexContainer>
        <TextField
          label="Imagem de Perfil (Tamanho 218x218px)"
          name="image_file"
          disabled={view}
        >
          <UploadCover
            isView={view}
            isCircularImage
            sizes={{
              width: 218,
              heigth: 218,
            }}
          />
        </TextField>
      </S.FlexContainer>

      <S.FlexContainer
        gap="0.875rem"
        flexDirection="column"
        alignItems="flex-start"
      >
        <S.Label>Redes Sociais</S.Label>
        <TextField
          label="facebook"
          startIcon={<Facebook />}
          name="social_links.facebook"
        />
        <TextField
          label="instagram"
          startIcon={<Instagram />}
          name="social_links.instagram"
        />
        <TextField
          label="linkedin"
          startIcon={<LinkedIn />}
          name="social_links.linkedin"
        />
        <TextField
          label="youtube"
          startIcon={<YouTube />}
          name="social_links.youtube"
        />
        <TextField
          label="tiktok"
          startIcon={<FaTiktok />}
          name="social_links.tiktok"
        />
        <TextField
          label="twitter"
          startIcon={<Twitter />}
          name="social_links.twitter"
        />
        <TextField
          label="site"
          startIcon={<AccountBox />}
          name="social_links.site"
        />
      </S.FlexContainer>

      <S.FlexContainer gap="0.875rem" justifyContent="center">
        <Button
          disabled={view}
          startIcon={<Delete />}
          color="error"
          type="button"
          variant="contained"
          onClick={returnPage}
        >
          <span>Cancelar</span>
        </Button>
        <Button
          disabled={view}
          startIcon={<SaveAs />}
          type="button"
          color="secondary"
          variant="outlined"
          onClick={handleSubmit(data => submitHandler(data, 'draft'), onError)}
        >
          <span>Salvar Rascunho</span>
        </Button>
        <Button
          disabled={view}
          startIcon={<Save />}
          color="success"
          variant="contained"
          type="button"
          onClick={handleSubmit(
            data => submitHandler(data, 'published'),
            onError,
          )}
        >
          <span>Publicar</span>
        </Button>
      </S.FlexContainer>
    </S.Form>
  );
}
