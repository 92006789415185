/* eslint-disable no-useless-escape */
import { AddCircle } from '@mui/icons-material';
import {
  AppBar,
  Box,
  Button,
  FormControl,
  Modal,
  Toolbar,
  Typography,
} from '@mui/material';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import ColorPicker from 'react-pick-color';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Select from 'react-select';
import Swal from 'sweetalert2';

import { Category } from '../../../../@types/categorization';
import * as appStyles from '../../../../AppStyle';
import ButtonGroup from '../../../../components/ButtonGroup';
import UploadFile from '../../../../components/FormComponent/UploadFile/UploadFile';
// Helpers
import toast from '../../../../helpers/toast';
import {
  createCategory,
  deleteCategory,
  getCategory,
  updateCategory,
} from '../../../../services/categorization';
import * as styles from './styles';

export default function CreateAndEditCategorization() {
  const [type, setType] = React.useState<string>('');
  const [categories, setCategories] = React.useState<any[]>([]);
  const [editedCategory, setEditedCategory] = React.useState<any>();
  const [modalOpen, setModalOpen] = React.useState<boolean>(false);
  const [isView, setIsView] = React.useState<boolean>(false);
  const [searchParams] = useSearchParams();
  const grandChild = searchParams.get('grandChild');

  const navigate = useNavigate();

  const { control, setValue, handleSubmit, setError, clearErrors, getValues } =
    useForm();

  const {
    control: modalControl,
    setValue: modalSetValue,
    handleSubmit: modalHandleSubmit,
    setError: modalSetError,
    clearErrors: modalClearErrors,
  } = useForm();

  const slugify = (text: string) => {
    return text
      .toLowerCase()
      .replace(/[àÀáÁâÂãäÄÅåª]+/g, 'a')
      .replace(/[èÈéÉêÊëË]+/g, 'e')
      .replace(/[ìÌíÍîÎïÏ]+/g, 'i')
      .replace(/[òÒóÓôÔõÕöÖº]+/g, 'o')
      .replace(/[ùÙúÚûÛüÜ]+/g, 'u')
      .replace(/[ýÝÿŸ]+/g, 'y')
      .replace(/[ñÑ]+/g, 'n')
      .replace(/[çÇ]+/g, 'c')
      .replace(/[ß]+/g, 'ss')
      .replace(/[Ææ]+/g, 'ae')
      .replace(/[Øøœ]+/g, 'oe')
      .replace(/[%]+/g, 'pct')
      .replace(/\s+/g, '-')
      .replace(/[^\w\-]+/g, '')
      .replace(/\-\-+/g, '-')
      .replace(/^-+/, '')
      .replace(/-+$/, '');
  };

  function onSubmit(data: any, status: any) {
    const backCategories: string[] = categories.map(
      element => element.category_id,
    );
    data = { ...data, status: status, children: backCategories };
    if (data.index === '') {
      data['index'] = null;
    }
    delete data['image_name'];
    delete data['image_toltip'];
    const id: string | null = searchParams.get('id');
    if (grandChild) {
      delete data['children'];
      data = { ...data, category_id: id, name: data.name };
    }
    const category: Category = data;

    if (id !== null) {
      updateCategory(category)
        .then(() => {
          Swal.fire({
            title: 'Categoria alterada com sucesso',
            icon: 'success',
          });
          navigate(-1);
        })
        .catch(error => {
          Swal.fire({
            title: error?.response?.data?.message,
            icon: 'error',
          });
        });
    } else {
      createCategory(category)
        .then(() => {
          Swal.fire({
            title: 'Categoria criada com sucesso',
            icon: 'success',
          });
          navigate(-1);
        })
        .catch(error => {
          Swal.fire({
            title: error?.response?.data?.message,
            icon: 'error',
          });
        });
    }
  }

  function onModalSubmit(data: any, status: any) {
    data = { ...data, status: status };
    if (data.index === '') {
      data['index'] = null;
    }
    delete data['image_name'];
    delete data['image_toltip'];
    const category: Category = data;
    createCategory(category)
      .then(response => {
        Swal.fire({
          title: 'Categoria criada com sucesso',
          icon: 'success',
        });
        let categoriesCopy = categories;
        categoriesCopy = categoriesCopy.concat(response);
        setCategories(categoriesCopy);
        modalSetValue('description', '');
        modalSetValue('name', '');
        modalSetValue('cod_sebrae', '');
        setModalOpen(false);
      })
      .catch(error => {
        toast({
          message: error?.response?.data?.message,
          type: 'error',
        });
      });
  }

  const getEditionCategory = async () => {
    getCategory(searchParams.get('id')).then(result => {
      setEditedCategory(result);
      setValue('category_id', result.category_id);
      setValue('name', result.name);
      setValue('description', result.description);
      setValue('type', result.type);
      setValue('index', result.index);
      if (result.color) setValue('color', result.color);
      if (result.cod_sebrae) setValue('cod_sebrae', result.cod_sebrae);
      if (result.icon_class) setValue('icon_class', result.icon_class);

      const mappedCategories = new Map();
      mappedCategories.set('skill', 'Desafio');
      mappedCategories.set('moment', 'Momento');
      mappedCategories.set('sector', 'Setor');

      const filteredCategories = result?.children?.filter(
        category => category.name !== undefined,
      );
      setType(mappedCategories.get(result.type));
      modalSetValue('type', result.type);
      setCategories(filteredCategories);
    });
  };

  React.useEffect(() => {
    const id: string | null = searchParams.get('id');
    const editingType: string | null = searchParams.get('type');
    const view: string | null = searchParams.get('view');
    if (view === 'true') {
      setIsView(true);
    }
    if (id !== null) {
      getEditionCategory();
    }
    if (editingType !== null) {
      const mappedCategories = new Map();
      mappedCategories.set('skill', 'Desafio');
      mappedCategories.set('moment', 'Momento');
      mappedCategories.set('sector', 'Setor');
      setType(mappedCategories.get(editingType));
      setValue('type', editingType);
      modalSetValue('type', editingType);
    }
  }, []);

  function getModalStyle() {
    const top = 25;

    return {
      top: `${top}%`,
      margin: 'auto',
      width: '85%',
      height: '95%',
      backgroundColor: 'white',
      marginTop: '1vw',
      overflow: 'hidden',
      overflowY: 'scroll',
    } as React.CSSProperties;
  }

  return (
    <>
      {!grandChild ? (
        <form
          style={{
            display: 'flex',
            flexDirection: 'row',
            margin: '0.5rem',
            width: '100%',
            maxWidth: '100%',
            gap: '22px',
            height: 'min-content',
          }}
        >
          <styles.CardInput>
            <div style={{ padding: '22px' }}>
              <Typography variant="h4" style={{ marginBottom: '22px' }}>
                Geral
              </Typography>

              <styles.ContentSeletor>
                <styles.Label>Nome *</styles.Label>
                <FormControl sx={{ width: '100%' }}>
                  <Controller
                    name="name"
                    control={control}
                    rules={{ required: true, maxLength: 48 }}
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <>
                        <styles.InputText
                          disabled={isView}
                          name="name"
                          value={value}
                          onChange={event => {
                            setValue('slug', slugify(event.target.value));
                            onChange(event);
                          }}
                          id="outlined-required"
                        />
                        {error !== undefined ? (
                          error.type === 'required' ? (
                            <appStyles.span
                              style={{ color: 'red', marginBottom: '0.5rem' }}
                            >
                              *Campo obrigatório
                            </appStyles.span>
                          ) : (
                            <appStyles.span
                              style={{ color: 'red', marginBottom: '0.5rem' }}
                            >
                              Tamanho máximo de 48 caracteres
                            </appStyles.span>
                          )
                        ) : (
                          <></>
                        )}
                      </>
                    )}
                  />
                </FormControl>
              </styles.ContentSeletor>
              <styles.ContentSeletor>
                <styles.Label>Cor *</styles.Label>
                <FormControl
                  sx={{ m: 0.6, width: '100%', marginBottom: '1rem' }}
                >
                  <Controller
                    name="color"
                    control={control}
                    rules={{ required: true }}
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <>
                        <ColorPicker
                          theme={{
                            background: 'lightgrey',
                            inputBackground: 'grey',
                            borderColor: 'darkgrey',
                            borderRadius: '8px',
                            color: 'black',
                            width: '100%',
                          }}
                          hideInputs={true}
                          color={value}
                          onChange={color => onChange(color.hex)}
                        />
                        <styles.InputText
                          disabled={isView}
                          name="color"
                          value={value}
                          onChange={onChange}
                          id="outlined-required"
                        />
                        {error && (
                          <appStyles.span
                            style={{ color: 'red', marginBottom: '0.5rem' }}
                          >
                            *Campo obrigatório
                          </appStyles.span>
                        )}
                      </>
                    )}
                  />
                </FormControl>
              </styles.ContentSeletor>
              <styles.ContentSeletor>
                <styles.Label>Classe do ícone</styles.Label>
                <FormControl sx={{ width: '100%' }}>
                  <Controller
                    name="icon_class"
                    control={control}
                    rules={{ required: false }}
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <>
                        <styles.InputText
                          disabled={isView}
                          name="icon_class"
                          value={value}
                          onChange={onChange}
                          id="outlined-required"
                        />
                      </>
                    )}
                  />
                </FormControl>
              </styles.ContentSeletor>
              <styles.ContentSeletor>
                <styles.Label>Índice</styles.Label>
                <FormControl sx={{ width: '100%' }}>
                  <Controller
                    name="index"
                    control={control}
                    rules={{ required: false }}
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <>
                        <styles.InputText
                          disabled={isView}
                          type="number"
                          name="index"
                          value={value}
                          onChange={onChange}
                          id="outlined-required"
                        />
                      </>
                    )}
                  />
                </FormControl>
              </styles.ContentSeletor>

              <UploadFile
                label="Capa *"
                setValue={setValue}
                control={control}
                isRequired={true}
                imageReference={
                  editedCategory ? editedCategory?.image_file?.reference : ''
                }
                imageId={editedCategory ? editedCategory.image_file_id : ''}
              />

              <styles.ContentSeletor>
                <styles.Label>Descrição *</styles.Label>
                <FormControl sx={{ width: '100%' }}>
                  <Controller
                    name="description"
                    control={control}
                    rules={{
                      validate: {
                        required: fieldValue => {
                          return (
                            (fieldValue && fieldValue.length > 1) ||
                            '*Campo obrigatório'
                          );
                        },
                        maxLength: fieldValue =>
                          fieldValue.length <= 100 ||
                          'Tamanho deve ser inferior a 100',
                      },
                    }}
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <>
                        <textarea
                          disabled={isView}
                          name="description"
                          value={value}
                          onChange={event => {
                            if (event.target.value.length <= 100) {
                              onChange(event);
                              clearErrors('description');
                            } else {
                              alert(
                                'Você pode digitar no máximo 100 caracteres',
                              );
                              setError('description', {
                                type: 'maxLength',
                                message: 'Tamanho deve ser inferior a 100',
                              });
                            }
                          }}
                          id="outlined-required"
                        ></textarea>
                        {error !== undefined && (
                          <appStyles.span
                            style={{ color: 'red', marginBottom: '0.5rem' }}
                          >
                            {error.message}
                          </appStyles.span>
                        )}
                      </>
                    )}
                  />
                </FormControl>
              </styles.ContentSeletor>
              <styles.ContentSeletor>
                <styles.Label>Tipo *</styles.Label>
                <FormControl sx={{ width: '100%' }}>
                  <Controller
                    name="type"
                    control={control}
                    rules={{ required: true }}
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <>
                        <styles.SelectorSelect disabled={true} value={type}>
                          <styles.MenuItemSelector value={'Desafio'}>
                            Desafio
                          </styles.MenuItemSelector>
                          <styles.MenuItemSelector value={'Momento'}>
                            Momento
                          </styles.MenuItemSelector>
                          <styles.MenuItemSelector value={'Setor'}>
                            Setor
                          </styles.MenuItemSelector>
                        </styles.SelectorSelect>
                      </>
                    )}
                  />
                </FormControl>
              </styles.ContentSeletor>
              <ButtonGroup
                setValue={setValue}
                view={isView}
                handleSubmit={handleSubmit(onSubmit)}
              />
            </div>
          </styles.CardInput>

          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              width: '100%',
              maxWidth: '50%',
            }}
          >
            {grandChild === null && (
              <styles.CardInput>
                <Box component={'div'} sx={{ padding: '22px' }}>
                  <Typography variant="h4" style={{ marginBottom: '22px' }}>
                    Opções
                  </Typography>
                  <styles.ContentSeletor>
                    <styles.Label>Categorizações *</styles.Label>

                    <FormControl sx={{ width: '100%' }}>
                      <Controller
                        name="categories"
                        control={control}
                        rules={{
                          validate: {
                            required: fieldValue => {
                              return (
                                categories.length > 0 || '*Campo obrigatório'
                              );
                            },
                          },
                        }}
                        render={({
                          field: { onChange, value },
                          fieldState: { error },
                        }) => (
                          <>
                            <Select
                              isDisabled={isView}
                              menuIsOpen={false}
                              isMulti
                              noOptionsMessage={() =>
                                'Nenhuma opção encontrada'
                              }
                              menuPlacement="auto"
                              className="react-select-container"
                              classNamePrefix="react-select"
                              isClearable={true}
                              isRtl={false}
                              isSearchable={false}
                              onChange={(event: any) => {
                                //removing all itens
                                if (
                                  categories.length > 1 &&
                                  event.length === 0
                                ) {
                                  categories.forEach(currentCategory => {
                                    deleteCategory(currentCategory).catch(
                                      error => {
                                        Swal.fire({
                                          title: error?.response?.data?.message
                                            ? error?.response?.data?.message
                                            : 'Algo deu errado',
                                          icon: 'error',
                                        });
                                      },
                                    );
                                  });
                                  setCategories([]);
                                } else if (event.length < categories.length) {
                                  //removing one
                                  const removedCategory = categories.find(
                                    element => {
                                      if (
                                        event.find(
                                          ev => ev.label === element.name,
                                        ) === undefined
                                      ) {
                                        return element;
                                      }
                                    },
                                  );
                                  deleteCategory(removedCategory)
                                    .then(resp => {
                                      const categoriesCopy = categories.filter(
                                        element =>
                                          element.category_id !==
                                          removedCategory.category_id,
                                      );
                                      setCategories(categoriesCopy);
                                      Swal.fire({
                                        title: 'Categoria deletada com sucesso',
                                        icon: 'success',
                                      });
                                    })
                                    .catch(error => {
                                      Swal.fire({
                                        title: error?.response?.data?.message
                                          ? error?.response?.data?.message
                                          : 'Algo deu errado',
                                        icon: 'error',
                                      });
                                    });
                                }
                              }}
                              options={[]}
                              placeholder="Categorizações selecionadas"
                              value={categories.map((category: any) => {
                                return {
                                  value: category.name,
                                  label: category.name,
                                };
                              })}
                            />
                            {error !== undefined && (
                              <appStyles.span
                                style={{ color: 'red', marginBottom: '0.5rem' }}
                              >
                                *Campo obrigatório
                              </appStyles.span>
                            )}
                          </>
                        )}
                      />
                    </FormControl>
                    <Button
                      disabled={isView}
                      onClick={() => {
                        setModalOpen(true);
                      }}
                      startIcon={<AddCircle />}
                      variant="contained"
                      size="small"
                      style={{
                        maxWidth: '166px',
                      }}
                    >
                      <span>Adicionar novo</span>
                    </Button>
                  </styles.ContentSeletor>
                </Box>
              </styles.CardInput>
            )}
          </div>
        </form>
      ) : (
        <form
          style={{
            display: 'flex',
            flexDirection: 'row',
            margin: '0.5rem',
            width: '100%',
            maxWidth: '100%',
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              margin: '0.5rem',
              width: '100%',
              maxWidth: '50%',
            }}
          >
            <styles.CardInput>
              <Typography variant="h4" style={{ margin: '1rem' }}>
                Geral
              </Typography>
              <styles.ContentSeletor>
                <styles.Label>Nome *</styles.Label>
                <FormControl
                  sx={{ m: 0.6, width: '100%', marginBottom: '1rem' }}
                >
                  <Controller
                    name="name"
                    control={control}
                    rules={{ required: true, maxLength: 60 }}
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <>
                        <styles.InputText
                          name="name"
                          value={value}
                          onChange={event => {
                            setValue('slug', slugify(event.target.value));
                            onChange(event);
                          }}
                          id="outlined-required"
                        />
                        {error !== undefined ? (
                          error.type === 'required' ? (
                            <appStyles.span
                              style={{ color: 'red', marginBottom: '0.5rem' }}
                            >
                              *Campo obrigatório
                            </appStyles.span>
                          ) : (
                            <appStyles.span
                              style={{ color: 'red', marginBottom: '0.5rem' }}
                            >
                              Tamanho máximo de 60 caracteres
                            </appStyles.span>
                          )
                        ) : (
                          <></>
                        )}
                      </>
                    )}
                  />
                </FormControl>
              </styles.ContentSeletor>
              <styles.ContentSeletor>
                <styles.Label>Código Sebrae *</styles.Label>
                <FormControl
                  sx={{ m: 0.6, width: '100%', marginBottom: '1rem' }}
                >
                  <Controller
                    name="cod_sebrae"
                    control={control}
                    rules={{ required: true }}
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <>
                        <styles.InputText
                          type={'number'}
                          disabled={isView}
                          name="cod_sebrae"
                          value={value}
                          onChange={onChange}
                          id="outlined-required"
                        />
                        {error !== undefined && (
                          <appStyles.span
                            style={{ color: 'red', marginBottom: '0.5rem' }}
                          >
                            *Campo numérico obrigatório
                          </appStyles.span>
                        )}
                      </>
                    )}
                  />
                </FormControl>
              </styles.ContentSeletor>
              <styles.ContentSeletor>
                <styles.Label>Índice</styles.Label>
                <FormControl
                  sx={{ m: 0.6, width: '100%', marginBottom: '1rem' }}
                >
                  <Controller
                    name="index"
                    control={control}
                    rules={{ required: false }}
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <>
                        <styles.InputText
                          disabled={isView}
                          name="index"
                          type="number"
                          value={value}
                          onChange={onChange}
                          id="outlined-required"
                        />
                      </>
                    )}
                  />
                </FormControl>
              </styles.ContentSeletor>
              <styles.ContentSeletor>
                <styles.Label>Descrição</styles.Label>
                <FormControl
                  sx={{ m: 0.6, width: '100%', marginBottom: '1rem' }}
                >
                  <Controller
                    name="description"
                    control={control}
                    rules={{
                      validate: {
                        required: fieldValue => {
                          return (
                            (fieldValue && fieldValue.length > 1) ||
                            '*Campo obrigatório'
                          );
                        },
                        maxLength: fieldValue =>
                          fieldValue.length <= 100 ||
                          'Tamanho deve ser inferior a 100',
                      },
                    }}
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <>
                        <textarea
                          disabled={isView}
                          name="description"
                          value={value}
                          onChange={event => {
                            if (event.target.value.length <= 100) {
                              onChange(event);
                              clearErrors('description');
                            } else {
                              alert(
                                'Você pode digitar no máximo 100 caracteres',
                              );
                              setError('description', {
                                type: 'maxLength',
                                message: 'Tamanho deve ser inferior a 100',
                              });
                            }
                          }}
                          id="outlined-required"
                        ></textarea>
                        {error !== undefined && (
                          <appStyles.span
                            style={{ color: 'red', marginBottom: '0.5rem' }}
                          >
                            {error.message}
                          </appStyles.span>
                        )}
                      </>
                    )}
                  />
                </FormControl>
              </styles.ContentSeletor>

              <styles.ContentSeletor>
                <styles.Label>Tipo *</styles.Label>
                <FormControl sx={{ m: 1, width: '100%' }}>
                  <styles.SelectorSelect disabled={true} value={type}>
                    <styles.MenuItemSelector value={'Desafio'}>
                      Desafio
                    </styles.MenuItemSelector>
                    <styles.MenuItemSelector value={'Momento'}>
                      Momento
                    </styles.MenuItemSelector>
                    <styles.MenuItemSelector value={'Setor'}>
                      Setor
                    </styles.MenuItemSelector>
                  </styles.SelectorSelect>
                </FormControl>
              </styles.ContentSeletor>
            </styles.CardInput>
            <ButtonGroup
              setValue={setValue}
              view={isView}
              handleSubmit={handleSubmit(onSubmit)}
            />
          </div>
        </form>
      )}
      <Modal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{ alignItems: 'center', justifyContent: 'center' }}
      >
        <Box style={getModalStyle()}>
          <AppBar position="static" style={{ marginBottom: '1rem' }}>
            <Toolbar>
              <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                Categoria
              </Typography>
              <Button onClick={() => setModalOpen(false)} color="inherit">
                x
              </Button>
            </Toolbar>
          </AppBar>
          <form
            style={{
              display: 'flex',
              flexDirection: 'row',
              margin: '0.5rem',
              width: '100%',
              maxWidth: '100%',
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                margin: '0.5rem',
                width: '100%',
                maxWidth: '50%',
              }}
            >
              <styles.CardInput>
                <Typography variant="h4" style={{ margin: '1rem' }}>
                  Geral
                </Typography>
                <styles.ContentSeletor>
                  <styles.Label>Nome *</styles.Label>
                  <FormControl
                    sx={{ m: 0.6, width: '100%', marginBottom: '1rem' }}
                  >
                    <Controller
                      name="name"
                      control={modalControl}
                      rules={{ required: true, maxLength: 60 }}
                      render={({
                        field: { onChange, value },
                        fieldState: { error },
                      }) => (
                        <>
                          <styles.InputText
                            name="name"
                            value={value}
                            onChange={event => {
                              modalSetValue(
                                'slug',
                                slugify(event.target.value),
                              );
                              onChange(event);
                            }}
                            id="outlined-required"
                          />
                          {error !== undefined ? (
                            error.type === 'required' ? (
                              <appStyles.span
                                style={{ color: 'red', marginBottom: '0.5rem' }}
                              >
                                *Campo obrigatório
                              </appStyles.span>
                            ) : (
                              <appStyles.span
                                style={{ color: 'red', marginBottom: '0.5rem' }}
                              >
                                Tamanho máximo de 60 caracteres
                              </appStyles.span>
                            )
                          ) : (
                            <></>
                          )}
                        </>
                      )}
                    />
                  </FormControl>
                </styles.ContentSeletor>
                <styles.ContentSeletor>
                  <styles.Label>Código Sebrae *</styles.Label>
                  <FormControl
                    sx={{ m: 0.6, width: '100%', marginBottom: '1rem' }}
                  >
                    <Controller
                      name="cod_sebrae"
                      control={modalControl}
                      rules={{ required: true }}
                      render={({
                        field: { onChange, value },
                        fieldState: { error },
                      }) => (
                        <>
                          <styles.InputText
                            type={'number'}
                            disabled={isView}
                            name="cod_sebrae"
                            value={value}
                            onChange={onChange}
                            id="outlined-required"
                          />
                          {error !== undefined && (
                            <appStyles.span
                              style={{ color: 'red', marginBottom: '0.5rem' }}
                            >
                              *Campo numérico obrigatório
                            </appStyles.span>
                          )}
                        </>
                      )}
                    />
                  </FormControl>
                </styles.ContentSeletor>
                <styles.ContentSeletor>
                  <styles.Label>Índice</styles.Label>
                  <FormControl
                    sx={{ m: 0.6, width: '100%', marginBottom: '1rem' }}
                  >
                    <Controller
                      name="index"
                      control={modalControl}
                      rules={{ required: false }}
                      render={({
                        field: { onChange, value },
                        fieldState: { error },
                      }) => (
                        <>
                          <styles.InputText
                            type="number"
                            disabled={isView}
                            name="index"
                            value={value}
                            onChange={onChange}
                            id="outlined-required"
                          />
                        </>
                      )}
                    />
                  </FormControl>
                </styles.ContentSeletor>
                <styles.ContentSeletor>
                  <styles.Label>Descrição</styles.Label>
                  <FormControl
                    sx={{ m: 0.6, width: '100%', marginBottom: '1rem' }}
                  >
                    <Controller
                      name="description"
                      control={modalControl}
                      rules={{
                        validate: {
                          required: fieldValue => {
                            return (
                              (fieldValue && fieldValue.length > 1) ||
                              '*Campo obrigatório'
                            );
                          },
                          maxLength: fieldValue =>
                            fieldValue.length <= 100 ||
                            'Tamanho deve ser inferior a 100',
                        },
                      }}
                      render={({
                        field: { onChange, value },
                        fieldState: { error },
                      }) => (
                        <>
                          <textarea
                            disabled={isView}
                            name="description"
                            value={value}
                            onChange={event => {
                              if (event.target.value.length <= 100) {
                                onChange(event);
                                modalClearErrors('description');
                              } else {
                                alert(
                                  'Você pode digitar no máximo 100 caracteres',
                                );
                                modalSetError('description', {
                                  type: 'maxLength',
                                  message: 'Tamanho deve ser inferior a 100',
                                });
                              }
                            }}
                            id="outlined-required"
                          ></textarea>
                          {error !== undefined && (
                            <appStyles.span
                              style={{ color: 'red', marginBottom: '0.5rem' }}
                            >
                              {error.message}
                            </appStyles.span>
                          )}
                        </>
                      )}
                    />
                  </FormControl>
                </styles.ContentSeletor>

                <styles.ContentSeletor>
                  <styles.Label>Tipo *</styles.Label>
                  <FormControl sx={{ m: 1, width: '100%' }}>
                    <styles.SelectorSelect disabled={true} value={type}>
                      <styles.MenuItemSelector value={'Desafio'}>
                        Desafio
                      </styles.MenuItemSelector>
                      <styles.MenuItemSelector value={'Momento'}>
                        Momento
                      </styles.MenuItemSelector>
                      <styles.MenuItemSelector value={'Setor'}>
                        Setor
                      </styles.MenuItemSelector>
                    </styles.SelectorSelect>
                  </FormControl>
                </styles.ContentSeletor>
              </styles.CardInput>
              <ButtonGroup
                setValue={modalSetValue}
                view={isView}
                handleSubmit={modalHandleSubmit(onModalSubmit)}
                notShowCancelModal={true}
                closeModal={() => setModalOpen(false)}
              />
            </div>
          </form>
        </Box>
      </Modal>
    </>
  );
}
