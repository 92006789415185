import React, { InputHTMLAttributes } from 'react';

import * as S from './styles';

export type InputProps = {
  thereIsError?: boolean;
  withIcon?: boolean;
  standard?: boolean;
  register?: any;
} & InputHTMLAttributes<HTMLInputElement>;

export default function Input({
  standard = true,
  thereIsError = false,
  ...props
}: InputProps) {
  return (
    <S.Input
      standard={standard}
      thereIsError={thereIsError}
      {...props.register}
      {...props}
    />
  );
}
