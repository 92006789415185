export function contentTypeMapping(value: string) {
  switch (value) {
    case 'course':
      return 'Curso';
    case 'podcast':
      return 'Podcast';
    case 'ebook':
      return 'Ebook';
    case 'infographics':
      return 'Infográfico';
    case 'thematic_kit':
      return 'kit_temático';
    case 'magazine':
      return 'Revista';
    case 'spreadsheet':
      return 'Ferramentas de gestão';
    case 'research':
      return 'Pesquisas';
    case 'video':
      return 'Video';
    case 'article':
      return 'Artigo';
    case 'series':
      return 'Serie';
    default:
      return '';
  }
}
