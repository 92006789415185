import styled, { css, DefaultTheme } from 'styled-components';

type ItemButtonProps = {
  isOpen?: boolean;
  isExpanded?: boolean;
};

const itemButtonModifiers = {
  open: (theme: DefaultTheme) => css`
    background-color: #e0ffff;
    border-left: 4px solid ${theme.colors.teal_400};
    color: ${theme.colors.teal_400};
    padding-left: 0.75rem;

    svg {
      transform: rotate(90deg);
    }
  `,
  close: (theme: DefaultTheme) => css`
    background-color: ${theme.colors.white};
    color: ${theme.colors.neutral_800};

    svg {
      transform: rotate(0);
    }
  `,
  expanded: () => css`
    span,
    .expansion-indicator {
      display: none;
    }
  `,
};

export const ItemButton = styled.button<ItemButtonProps>`
  ${({ theme, isOpen = false, isExpanded = false }) => css`
    align-items: center;
    border: 0;
    cursor: pointer;
    display: flex;
    font-size: ${theme.font.sizes.small};
    gap: 1.5rem;
    justify-content: space-between;
    padding: 1rem;
    position: relative;
    transition: ${theme.transition.default};
    width: 100%;

    svg {
      width: 1rem;
    }

    span {
      text-align: left;
      width: 100%;
    }

    .expansion-indicator {
      align-self: flex-end;
    }

    ${isOpen
      ? itemButtonModifiers.open(theme)
      : itemButtonModifiers.close(theme)};
    ${!isExpanded && itemButtonModifiers.expanded()};
  `}
`;
