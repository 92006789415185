import SearchIcon from '@mui/icons-material/Search';
import { GridInitialStateCommunity } from '@mui/x-data-grid/models/gridStateCommunity';
import React, { useEffect, useState } from 'react';

import { useGetContent, useTablePagination } from '../../../hooks';
import Button from '../../Button/index';
import { FlexContainer } from '../../FlexContainer';
import Table from '../../Table';
import SimpleModal from '..';
import * as S from './styles';

export type GenericContentModalProps = {
  isOpen: boolean;
  onCloseChange: () => void;
  keyId: string;
  columns: any[];
  callback: (args?: any) => Promise<any>;
};

enum Title {
  knowledge_id = 'Conhecimentos',
  topic_id = 'Tópicos',
  tag_id = 'Tag',
}

export default function GenericContentModal({
  isOpen,
  onCloseChange,
  keyId,
  columns,
  callback,
}: GenericContentModalProps) {
  const [search, setSearch] = useState('');
  const [searchTrigger, setSearchTrigger] = useState(false);
  const [rows, setRows] = useState<any[]>([]);

  const { loading, getVariedContents } = useGetContent(callback);
  const {
    paginationModel,
    rowCount,
    setPaginationModel,
    setRowCount,
    changePage,
    changePageSize,
  } = useTablePagination();

  const initialStateTable: GridInitialStateCommunity = {
    sorting: {
      sortModel: [{ field: 'created_at', sort: 'desc' }],
    },
  };

  const params = {
    limit: paginationModel.pageSize,
    search: search.length ? search : null,
  };

  useEffect(() => {
    setPaginationModel(oldState => ({ ...oldState, page: 1 }));
    getVariedContents(setRows, setRowCount, { ...params, offset: 1 });
  }, [paginationModel.pageSize, searchTrigger]);

  useEffect(() => {
    getVariedContents(setRows, setRowCount, {
      ...params,
      offset: paginationModel.page,
    });
  }, [paginationModel.page, searchTrigger]);

  const tableProps = {
    columns,
    rows,
    keyId,
    initialState: initialStateTable,
    onPageChange: page => changePage(page),
    onPageSizeChange: pageSize => changePageSize(pageSize),
    page: paginationModel.page - 1,
    rowsPerPageOptions: [10, 25, 50, 75, 100],
    pageSize: paginationModel.pageSize,
    rowCount,
    loading,
    // TODO: ADICIONAR ORDENAÇÃO
    // onSortModelChange: model => handleSort(model),
  };

  return (
    <SimpleModal
      isOpen={isOpen}
      onCloseChange={onCloseChange}
      title={Title[keyId]}
    >
      <S.Wrapper>
        <FlexContainer gap="0.875rem">
          <S.Label htmlFor="search">
            Busca por Nome
            <FlexContainer gap="0.875rem" alignItems="flex-end">
              <S.Input id="search" onChange={e => setSearch(e.target.value)} />
              <Button
                type="button"
                icon={<SearchIcon />}
                onClick={() => setSearchTrigger(!searchTrigger)}
              />
            </FlexContainer>
          </S.Label>
        </FlexContainer>

        <Table {...tableProps} width="90vw" height="500px" disableColumnMenu />
      </S.Wrapper>
    </SimpleModal>
  );
}
