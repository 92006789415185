import {
  AspectRatio,
  Close,
  Crop,
  DeleteForever,
  Download,
  ModeEdit,
  RotateLeft,
} from '@mui/icons-material';
import React, { useEffect, useState } from 'react';
import { CropperFade, CropperRef } from 'react-advanced-cropper';

import { ReactComponent as Free } from '../../assets/free.svg';
import { ReactComponent as OneByOne } from '../../assets/one-by-one.svg';
import { ReactComponent as ThreeByFour } from '../../assets/three-by-four.svg';
import { FlexContainer } from '../FlexContainer';
import { CropperSettings } from '../UploadCover';
import * as S from './styles';

interface ICropperWrapperProps<CropperRef = unknown> {
  cropper: CropperRef;
  children?: React.ReactNode;
  className?: string;
  navigationProps?: NavigationProps;
  isEdit?: boolean;
}

export type NavigationProps = {
  isView: boolean;
  onCrop: () => void;
  handleHistoric: () => void;
  handleRemoveImage: () => void;
  handleDownloadImage: (src?: string) => void;
  handleUploadImage: () => void;
  handleEdit: () => void;
  openCategoryModal: () => void;
  validationSizes: (width: number, height: number) => void;
  handleSettings: (settings: CropperSettings) => void;
};

type CropPanelType = 'crop' | 'aspect' | 'stencil';

export default function CropperWrapper({
  cropper,
  children,
  className,
  navigationProps,
  isEdit,
}: ICropperWrapperProps<CropperRef>) {
  const [cropPanel, setCropPanel] = useState<CropPanelType>('crop');

  useEffect(() => {
    const width = cropper.getImage()?.width;
    const height = cropper.getImage()?.height;

    if (cropper.isLoaded() && width && height) {
      navigationProps?.validationSizes(width, height);
    }
  }, [cropper.isLoaded(), cropper.getImage()?.src]);

  return (
    <S.Wrapper className={className}>
      {isEdit && (
        <S.NavTools>
          <S.Button
            variant="round"
            type="button"
            onClick={navigationProps?.handleHistoric}
            disabled={navigationProps?.isView}
          >
            <RotateLeft />
          </S.Button>
          <FlexContainer gap="0.875rem">
            <S.Button
              type="button"
              variant="remove"
              onClick={navigationProps?.handleRemoveImage}
              disabled={navigationProps?.isView}
              title="Remover Imagem"
            >
              <DeleteForever />
            </S.Button>
            <S.Button
              type="button"
              variant="round"
              onClick={() =>
                navigationProps?.handleDownloadImage(cropper.getImage()?.src)
              }
              disabled={navigationProps?.isView}
              title="Baixar Imagem"
            >
              <Download />
            </S.Button>
            <S.Button
              type="button"
              variant="done"
              onClick={navigationProps?.handleUploadImage}
              disabled={navigationProps?.isView}
              title="Enviar Imagem"
            >
              Enviar
            </S.Button>
          </FlexContainer>
        </S.NavTools>
      )}

      <S.NavMain>
        {isEdit ? (
          <>
            <S.Button
              type="button"
              onClick={() => setCropPanel('aspect')}
              isSelected={cropPanel === 'aspect'}
              variant="setting"
            >
              <AspectRatio />
              <span>Proporção</span>
            </S.Button>
            <S.Button
              type="button"
              onClick={() => setCropPanel('crop')}
              isSelected={cropPanel === 'crop'}
              variant="setting"
            >
              <Crop />
              <span>Cortar</span>
            </S.Button>
            <S.Button
              type="button"
              onClick={navigationProps?.handleEdit}
              variant="setting"
            >
              <Close />
              <span>Fechar Edição</span>
            </S.Button>
          </>
        ) : (
          <S.Button
            type="button"
            onClick={navigationProps?.handleEdit}
            variant="setting"
          >
            <ModeEdit />
            <span>Editar</span>
          </S.Button>
        )}
      </S.NavMain>

      <CropperFade className="fade" visible={cropper.isLoaded()}>
        {children}
      </CropperFade>

      <S.Loading visible={cropper.isLoaded()}>
        <span>Processando Imagem </span>
        <div className="custom-loader" />
      </S.Loading>

      {isEdit && (
        <S.UtilFooter>
          {cropPanel === 'aspect' && (
            <FlexContainer alignItems="center">
              <S.SettingIcon
                type="button"
                onClick={() =>
                  navigationProps?.handleSettings({
                    aspectRatio: undefined,
                  })
                }
              >
                <Free />
              </S.SettingIcon>

              <S.SettingIcon
                type="button"
                onClick={() =>
                  navigationProps?.handleSettings({
                    aspectRatio: 1 / 1,
                  })
                }
              >
                <OneByOne />
              </S.SettingIcon>

              <S.SettingIcon
                type="button"
                onClick={() =>
                  navigationProps?.handleSettings({
                    aspectRatio: 3 / 4,
                  })
                }
              >
                <ThreeByFour />
              </S.SettingIcon>
            </FlexContainer>
          )}

          {cropPanel === 'crop' && (
            <FlexContainer gap="0.875rem" flexWrap="nowrap" alignItems="center">
              <S.InputDimension title="Largura">
                <input
                  type="number"
                  value={cropper.getCoordinates()?.width}
                  onChange={e => {
                    const obj = Object.assign({}, cropper.getCoordinates());
                    obj['width'] = Number(e.target.value);
                    cropper.setCoordinates(obj);
                  }}
                  disabled={navigationProps?.isView}
                />
                <span>W</span>
              </S.InputDimension>
              <span>x</span>
              <S.InputDimension title="Altura">
                <input
                  type="number"
                  value={cropper.getCoordinates()?.height}
                  onChange={e => {
                    const obj = Object.assign({}, cropper.getCoordinates());
                    obj['height'] = Number(e.target.value);
                    cropper.setCoordinates(obj);
                  }}
                  disabled={navigationProps?.isView}
                />
                <span>H</span>
              </S.InputDimension>

              <S.Button
                type="button"
                variant="done"
                onClick={navigationProps?.onCrop}
                disabled={navigationProps?.isView}
              >
                Cortar
              </S.Button>
            </FlexContainer>
          )}
        </S.UtilFooter>
      )}
    </S.Wrapper>
  );
}
