import styled from '@emotion/styled';
import { Button, TextField } from '@mui/material';

export const ContentSeletor = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const Input = styled.input({
  display: 'none',
});

export const InputText = styled(TextField)`
  line-height: 1.42857143;
  color: #333;
  font-size: 5px;

  fieldset {
    height: 38px;
    border-color: #d2d6de;
    top: 7px;
  }
`;
export const CropButton = styled(Button)`
  display: flex;
`;

export const Label = styled.label`
  display: inline-block;
  max-width: 100%;
  font-weight: 700;
  font-size: 14px;
  line-height: 1;
`;
export const Preview = styled.img`
  margin: 0 auto;
  max-height: 200px;
  max-width: 100%;
`;
export const Span = styled.span`
  color: gray;
`;

export const ContentImg = styled.div`
  padding: 0px 15px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;
