import {
  Article,
  BlurLinear,
  Delete,
  FilterAlt,
  InsertPhoto,
  Lyrics,
  ManageSearch,
  Videocam,
} from '@mui/icons-material';
import {
  AppBar,
  Box,
  Button,
  ButtonGroup,
  Modal,
  Toolbar,
  Typography,
} from '@mui/material';
import { DataGrid, GridToolbarQuickFilter, ptBR } from '@mui/x-data-grid';
import React, { useEffect, useRef } from 'react';
import { Controller } from 'react-hook-form';
import { FaBook } from 'react-icons/fa';

import * as appStyles from '../../AppStyle';
import {
  getContentsListEpisode,
  getContentsListFilter,
} from '../../services/contents';
import { contentTypeMapping } from '../../utils/contentTypeMapping';
import { getApplyFilterFnTitle } from '../../utils/getApplyFilterFnTitle';
import * as styles from './styles';

export default function ContentListEpisode(props: any) {
  const [filterModalOpen, setFilterModalOpen] = React.useState<boolean>(false);
  const [selectedContent, setSelectedContent] = React.useState<any>();
  const [content, setContent] = React.useState<any[]>([]);
  const [contentName, setContentName] = React.useState<string>('');
  const [openFilter, setOpenFilter] = React.useState<boolean>(false);
  const [typeFilter, setTypeFilter] = React.useState<string>('');

  const ordersToRefFileContents: any = useRef();
  ordersToRefFileContents.current = selectedContent;

  const fillContents = () => {
    const editingContents = props.content;
    setContentName(editingContents?.content?.title);
    if (editingContents.length > 0) {
      setSelectedContent(editingContents);
    }
  };

  useEffect(() => {
    if (props.content != null) {
      fillContents();
    }
  }, [props.content]);

  function QuickSearchToolbar() {
    return (
      <Box
        sx={{
          p: 0.5,
          pb: 0,
        }}
      >
        <GridToolbarQuickFilter sx={{ width: 300 }} />
      </Box>
    );
  }

  const contentColumns = [
    {
      headerName: 'Título',
      field: 'title',
      minWidth: 200,
      getApplyQuickFilterFn: getApplyFilterFnTitle,
      flex: 2,
      renderCell: (cellValues: any) => (
        <p style={{ whiteSpace: 'normal' }}>{cellValues?.value}</p>
      ),
    },
    {
      headerName: 'Criado em',
      field: 'created_at',
      minWidth: 150,
      flex: 1,
      renderCell: (cellValues: any) => {
        return (
          cellValues &&
          cellValues?.value.substring(8, 10) +
            '/' +
            cellValues?.value.substring(5, 7) +
            '/' +
            cellValues?.value.substring(0, 4)
        );
      },
    },
    {
      headerName: 'Atualizado em',
      field: 'updated_at',
      minWidth: 150,
      flex: 1,
      renderCell: (cellValues: any) => {
        return (
          cellValues &&
          cellValues?.value.substring(8, 10) +
            '/' +
            cellValues?.value.substring(5, 7) +
            '/' +
            cellValues?.value.substring(0, 4)
        );
      },
    },
    {
      headerName: 'Tipo',
      field: 'type',
      minWidth: 80,
      flex: 1,
      renderCell: (cellValues: any) => (
        <p style={{ whiteSpace: 'normal' }}>
          {contentTypeMapping(cellValues?.value)}
        </p>
      ),
    },

    {
      headerName: 'Selecionar',
      field: '',
      minWidth: 150,
      flex: 1,
      renderCell: (cellValues: any) => {
        return (
          <Button
            variant="contained"
            size="small"
            onClick={() => {
              props.handleCleanEditor();
              setFilterModalOpen(false);
              setContentName(cellValues.row.title);
              props.setValue('content_id', cellValues.row.content_id);
              props.setValue('title', cellValues.row.title);
              props.setValue('description', cellValues.row.resume);
              props.setValue('resume', cellValues.row.resume);
            }}
          >
            Selecionar
          </Button>
        );
      },
    },
  ];

  React.useEffect(() => {
    getContentsListEpisode().then((result: any) => {
      setContent(result.data);
    });
  }, []);

  function getModalStyle() {
    const top = 25;

    return {
      top: `${top}%`,
      margin: 'auto',
      width: '85%',
      height: '95%',
      backgroundColor: 'white',
      marginTop: '1vw',
      overflow: 'hidden',
      overflowY: 'scroll',
    } as React.CSSProperties;
  }

  function handleSearchFilter(type?: string) {
    getContentsListFilter(type).then((el: any) => {
      setContent(el.data);
    });
  }

  function handleFilter(params) {
    getContentsListEpisode(params).then((resp: any) => {
      setContent(resp.data);
    });
  }

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        padding: '22px',
      }}
    >
      <Typography variant="h4" style={{ marginBottom: '16px' }}>
        {props.label ? props.label : 'Conteúdos *'}
      </Typography>
      <Controller
        name="lessons"
        control={props.control}
        rules={{
          required: props.type === 'lesson' && !contentName ? true : false,
        }}
        render={({ field: { onChange, value }, fieldState: { error } }) => {
          return (
            <>
              <styles.InputText
                name="file"
                value={
                  contentName ? contentName : 'Nenhum conteúdo selecionado'
                }
                disabled
                required
                id="outlined-required"
              />

              {error !== undefined &&
              props.type === 'lesson' &&
              !contentName ? (
                <appStyles.inputLabel
                  style={{ color: 'red', marginBottom: '0.5rem' }}
                >
                  *Campo obrigatório
                </appStyles.inputLabel>
              ) : (
                <></>
              )}
            </>
          );
        }}
      />
      <ButtonGroup
        style={{ boxShadow: '0px 0px' }}
        variant="contained"
        size="small"
      >
        <Button
          disabled={props.view}
          onClick={() => {
            setSelectedContent([]);
            setContentName('');
          }}
          variant="contained"
          startIcon={<Delete />}
          color="error"
          style={{
            width: '12em',
            marginTop: '1rem',
            right: '0.1rem',
          }}
        >
          <span>Limpar</span>
        </Button>
        <Button
          disabled={props.view}
          startIcon={<ManageSearch />}
          onClick={() => setFilterModalOpen(true)}
          style={{
            backgroundColor: '#008d4c',
            width: '12em',
            marginTop: '1rem',
            right: '0.1rem',
          }}
        >
          <span>Listar</span>
        </Button>
      </ButtonGroup>
      <Modal
        open={filterModalOpen}
        onClose={() => setFilterModalOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{ alignItems: 'center', justifyContent: 'center' }}
      >
        <Box style={getModalStyle()}>
          <AppBar
            position="static"
            style={{ marginBottom: '1rem' }}
            sx={{ bgcolor: '#1565c0', height: '3.5rem' }}
          >
            <Toolbar>
              <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                Lista Conteúdo
              </Typography>
              <Button onClick={() => setFilterModalOpen(false)} color="inherit">
                x
              </Button>
            </Toolbar>
          </AppBar>
          <div>
            <div>
              <Button
                variant="contained"
                size="small"
                component="span"
                startIcon={<FilterAlt />}
                style={{
                  backgroundColor: '#1565c0',
                  color: '#fff',
                  marginLeft: '0.5rem',
                  marginBottom: '1rem',
                }}
                onClick={() =>
                  openFilter ? setOpenFilter(false) : setOpenFilter(true)
                }
              >
                <span>Filtros</span>
              </Button>
            </div>
            {openFilter && (
              <>
                <span style={{ marginLeft: '0.5rem' }}>Por tipo:</span>
                <Box
                  marginBottom={'0.5rem'}
                  marginLeft={'0.5rem'}
                  sx={{
                    display: 'flex',
                    '& > *': {
                      m: 1,
                    },
                  }}
                >
                  <ButtonGroup
                    size="small"
                    variant="contained"
                    aria-label="outlined primary button group"
                    style={{ boxShadow: '0px 0px 0px' }}
                  >
                    <div>
                      <div>
                        <Button
                          startIcon={<Lyrics />}
                          style={{
                            marginRight: 2,
                            color: '#fff',
                            backgroundColor:
                              typeFilter === 'course' ? '#3630b1' : '#8f8b8b',
                          }}
                          onClick={() => {
                            handleSearchFilter('course');
                            setTypeFilter('course');
                          }}
                        >
                          Curso
                        </Button>
                        <Button
                          startIcon={<Article />}
                          style={{
                            marginRight: 2,
                            color: '#fff',
                            backgroundColor:
                              typeFilter === 'article' ? '#3630b1' : '#8f8b8b',
                          }}
                          onClick={() => {
                            handleSearchFilter('article');
                            setTypeFilter('article');
                          }}
                        >
                          Artigo
                        </Button>
                        <Button
                          startIcon={<FaBook />}
                          style={{
                            marginRight: 2,
                            color: '#fff',
                            backgroundColor:
                              typeFilter === 'ebook' ? '#3630b1' : '#8f8b8b',
                          }}
                          onClick={() => {
                            handleSearchFilter('ebook');
                            setTypeFilter('ebook');
                          }}
                        >
                          Ebook
                        </Button>
                        <Button
                          startIcon={<InsertPhoto />}
                          style={{
                            marginRight: 2,
                            color: '#fff',
                            backgroundColor:
                              typeFilter === 'infographics'
                                ? '#3630b1'
                                : '#8f8b8b',
                          }}
                          onClick={() => {
                            handleSearchFilter('infographics');
                            setTypeFilter('infographics');
                          }}
                        >
                          Infográfico
                        </Button>
                        <Button
                          startIcon={<Videocam />}
                          style={{
                            marginRight: 2,
                            color: '#fff',
                            backgroundColor:
                              typeFilter === 'thematic_kit'
                                ? '#3630b1'
                                : '#8f8b8b',
                          }}
                          onClick={() => {
                            handleSearchFilter('thematic_kit');
                            setTypeFilter('thematic_kit');
                          }}
                        >
                          Kits Temáticos
                        </Button>
                        <Button
                          startIcon={<Videocam />}
                          style={{
                            marginRight: 2,
                            color: '#fff',
                            backgroundColor:
                              typeFilter === 'magazine' ? '#3630b1' : '#8f8b8b',
                          }}
                          onClick={() => {
                            handleSearchFilter('magazine');
                            setTypeFilter('magazine');
                          }}
                        >
                          Revistas
                        </Button>
                      </div>
                      <div style={{ marginTop: '0.2rem' }}>
                        <Button
                          startIcon={<Videocam />}
                          style={{
                            marginRight: 2,
                            color: '#fff',
                            backgroundColor:
                              typeFilter === 'spreadsheet'
                                ? '#3630b1'
                                : '#8f8b8b',
                          }}
                          onClick={() => {
                            handleSearchFilter('spreadsheet');
                            setTypeFilter('spreadsheet');
                          }}
                        >
                          Ferramentas de gestão
                        </Button>
                        <Button
                          startIcon={<Videocam />}
                          style={{
                            marginRight: 2,
                            color: '#fff',
                            backgroundColor:
                              typeFilter === 'podcast' ? '#3630b1' : '#8f8b8b',
                          }}
                          onClick={() => {
                            handleSearchFilter('podcast');
                            setTypeFilter('podcast');
                          }}
                        >
                          Podcast
                        </Button>
                        <Button
                          startIcon={<Videocam />}
                          style={{
                            marginRight: 2,
                            color: '#fff',
                            backgroundColor:
                              typeFilter === 'research' ? '#3630b1' : '#8f8b8b',
                          }}
                          onClick={() => {
                            handleSearchFilter('research');
                            setTypeFilter('research');
                          }}
                        >
                          Pesquisas
                        </Button>
                        <Button
                          startIcon={<Videocam />}
                          style={{
                            marginRight: 2,
                            color: '#fff',
                            backgroundColor:
                              typeFilter === 'video' ? '#3630b1' : '#8f8b8b',
                          }}
                          onClick={() => {
                            handleSearchFilter('video');
                            setTypeFilter('video');
                          }}
                        >
                          Video
                        </Button>
                        <Button
                          startIcon={<BlurLinear />}
                          style={{
                            marginRight: 2,
                            color: '#fff',
                            backgroundColor:
                              typeFilter === 'video' ? '#3630b1' : '#8f8b8b',
                          }}
                          onClick={() => {
                            handleFilter({ status: ['exclusive_series'] });
                          }}
                        >
                          Conteúdo Exclusivo
                        </Button>
                        <Button
                          startIcon={<BlurLinear />}
                          style={{
                            marginRight: 2,
                            color: '#fff',
                            backgroundColor:
                              typeFilter === '' ? '#3630b1' : '#8f8b8b',
                          }}
                          onClick={() => {
                            getContentsListEpisode().then((result: any) => {
                              setContent(result.data);
                            });
                            setTypeFilter('');
                          }}
                        >
                          Todos
                        </Button>
                      </div>
                    </div>
                  </ButtonGroup>
                </Box>
              </>
            )}
          </div>
          <styles.dataGridHeight>
            <styles.dataGridDisplay>
              <styles.dataGridFlexGrow>
                <DataGrid
                  disableColumnMenu
                  initialState={{
                    sorting: {
                      sortModel: [{ field: 'created_at', sort: 'desc' }],
                    },
                  }}
                  localeText={
                    ptBR.components.MuiDataGrid.defaultProps.localeText
                  }
                  getRowId={row => row.content_id}
                  rowHeight={100}
                  aria-labelledby={'content_id'}
                  columns={contentColumns}
                  rows={content}
                  components={{ Toolbar: QuickSearchToolbar }}
                />
              </styles.dataGridFlexGrow>
            </styles.dataGridDisplay>
          </styles.dataGridHeight>
        </Box>
      </Modal>
    </div>
  );
}
