import styled, { css, DefaultTheme } from 'styled-components';

type ContainerProps = {
  cursor?: 'pointer' | 'auto';
};

export const Wrapper = styled.div`
  ${({ theme }) => css`
    align-items: stretch;
    display: flex;
    flex-direction: column;
    gap: 1rem;
  `}
`;

export const InvalidFeedback = styled.span`
  ${({ theme }) => css`
    color: ${theme.colors.red_400};
    font-size: 0.75rem;
    margin-top: -0.5rem;
    text-align: center;
  `}
`;

export const Container = styled.div<ContainerProps>`
  ${({ theme, cursor }) => css`
    background-color: rgb(246, 246, 249);
    border-radius: ${theme.border.radius};
    flex: 1;
    max-height: 40rem;
    max-width: 100%;
    min-height: 30rem;
    min-width: 20rem;
    overflow: hidden;
    position: relative;
  `}
`;

export const Editor = styled.div`
  ${({ theme }) => css`
    align-items: center;
    border: 1px solid hsl(0, 0%, 80%);
    display: flex;
    height: 40rem;
    max-height: 100vh;

    .preview {
      height: 100%;
      width: 100%;
    }
  `}
`;

export const CropperMain = styled.div`
  ${({ theme }) => css`
    flex: 2;
    height: 100%;
    max-height: 100vh;
  `}
`;

export const CropperNavMain = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    justify-content: center;
    position: absolute;
    width: max-content;
    z-index: ${theme.layers.menu};

    top: calc(4rem + 0px);
    bottom: calc(4rem + 0px);
    left: 1rem;
  `}
`;

export const CroppersWizardSettings = styled.div`
  ${({ theme }) => css`
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 0.875rem;
    width: 100%;

    & > label {
      color: rgba(0, 0, 0, 0.8);
      font-size: ${theme.font.sizes.small};
    }
  `}
`;

type SettingIconProps = {
  isSelected?: boolean;
};

export const SettingIcon = styled.button<SettingIconProps>`
  ${({ theme }) => css`
    align-items: center;
    background-color: transparent;
    border: none;
    cursor: pointer;
    display: flex;
    fill: ${theme.colors.neutral_800};
    height: 100%;
    outline: 0;
    padding: 5px 15px;
    stroke: ${theme.colors.neutral_800};
    transition: fill 0.5s, stroke 0.5s;
  `}
`;

export const InputDimension = styled.div`
  ${({ theme }) => css`
    align-items: center;
    background-color: rgba(0, 0, 0, 0.075);
    border: 1px solid rgba(0, 0, 0, 0.8);
    border-radius: ${theme.border.radius};
    display: inline-flex;
    max-width: 5rem;
    position: relative;
    width: 100%;

    & > input {
      background-color: transparent;
      border: 0;
      color: rgba(0, 0, 0, 0.9);
      font-size: ${theme.font.sizes.xxsmall};
      font-variant-numeric: tabular-nums;
      padding: 0.4rem 0 0.4rem 0.4rem;
      outline: 0;
      width: 100%;
    }

    & > input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
    }

    & > span {
      color: rgba(0, 0, 0, 0.9);
      font-size: ${theme.font.sizes.xxsmall};
      padding-right: 0.625rem;
    }
  `}
`;

export const CropperFooter = styled.div`
  ${({ theme }) => css`
    align-items: center;
    background-color: transparent;
    display: flex;
    justify-content: center;
    height: 5rem;
    padding: 1rem;
    position: absolute;

    left: 0;
    right: 0;
    bottom: 0;
  `}
`;

export const CropperToolBar = styled.div`
  ${({ theme }) => css`
    align-items: center;
    background-color: transparent;
    display: flex;
    justify-content: space-between;
    padding: 1rem;
    position: absolute;

    left: 0;
    right: 0;
    top: 0;
  `}
`;

type ButtonProps = {
  isSelected?: boolean;
  variant: 'setting' | 'done' | 'round';
};

const buttonModifiers = {
  setting: (theme: DefaultTheme, isSelected) => css`
    background-color: ${isSelected ? 'rgba(0, 0, 0, 0.075)' : 'transparent'};
    box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.075);
    border-radius: 0.625rem;
    border: none;
    gap: 0.5rem;
    height: 4rem;
    width: 4rem;
  `,
  done: (theme: DefaultTheme) => css`
    background-color: ${theme.colors.yellow_400};
    border: none;
    border-radius: 9999rem;
    padding: 0.5rem 0.75rem;
  `,
  round: () => css`
    background-color: rgba(0, 0, 0, 0.075);
    box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.075);
    border: none;
    border-radius: 9999rem;
    padding: 0.5rem 0.75rem;
    height: 2rem;
    width: 2rem;
  `,
};

export const Button = styled.button<ButtonProps>`
  ${({ theme, isSelected, variant }) => css`
    align-items: center;
    color: rgba(0, 0, 0, 0.9);
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;

    & > svg {
      color: rgba(0, 0, 0, 0.8);
      font-size: 1rem;
    }

    & > span {
      font-size: ${theme.font.sizes.xxsmall};
    }

    ${!!variant && buttonModifiers[variant](theme, isSelected)}
  `}
`;

export const Toolbar = styled.div`
  bottom: 24px;
  display: flex;
  justify-content: center;
  gap: 4px;
  position: absolute;
  width: 100%;
`;
