import { Button } from '@mui/material';
import Card from '@mui/material/Card';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import styled from 'styled-components';

export const ContentContainer = styled.div`
  margin-bottom: 25px;

  & > :not(:first-child) {
    &::before {
      content: '/';
      margin: 0 3px;
    }
  }
  a,
  span {
    font-size: var(--font-small);
    color: var(--primary-color);
  }
`;

export const select = styled(Select)`
  outline: none;
  font-size: var(--font-small);
  width: 100%;
  height: 44px;
  color: var(--default-gray);
  margin-top: 8px;
  line-height: 10px;
  div {
    min-height: 6px !important;

    div {
      padding-top: 5px;
    }
  }
`;

export const DefaultInput = styled.input`
  border-radius: 5px;
  border: solid 1px var(--default-dark-gray);
  outline: none;
  font-size: var(--font-small);
  width: 100%;
  height: 44px;
  padding: 0 10px;
  color: var(--default-gray);
  margin-top: 8px;
`;
export const ContainerData = styled.div`
display: flex;
place-content: space-between;
`
export const CreateAndEditContentContainer = styled.div`
  padding: 22px;

  .extra-materials {
    display: flex;

    background: var(--primary-color);
    color: white;
    outline: none;
    border: none;
    cursor: pointer;
    padding: 15px 20px;
    font-size: var(--font-small);
    font-weight: bold;
    border-radius: 5px;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.08),
      0px 4px 6px rgba(50, 50, 93, 0.1);
  }
`;

export const CardInputContent = styled(Card)`
  margin: 2rem;
  width: '100%';
  max-width: '100%';
  height: 2.4rem;
`;

export const CardInput = styled(Card)`
  margin: 2rem;
  width: '100%';
  max-width: '100%';
`;

export const ContentInput = styled.div`
  width: 100%;
`;

export const Label = styled.label`
  display: inline-block;
  max-width: 100%;

  font-weight: 700;
  font-size: 14px;
  line-height: 1;
`;

export const LabelTitulo = styled.label`
  display: inline-block;
  font-size: 16px;
  margin: 0;
  line-height: 1;
  padding: 10px;
`;

export const InputText = styled(TextField)`
  line-height: 1.42857143;
  color: #333;
  font-size: 5px;

  .MuiInputBase-input {
    padding: 16px 14px !important;
  }
  
  fieldset {
    height: 38px;
    border-color: #d2d6de;
    top: 7px;
  }
`;

export const SelectorSelect = styled(Select)`
  height: 38px;
  border-color: #d2d6de;
  top: 7px;
  right: 5px;
`;

export const ContentSeletor = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 22px;
  .MuiInputLabel-root{
    top: -6px !important;
  }
`;

export const MenuItemSelector = styled(MenuItem)`
  font-size: 14px;
`;

export const ButtonAddNew = styled(Button)`
  display: flex;
  margin-top: 8px;
  left: 5px;
  padding: 1px 20px;
`;
